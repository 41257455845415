export const PRODUCT_DETAILS_BACK_LABEL = "PRODUCT_DETAILS_BACK_LABEL";

export const PRODUCT_DETAILS_TAB_TITLE_OVERVIEW = "PRODUCT_DETAILS_TAB_TITLE_OVERVIEW";

export const PRODUCT_DETAILS_DESCRIPTION = "PRODUCT_DETAILS_DESCRIPTION";

export const PRODUCT_DETAILS_TERMS = "PRODUCT_DETAILS_TERMS";

export const PRODUCT_DETAILS_TAB_TITLE_REFERENCE_INFORMATION = "PRODUCT_DETAILS_TAB_TITLE_REFERENCE_INFORMATION";

export const PRODUCT_DETAILS_TAB_TITLE_UNDERLYING_INTEREST = "PRODUCT_DETAILS_TAB_TITLE_UNDERLYING_INTEREST";

export const PRODUCT_DETAILS_TAB_TITLE_PERFORMANCE_ANALYSIS = "PRODUCT_DETAILS_TAB_TITLE_PERFORMANCE_ANALYSIS";

export const PRODUCT_DETAILS_TAB_TITLE_NOTE_EVENTS = "PRODUCT_DETAILS_TAB_TITLE_NOTE_EVENTS";

export const PRODUCT_DETAILS_TAB_TITLE_DOCUMENTS = "PRODUCT_DETAILS_TAB_TITLE_DOCUMENTS";

export const PRODUCT_DETAILS_TAB_TITLE_ADDITIONAL_PERFORMANCE_DATA = "PRODUCT_DETAILS_TAB_TITLE_ADDITIONAL_PERFORMANCE_DATA";

export const PRODUCT_DETAILS_ICON_PRINT_LABEL = "PRODUCT_DETAILS_ICON_PRINT_LABEL";

export const PRODUCT_DETAILS_ICON_EMAIL_LABEL = "PRODUCT_DETAILS_ICON_EMAIL_LABEL";


export const PRODUCT_DETAILS_OVERVIEW_CASH_FLOW_LABEL = "PRODUCT_DETAILS_OVERVIEW_CASH_FLOW_LABEL";

export const PRODUCT_DETAILS_OVERVIEW_CALL_FEATURE_LABEL = "PRODUCT_DETAILS_OVERVIEW_CALL_FEATURE_LABEL";

export const PRODUCT_DETAILS_OVERVIEW_BARRIER_LEVEL_LABEL = "PRODUCT_DETAILS_OVERVIEW_BARRIER_LEVEL_LABEL";

export const PRODUCT_DETAILS_OVERVIEW_CANADIAN_BANKING_INDUSTRY_EXPOSURE_LABEL = "PRODUCT_DETAILS_OVERVIEW_CANADIAN_BANKING_INDUSTRY_EXPOSURE_LABEL";

export const PRODUCT_DETAILS_OVERVIEW_ICON_EMAIL_DOCUMENTS_TEXT = "PRODUCT_DETAILS_OVERVIEW_ICON_EMAIL_DOCUMENTS_TEXT";

export const PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_TITLE = "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_TITLE";

export const DOLLAR_SIGN = "$";
export const NOT_APPLICABLE = "N/A";

export const CURRENT_STATUSES = {
  New: "New",
  Called: "Called",
  Matured: "Matured",
  ClosingSoon: "Closing Soon",
  Closed: "Closed",
  Active: "Active"
}

export const STATUS_TYPE = {
  New: "NOTE_STATUS_NEW",
  Called: "NOTE_STATUS_CALLED",
  Matured: "NOTE_STATUS_MATURED",
  ClosingSoon: "NOTE_STATUS_CLOSING_SOON",
  Closed: "NOTE_STATUS_CLOSED",
  Active: "NOTE_STATUS_ACTIVE",
}

export const INVESTOR_INFORMATION_LABEL = "INVESTOR_INFORMATION_LABEL";
export const CANADIAN_STRUCTURED_NOTE_PROGRAM_LABEL = "CANADIAN_STRUCTURED_NOTE_PROGRAM_LABEL";

export const PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS = {
  w360: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_W360_LABEL",
  ism: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_ISM_LABEL",
  cusip: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_CUSIP_LABEL",
  fundCode: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_FUND_CODE_LABEL",
  availableUntil: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_MARKETING_END_DATE_LABEL",
  issueDate: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_ISSUE_DATE_LABEL",
  maturityDate: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_MATURITY_DATE_LABEL",
  termInYears: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_TERMS_IN_YEARS_LABEL",
  issuer: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_ISSUER_LABEL",
  ratings: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_RATINGS_LABEL",
  extensionFrequency: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_EXTENSION_FREQUENCY_LABEL",
  bailInEligibleNote: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_BAIL_IN_ELIGIBLE_NOTE_LABEL",
  currentStatus: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_CURRENT_STATUS_LABEL",
  currency: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_CURRENCY_LABEL",
  productClass: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_PRODUCT_CLASS_LABEL",
  couponType: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_COUPON_TYPE_LABEL",
  couponRate: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_COUPON_RATE_LABEL",
  couponFrequency: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_COUPON_FREQUENCY_LABEL",
  spread: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_SPREAD_LABEL",
  minimumCouponRate: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_MINIMUM_COUPON_RATE_LABEL",
  maximumCouponRate: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_MAXIMUM_COUPON_RATE_LABEL",
  salesCommission: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_SALES_COMMISSION_LABEL",
  productType: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_PRODUCT_TYPE_LABEL",
  productCategory: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_PRODUCT_CATEGORY_LABEL",
  paymentFrequency: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_PAYMENT_FREQUENCY_LABEL",
  paymentRateAnnualised: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_PAYMENT_RATE_ANNUALIZED",
  bidPrice: "PRODUCT_DETAILS_REFERENCE_INFORMATION_CURRENT_BID_PRICE_LABEL",
  currentETF: "PRODUCT_DETAILS_REFERENCE_INFORMATION_BPI_CURRENT_ETF_LABEL",
  underlyingInterestType: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_UNDERLYING_INTEREST_TYPE_LABEL",
  underlier: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_UNDERLIER_LABEL",
  closingBidPrice: "PRODUCT_DETAILS_REFERENCE_INFORMATION_CLOSING_BID_PRICE_LABEL",
};

export const PRODUCT_DETAILS_HEADER_CLOSING_BID_PRICE_LABEL = "PRODUCT_DETAILS_HEADER_CLOSING_BID_PRICE_LABEL";
export const SALES_COMMISSION_VALUE= "Nil";
export const SALES_COMMISSION_VALUE_FR= "Néant";

export const PRODUCT_DETAILS_IRN_KEY_TERMS_LABELS = {
  termInYears: "PRODUCT_DETAILS_IRN_KEY_TERMS_IN_YEARS_LABEL",
  accountEligibility: "PRODUCT_DETAILS_IRN_KEY_TERMS_ACCT_ELIGIBILITY_LABEL",
}

export const PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_TITLE_LABEL = "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_TITLE_LABEL";

export const PRODUCT_DETAILS_REFERENCE_INFORMATION_BID_PRICE_INFORMATION_TITLE_LABEL = "PRODUCT_DETAILS_REFERENCE_INFORMATION_BID_PRICE_INFORMATION_TITLE_LABEL";

export const PRODUCT_DETAILS_REFERENCE_INFORMATION_EARLY_TRADING_FEE_SCHEDULE_TITLE_LABEL = "PRODUCT_DETAILS_REFERENCE_INFORMATION_EARLY_TRADING_FEE_SCHEDULE_TITLE_LABEL";

export const PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS = {
  barrierLevel: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_BARRIER_LEVEL_LABEL",
  bufferLevel: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_BUFFER_LEVEL_LABEL",
  paymentThreshold: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_PAYMENT_THRESHOLD_LABEL",
  paymentRate: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_PAYMENT_RATE_LABEL",
  structureType: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_STRUCTURE_TYPE_LABEL",
  assetSector: "PRODUCT_DETAILS_OVERVIEW_SECTOR",
  assetGeography: "PRODUCT_DETAILS_OVERVIEW_GEOGRAPHY",
  investmentObjective: "PRODUCT_DETAILS_INVESTMENT_OBJECTIVE",
  couponPaymentFrequency: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_COUPON_PAYMENT_FREQUENCY_LABEL",
  autocallThreshold: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_AUTOCALL_THRESHOLD_LABEL",
  fixedReturns: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_FIXED_RETURNS_LABEL",
  fixedShareReturn: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_FIXED_SHARE_RETURN_LABEL",
  boostLevel: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_BOOST_LEVEL_LABEL",
  boostedReturn: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_BOOSTED_RETURN_LABEL",
  participationRate: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_PARTICIPATION_RATE_LABEL",
  callFrequency: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_CALL_FREQUENCY_LABEL",
  nonCallPeriod: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_NON_CALL_PERIOD",
  variableParticipation: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_VARIABLE_PARTICIPATION",
  autoCallLevel: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_AUTO_CALL_LEVEL",
  variableAutoCall: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_VARIABLE_AUTO_CALL",
  variableCouponThreshold: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_VARIABLE_COUPON_THRESHOLD_LABEL",
  couponParticipation: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_MEMORY_COUPON_PARTICIPATION",
  minimumVariableCouponRate: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_MINIMUM_VARIABLE_COUPON_RATE_LABEL",
  minimumVariableCouponPeriod: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_MINIMUM_VARIABLE_COUPON_PERIOD_LABEL",
  downsideProtectionStrike: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_DOWNSIDE_PROTECTION_STRIKE_LABEL",
  downsideMultiplier: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_DOWNSIDE_MULTIPLIER_LABEL",
  floorLevel: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_FLOOR_LEVEL_LABEL",
  participationLevel: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_PARTICIPATION_LEVEL_LABEL",
  participationCap: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_PARTICIPATION_CAP_LABEL",
  excessReturnStrike: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_EXCESS_RETURN_STRIKE_LABEL",
  knockoutReturn: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_KNOCKOUT_RETURN_LABEL",
  knockoutLevel: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_KNOCKOUT_LEVEL_LABEL",
  maximumNoteReturn: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_MAX_NOTE_RETURN_LABEL",
  minimumShareReturn: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_MIN_SHARE_RETURN_LABEL",
  maximumShareReturn: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_MAX_SHARE_RETURN_LABEL",
  maximumTerm: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_MAX_TERM_YEARS_LABEL",
  referenceRate: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_REFERENCE_RATE_LABEL",
  rangeAccrual: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_RANGE_ACCRUAL_LABEL",
  memory: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_MEMORY",
};

export const PRODUCT_DETAILS_REFERENCE_INFORMATION_BID_PRICE_INFORMATION_LABELS = {
  closingBidPrice: "PRODUCT_DETAILS_REFERENCE_INFORMATION_BPI_CLOSING_BID_PRICE_LABEL",
  currentEtf: "PRODUCT_DETAILS_REFERENCE_INFORMATION_BPI_CURRENT_ETF_LABEL",
  bidPriceLessETF: "PRODUCT_DETAILS_REFERENCE_INFORMATION_BPI_BID_PRICE_ETF_LABEL",
  etfEndDate: "PRODUCT_DETAILS_REFERENCE_INFORMATION_BPI_ETF_END_DATE_LABEL",
};

export const PRODUCT_DETAILS_REFERENCE_INFORMATION_EARLY_TRADING_FEE_SCHEDULE_COLUMNS = [
  { title: "PRODUCT_DETAILS_REFERENCE_INFORMATION_ETFS_FROM_LABEL", field: "from", sortable: true, className: "from", translate: true },
  { title: "PRODUCT_DETAILS_REFERENCE_INFORMATION_ETFS_UNTIL_LABEL", field: "until", sortable: true, className: "until", translate: true },
  { title: "PRODUCT_DETAILS_REFERENCE_INFORMATION_ETFS_ETF_PER_NOTE_LABEL", field: "etfPerNote", sortable: true, className: "etfPerNote", translate: true },
];

export const PRODUCT_DETAILS_UNDERLYING_INTEREST_NOTE_NOTIFICATIONS_LABEL = "PRODUCT_DETAILS_UNDERLYING_INTEREST_NOTE_NOTIFICATIONS_LABEL";

export const PRODUCT_DETAILS_UNDERLYING_INTEREST_AGGREGATE_PERFORMANCE_LABEL = "PRODUCT_DETAILS_UNDERLYING_INTEREST_AGGREGATE_PERFORMANCE_LABEL";

export const PRODUCT_DETAILS_UNDERLYING_INTEREST_NOTE_NOTIFICATION_COLUMNS = [
  { title: "PRODUCT_DETAILS_UNDERLYING_INTEREST_NOTE_NOTIFICATION_DATE_LABEL", field: "dateOfEvent", className: "dateOfEvent", translate: true, isDate: true },
  { title: "PRODUCT_DETAILS_UNDERLYING_INTEREST_NOTE_NOTIFICATION_HEADLINE_LABEL", field: "headline", clickable: true, className: "headline", translate: true },
];

export const PRODUCT_DETAILS_NOTE_EVENTS_BARRIER_DETAILS_LABEL = "PRODUCT_DETAILS_NOTE_EVENTS_BARRIER_DETAILS_LABEL";

export const PRODUCT_DETAILS_NOTE_EVENTS_ROC_DETAILS_LABEL = "PRODUCT_DETAILS_NOTE_EVENTS_ROC_DETAILS_LABEL";

export const PRODUCT_DETAILS_NOTE_EVENTS_COUPON_DETAILS_LABEL = "PRODUCT_DETAILS_NOTE_EVENTS_COUPON_DETAILS_LABEL";

export const PRODUCT_DETAILS_NOTE_EVENTS_MATURITY_REDEMPTION_PAYMENT_DETAILS_LABEL = "PRODUCT_DETAILS_NOTE_EVENTS_MATURITY_REDEMPTION_PAYMENT_DETAILS_LABEL";
export const PRODUCT_DETAILS_NOTE_EVENTS_MATURITY_AVERAGING_DATE_DETAILS_LABEL = "PRODUCT_DETAILS_NOTE_EVENTS_MATURITY_AVERAGING_DATE_DETAILS_LABEL";

export const PRODUCT_DETAILS_NOTE_EVENTS_BARRIER_DETAILS_COLUMNS = [
  { title: "PRODUCT_DETAILS_NOTE_EVENTS_BARRIER_DETAILS_AUTO_CALL_DATE", field: "autoCallDate", className: "autoCallDate", translate: true, isDate: true },
  { title: "PRODUCT_DETAILS_NOTE_EVENTS_BARRIER_DETAILS_VALUATION_DATE", field: "valuationDate", className: "valuationDate", translate: true, isDate: true },
  { title: "PRODUCT_DETAILS_NOTE_EVENTS_BARRIER_DETAILS_AUTO_CALL_LEVEL", field: "autoCallLevel", className: "autoCallLevel", translate: true },
  { title: "PRODUCT_DETAILS_NOTE_EVENTS_BARRIER_DETAILS_FIXED_RETURN", field: "fixedReturn", className: "fixedReturn", translate: true },
  { title: "PRODUCT_DETAILS_NOTE_EVENTS_BARRIER_DETAILS_CLOSING_BASKET_LEVEL", field: "closingLevel", className: "closingLevel", translate: true },
  { title: "PRODUCT_DETAILS_NOTE_EVENTS_BARRIER_DETAILS_AUTO_CALL_FEATURE", field: "autoCallFeature", className: "autoCallFeature", translate: true },
  { title: "PRODUCT_DETAILS_NOTE_EVENTS_BARRIER_DETAILS_BASKET_RETURN", field: "underlyingInterestReturn", className: "underlyingInterestReturn", translate: true },
  { title: "PRODUCT_DETAILS_NOTE_EVENTS_BARRIER_DETAILS_MATURITY", field: "maturity", className: "maturity", translate: true }
];

export const PRODUCT_DETAILS_NOTE_EVENTS_COUPON_DETAILS_COLUMNS = [
  { title: "PRODUCT_DETAILS_NOTE_EVENTS_COUPON_DETAILS_COUPON_PAY_DATES", field: "couponPayDates", className: "couponPayDates", translate: true, isDate: true },
  { title: "PRODUCT_DETAILS_NOTE_EVENTS_COUPON_DETAILS_VALUATION_DATES", field: "valuationDate", className: "valuationDate", translate: true, isDate: true },
  { title: "PRODUCT_DETAILS_NOTE_EVENTS_COUPON_DETAILS_PAY_THRESHOLD", field: "payThreshold", className: "payThreshold", translate: true },
  { title: "PRODUCT_DETAILS_NOTE_EVENTS_COUPON_DETAILS_CLOSING_LEVEL", field: "closingLevel", className: "closingLevel", translate: true },
  { title: "PRODUCT_DETAILS_NOTE_EVENTS_COUPON_DETAILS_COUPON_PAID", field: "couponPaid", className: "couponPaid", translate: true },
  { title: "PRODUCT_DETAILS_NOTE_EVENTS_COUPON_DETAILS_BASKET_RETURN", field: "underlyingInterestReturn", className: "underlyingInterestReturn", translate: true },
  { title: "PRODUCT_DETAILS_NOTE_EVENTS_COUPON_DETAILS_COUPON", field: "coupon", className: "coupon", translate: true },
];

export const PRODUCT_DETAILS_NOTE_EVENTS_ROC_DETAILS_COLUMNS = [
  { title: "PRODUCT_DETAILS_NOTE_EVENTS_COUPON_DETAILS_ROC_DATES", field: "couponPayDates", className: "couponPayDates", translate: true, isDate: true },
  { title: "PRODUCT_DETAILS_NOTE_EVENTS_COUPON_DETAILS_VALUATION_DATES", field: "valuationDate", className: "valuationDate", translate: true, isDate: true },
  { title: "PRODUCT_DETAILS_NOTE_EVENTS_COUPON_DETAILS_PAY_THRESHOLD", field: "payThreshold", className: "payThreshold", translate: true },
  { title: "PRODUCT_DETAILS_NOTE_EVENTS_COUPON_DETAILS_CLOSING_LEVEL", field: "closingLevel", className: "closingLevel", translate: true },
  { title: "PRODUCT_DETAILS_NOTE_EVENTS_COUPON_DETAILS_BASKET_RETURN", field: "underlyingInterestReturn", className: "underlyingInterestReturn", translate: true },
  { title: "PRODUCT_DETAILS_NOTE_EVENTS_COUPON_DETAILS_PAYMENT", field: "payment", className: "payment", translate: true },
  { title: "PRODUCT_DETAILS_NOTE_EVENTS_COUPON_DETAILS_ROC", field: "roc", className: "roc", translate: true },
  { title: "PRODUCT_DETAILS_NOTE_EVENTS_COUPON_DETAILS_ADJUSTED_COST_BASE", field: "adjustedCostBase", className: "adjustedCostBase", translate: true },
];

export const PRODUCT_DETAILS_NOTE_EVENTS_MATURITY_REDEMPTION_PAYMENT_DETAILS_COLUMNS = [
  { title: "PRODUCT_DETAILS_NOTE_EVENTS_REDEMPTION_PAYMENT_DETAILS_MATURITY_DATES", field: "maturityDate", className: "maturityDate", translate: true, isDate: true },
  { title: "PRODUCT_DETAILS_NOTE_EVENTS_REDEMPTION_PAYMENT_DETAILS_FINAL_VALIDATE_DATES", field: "finalValDate", className: "finalValDate", translate: true, isDate: true },
  { title: "PRODUCT_DETAILS_NOTE_EVENTS_REDEMPTION_PAYMENT_DETAILS_OPENING_LEVEL", field: "openingLevel", className: "openingLevel", translate: true },
  { title: "PRODUCT_DETAILS_NOTE_EVENTS_REDEMPTION_PAYMENT_DETAILS_CLOSING_LEVEL", field: "closingLevel", className: "closingLevel", translate: true },
  { title: "PRODUCT_DETAILS_NOTE_EVENTS_REDEMPTION_PAYMENT_DETAILS_BASKET_RETURN", field: "underlyingInterestReturn", className: "underlyingInterestReturn", translate: true },
  //{ title: "PRODUCT_DETAILS_NOTE_EVENTS_REDEMPTION_PAYMENT_DETAILS_BARRIER_BUFFER_FLOOR_LEVEL", field: "barrierBufferOrFloorLevel", className: "barrierBufferOrFloorLevel", translate: true },
  //{ title: "PRODUCT_DETAILS_NOTE_EVENTS_REDEMPTION_PAYMENT_DETAILS_DOWNSIDE_MULTIPLIER", field: "downsideMultiplier", className: "downsideMultiplier", translate: true },
  { title: "PRODUCT_DETAILS_NOTE_EVENTS_REDEMPTION_DETAILS_MATURITY_PAYMENT", field: "maturiyRedemptionPayment", className: "maturiyRedemptionPayment", translate: true },
];

export const PRODUCT_DETAILS_NOTE_EVENTS_REDEMPTION_PAYMENT_DETAILS_BARRIER_LEVEL = "PRODUCT_DETAILS_NOTE_EVENTS_REDEMPTION_PAYMENT_DETAILS_BARRIER_LEVEL";
export const PRODUCT_DETAILS_NOTE_EVENTS_REDEMPTION_PAYMENT_DETAILS_BUFFER_LEVEL = "PRODUCT_DETAILS_NOTE_EVENTS_REDEMPTION_PAYMENT_DETAILS_BUFFER_LEVEL";
export const PRODUCT_DETAILS_NOTE_EVENTS_REDEMPTION_PAYMENT_DETAILS_FLOOR_LEVEL = "PRODUCT_DETAILS_NOTE_EVENTS_REDEMPTION_PAYMENT_DETAILS_FLOOR_LEVEL"

export const PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_HISTORICAL_PERFORMANCE_TITLE_LABEL = "PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_HISTORICAL_PERFORMANCE_TITLE_LABEL";

export const PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_CURRENT_PERFORMANCE_TITLE_LABEL = "PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_CURRENT_PERFORMANCE_TITLE_LABEL";

export const PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_BID_PRICE_TITLE_LABEL = "PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_BID_PRICE_TITLE_LABEL";

export const PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_DODPC_TITLE_LABEL = "PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_DODPC_TITLE_LABEL";

export const PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_HISTORICAL_PRICE_TABLE_LABEL = "PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_HISTORICAL_PRICE_TABLE_LABEL";

export const PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_ETF_PRICE_TABLE_LABEL = "PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_ETF_PRICE_TABLE_LABEL";

export const PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_CURRENT_PERFORMANCE_INDICATIVE_LABELS = {
  indicativeUnderlyingInterestReturn: "PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_CURRENT_PERFORMANCE_IBR_INDICATIVE_LABEL",
  indicativeUpcomingCoupon: "PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_CURRENT_PERFORMANCE_IUC_INDICATIVE_LABEL",
  indicativeUpcomingPartialRepayment: "PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_CURRENT_PERFORMANCE_IUPR_INDICATIVE_LABEL",
  indicativeMaturityRedemptionAmount: "PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_CURRENT_PERFORMANCE_IMRA_INDICATIVE_LABEL",
  indicativeVariableReturn: "PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_CURRENT_PERFORMANCE_VR_INDICATIVE_LABEL",
}; 

export const PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_CURRENT_PERFORMANCE_LABELS = {
  indicativeUnderlyingInterestReturn: "PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_CURRENT_PERFORMANCE_IBR_LABEL",
  indicativeUpcomingCoupon: "PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_CURRENT_PERFORMANCE_IUC_LABEL",
  indicativeUpcomingPartialRepayment: "PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_CURRENT_PERFORMANCE_IUPR_LABEL",
  indicativeMaturityRedemptionAmount: "PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_CURRENT_PERFORMANCE_IMRA_LABEL",
  indicativeVariableReturn: "PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_CURRENT_PERFORMANCE_VR_LABEL",
};

export const PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_BID_PRICE_LABELS = {
  closingBidPrice: "PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_BID_PRICE_CBP_LABEL",
  currentETF: "PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_BID_PRICE_CETF_LABEL",
  bidPriceLessETF: "PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_BID_PRICE_LETF_LABEL",
  ETFEndDate: "PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_BID_PRICE_ETFE_LABEL",
  yearHigh: "PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_BID_PRICE_WEEK_HIGH_LABEL",
  yearLow: "PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_BID_PRICE_WEEK_LOW_LABEL",
};

export const PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_BID_PRICE_LABELS_WHEN_FEE_FALSE = {
  closingBidPrice: "PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_BID_PRICE_CBP_LABEL",
  yearHigh: "PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_BID_PRICE_WEEK_HIGH_LABEL",
  yearLow: "PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_BID_PRICE_WEEK_LOW_LABEL",
};

export const PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_DAY_OVER_DAY_PRICE_CHANGE_LABELS = {
  weekHigh: "PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_DODPC_WEEK_HIGH_LABEL",
  weekLow: "PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_DODPC_WEEK_LOW_LABEL",
};

export const PRODUCT_DETAILS_PERFORMANCE_LAST_WEEK_KEY = "lastWeek";
export const PRODUCT_DETAILS_PERFORMANCE_MONTH_KEY = "month";
export const PRODUCT_DETAILS_PERFORMANCE_SIX_MONTH_KEY = "6month";
export const PRODUCT_DETAILS_PERFORMANCE_YEAR_KEY = "year";
export const PRODUCT_DETAILS_PERFORMANCE_SINCE_ISSUE_DATE_KEY = "sinceIssueDate";
//export const PRODUCT_DETAILS_PERFORMANCE_VALUATION_DATES_KEY = "valuationDates";

export const PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_HISTORICAL_PERFORMANCE_FILTER_OPTIONS = [
  { id: PRODUCT_DETAILS_PERFORMANCE_LAST_WEEK_KEY, label: "PRODUCT_DETAILS_PERFORMANCE_LAST_WEEK_LABEL", translate: true },
  { id: PRODUCT_DETAILS_PERFORMANCE_MONTH_KEY, label: "PRODUCT_DETAILS_PERFORMANCE_MONTH_LABEL", translate: true },
  { id: PRODUCT_DETAILS_PERFORMANCE_SIX_MONTH_KEY, label: "PRODUCT_DETAILS_PERFORMANCE_SIX_MONTH_LABEL", translate: true },
  { id: PRODUCT_DETAILS_PERFORMANCE_YEAR_KEY, label: "PRODUCT_DETAILS_PERFORMANCE_YEAR_LABEL", translate: true },
  { id: PRODUCT_DETAILS_PERFORMANCE_SINCE_ISSUE_DATE_KEY, label: "PRODUCT_DETAILS_PERFORMANCE_SINCE_ISSUE_DATE_LABEL", translate: true },
  //{ id: PRODUCT_DETAILS_PERFORMANCE_VALUATION_DATES_KEY, label: "PRODUCT_DETAILS_PERFORMANCE_VALUATION_DATES_LABEL", translate: true },
];

export const PRODUCT_DETAIL_PERFORMANCE_ANALYSIS_DISCLAIMER = "*Valuation is as of the close of business on the date indicated. Actual Bid Price applicable to a "+
"sale in the secondary market will be determined at the time of the sale and may be different than "+
"the value indicated above. ";

export const PRODUCT_DETAIL_PERFORMANCE_ANALYSIS_DISCLAIMER_FR = "*Évaluation à la clôture des marchés à la date indiquée. Le cours acheteur réel applicable à une "+
"vente sur le marché secondaire sera déterminé au moment de la vente et peut différer de la valeur indiquée ci-dessus.";

export const PRODUCT_DETAIL_PERFORMANCE_ANALYSIS_UNDERLYING_DISCLAIMER = "** The Indicative Maturity Redemption Amount assumes the current level of the Underlying "+
"Interest does not change and can include any coupons or other payments, fixed or variable, payable at maturity.";

export const PRODUCT_DETAIL_PERFORMANCE_ANALYSIS_UNDERLYING_DISCLAIMER_FR = "** Le montant indicatif de remboursement à l'échéance suppose que le niveau actuel du "+
"sous-jacent demeure inchangé et pourrait inclure des versements de coupons ou autres paiements, fixes ou variable, à l'échéance.";

export const PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_HISTORICAL_PRICE_TABLE_EXPORT_LABEL = "PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_HISTORICAL_PRICE_TABLE_EXPORT_LABEL";

export const PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_HISTORICAL_PRICE_TABLE_COLUMNS = [
  { title: "PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_HISTORICAL_PRICE_TABLE_DATE", field: "date", className: "date", translate: true },
  { title: "PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_HISTORICAL_PRICE_TABLE_PRICE", field: "price", className: "price", translate: true },
];

export const PRODUCT_TYPE = { 
  indexLinked: "Index-Linked",
  fundLinked: "Fund-Linked",
  equityLinked: "Equity-Linked",
  multiAsset: "Multi-Asset" 
};

export const PRINT_ALL = "PRINT_ALL";
export const PRINT_HEADER = "PRINT_HEADER";
export const PRINT_ALERT = "PRINT_ALERT";
export const PRINT = "PRINT";
export const NOTE_OPTIONS = [PRODUCT_DETAILS_TAB_TITLE_OVERVIEW, 
  PRODUCT_DETAILS_TAB_TITLE_UNDERLYING_INTEREST, 
  PRODUCT_DETAILS_TAB_TITLE_NOTE_EVENTS, 
  PRODUCT_DETAILS_TAB_TITLE_PERFORMANCE_ANALYSIS];

export const NOTE_OPTIONS_WITH_HISTORICAL_NOTE = [PRODUCT_DETAILS_TAB_TITLE_OVERVIEW, 
  PRODUCT_DETAILS_TAB_TITLE_ADDITIONAL_PERFORMANCE_DATA];

export const AS_OF = "AS_OF";

export const PRODUCT_DETAILS_HISTORICAL_PRICE_TABLE_PAGE_SIZE = 8;