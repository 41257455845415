import React, { useState, useEffect } from "react";
import {
  DOLLAR_SIGN,
  PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_ETF_PRICE_TABLE_LABEL,
  PRODUCT_DETAILS_REFERENCE_INFORMATION_EARLY_TRADING_FEE_SCHEDULE_COLUMNS,
} from "../../../constants/ProductDetails.constants";
import UtilsService from "../../../services/Utils.service";
import TableComponent from "../../misc/table/Table.component";
import TranslateComponent from "../../misc/translate/Translate.component";


const ProductDetailsETFTable = (props) => {
  const data = props?.data;
  const [etfData, setEtfData] = useState([]);
  const [etfDataSortAscendingState, setEtfDataSortAscendingState] = useState(
    {}
  );

  // update data based on date selection
  useEffect(() => {
   const etfData = [];
   if(data) {
     data.forEach((etf) => {
      etfData.push({
          "from": etf.from,
          "until": etf.until,
          "etfPerNote": etf.etfPerNote ? DOLLAR_SIGN + etf.etfPerNote : '',
        });
     });
     setEtfData(etfData);
   }
  }, [data]);

  const handleEtfTableSort = (field) => () => {
    const isSortOrderAscending = !!etfDataSortAscendingState[field];
    const sortedData = etfData.slice()
      .sort(UtilsService.stringComparator({ field, isAscending: !isSortOrderAscending }));
    setEtfData(sortedData);
    setEtfDataSortAscendingState({
      ...etfDataSortAscendingState,
      [field]: !isSortOrderAscending,
    });
  };
  
  return (
    <div className="tds-sn-product-details-etf-information-container">
      <h3>
        <TranslateComponent label={PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_ETF_PRICE_TABLE_LABEL} />
      </h3>
      <TableComponent
        className="early-trading-fee-schedule-table"
        columns={PRODUCT_DETAILS_REFERENCE_INFORMATION_EARLY_TRADING_FEE_SCHEDULE_COLUMNS}
        data={etfData}
        onSort={handleEtfTableSort}
      />
    </div>
  );
};

export default ProductDetailsETFTable;
