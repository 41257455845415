import React, { useState } from "react";

const CollapseComponent = (props) => {
  const { id, title, className, bottomBorder, displayType, children } = props;
  const [open, setOpen] = useState(props.isExpandable? true: false);

  const toggleDetails = () => {
    setOpen(!open);
  };

  return (
    <div className={`tds-sn-collapse-container ${className || null} ${open ? "open": null}`} >
      <div className="summary" style={{borderBottom:bottomBorder}} >
        <button title={title} onClick={toggleDetails} aria-controls={id} aria-expanded={open} style={{display:displayType}}>
          {open ? (
            <span className="td-icon td-icon-collapse icon-small active" title="Collapse"/>
          ) : (
            <span className="td-icon td-icon-expand icon-small active" title="Expand" />
          )}
          <h2>{title}</h2>
        </button>
      </div>
      {open ? <div id={id} className="details">{children}</div> : null}
    </div>
  );
};

export default CollapseComponent;
