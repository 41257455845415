import React, {useEffect} from "react";
import { FormattedMessage } from "react-intl";
import { DISCLOSURE_TITLE } from "../../../constants/PageTitle.constants";
import { tr } from "../../../services/Language.service";

const SNLegalDisclosureComponent = () => {

  useEffect(() => {
    document.title = DISCLOSURE_TITLE
  }, [])

  const formatContent = () => {
    const content = tr("legal.disclosure.structured.notes.content");
    const disclosures = content.split("<br/>")
    const html = [];
    let i = 1;
    for ( const disclosure of disclosures) {
      html.push(<p key={"disclosure" + i++}>{disclosure}</p>);
    }
    return html;

  }

  return (
    <React.Fragment>
        <div className="tds-container tds-sn-legal-dislosure">          
          <h2><FormattedMessage id="legal.disclosure.structured.notes.title"/></h2>
          {
            formatContent()
          }
        </div>        
    </React.Fragment>
  );
};

export default SNLegalDisclosureComponent;