import React, {useState, useEffect} from "react";
import TranslateComponent from "../../misc/translate/Translate.component";
import { NOTE_OPTIONS, NOTE_OPTIONS_WITH_HISTORICAL_NOTE, PRINT, PRINT_ALERT, PRINT_ALL, PRINT_HEADER } from "../../../constants/ProductDetails.constants";
import LanguageService from "../../../services/Language.service";

const PrintOption = (props) => {
  const [checked, setChecked] = useState([]);
  const noteOptions = props.isHistoricalNote ? NOTE_OPTIONS_WITH_HISTORICAL_NOTE : NOTE_OPTIONS;
  const handleCheck = (event) => {
    let updatedList = [...checked];
    
    if (event.target.checked) {
      if(event.target.value === LanguageService.translate(PRINT_ALL)) {
        if(!props.isIrnNote) {
          noteOptions.forEach((item) => {
              document.getElementById(LanguageService.translate(item)).disabled = true;
              document.getElementById(LanguageService.translate(item)).checked = false;
            })
          }
          updatedList = [event.target.value];
      } else {
        updatedList = [...checked, event.target.value];
      }
    } else {
        if(event.target.value === LanguageService.translate(PRINT_ALL)) {
          if(!props.isIrnNote) {
            noteOptions.forEach((item) => {
              document.getElementById(LanguageService.translate(item)).disabled = false;
            })
          }
        }
        updatedList.splice(checked.indexOf(event.target.value), 1);
      };
      setChecked(updatedList);
  }

  useEffect(() => {
    document.getElementById(LanguageService.translate(PRINT_ALL)).checked = true;
    setChecked([LanguageService.translate(PRINT_ALL)]);
    if(!props.isIrnNote) {
      noteOptions.forEach((item) => {
        document.getElementById(LanguageService.translate(item)).disabled = true;
      })
    }
  }, [props.isIrnNote, noteOptions])

  const handlePrint = () => {
    if(checked.length > 0) {      
      window.open('/print-comp/'+props.code+'/'+checked, '_blank', 'noopener,noreferrer');
    } else {
      alert(LanguageService.translate(PRINT_ALERT));
      return;
    }
  }

  return (
    <div>
      <div style={{fontWeight: "bold"}}><TranslateComponent label={PRINT_HEADER} /></div>
      <div className="tds-options padding">
      <input  name={LanguageService.translate(PRINT_ALL)}
                    type="checkbox"
                    id={LanguageService.translate(PRINT_ALL)}
                    value={LanguageService.translate(PRINT_ALL)}
                    onChange={handleCheck}/>
              <span className="tds-options margin"><TranslateComponent label={PRINT_ALL} /></span>
        { !props.isIrnNote && noteOptions.map((item, index) => (
          <div key={index}>
            <input  name={LanguageService.translate(item)}
                    type="checkbox"
                    id={LanguageService.translate(item)}
                    value={LanguageService.translate(item)}
                    onChange={handleCheck}/>
            <span className="tds-options margin"><TranslateComponent label={item} /></span>
          </div>
        ))}<br/>
        <button className="btn td-btn-primary-light tds-options printButton" onClick={handlePrint}>
          <TranslateComponent label={PRINT} />
        </button>
      </div>
    </div>
  );
 
};
export default PrintOption;
