export const getLanguage = (code) => {
  switch (code) {
    case "en":
      return "LANGUAGE_en";
    case "bg":
      return "LANGUAGE_bg";
    case "cs":
      return "LANGUAGE_cs";
    case "de":
      return "LANGUAGE_de";
    case "el":
      return "LANGUAGE_el";
    case "es":
      return "LANGUAGE_es";
    case "fi":
      return "LANGUAGE_fi";
    case "fr":
      return "LANGUAGE_fr";
    case "hr":
      return "LANGUAGE_hr";
    case "hu":
      return "LANGUAGE_hu";
    case "it":
      return "LANGUAGE_it";
    case "nl":
      return "LANGUAGE_nl";
    case "no":
      return "LANGUAGE_no";
    case "pl":
      return "LANGUAGE_pl";
    case "pt":
      return "LANGUAGE_pt";
    case "ro":
      return "LANGUAGE_ro";
    case "sk":
      return "LANGUAGE_sk";
    default:
      return "LANGUAGE_sk";
  }
};
