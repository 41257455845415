import React from 'react';
import { Link } from "react-router-dom";
import {
    TDS_TOP_NAV, TDS_TOP_NAV_DROPDOWN_LINK, TDS_DROPDOWN_CONTENT,
    TYPE_DROPDOWN, TYPE_LINK, LINK_DESTINATION_INTERNAL,
    LINK_DESTINATION_EXTERNAL, LINK_DESTINATION_NONE
} from '../../constants/HeaderNav.constants';

import TranslateComponent from '../misc/translate/Translate.component';
import HeaderNavDropDownComponent from './HeaderNavDropDown.component';
import { LOG_LEVEL, logger } from '../../system/Logger';
import { FormattedMessage } from 'react-intl';
import LanguageService, {tr} from '../../services/Language.service';
import { LANGUAGE_ENGLISH } from '../../constants/Misc.constants';


export default class HeaderNavBarComponent extends React.Component {
    topClassName = TDS_TOP_NAV;
    dropDownClassName = TDS_TOP_NAV_DROPDOWN_LINK;
    dropDownContentClassName = TDS_DROPDOWN_CONTENT;
    menuItemsDOM = [];
    menuItemsREF = [];

    constructor(props) {
        super(props);
        logger.log(LOG_LEVEL.DEBUG, "HeaderNavBarComponent", this.props);
        this.topClassName = this.props.topClassName || this.topClassName;
        this.dropDownClassName = this.props.dropDownClassName || this.dropDownClassName;
        this.dropDownContentClassName = this.props.dropDownContentClassName || this.dropDownContentClassName;
        this.hover = false || this.props.hover;
        this.menuItems = (this.props.menuItems) ? this.props.menuItems : (this.props.menuItemsRef) ? this.props.menuItemsRef : null;
        this.handleTopMenuClick = this.handleTopMenuClick.bind(this);
    }
    handleTopMenuClick(e) {
        e.preventDefault();
        this.props.showModal(e.target.dataset.modaltype);
    }
    createMenuItems() {
        this.menuItemsDOM = [];
        this.menuItemsREF = [];
        const menuItems = (this.props.menuItemsRef) ? this.menuItems() : this.menuItems;
        if (menuItems) {
            for (var menuItem of menuItems) {
                if (menuItem.destination === LINK_DESTINATION_EXTERNAL &&
                    menuItem.type === TYPE_LINK) {
                    const link = tr(menuItem.link);
                    this.menuItemsDOM.push(
                        <div className="tds-nav-item" key={menuItem.text}>
                            <a href={link} target="_blank" rel="noopener noreferrer">
                                {
                                    menuItem.isTranslatable &&
                                    <FormattedMessage id={menuItem.text} />
                                }
                                {
                                    !menuItem.isTranslatable &&
                                    <React.Fragment>
                                        {menuItem.text}
                                    </React.Fragment>
                                }
                            </a>
                        </div>
                    )
                }
                if (menuItem.destination === LINK_DESTINATION_INTERNAL &&
                    menuItem.type === TYPE_LINK) {
                    if (menuItem.hasAccess) {
                        this.menuItemsDOM.push(
                            <div className="tds-nav-item" key={menuItem.text}>
                                <Link
                                    to={menuItem.link}
                                >
                                    { menuItem.icon &&
                                        <span className="td-icon td-icon-homepage icon-small"></span>
                                    }
                                    {
                                        menuItem.isTranslatable &&
                                        <FormattedMessage id={menuItem.text} />
                                    }
                                    {
                                        !menuItem.isTranslatable &&
                                        <React.Fragment>
                                            {LanguageService.isLanguage(LANGUAGE_ENGLISH) ? menuItem.text : 'Accueil'}
                                        </React.Fragment>
                                    }
                                </Link>
                            </div>
                        )
                    } else {
                        this.menuItemsDOM.push(
                            <div className="tds-nav-item" key={menuItem.text}>
                                <Link
                                    to={menuItem.link}
                                    onClick={this.handleTopMenuClick.bind(this)}
                                    data-modaltype={menuItem.modalType}
                                >
                                    {
                                        menuItem.isTranslatable &&
                                        <TranslateComponent label={menuItem.text} />
                                    }
                                    {
                                        !menuItem.isTranslatable &&
                                        <React.Fragment>
                                            {menuItem.text}
                                        </React.Fragment>
                                    }
                                </Link>
                            </div>
                        )
                    }
                }
                if (menuItem.destination === LINK_DESTINATION_NONE &&
                    menuItem.type === TYPE_DROPDOWN) {
                    var child = React.createRef();
                    this.menuItemsREF.push(child);
                    this.menuItemsDOM.push(
                        <HeaderNavDropDownComponent
                            ref={child}
                            dropDownData={menuItem}
                            dropDownClassName={this.dropDownClassName}
                            key={menuItem.text}
                            hover={this.hover}
                            closeOtherMenus={() => this.closeMenus()}
                            showModal={this.props.showModal}
                            triggerLanguageSwitch={this.props.triggerLanguageSwitch}
                        />
                    )
                }

            }
        }
    }
    closeMenus() {
        for (var i = 0; i < this.menuItemsREF.length; i++) {
            this.menuItemsREF[i].current.closeMenu();
        }
    }
    render() {
        if (this.menuItems) {
            this.createMenuItems();
            return (
                <div className={this.topClassName}>
                    {this.menuItemsDOM}
                </div>
            )
        } else {
            return null;
        }
    }

}