import React from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import { COOKIE_CONSENT_COOKIE_EXPIRATION, COOKIE_CONSENT_COOKIE_NAME } from "../../constants/GoogleAnalytics.constants";
import {
  ENGLISH, 
  FRENCH, 
  LANGUAGE_ENGLISH,
  LANGUAGE_FRENCH} from "../../constants/Misc.constants";
import { ROUTE_PATH_PREFERENCES } from "../../constants/Routes.constants";
import LanguageService from "../../services/Language.service";
import TranslateComponent from "../misc/translate/Translate.component";

const CookieConsentBanner = (props) => {

  const handleAccept = () => {
    props.acceptCallback();
  };

  const handleDecline = () => {
    props.declineCallBack();
  };

 const handleLanguageClick = (e) => {
    e.preventDefault();
    let language = LANGUAGE_ENGLISH;
    if (e.target && e.target.dataset && e.target.dataset.language) {
        language = e.target.dataset.language;
    }
    LanguageService.switchLanguage(language);
}

  return (
    <React.Fragment>
      <CookieConsent
        enableDeclineButton
        overlay
        disableStyles={true}
        containerClasses="cookie-consent"
        contentClasses="consent-content"
        overlayClasses="consent-overlay"
        buttonWrapperClasses="consent-buttons"
        buttonClasses="btn td-btn-primary-light"
        declineButtonClasses="btn td-btn-secondary-clear"
        flipButtons
        location="bottom"
        buttonText={LanguageService.translate("cookie.consent.banner.accept")}
        declineButtonText={LanguageService.translate("cookie.consent.banner.decline")}
        cookieName={COOKIE_CONSENT_COOKIE_NAME}
        expires={COOKIE_CONSENT_COOKIE_EXPIRATION}
        onAccept={() => handleAccept()}
        onDecline={() => handleDecline()}
      >
        {LanguageService.translate("cookie.consent.banner.text")}{" "}
        <Link to={ROUTE_PATH_PREFERENCES} title={LanguageService.translate("cookie.consent.banner.link")}>
          {LanguageService.translate("cookie.consent.banner.link")}
        </Link><br/>
        <a href={'# '} className="language-link"
          data-language={LanguageService.isLanguage('en') ? LANGUAGE_FRENCH : LANGUAGE_ENGLISH}
          onClick={handleLanguageClick}
        >
          {
            <TranslateComponent label={LanguageService.isLanguage('en') ? FRENCH : ENGLISH} />
          }
      </a>
      </CookieConsent>
    </React.Fragment> 
  );
};

export default CookieConsentBanner;
