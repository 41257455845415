export const PAGE_TITLE_LABEL_KEY = "PRIIP_TITLE";
export const PAGE_DISCLAIMER_TITLE = "PRIIP_DISCLAIMER_TITLE";
export const PAGE_DISCLAIMER_NOTICE = "PRIIP_DISCLAIMER_NOTICE";
export const PAGE_DISCLAIMER_CONTENT = "PRIIP_DISCLAIMER_CONTENT";
export const PAGE_DISCLAIMER_CONFIRMATION = "PRIIP_DISCLAIMER_CONFIRMATION";
export const PAGE_DISCLAIMER_COOKIE_CONFIRMATION =
  "PRIIP_DISCLAIMER_COOKIES_CONFIRMATION";
export const PAGE_DISCLAIMER_ENTER = "PRIIP_ENTER";
export const PAGE_TABLE_TITLE = "PRIIP_TABLE_TITLE";
export const PRIIP_TABLE_PAGE_SIZE = 10;
export const PRIIP_SEARCH_PLACEHOLDER = "SEARCH_PLACEHOLDER";
export const PRIIP_SEARCH_TITLE_LABEL = "PRIIP_SEARCH_TITLE_LABEL";
export const PRIIP_COPY_TO_CLIPBOARD = "COPY_TO_CLIPBOARD"
export const PRIIP_COLUMN_DEFINITIONS = [
  {
    title: "PRIIP_TABLE_ISIN",
    field: "isin",
    className: "isin-detail",
    translate: true,
    sortable: true,
  },
  {
    title: "PRIIP_TABLE_DATE_OF_CREATION",
    field: "createdAt",
    className: "eventDate",
    translate: true,
    isDate: true,
    sortable: true,
  },
  {
    title: "PRIIP_TABLE_LANGUAGE",
    field: "fileLanguage",
    className: "language",
    translate: true,
  },
  {
    title: "PRIIP_TABLE_ACTIONS",
    field: "action",
    className: "action",
    translate: true,
  },
];
export const ASCENDING_SORT = "ascend";
export const DESCENDING_SORT = "descend";
export const PRIIP_ERROR = "PRIIP_REQUIRED_ERROR"
