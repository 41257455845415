
import {
    ENV_LOCAL_URL, 
    ENV_DEV_URL, 
    ENV_PAT_URL,
    ENV_PROD_URL,
    ENV_LOCAL_LABEL,
    ENV_DEV_LABEL,
    ENV_PAT_LABEL,
    ENV_PROD_LABEL
} from '../constants/Enivornment.constants';

export default class EnvironmentService {

    static environment = null;

    static isLocal() {
        return EnvironmentService.environment === ENV_LOCAL_LABEL;
    }

    static isDev() {
        return EnvironmentService.environment === ENV_DEV_LABEL;
    }

    static isPat() {
        return EnvironmentService.environment === ENV_PAT_LABEL;
    }

    static isProd() {
        return EnvironmentService.environment === ENV_PROD_LABEL;
    }

    static initialize() {
        EnvironmentService.environment = EnvironmentService.getEnvironment();
        /* DO NOT REMOVE OR COMMENT OUT */
        console.log("EnvironmentService.environment:", EnvironmentService.environment);
        /* DO NOT REMOVE OR COMMENT OUT */
    }

    static getEnvironment() {
        const url = document.createElement('a');
        url.setAttribute('href', window.location.href);
        if (url.hostname === ENV_LOCAL_URL) {
            return ENV_LOCAL_LABEL;
        } else if (url.hostname === ENV_DEV_URL) {
            return ENV_DEV_LABEL;
        } else if (url.hostname === ENV_PAT_URL) {
            return ENV_PAT_LABEL;
        } else if (url.hostname === ENV_PROD_URL) {
            return ENV_PROD_LABEL;
        }
        return null;
    }

    static isSearch() {
        var pathName = window.location.pathname;
        var pattern = new RegExp("search");
        var results = pathName.match(pattern);
        if (results && results.index && results.index === 1) {
            return true
        }
        return false;
    }
}