import React from 'react';
import { withRouter } from "react-router-dom";

class RouteChangeDetectorComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            if (location === '/search') {
                this.props.hideSearchBar();
            } else {
                this.props.showSearchBar();
            }
            const currentPath = this.props.location.pathname;
            sessionStorage.setItem('prevPath', currentPath);
        });
    }
    componentWillUnmount() {
        this.unlisten();
    }
    render() {
        return (
            <span className="tds-route-change-detector">

            </span>
        )
    }
}
export default withRouter(RouteChangeDetectorComponent);