/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from "react";
import TranslateComponent from "../../misc/translate/Translate.component";
import {
  ASCENDING_SORT,
  DESCENDING_SORT,
  PAGE_TABLE_TITLE,
  PRIIP_COLUMN_DEFINITIONS,
  PRIIP_COPY_TO_CLIPBOARD,
  PRIIP_SEARCH_PLACEHOLDER,
  PRIIP_SEARCH_TITLE_LABEL,
  PRIIP_TABLE_PAGE_SIZE,
} from "../../../constants/Priip.constants";

import {useIntl} from "react-intl";
import {NO_RESULTS} from "../../../constants/Notes.constants";
import SimpleSearchComponent from "../../misc/search/SimpleSearch.component";
import TableComponent from "../../misc/table/Table.component";
import PriipService, {
  handleCopyPriip,
  priipDownload,
} from "../../../services/Priip.service";
import PaginatorComponent from "../../pagination/Paginator.component";
import SpinnerComponent from "../../spinner/Spinner.component";
import {useHistory} from "react-router-dom/cjs/react-router-dom";
import LanguageService from "../../../services/Language.service";
import ModalComponent from "../../misc/modal/Modal.component";
import {getLanguage} from "./utils";

function PriipTable() {
  //State
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState();
  const [loading, setLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [priipOptions, setPriipOptions] = useState({
    search: false,
    searchQuery: "",
    sort: false,
    key: "",
    direction: DESCENDING_SORT,
    page: 1,
  });

  //Hooks

  const copyModalRef = useRef();
  const intl = useIntl();
  const history = useHistory();

  useEffect(() => {
    if (!history?.goBackFlag) {
      setCurrentPage(1);
      history.page = 1;
    } else {
      setCurrentPage(history.page);
      if (history?.goBackFlag) {
        history.goBackFlag = false;
      }
    }
  }, [history, intl]);

  useEffect(() => {
    PriipService.getPriipData(currentPage, priipOptions)
      .then((data) => generateTableData(data))
      .catch((err) => console.error(err));
  }, [currentPage]);

  //Handlers

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const handleDownload = (id, name) => {
    priipDownload(id, name);
  };

  const handleSearch = (e) => {
    PriipService.getPriipSearchData(e, 1)
      .then((data) => generateTableData(data))
      .catch((err) => console.error(err));
    setCurrentPage(1);
    setPriipOptions({
      ...priipOptions,
      search: true,
      searchQuery: e,
      sort: false,
    });
  };

  const handleCopy = (id) => {
    handleCopyPriip(id);
    copyModalRef.current.showModal();
  };

  const handleColumnSorting = (key) => {
    let direction =
      priipOptions.direction === ASCENDING_SORT
        ? DESCENDING_SORT
        : ASCENDING_SORT;
    PriipService.getPriipData(currentPage, {
      ...priipOptions,
      sort: true,
      key: key,
      direction: direction,
    })
      .then((data) => generateTableData(data))
      .catch((err) => console.error(err));
    setPriipOptions(() => ({
      ...priipOptions,
      sort: true,
      key: key,
      direction: direction,
    }));
  };

  const generateTableData = (data) => {
    if (data.results.length < 1) {
      setNoResults(() => true);
    } else {
      setNoResults(() => false);
      setLoading(() => true);
      const dataWithActions = data.results.map((row) => ({
        ...row,
        fileLanguage: (
          <TranslateComponent label={getLanguage(row.fileLanguage)} />
        ),
        action: (
          <div className="tds-priip-actions" data-testid="actions-wrapper">
            <button
            data-testid="actions-copy"
              onClick={() => {
                handleCopy(row.id);
              }}
              className="td-icon td-icon-insights"
            ></button>
            <button
            data-testid="actions-download"
              onClick={() => {
                handleDownload(row.id, row.isin);
              }}
              className="td-icon td-icon-download"
            ></button>
          </div>
        ),
      }));
      setTotalResults(data.total);
      setData(dataWithActions);
      setLoading(() => false);
    }
  };

  return (
    <section>
      <div className="tds-priip-table-container">
        <div className="tds-priip-table-header">
          <h3>
            <TranslateComponent label={PAGE_TABLE_TITLE} />
          </h3>
        </div>
        <div className="tds-priip-searchWrapper">
          <SimpleSearchComponent
            id="kidSearch"
            className="search-section"
            placeholder={LanguageService.translate(PRIIP_SEARCH_PLACEHOLDER)}
            onSearch={handleSearch}
            label={LanguageService.translate(PRIIP_SEARCH_TITLE_LABEL)}
            hideLabel={true}
          />
        </div>
        {!loading & !noResults ? (
          <>
            <div className="lifecycle-event-table-wrapper">
              <TableComponent
                columns={PRIIP_COLUMN_DEFINITIONS}
                data={data}
                onSort={handleColumnSorting}
              />
            </div>
          </>
        ) : null}
        {loading && <SpinnerComponent />}
      </div>
      {noResults && (
        <div className="tds-current-offering-search-results-description">
          <TranslateComponent label={NO_RESULTS} />
        </div>
      )}

      {!loading && !noResults && (
        <PaginatorComponent
          className="event-search-table-paginator"
          currentPage={currentPage}
          resultsPerPage={PRIIP_TABLE_PAGE_SIZE}
          total={totalResults}
          goToPage={goToPage}
          key={`${currentPage} - ${totalResults}`}
        />
      )}

      <ModalComponent
        ref={copyModalRef}
        id={"noteDetailsModal"}
        className="note-details-modal-content"
      >
        <TranslateComponent label={PRIIP_COPY_TO_CLIPBOARD} />
      </ModalComponent>
    </section>
  );
}

export default React.memo(PriipTable);
