export const PAR_NOTE_TYPE = "Principal-At-Risk Notes";
export const PPN_NOTE_TYPE = "Principal Protected Notes";
export const IRN_NOTE_TYPE = "Interest-rate Linked Notes";

export const PAR_NOTE_TYPE_LABEL_KEY = "CURRENT_OFFERINGS_PAR_NOTE";
export const PPN_NOTE_TYPE_LABEL_KEY = "CURRENT_OFFERINGS_PPN_NOTE";
export const IRN_NOTE_TYPE_LABEL_KEY = "CURRENT_OFFERINGS_IRN_NOTE";

export const NON_FEE_NOTE_TYPE_LABEL_KEY = 'CURRENT_OFFEREINGS_PAGE_NON_FEE_BASED_NOTES';
export const FEE_NOTE_TYPE_LABEL_KEY = 'CURRENT_OFFEREINGS_PAGE_FEE_BASED_NOTES';

export const NON_FEE_NOTE_TYPE_TAB_VALUE = 'tds-tab-non-fee-based-noted';
export const FEE_NOTE_TYPE_TAB_VALUE = 'tds-tab-fee-based-notes';

export const NON_FEE_NOTE_TYPE_TAB_NAME = 'selectNonFeeBasedNotes';
export const FEE_NOTE_TYPE_TAB_NAME = 'selectFeeBasedNotes';
export const NO_RESULTS = "NO_RESULTS";

export const PAR_NOTE_TYPE_DESC_LABEL_KEY = "CURRENT_OFFERINGS_PAR_NOTE_TYPE_DESC_LABEL_KEY";
export const PPN_NOTE_TYPE_DESC_LABEL_KEY = "CURRENT_OFFERINGS_PPN_NOTE_TYPE_DESC_LABEL_KEY";
export const IRN_NOTE_TYPE_DESC_LABEL_KEY = "CURRENT_OFFERINGS_IRN_NOTE_TYPE_DESC_LABEL_KEY";

export const NOTE_MODALS_PAYOFF_FEATURES_LABEL_KEY = 'PRODUCT_DETAILS_OVERVIEW_PAYOFF_FEATURES';
export const NOTE_MODALS_RETURN_FEATURES_LABEL = 'RETURN FEATURES';

export const NOTE_FIELD_LABEL_NAME = 'Name';
export const NOTE_FIELD_LABEL_FUND_SERVE_CODE = 'Fundserv Code';
export const NOTE_FIELD_LABEL_CUSIP = 'CUSIP';
export const NOTE_FIELD_LABEL_AVAILABLE_UNTIL_DATE = 'Available Until';
export const NOTE_FIELD_LABEL_MATURITY_DATE = 'Maturity Date';
export const NOTE_FIELD_LABEL_TERM = 'Term';
export const NOTE_FIELD_LABEL_STATUS = 'Current Status';
export const NOTE_FIELD_LABEL_FEATURE = 'Note Feature';
export const NOTE_FIELD_LABEL_COMPARE = 'Compare';
export const NOTE_FIELD_LABEL_ISSUE_DATE = 'Issue Date';
export const NOTE_FIELD_LABEL_CURRENCY = 'Currency';
export const NOTE_FIELD_LABEL_PRODUCT_TYPE = 'Product Type';
export const NOTE_FIELD_LABEL_ASSET_TYPE = 'Underlying Asset Type';
export const NOTE_FIELD_LABEL_PRODUCT_CLASS = 'Product Class';
export const NOTE_FIELD_LABEL_ASSET_GEOGRAPHY = 'Geography';
export const NOTE_FIELD_LABEL_STRUCTURE_TYPE = 'Structure Type';
export const NOTE_FIELD_LABEL_AUTO_CALL_LEVEL = 'Autocall Level';
export const NOTE_FIELD_LABEL_AUTO_CALL_FREQUENCY = 'Autocall Frequency';
export const NOTE_FIELD_LABEL_BARRIER_LEVEL = 'Call Level';
export const NOTE_FIELD_LABEL_BUFFER_LEVEL = 'Buffer Level';
export const NOTE_FIELD_LABEL_FIXED_RETURN = "Fixed Return";
export const NOTE_FIELD_LABEL_DOWN_SIDE_PROTECTION_STRIKE = 'Downside Protection Strike';
export const NOTE_FIELD_LABEL_DOWN_SIDE_PARTICIPATION = 'Downside Multiplier';
export const NOTE_FIELD_LABEL_DOWN_SIDE_CAP = "Floor Level";
export const NOTE_FIELD_LABEL_DOWNSIDE_MULTIPLIER = "Downside Multiplier";
export const NOTE_FIELD_LABEL_COUPON_BARRIER = 'Payment Threshold';
export const NOTE_FIELD_LABEL_COUPON_RATE = 'Payment Rate';
export const NOTE_FIELD_LABEL_IRN_COUPON_RATE = 'Coupon Rate';
export const NOTE_FIELD_LABEL_COUPON_FREQUENCY = 'Payment Frequency';
export const NOTE_FIELD_LABEL_COUPON_PAYMENT_THRESHOLD = 'Coupon Payment Threshold';
export const NOTE_FIELD_LABEL_COUPON_YIELD = 'Coupon Yield';
export const NOTE_FIELD_LABEL_COUPON_PAYMENT_FREQUENCY = 'Coupon Payment Frequency';
export const NOTE_FIELD_LABEL_VARIABLE_COUPON_STRIKE = 'Variable Coupon Threshold';
export const NOTE_FIELD_LABEL_VARIABLE_COUPON_PARTICIPATION = 'Variable Coupon Participation';
export const NOTE_FIELD_LABEL_MINI_MUM_VARIABLE_COUPON = 'Minimum Variable Coupon Rate';
export const NOTE_FIELD_LABEL_MINI_MUM_VARIABLE_COUPON_PERIOD = 'Minimum Variable Coupon Period';
export const NOTE_FIELD_LABEL_AUTOCALL_FREQUENCY = 'Call Frequency';
export const NOTE_FIELD_LABEL_EXCESS_RETURN_PARTICIPATION = 'Excess Return Participation';
export const NOTE_FIELD_LABEL_BOOSTED_RETURN = 'Boosted Return';
export const NOTE_FIELD_LABEL_BOOST_LEVEL = 'Boost Level';
export const NOTE_FIELD_LABEL_PARTICIPATION_ABOVE_BOOST = 'Participation Above Boost';
export const NOTE_FIELD_LABEL_PARTICIPATION_RATE = 'Participation Rate';
export const NOTE_FIELD_LABEL_PARTICIPATION_LEVEL = 'Participation Level';
export const NOTE_FIELD_LABEL_PARTICIPATION_CAP = 'Participation Cap';
export const NOTE_FIELD_LABEL_KNOCK_OUT_RETURN = 'Knockout Return';
export const NOTE_FIELD_LABEL_KNOCK_OUT_LEVEL = 'Knockout Level';
export const NOTE_FIELD_LABEL_FIXED_SHARE_RETURN = 'Fixed Share Return';
export const NOTE_FIELD_LABEL_MAXI_MUM_SHARE_RETURN = 'Maximum Share Return';
export const NOTE_FIELD_LABEL_MINI_MUM_SHARE_RETURN = 'Minimum Share Return';
export const NOTE_FIELD_LABEL_ASSET_LEAST_PERFORMING_TICKER = 'Asset return calculation is based on least performing ticker';
export const NOTE_FIELD_LABEL_ASSET_BEST_PERFORMING_TICKER = 'Asset return calculation is based on best performing ticker';
export const NOTE_FIELD_LABEL_ASSET_RAINBOW_WEIGHT = 'Asset return calculation is based on rainbow weighting';
export const NOTE_FIELD_LABEL_FINAL_MATURITY_LEVEL = 'Final Maturity Level is based on averaging dates';
export const NOTE_FIELD_LABEL_SECTOR = 'Sector';
export const NOTE_FIELD_AUTOCALL_VARIABLE_VALUE = 'Variable';
export const NOTE_FIELD_COUPON_RATE_POTENTIAL_VALUE = 'Potential';
export const NOTE_FIELD_COUPON_FREQUENCY_CONDITIONAL_VALUE = 'Subject to payment threshold';
export const NOTE_FIELD_AUTOCALL_VARIABLE_LABEL = 'Varies - refer to call schedule';
export const NOTE_FIELD_PERCENTAGE_LEVEL_LABEL = '% of the Opening Level';
export const NOTE_FIELD_PERCENTAGE_LEVEL_PREFIX = ' - An investor\'s principal is protected at maturity unless the Final Level is below ';

export const NOTE_FIELD_KEYS_ISM = "ismCode";
export const NOTE_FIELD_KEYS_W360 = "w360";
export const NOTE_FIELD_KEY_CODE = "code";
export const NOTE_FIELD_KEY_NAME = 'longName';
export const NOTE_FIELD_KEY_NAME_FR = 'longNameFr';
export const NOTE_FIELD_KEY_FUND_SERVE_CODE = 'fundservCode';
export const NOTE_FIELD_KEY_CUSIP = 'cusip';
export const NOTE_FIELD_KEY_INVESTMENT_OBJECTIVE = 'Investment Objective';
export const NOTE_FIELD_KEY_AVAILABLE_UNTIL_DATE = 'marketingEndDate';
export const NOTE_FIELD_KEY_MATURITY_DATE = 'maturityDate';
export const NOTE_FIELD_KEY_FINAL_VALUATION_DATE = 'finalValDate';
export const NOTE_FIELD_KEY_TERM = 'termMaturity';
export const NOTE_FIELD_KEY_STATUS = 'noteStatus';
export const NOTE_FIELD_KEY_STATUS_OVERRIDE = 'statusOverride';
export const NOTE_FIELD_KEY_ISSUE_DATE = 'issueDate';
export const NOTE_FIELD_KEY_CURRENCY = 'currency';
export const NOTE_FIELD_KEY_NOTE_TYPE = 'noteType';
export const NOTE_FIELD_KEY_ASSET_TYPE = 'assetType';
export const NOTE_FIELD_KEY_ASSET_SECTOR = 'assetSector';
export const NOTE_FIELD_KEY_PRODUCT_CLASS = 'productClass';
export const NOTE_FIELD_KEY_PRODUCT_TYPE = 'productType';
export const NOTE_FIELD_KEY_ASSET_GEOGRAPHY = 'assetGeography';
export const NOTE_FIELD_KEY_STRUCTURE_TYPE = 'noteSubType';
export const NOTE_FIELD_KEY_AUTO_CALL_LEVEL = 'autocallLevel';
export const NOTE_FIELD_KEY_BARRIER_LEVEL = 'downsideMaturityBarrier';
export const NOTE_FIELD_KEY_BUFFER_LEVEL = 'bufferLevel';
export const NOTE_FIELD_KEY_FIXED_RETURN = "couponRate";
export const NOTE_FIELD_KEY_DOWN_SIDE_PROTECTION_STRIKE = 'downsideProtectionStrike';
export const NOTE_FIELD_KEY_DOWN_SIDE_PARTICIPATION = 'downsideParticipation';
export const NOTE_FIELD_DOWNSIDE_MATURITY_BARRIER = 'downsideMaturityBarrier';
export const NOTE_FIELD_KEY_DOWN_SIDE_CAP = "downsideCap";
export const NOTE_FIELD_KEY_COUPON_BARRIER = 'couponBarrier';
export const NOTE_FIELD_KEY_COUPON_RATE = 'couponRate';
export const NOTE_FIELD_KEY_COUPON_FREQUENCY = 'couponFrequency';
export const NOTE_FIELD_KEY_COUPON_PAYMENT_TYPE = 'couponPaymentType';
export const NOTE_FIELD_KEY_VARIABLE_COUPON_STRIKE = 'variableCouponStrike';
export const NOTE_FIELD_KEY_VARIABLE_COUPON_THRESHOLD = 'variableStrike';
export const NOTE_FIELD_KEY_MINI_MUM_VARIABLE_COUPON = 'minimumVariableCoupon';
export const NOTE_FIELD_KEY_MINI_MUM_VARIABLE_COUPON_PERIOD = 'minimumVariableCouponPeriod';
export const NOTE_FIELD_KEY_MEMORY = 'memory';
export const NOTE_FIELD_KEY_AUTOCALL_FREQUENCY = 'autocallFrequency';
export const NOTE_FIELD_KEY_IS_CALLABLE = 'isCallable';
export const NOTE_FIELD_KEY_CALL_FREQUENCY = "callFrequency";
export const NOTE_FIELD_KEY_VARIABLE_PARTICIPATION = 'variableParticipation';
export const NOTE_FIELD_KEY_UP_SIDE_JUMP_LEVEL = 'upsideJumpLevel';
export const NOTE_FIELD_KEY_UP_SIDE_JUMP_STRIKE = 'upsideJumpStrike';
export const NOTE_FIELD_KEY_PARTICIPATION_ABOVE_BOOST = 'participationAboveBoost';
export const NOTE_FIELD_KEY_UP_SIDE_PARTICIPATION = 'upsideParticipation';
export const NOTE_FIELD_KEY_PARTICIPATION_LEVEL = 'participationLevel';
export const NOTE_FIELD_KEY_COUPON_PARTICIPATION = 'memoryCouponParticipation';
export const NOTE_FIELD_KEY_UP_SIDE_CAP = 'upsideCap';
export const NOTE_FIELD_KEY_KNOCK_OUT_RETURN = 'knockoutReturn';
export const NOTE_FIELD_KEY_KNOCK_OUT_LEVEL = 'knockoutLevel';
export const NOTE_FIELD_KEY_FIXED_SHARE_RETURN = 'fixedShareReturn';
export const NOTE_FIELD_KEY_MAXI_MUM_SHARE_RETURN = 'maximumShareReturn';
export const NOTE_FIELD_KEY_MINI_MUM_SHARE_RETURN = 'minimumShareReturn';
export const NOTE_FIELD_KEY_ASSET_LEAST_PERFORMING_TICKER = 'assetLeastPerformingTicker';
export const NOTE_FIELD_KEY_ASSET_BEST_PERFORMING_TICKER = 'assetBestPerformingTicker';
export const NOTE_FIELD_KEY_ASSET_RAINBOW_WEIGHT = 'assetRainbowWeight';
export const NOTE_FIELD_KEY_FINAL_MATURITY_LEVEL = 'finalMaturityLevel';
export const NOTE_FIELD_KEY_IS_CONTINGENT_COUPON = 'isContingentCoupon';
export const NOTE_FIELD_KEY_IS_COUPON_PEROIDIC = 'isCouponPeriodic';
export const NOTE_FIELD_KEY_IS_VARIABLE_AUTOCALL = 'isVariableAutocall';
export const NOTE_FIELD_KEY_UPSIDE_STRIKE = 'upsideStrike';
export const NOTE_FIELD_KEY_HAS_UPSDIE_JUMP = "hasUpsideJump";
export const NOTE_FIELD_KEY_IS_BOOSTED = 'isBoosted';
export const NOTE_FIELD_KEY_IS_RAINBOW = 'isRainbow';
export const NOTE_FIELD_KEY_RAINBOW_TYPE = 'rainbowType';
export const NOTE_FIELD_KEY_MATURITY_AVERAGING_DATES = 'maturityAveragingDates';
export const NOTE_FIELD_KEY_NON_CALL_PERIOD = "nonCallPeriod";
export const NOTE_FIELD_KEY_SALES_COMMISSION = "salesCommission";
export const NOTE_FIELD_KEY_EXCESS_RETURN_STRIKE = "excessReturnStrike";
export const NOTE_FIELD_KEY_MAX_NOTE_RETURN = "maxNoteReturn";
export const NOTE_FIELD_KEYS_INVESTMENT_OBJECTIVE = "investmentObjective";
export const NOTE_FIELD_KEY_IRN_TERM = "irnTerm";
export const NOTE_FIELD_KEY_IRN_MAX_TERM = "irnMaxTerm";
export const NOTE_FIELD_KEY_IRN_EXTENSION_FREQ= "irnExtensionFrequency";
export const NOTE_FIELD_KEY_IRN_IS_BAIL_IN = "isIrnBailin";
export const NOTE_FIELD_KEY_IRN_COUPON_TYPE = "irnCouponType";
export const NOTE_FIELD_KEY_IRN_COUPON_RATE = "irnCouponRate";
export const NOTE_FIELD_KEY_IRN_REFERENCE_RATE = "irnUnderlying";
export const NOTE_FIELD_KEY_IRN_SPREAD = "irnSpread";
export const NOTE_FIELD_KEY_IRN_MIN_COUPON_RATE = "irnMinimumCoupon";
export const NOTE_FIELD_KEY_IRN_MAX_COUPON_RATE = "irnMaximumCoupon";
export const NOTE_FIELD_KEY_IRN_COUPON_FREQ = "irnCouponFrequency";
export const NOTE_FIELD_KEY_IS_ROC = 'isRoc';
export const NOTE_FIELD_KEY_IS_COUPON = 'isCoupon';
export const NOTE_FIELD_KEYS_ACCOUNT_ELIGIBILITY = "accountEligibility";
export const NOTE_FIELD_KEYS_ASSET_PERFORMANCE_TYPE = "assetPerformanceType";
export const NOTE_FIELD_KEYS_COUPON_TYPE = "couponType";


export const NOTE_FIELD_LABELS = {
    NAME: NOTE_FIELD_LABEL_NAME,
    FUND_SERVE_CODE: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_FUND_CODE_LABEL",
    CUSIP: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_CUSIP_LABEL",
    AVAILABLE_UNTIL_DATE: NOTE_FIELD_LABEL_AVAILABLE_UNTIL_DATE,
    MATURITY_DATE: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_MATURITY_DATE_LABEL",
    TERM: NOTE_FIELD_LABEL_TERM,
    STATUS: NOTE_FIELD_LABEL_STATUS,
    FEATURE: NOTE_FIELD_LABEL_FEATURE,
    COMPARE: NOTE_FIELD_LABEL_COMPARE,
    ISSUE_DATE: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_ISSUE_DATE_LABEL",
    CURRENCY: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_CURRENCY_LABEL",
    ASSET_TYPE: NOTE_FIELD_LABEL_ASSET_TYPE,
    PRODUCT_TYPE: NOTE_FIELD_LABEL_PRODUCT_TYPE,
    PRODUCT_CLASS: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_PRODUCT_CLASS_LABEL",
    ASSET_GEOGRAPHY: "PRODUCT_DETAILS_OVERVIEW_GEOGRAPHY",
    STRUCTURE_TYPE: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_STRUCTURE_TYPE_LABEL",
    UNDERLIER: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_UNDERLIER_LABEL",
    AUTO_CALL_LEVEL: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_AUTOCALL_LEVEL_LABEL",
    AUTO_CALL_FREQUENCY: NOTE_FIELD_LABEL_AUTO_CALL_FREQUENCY,
    BARRIER_LEVEL: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_BARRIER_LEVEL_LABEL",
    BUFFER_LEVEL: "PRODUCT_DETAILS_OVERVIEW_BUFFER_LEVEL_LABEL",
    BOOSTED_RETURN: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_BOOSTED_RETURN_LABEL",
    FIXED_RETURN: "PRODUCT_DETAILS_OVERVIEW_FIXED_RETURN",
    DOWN_SIDE_PROTECTION_STRIKE: NOTE_FIELD_LABEL_DOWN_SIDE_PROTECTION_STRIKE,
    DOWN_SIDE_PARTICIPATION: NOTE_FIELD_LABEL_DOWN_SIDE_PARTICIPATION,
    DOWNSIDE_CAP: NOTE_FIELD_LABEL_DOWN_SIDE_CAP,
    DOWNSIDE_MULTIPLIER: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_DOWNSIDE_MULTIPLIER_LABEL",
    PAYMENT_RATE: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_PAYMENT_RATE_LABEL",
    ANNUALIZED_PAYMENT_RATE: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_PAYMENT_RATE_ANNUALIZED",
    COUPON_BARRIER: NOTE_FIELD_LABEL_COUPON_BARRIER,
    COUPON_RATE: NOTE_FIELD_LABEL_COUPON_RATE,
    IRN_COUPON_RATE: NOTE_FIELD_LABEL_IRN_COUPON_RATE,
    COUPON_FREQUENCY: NOTE_FIELD_LABEL_COUPON_FREQUENCY,
    IRN_COUPON_RATE_LABEL: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_COUPON_RATE_LABEL",
    IRN_MIN_COUPON_RATE_LABEL: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_MINIMUM_COUPON_RATE_LABEL",
    IRN_MAX_COUPON_RATE_LABEL: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_MAXIMUM_COUPON_RATE_LABEL",
    COUPON_PAYMENT_THRESHOLD: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_PAYMENT_THRESHOLD_LABEL",
    COUPON_PAYMENT_FREQUENCY: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_PAYMENT_FREQUENCY_LABEL",
    COUPON_YIELD: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_COUPON_YIELD_LABEL",
    VARIABLE_COUPON_STRIKE: NOTE_FIELD_LABEL_VARIABLE_COUPON_STRIKE,
    VARIABLE_COUPON_PARTICIPATION: NOTE_FIELD_LABEL_VARIABLE_COUPON_PARTICIPATION,
    MINI_MUM_VARIABLE_COUPON: NOTE_FIELD_LABEL_MINI_MUM_VARIABLE_COUPON,
    MINI_MUM_VARIABLE_COUPON_PERIOD: NOTE_FIELD_LABEL_MINI_MUM_VARIABLE_COUPON_PERIOD,
    AUTOCALL_FREQUENCY: NOTE_FIELD_LABEL_AUTOCALL_FREQUENCY,
    EXCESS_RETURN_PARTICIPATION: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_EXCESS_RETURN_PARTICIPATION_LABEL",
    UP_SIDE_JUMP_LEVEL: NOTE_FIELD_LABEL_BOOSTED_RETURN,
    BOOST_LEVEL: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_BOOST_LEVEL_LABEL",    
    PARTICIPATION_ABOVE_BOOST: NOTE_FIELD_LABEL_PARTICIPATION_ABOVE_BOOST,
    PARTICIPATION_RATE: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_PARTICIPATION_RATE_LABEL",
    PARTICIPATION_LEVEL: NOTE_FIELD_LABEL_PARTICIPATION_LEVEL,
    PARTICIPATION_CAP: "PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_PARTICIPATION_CAP_LABEL",
    KNOCK_OUT_RETURN: NOTE_FIELD_LABEL_KNOCK_OUT_RETURN,
    KNOCK_OUT_LEVEL: NOTE_FIELD_LABEL_KNOCK_OUT_LEVEL,
    FIXED_SHARE_RETURN: NOTE_FIELD_LABEL_FIXED_SHARE_RETURN,
    MAXI_MUM_SHARE_RETURN: NOTE_FIELD_LABEL_MAXI_MUM_SHARE_RETURN,
    MINI_MUM_SHARE_RETURN: NOTE_FIELD_LABEL_MINI_MUM_SHARE_RETURN,
    ASSET_LEAST_PERFORMING_TICKER: NOTE_FIELD_LABEL_ASSET_LEAST_PERFORMING_TICKER,
    ASSET_BEST_PERFORMING_TICKER: NOTE_FIELD_LABEL_ASSET_BEST_PERFORMING_TICKER,
    ASSET_RAINBOW_WEIGHT: NOTE_FIELD_LABEL_ASSET_RAINBOW_WEIGHT,
    FINAL_MATURITY_LEVEL: NOTE_FIELD_LABEL_FINAL_MATURITY_LEVEL,
    SECTOR: "PRODUCT_DETAILS_OVERVIEW_SECTOR",
    INVESTMENT_OBJECTIVE: "PRODUCT_DETAILS_INVESTMENT_OBJECTIVE"
};

export const NOTE_FIELD_KEYS = {
    ISM:  NOTE_FIELD_KEYS_ISM,
    W360: NOTE_FIELD_KEYS_W360,
    CODE: NOTE_FIELD_KEY_CODE,
    NAME: NOTE_FIELD_KEY_NAME,
    NAME_FR: NOTE_FIELD_KEY_NAME_FR,
    FUND_SERVE_CODE: NOTE_FIELD_KEY_FUND_SERVE_CODE,
    CUSIP: NOTE_FIELD_KEY_CUSIP,
    AVAILABLE_UNTIL_DATE: NOTE_FIELD_KEY_AVAILABLE_UNTIL_DATE,
    MATURITY_DATE: NOTE_FIELD_KEY_MATURITY_DATE,
    FINAL_VALUATION_DATE: NOTE_FIELD_KEY_FINAL_VALUATION_DATE,
    TERM: NOTE_FIELD_KEY_TERM,
    STATUS: NOTE_FIELD_KEY_STATUS,
    STATUS_OVERRIDE: NOTE_FIELD_KEY_STATUS_OVERRIDE,
    ISSUE_DATE: NOTE_FIELD_KEY_ISSUE_DATE,
    CURRENCY: NOTE_FIELD_KEY_CURRENCY,
    NOTE_TYPE: NOTE_FIELD_KEY_NOTE_TYPE,
    ASSET_TYPE: NOTE_FIELD_KEY_ASSET_TYPE,
    ASSET_SECTOR: NOTE_FIELD_KEY_ASSET_SECTOR,
    PRODUCT_CLASS: NOTE_FIELD_KEY_PRODUCT_CLASS,
    PRODUCT_TYPE: NOTE_FIELD_KEY_PRODUCT_TYPE,
    ASSET_GEOGRAPHY: NOTE_FIELD_KEY_ASSET_GEOGRAPHY,
    STRUCTURE_TYPE: NOTE_FIELD_KEY_STRUCTURE_TYPE,
    AUTO_CALL_LEVEL: NOTE_FIELD_KEY_AUTO_CALL_LEVEL,
    AUTO_CALL_FREQUENCY: NOTE_FIELD_KEY_CALL_FREQUENCY,
    BARRIER_LEVEL: NOTE_FIELD_KEY_BARRIER_LEVEL,
    BUFFER_LEVEL: NOTE_FIELD_KEY_BUFFER_LEVEL,
    BOOSTED_RETURN: NOTE_FIELD_KEY_UP_SIDE_JUMP_LEVEL,
    FIXED_RETURN: NOTE_FIELD_KEY_FIXED_RETURN,
    DOWN_SIDE_PROTECTION_STRIKE: NOTE_FIELD_KEY_DOWN_SIDE_PROTECTION_STRIKE,
    DOWN_SIDE_PARTICIPATION: NOTE_FIELD_KEY_DOWN_SIDE_PARTICIPATION,
    DOWNSIDE_CAP: NOTE_FIELD_KEY_DOWN_SIDE_CAP,
    DOWNSIDE_MULTIPLIER: NOTE_FIELD_KEY_DOWN_SIDE_PARTICIPATION,
    DOWNSIDE_MATURITY_BARRIER: NOTE_FIELD_DOWNSIDE_MATURITY_BARRIER,
    COUPON_BARRIER: NOTE_FIELD_KEY_COUPON_BARRIER,
    COUPON_RATE: NOTE_FIELD_KEY_COUPON_RATE,
    COUPON_FREQUENCY: NOTE_FIELD_KEY_COUPON_FREQUENCY,
    COUPON_YIELD: NOTE_FIELD_KEY_COUPON_RATE,
    COUPON_PAYMENT_THRESHOLD: NOTE_FIELD_KEY_COUPON_BARRIER,
    COUPON_PAYMENT_FREQUENCY: NOTE_FIELD_KEY_COUPON_FREQUENCY,
    COUPON_PAYMENT_TYPE: NOTE_FIELD_KEY_COUPON_PAYMENT_TYPE,
    COUPON_PARTICIPATION: NOTE_FIELD_KEY_COUPON_PARTICIPATION,
    VARIABLE_COUPON_STRIKE: NOTE_FIELD_KEY_VARIABLE_COUPON_STRIKE,
    VARIABLE_COUPON_THRESHOLD: NOTE_FIELD_KEY_VARIABLE_COUPON_THRESHOLD,
    MINI_MUM_VARIABLE_COUPON: NOTE_FIELD_KEY_MINI_MUM_VARIABLE_COUPON,
    MINI_MUM_VARIABLE_COUPON_PERIOD: NOTE_FIELD_KEY_MINI_MUM_VARIABLE_COUPON_PERIOD,
    MEMORY: NOTE_FIELD_KEY_MEMORY,
    EXCESS_RETURN_PARTICIPATION: NOTE_FIELD_KEY_VARIABLE_PARTICIPATION,
    BOOST_LEVEL: NOTE_FIELD_KEY_UP_SIDE_JUMP_STRIKE,
    PARTICIPATION_ABOVE_BOOST: NOTE_FIELD_KEY_PARTICIPATION_ABOVE_BOOST,
    PARTICIPATION_RATE: NOTE_FIELD_KEY_UP_SIDE_PARTICIPATION,
    PARTICIPATION_LEVEL: NOTE_FIELD_KEY_PARTICIPATION_LEVEL,
    PARTICIPATION_CAP: NOTE_FIELD_KEY_UP_SIDE_CAP,
    KNOCK_OUT_RETURN: NOTE_FIELD_KEY_KNOCK_OUT_RETURN,
    KNOCK_OUT_LEVEL: NOTE_FIELD_KEY_KNOCK_OUT_LEVEL,
    FIXED_SHARE_RETURN: NOTE_FIELD_KEY_FIXED_SHARE_RETURN,
    MAXI_MUM_SHARE_RETURN: NOTE_FIELD_KEY_MAXI_MUM_SHARE_RETURN,
    MINI_MUM_SHARE_RETURN: NOTE_FIELD_KEY_MINI_MUM_SHARE_RETURN,
    ASSET_LEAST_PERFORMING_TICKER: NOTE_FIELD_KEY_ASSET_LEAST_PERFORMING_TICKER,
    ASSET_BEST_PERFORMING_TICKER: NOTE_FIELD_KEY_ASSET_BEST_PERFORMING_TICKER,
    ASSET_RAINBOW_WEIGHT: NOTE_FIELD_KEY_ASSET_RAINBOW_WEIGHT,
    FINAL_MATURITY_LEVEL: NOTE_FIELD_KEY_FINAL_MATURITY_LEVEL,
    IS_CONTINGENT_COUPON: NOTE_FIELD_KEY_IS_CONTINGENT_COUPON,
    IS_COUPON_PEROIDIC: NOTE_FIELD_KEY_IS_COUPON_PEROIDIC,
    IS_VARIABLE_AUTOCALL: NOTE_FIELD_KEY_IS_VARIABLE_AUTOCALL,
    IS_CALLABLE: NOTE_FIELD_KEY_IS_CALLABLE,
    IS_ROC: NOTE_FIELD_KEY_IS_ROC,
    IS_COUPON: NOTE_FIELD_KEY_IS_COUPON,
    UPSIDE_PARTICIPATION: NOTE_FIELD_KEY_UP_SIDE_PARTICIPATION,
    UPSIDE_STRIKE: NOTE_FIELD_KEY_UPSIDE_STRIKE,
    HAS_UPSIDE_JUMP: NOTE_FIELD_KEY_HAS_UPSDIE_JUMP,
    IS_BOOSTED: NOTE_FIELD_KEY_IS_BOOSTED,
    IS_RAINBOW: NOTE_FIELD_KEY_IS_RAINBOW,
    RAINBOW_TYPE: NOTE_FIELD_KEY_RAINBOW_TYPE,
    MATURITY_AVERAGING_DATES: NOTE_FIELD_KEY_MATURITY_AVERAGING_DATES,
    CALL_FREQUENCY: NOTE_FIELD_KEY_CALL_FREQUENCY,
    NON_CALL_PERIOD: NOTE_FIELD_KEY_NON_CALL_PERIOD,
    VARIABLE_COUPON_PARTICIPATION: NOTE_FIELD_KEY_VARIABLE_PARTICIPATION,
    SALES_COMMISSION: NOTE_FIELD_KEY_SALES_COMMISSION,
    EXCESS_RETURN_STRIKE: NOTE_FIELD_KEY_EXCESS_RETURN_STRIKE,
    MAX_NOTE_RETURN: NOTE_FIELD_KEY_MAX_NOTE_RETURN,
    INVESTMENT_OBJECTIVE: NOTE_FIELD_KEYS_INVESTMENT_OBJECTIVE,
    ACCOUNT_ELIGIBILITY: NOTE_FIELD_KEYS_ACCOUNT_ELIGIBILITY,
    ASSET_PERFORMANCE_TYPE: NOTE_FIELD_KEYS_ASSET_PERFORMANCE_TYPE,
    COUPON_TYPE: NOTE_FIELD_KEYS_COUPON_TYPE
};

export const IRN_NOTE_FEATURES = {
    TERM: NOTE_FIELD_KEY_IRN_TERM,
    MAX_TERM: NOTE_FIELD_KEY_IRN_MAX_TERM,
    EXTENSION_FREQ: NOTE_FIELD_KEY_IRN_EXTENSION_FREQ,
    IS_BAIL_IN: NOTE_FIELD_KEY_IRN_IS_BAIL_IN,
    COUPON_TYPE: NOTE_FIELD_KEY_IRN_COUPON_TYPE,
    COUPON_RATE: NOTE_FIELD_KEY_IRN_COUPON_RATE,
    REFERENCE_RATE: NOTE_FIELD_KEY_IRN_REFERENCE_RATE,
    SPREAD: NOTE_FIELD_KEY_IRN_SPREAD,
    MIN_COUPON_RATE: NOTE_FIELD_KEY_IRN_MIN_COUPON_RATE,
    MAX_COUPON_RATE: NOTE_FIELD_KEY_IRN_MAX_COUPON_RATE,
    COUPON_FREQUENCY: NOTE_FIELD_KEY_IRN_COUPON_FREQ,
}

export const NOTE_SEARCH_LABEL_KEYS = {
    KEYWORD_SEARCH_PLACEHOLDER: "KEYWORD_SEARCH_PLACEHOLDER",
    ADVANCED_SEARCH: "ADVANCED_SEARCH",
    PRODUCT_CLASS: "ADVANCED_SEARCH_FIELD_PRODUCT_CLASS",
    ASSET_TYPE: "ADVANCED_SEARCH_FIELD_UNDERLYING_INTEREST_TYPE",
    NOTE_TYPE: "Note Type",
    CURRENCY: "ADVANCED_SEARCH_FIELD_CURRENCY",
    INVESTOR_PROFILE: "ADVANCED_SEARCH_FIELD_INVESTMENT_OBJECTIVE",
    GEOGRAPHY: "ADVANCED_SEARCH_FIELD_GEOGRAPHY",
    SECTOR: "ADVANCED_SEARCH_FIELD_SECTOR",
    PRODUCT_CODE: "Product Code",
    PAYMENT_FREQUENCY: "ADVANCED_SEARCH_FIELD_PAYMENT_FREQUENCY",
    ISSUE_DATE: "Issue Date",
    MATURITY_DATE: "Maturity Date",
    ISSUE_DATE_FROM: "ADVANCED_SEARCH_FIELD_ISSUE_DATE_FROM",
    ISSUE_DATE_TO: "ADVANCED_SEARCH_FIELD_ISSUE_DATE_TO",
    MATURITY_DATE_FROM: "ADVANCED_SEARCH_FIELD_MATURITY_DATE_FROM",
    MATURITY_DATE_TO: "ADVANCED_SEARCH_FIELD_MATURITY_DATE_TO",
    DATE_TO: "to",
    TERM: "Term (Years)",
    TERM_FROM: "ADVANCED_SEARCH_FIELD_TERM_FROM",
    TERM_TO: "ADVANCED_SEARCH_FIELD_TERM_TO",
    PRODUCT_TYPE: "ADVANCED_SEARCH_FIELD_PRODUCT_TYPE",
    STRUCTURE_TYPE: "ADVANCED_SEARCH_FIELD_STRUCTURE_TYPE",
    CURRENT_STATUS: "ADVANCED_SEARCH_FIELD_CURRENT_STATUS"
};

export const CATEGORY = {
    NOTE_TYPE: "note_type",
    PRODUCT_CLASS: "product_class",
    PRODUCT_TYPE: "product_type",
    NOTE_SUB_TYPE: "note_sub_type",
    IRN_COUPON_FREQ: "irn_coupon_frequency",
    INVESTMENT_OBJECTIVE: "investment_objective",
    ASSET_SECTOR: "asset_sector",
    CURRENCY: "currency",
    ASSET_GEOGRAPHY: "asset_geography",
}

export const NOTE_SEARCH_DROPDOWN_OPTIONS = {
    // label_key, value
    BUFFER_LEVELS: [
        "0-10",
        "11-25",
        "26-30",
        "31-40",
        "41-50",
        "51+"
    ],
    CURRENT_STATUSES: [
        ["NOTE_STATUS_NEW", "New"],
        ["NOTE_STATUS_CLOSING_SOON", "Closing Soon"],
        ["NOTE_STATUS_CLOSED", "Closed"],
        ["NOTE_STATUS_ACTIVE", "Active"],
        ["NOTE_STATUS_CALLED", "Called"],
        ["NOTE_STATUS_MATURED", "Matured"]
    ],
    TERMS: [
        "0.5",
        "1",
        "1.5",
        "2",
        "2.5",
        "3",
        "3.5",
        "4",
        "4.5",
        "5",
        "5.5",
        "6",
        "6.5",
        "7",
        "7.5",
        "8",
        "8.5",
        "9",
        "9.5",
        "10"
    ]
};

export const NOTE_SEARCH_FIELDS = {
    KEYWORD: "keyword",
    CURRENT_OFFERINGS: "currentOfferings",
    PREVIOUS_OFFERINGS: "previousOfferings",
    PRODUCT_TYPE: "productType",
    PRODUCT_CLASS: "productClass",
    UNDERLYING_ASSET_TYPE: "underlyingAssetType",
    STRUCTURE_TYPE: "structureType",
    BARRIER_BUFFER_LEVEL: "barrierBufferLevel",
    PAYMENT_FREQUENCY: "paymentFrequency",
    INVESTMENT_OBJECTIVE: "investmentObjective",
    SECTOR: "sector",
    CURRENCY: "currency",
    GEOGRAPHY: "geography",
    CURRENT_STATUS: "currentStatus",
    ISSUE_DATE_FROM: "issueDateFrom",
    ISSUE_DATE_TO: "issueDateTo",
    MATURITY_DATE_FROM: "maturityDateFrom",
    MATURITY_DATE_TO: "maturityDateTo",
    TERM_YEARS_FROM: "termYearsFrom",
    TERM_YEARS_TO: "termYearsTo",
    PAGE: "page",
    PAGE_SIZE: "pageSize",
    ORDER_BY: "orderBy",
    DESCENDING: "descending"
};

export const NOTE_EVENT_FIELDS = {
    AUTOCALL_DATE: "autoCallDate",
    VALUATION_DATE: "valuationDate",
    FIXED_RETURN: "fixedReturn",
    AUTOCALL_LEVEL: "autoCallLevel",
    CLOSING_LEVEL: "closingLevel",
    AUTOCALL_FEATURE: "autoCallFeature",
    UNDERLYING_INTEREST_RETURN: "underlyingInterestReturn",
    MATURITY: "maturity",
    PAY_THRESHOLD: "payThreshold",
    PAYMENT: "payment",
    ROC: "roc",
    ADJUST_COST_BASE: "adjustedCostBase",
    COUPON_PAY_DATE: "couponPayDates",
    COUPON_PAID: "couponPaid",
    COUPON: "coupon",
    MATURITY_DATE: "maturityDate",
    FINAL_VALIDATE_DATE: "finalValDate",
    OPENING_LEVEL: "openingLevel",
    BARRIER_BUFFER_FLOOR_LEVEL: "barrierBufferOrFloorLevel",
    DOWNSIDE_MULTIPLIER: "downsideMultiplier",
    MATURITY_REDEMPTION_PAYMENT: "maturiyRedemptionPayment", 
};

export const COUPON_PAYMENT_TYPE = {
    AT_REDEMPTION: 'At Redemption',
    AT_MATURITY: "At Maturity",
    PERIODIC: "Periodic",
}

export const EVENT_TYPE = {
    AUTOCALLED: 'Autocall',
    MATURITY: "Maturity",
    COUPON: "Coupon",
}

export const STRUCTURE_TYPE = {
    AUTOCALLABLE: 'Auto-callable',
    ISSUER_CALLABLE: 'Issuer Callable'
}

export const COUPON_TYPE = {
    FIXED: 'Fixed',
}

export const PERFORMANCE_TIME_SERIES = {
    UNDERLYING_REFERENCE: 'asset',
    MATURITY: "maturity",
}

export const PERFORMANCE_TYPE = {
    ASSET_LEVEL: 'assetLevel', 
    CURRENT_LEVEL: 'currentLevel', 
    ASSET_RETURN: 'assetReturn',
    BID_PRICE: 'bidPrice',
    ASSET_PERFORMANCE: 'assetPerformance',
    INDICATIVE_COUPON: 'indicativeCoupon',
    INDICATIVE_MATURITY: 'indicativeMaturity',
    ASSET_PERFORMANCE_ADJUSTED: 'assetPerformanceAdjusted',
    INITIAL_LEVEL: 'initialLevel',
    SINGLE_ASSET: 'Single asset', 
    DYNAMIC: 'Dynamic',
    EARLY_TRADING_FEE: 'earlyTradingFee',
    WEIGHT: "weight",
}

export const PRODUCT_CLASS = {
    A_CLASS: 'A-Class', 
    F_CLASS: 'F-Class'
}