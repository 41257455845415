import React from 'react';
import { Link } from "react-router-dom";
import {
    TDS_TOP_NAV_MOBILE,
    TYPE_DROPDOWN,
    TYPE_LINK,
    LINK_DESTINATION_INTERNAL,
    LINK_DESTINATION_EXTERNAL,
    LINK_DESTINATION_NONE,
    LINK_DESTINATION_LANGUAGE
} from '../../../constants/HeaderNav.constants';
import {
    LANGUAGE_ENGLISH
} from '../../../constants/Misc.constants';
import TranslateComponent from '../../misc/translate/Translate.component';
import HeaderNavDropDownMobileComponent from './HeaderNavDropDownMobile.component';
import LanguageService from '../../../services/Language.service';
import { LOG_LEVEL, logger } from '../../../system/Logger';

export default class HeaderNavBarMobileComponent extends React.Component {
    topClassName = TDS_TOP_NAV_MOBILE;
    constructor(props) {
        super(props);
        logger.log(LOG_LEVEL.DEBUG, "HeaderNavBarMobileComponent", this.props);
        this.menuItems = this.props.menuItems;
        this.handleMenuItemLinkClick = this.handleMenuItemLinkClick.bind(this);
        this.handleLanguageClick = this.handleLanguageClick.bind(this);
        this.createMenuItems();
        this.state = {
            menuItems: props.menuItems || []
        }
    }
    // Added to ensure updates to component when props changes
    static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.menuItems !== prevState.menuItems) {
        return {
            menuItems: nextProps.menuItems
        }
    }
    return null;
}
    handleMenuItemLinkClick = (event) => {
        if (this.props.closeNavPanel) {
            this.props.closeNavPanel();
        }
        if (event.target.dataset.hasaccess === "false") {
            event.preventDefault();
            this.props.showModal(event.target.modaltype);
        }        
    }
    handleLanguageClick(e) {
        e.preventDefault();
        var language = LANGUAGE_ENGLISH;
        if (e.target && e.target.dataset && e.target.dataset.language) {
            language = e.target.dataset.language;
        }
        LanguageService.switchLanguage(language);
        if (this.props.closeNavPanel) {
            this.props.closeNavPanel();
        }
    }
    createMenuItems() {
        let menuItemsDOM = [];
         let menuItemsREF = [];
    
        if (this.props.menuItems) {
            for (var menuItem of this.props.menuItems) {
                if (menuItem.destination === LINK_DESTINATION_EXTERNAL && menuItem.type === TYPE_LINK) {
                    menuItemsDOM.push(
                        <div className="tds-mobile-navbar-item tds-mobile-navbar-main-link" key={menuItem.text}>
                            <a
                                href={menuItem.link}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {
                                    menuItem.isTranslatable &&
                                    <TranslateComponent label={menuItem.text} />
                                }
                                {
                                    !menuItem.isTranslatable &&
                                    <React.Fragment>
                                        {menuItem.text}
                                    </React.Fragment>
                                }
                            </a>
                        </div>
                    )
                } else if (menuItem.destination === LINK_DESTINATION_INTERNAL && menuItem.type === TYPE_LINK) {
                    menuItemsDOM.push(
                        <div className="tds-mobile-navbar-item tds-mobile-navbar-main-link" key={menuItem.text}>
                            <Link
                                to={menuItem.link}
                                data-link={menuItem.link}
                                data-hasaccess={menuItem.hasAccess}
                                data-modaltype={menuItem.modalType}
                                onClick={this.handleMenuItemLinkClick}
                            >
                                {
                                    menuItem.isTranslatable &&
                                    <TranslateComponent label={menuItem.text} />
                                }
                                {
                                    !menuItem.isTranslatable &&
                                    <React.Fragment>
                                        {menuItem.text}
                                    </React.Fragment>
                                }
                            </Link>
                        </div>
                    )
                } else if (menuItem.destination === LINK_DESTINATION_LANGUAGE) {
                    menuItemsDOM.push(
                        <div className="tds-mobile-navbar-item tds-mobile-navbar-main-link" key={menuItem.text}>
                            <a
                                href={'# '}
                                data-language={menuItem.language}
                                onClick={this.handleLanguageClick}
                            >
                                {
                                    menuItem.isTranslatable &&
                                    <TranslateComponent label={menuItem.text} />
                                }
                                {
                                    !menuItem.isTranslatable &&
                                    <React.Fragment>
                                        {menuItem.text}
                                    </React.Fragment>
                                }
                            </a>
                            {
                                LanguageService.isLanguage(menuItem.language) &&
                                <span className="td-icon td-icon-checkMark icon-small"></span>
                            }
                        </div>
                    )
                } else if (menuItem.destination === LINK_DESTINATION_NONE && menuItem.type === TYPE_DROPDOWN) {
                    var child = React.createRef();
                    menuItemsREF.push(child);
                    menuItemsDOM.push(
                        <HeaderNavDropDownMobileComponent
                            dropDownData={menuItem}
                            key={menuItem.text}
                            closeNavPanel={this.props.closeNavPanel}
                            showModal={this.props.showModal}
                        />
                    )
                } else {
                    logger.log(LOG_LEVEL.DEBUG, "HeaderNavBarMobileComponent did not do anything with this", menuItem);
                }
            }
        }
        //Added with refactor for PRIIP to ensure updates with new props
    return menuItemsDOM
    }
    render() {
        if (this.props.menuItems) {
            return (
                <div className={this.topClassName}>
                    {this.createMenuItems()}
                </div>
            )
        } else {
            return null;
        }
    }

}