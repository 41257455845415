// HEADER NAV CLASS NAMES BEGIN

import { LINK_PATH_HOME, LINK_PATH_RESOURCES_FAQS, LINK_PATH_RESOURCES_PRODUCT_INFO } from "./LinkPaths.constants";
import { INVESTOR_FILE_NAME } from "./Misc.constants";
import { REST_STATIC_FILE_DOWNLOAD_PATH } from "./Rest.constants";
import LanguageService from "../services/Language.service";

export const TDS_TOP_NAV = "tds-top-nav";
export const TDS_TOP_NAV_RIGHT = "tds-top-nav right";
export const TDS_TOP_NAV_DROPDOWN_LINK = "tds-top-nav-dropdown-link";
export const TDS_DROPDOWN_CONTENT = "tds-dropdown-content";
export const TDS_NAVBAR = "tds-navbar";
export const TDS_NAVBAR_DROPDOWN_LINK = "tds-navbar-dropdown-link";
export const TDS_DROPDOWN_ACTIVE = " active";

export const TDS_TOP_NAV_MOBILE = "tds-mobile-navbar";
export const TDS_ACTIVE = " active";
export const TDS_TOP_NAV_DROPDOWN_LINK_MOBILE = "tds-mobile-navbar-dropdown-link";
export const TDS_DROPDOWN_CONTENT_MOBILE = "tds-mobile-dropdown-content";
// HEADER NAV CLASS NAMES END

export const T_SEARCH = 'SEARCH';
export const T_HOME = 'HOME';
export const HOME_PATH = '/home';

export const TYPE_DROPDOWN = "dropDown";
export const TYPE_LINK = "link";
export const LINK_DESTINATION_INTERNAL = 'internal';
export const LINK_DESTINATION_EXTERNAL = 'external';
export const LINK_DESTINATION_LANGUAGE = 'language';
export const LINK_DESTINATION_NONE = 'none';

export const MODAL_TYPE_DEFAULT = 'default';
/* other nav beside logo */
export const HEADER_TOP_LEFT_NAV_MENU_DATA = [
    {

        link: LINK_PATH_HOME,
        text: "Home",
        title: "Home",
        icon: "td-icon-homepage",
        type: TYPE_LINK,
        hasAccess: true,
        modalType: MODAL_TYPE_DEFAULT,
        destination: LINK_DESTINATION_INTERNAL
    }
];

/* other nav beside logo */
export const HEADER_TOP_RIGHT_MENU_DATA = () => [    
    {
        link: null,
        text: "QUICK_LINKS",
        title: "QUICK_LINKS",
        type: "dropDown",
        destination: LINK_DESTINATION_NONE,
        isTranslatable: true,
        items: [
            { 
                link: `${REST_STATIC_FILE_DOWNLOAD_PATH}?lang=${LanguageService.currentLanguage}&fileName=${INVESTOR_FILE_NAME}`,
                text: "quick.link.shelf.prospectus", 
                title: "quick.link.shelf.prospectus",
                hasAccess: true,
                modalType: "default",
                isTranslatable: true,
                destination: LINK_DESTINATION_EXTERNAL
            },
            { 
                link: "quick.link.gcba.principal.notes.link", 
                text: "quick.link.gcba.principal.notes", 
                title: "quick.link.gcba.principal.notes",
                hasAccess: true,
                modalType: "default",
                isTranslatable: true,
                destination: LINK_DESTINATION_EXTERNAL
            },
            { 
                link: "https://portal.tdsecurities.com/alpha/landing", 
                text: "quick.link.ficmAndEquity.research", 
                title: "quick.link.ficmAndEquity.research",
                hasAccess: true,
                modalType: "default",
                isTranslatable: true,
                destination: LINK_DESTINATION_EXTERNAL
            },
            { 
                link: "quick.link.investing.solutions.link", 
                text: "quick.link.investing.solutions", 
                title: "quick.link.investing.solutions",
                hasAccess: true,
                modalType: "default",
                isTranslatable: true,
                destination: LINK_DESTINATION_EXTERNAL
            }
        ]
    },
    {
        link: null,
        text: "ENGLISH",
        title: "ENGLISH",
        type: "dropDown",
        destination: LINK_DESTINATION_NONE,
        isTranslatable: true,
        items: [
            { 
                link: "/english", 
                text: "ENGLISH", 
                title: "ENGLISH",
                hasAccess: true,
                modalType: "default",
                isTranslatable: true,
                destination: LINK_DESTINATION_LANGUAGE,
                language: 'en'
            },
            { 
                link: "/french", 
                text: "FRENCH", 
                title: "FRENCH",
                hasAccess: true,
                modalType: "default",
                isTranslatable: true,
                destination: LINK_DESTINATION_LANGUAGE,
                language: 'fr'
            }
        ]
    }    
];

export const HEADER_MAIN_NAV_MENU_DATA = [
    {
        link: "/current-offerings",
        text: "CURRENT_OFFERINGS",
        title: "CURRENT_OFFERINGS",
        type: "link",
        hasAccess: true,
        modalType: "default",
        isTranslatable: true,
        destination: LINK_DESTINATION_INTERNAL
    },
    {
        link: "/previous-offerings",
        text: "PREVIOUS_OFFERINGS",
        title: "PREVIOUS_OFFERINGS",
        type: "link",
        hasAccess: true,
        modalType: "default",
        isTranslatable: true,
        destination: LINK_DESTINATION_INTERNAL
    },
    {
        link: "/events",
        text: "EVENTS",
        title: "EVENTS",
        type: "link",
        hasAccess: true,
        modalType: "default",
        isTranslatable: true,
        destination: LINK_DESTINATION_INTERNAL
    },
    {
        link: null,
        text: "RESOURCES",
        title: "RESOURCES",
        type: "dropDown",
        destination: LINK_DESTINATION_NONE,
        isTranslatable: true,
        items: [
           /* {
                link: LINK_PATH_RESOURCES_INVESTOR_INFO,
                text: "INVESTOR_PROFILE_FRAMEWORK",
                title: "INVESTOR_PROFILE_FRAMEWORK",
                type: TYPE_LINK,
                modalType: MODAL_TYPE_DEFAULT,
                hasAccess: true,
                isTranslatable: true,
                destination: LINK_DESTINATION_INTERNAL,
            },*/
            {
                link: LINK_PATH_RESOURCES_PRODUCT_INFO,
                text: "PRODUCT_INFO",
                title: "PRODUCT_INFO",
                type: TYPE_LINK,
                modalType: MODAL_TYPE_DEFAULT,
                hasAccess: true,
                isTranslatable: true,
                destination: LINK_DESTINATION_INTERNAL,
            },
            // {
            //     link: LINK_PATH_RESOURCES_PRIIP_PAGE,
            //     text: "PRIIPs KID Service",
            //     title: "PRIIPs KID Service",
            //     type: TYPE_LINK,
            //     modalType: MODAL_TYPE_DEFAULT,
            //     hasAccess: true,
            //     isTranslatable: true,
            //     destination: LINK_DESTINATION_INTERNAL,
            // },
            {
                link: LINK_PATH_RESOURCES_FAQS,
                text: "FAQS",
                title: "FAQS",
                type: TYPE_LINK,
                modalType: MODAL_TYPE_DEFAULT,
                hasAccess: true,
                isTranslatable: true,
                destination: LINK_DESTINATION_INTERNAL,
            }
        ]
    }
];

export const HEADER_MAIN_NAV_MOBILE_MENU_DATA = [
    {
        link: "/current-offerings",
        text: "CURRENT_OFFERINGS",
        title: "CURRENT_OFFERINGS",
        type: "link",
        hasAccess: true,
        modalType: "default",
        isTranslatable: true,
        destination: LINK_DESTINATION_INTERNAL
    },
    {
        link: "/previous-offerings",
        text: "PREVIOUS_OFFERINGS",
        title: "PREVIOUS_OFFERINGS",
        type: "link",
        hasAccess: true,
        modalType: "default",
        isTranslatable: true,
        destination: LINK_DESTINATION_INTERNAL
    },
    {
        link: "/events",
        text: "EVENTS",
        title: "EVENTS",
        type: "link",
        hasAccess: true,
        modalType: "default",
        isTranslatable: true,
        destination: LINK_DESTINATION_INTERNAL
    },    
    {
        link: null,
        text: "ENGLISH",
        title: "ENGLISH",
        type: "link",
        language: 'en',
        isTranslatable: true,
        destination: LINK_DESTINATION_LANGUAGE
    },
    {
        link: null,
        text: "FRENCH",
        title: "FRENCH",
        type: "link",
        language: 'fr',
        isTranslatable: true,
        destination: LINK_DESTINATION_LANGUAGE
    }
];
export const HEADER_MAIN_NAV_MOBILE_MENU_DATA_PRIIP = [   
    {
        link: null,
        text: "ENGLISH",
        title: "ENGLISH",
        type: "link",
        language: 'en',
        isTranslatable: true,
        destination: LINK_DESTINATION_LANGUAGE
    },
    {
        link: null,
        text: "FRENCH",
        title: "FRENCH",
        type: "link",
        language: 'fr',
        isTranslatable: true,
        destination: LINK_DESTINATION_LANGUAGE
    }
];