import React, { useState } from "react";
import { KEY_ENTER } from "../../../constants/Misc.constants";

const SimpleSearchComponent = (props) => {
  const { id, label, placeholder, className, hideLabel } = props;

  const [value, setValue] = useState("");

  const submit = () => {
      props.onSearch(value);
  }

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === KEY_ENTER) {
      submit();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submit();
  };

  return (
    <form className={`tds-sn-simple-search-container ${className || ''}`} onSubmit={handleSubmit}>
      <label className={hideLabel ? "tds-sn-visually-hidden": null} htmlFor={id}>{label}</label>
      <div className="search-input-container">
        <input
          type="search"
          name="search"
          className="form-control search"
          id={id}
          value={value}
          placeholder={placeholder}
          onKeyPress={handleKeyPress}
          onChange={handleChange}
        />
        <button
          type="submit"
          value="Search"
          className="td-icon td-icon-search"
          title={label}
        ></button>
      </div>
    </form>
  );
};

export default SimpleSearchComponent;
