import React, {useState} from "react";
import TranslateComponent from "../../misc/translate/Translate.component";
import {
  PAGE_DISCLAIMER_CONFIRMATION,
  PAGE_DISCLAIMER_CONTENT,
  PAGE_DISCLAIMER_COOKIE_CONFIRMATION,
  PAGE_DISCLAIMER_ENTER,
  PAGE_DISCLAIMER_NOTICE,
  PRIIP_ERROR,
} from "../../../constants/Priip.constants";
import Checkbox from "../checkbox/Checkbox.component";

const PriipDisclaimer = ({setRender}) => {
  const [formState, setFormState] = useState({
    country: false,
    cookie: false,
    countryError: "",
    cookieError: "",
  });
  const handleCheckboxChange = (name) => {
    setFormState((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
      countryError: "",
      cookieError: "",
    }));
  };

  const handleSubmit = () => {
    const {cookie, country} = formState;
    if (cookie && country) {
      setRender();
    } else if (!cookie && country) {
      setFormState((prevState) => ({
        ...prevState,
        cookieError: PRIIP_ERROR,
      }));
    } else if (!country && cookie) {
      setFormState((prevState) => ({
        ...prevState,
        countryError: PRIIP_ERROR,
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        cookieError: PRIIP_ERROR,
        countryError: PRIIP_ERROR,
      }));
    }
  };

;

  const {cookie, country, countryError, cookieError} = formState;

  return (
    <div className="tds-priip-disclaimer-container">
      <div className="tds-priip-disclaimer-content">
        <h3 className="tds-priip-disclaimer-title">
          <TranslateComponent label={PAGE_DISCLAIMER_NOTICE} />
        </h3>
        <p>
          <TranslateComponent label={PAGE_DISCLAIMER_CONTENT} />
        </p>
        <div className="tds-priips-disclaimer-checkbox">
          <Checkbox
            selected={country}
            onchange={handleCheckboxChange}
            label={PAGE_DISCLAIMER_CONFIRMATION}
            name={"country"}
            error={countryError}
          />
        </div>
        <div className="tds-priips-disclaimer-checkbox">
          <Checkbox
            selected={cookie}
            onchange={handleCheckboxChange}
            label={PAGE_DISCLAIMER_COOKIE_CONFIRMATION}
            name={"cookie"}
            error={cookieError}
          />
        </div>
        <div className="tds-priip-disclaimer-btn">
        {/* Removing Cancel button and logic as part of business feedback */}
          <button
            className="btn btn-block td-btn-primary-light"
            onClick={handleSubmit}
          >
            <TranslateComponent label={PAGE_DISCLAIMER_ENTER} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(PriipDisclaimer);
