import React, {useEffect, useState} from "react";
import {Redirect, useParams} from "react-router-dom";
import SpinnerComponent from "../../spinner/Spinner.component";
import ProductDetailsOverviewComponent from "../overview/ProductDetailsOverview.component";
import ProductDetailsUnderlyingInterestComponent
  from "../underlying-interest/ProductDetailsUnderlyingInterest.component";
import {PRODUCT_DETAILS_PAGE_TITLE} from "../../../constants/PageTitle.constants";
import LanguageService from "../../../services/Language.service";
import IrnNoteDetailsComponent from "../irn/IrnNoteDetails.component";
import {ROUT_PATH_404_PAGE} from "../../../constants/Routes.constants";
import ProductDetailsNoteEventsComponent from "../note-events/ProductDetailsNoteEvents.component";
import ProductDetailsPerformanceAnalysisComponent from "../performance-analysis/ProductDetailsPerformanceAnalysis.component";
import { NOTE_OPTIONS, NOTE_OPTIONS_WITH_HISTORICAL_NOTE, PRINT_ALL } from "../../../constants/ProductDetails.constants";
import Note from "../../../utils/Note";
import ProductDetailsAdditionalPerformance from "../additionalPerformance/ProductDetailsAdditionalPerformance.component";
import NoteService from "../../../services/Note.service";

const PrintComponent = (props) => {
  const [hasError, setHasError] = useState(false);
  const [noteDetails, setNoteDetails] = useState();
  const [loading, setLoading] = useState(true);
  let { code, checkedOptions } = useParams();
  const [pageSelection, setPageSelection] = useState([]);
  
useEffect(() => {
  const getNoteDetails = async () => {
    try {
      const noteDetail = await NoteService.getNoteDetailsById(code);
      setNoteDetails(noteDetail);
    } catch(err) {
      setHasError(true);
    }      
  };
  getNoteDetails();
}, [code]);

useEffect(() => {
  let checkedOptionArr = checkedOptions ? checkedOptions.split(",") : [];
  const selectPrintByOption = () => {
    let pageSelection = [];
    let isHistoricalNote = NoteService.isHistoricalNote(noteDetails);
    if(noteDetails) {
      if(checkedOptionArr.includes(LanguageService.translate(PRINT_ALL))) {
        pageSelection.push(<ProductDetailsOverviewComponent data={noteDetails}/>);
        if(isHistoricalNote) {
          pageSelection.push(<ProductDetailsAdditionalPerformance data={noteDetails}/>);
        } else {
          pageSelection.push(<ProductDetailsUnderlyingInterestComponent data={noteDetails}/>);
          pageSelection.push(<ProductDetailsNoteEventsComponent data={noteDetails} isExpandable = {"true"}/>);
          pageSelection.push(<ProductDetailsPerformanceAnalysisComponent data={noteDetails}/>);
        }
      }
      else {
        if(checkedOptionArr.includes(LanguageService.translate(NOTE_OPTIONS[0])))
          pageSelection.push(<ProductDetailsOverviewComponent data={noteDetails}/>);
          if(!isHistoricalNote) {
            if(checkedOptionArr.includes(LanguageService.translate(NOTE_OPTIONS[1])))
              pageSelection.push(<ProductDetailsUnderlyingInterestComponent data={noteDetails}/>);
            if(checkedOptionArr.includes(LanguageService.translate(NOTE_OPTIONS[2])))
              pageSelection.push(<ProductDetailsNoteEventsComponent data={noteDetails} isExpandable = {"true"}/>);
            if(checkedOptionArr.includes(LanguageService.translate(NOTE_OPTIONS[3])))
              pageSelection.push(<ProductDetailsPerformanceAnalysisComponent data={noteDetails}/>);
          }
        if(isHistoricalNote && checkedOptionArr.includes(LanguageService.translate(NOTE_OPTIONS_WITH_HISTORICAL_NOTE[1]))) {
            pageSelection.push(<ProductDetailsAdditionalPerformance data={noteDetails}/>);
          } 
      }
      setPageSelection(pageSelection);
      setLoading(false);
    }
  }
  selectPrintByOption();  
}, [checkedOptions, noteDetails]);

  useEffect(() => {
    document.title = PRODUCT_DETAILS_PAGE_TITLE;
  }, [])

  useEffect(() => {
    if(loading === false) {
      return setTimeout(() => {
        handlePrint();
      }, 3000);
    }
  }, [loading]);
  
  const handlePrint = () => {
    window.print();   
  }

  const showSpinner = () => loading && <SpinnerComponent />;
  const resultFetched = () => !loading;
  const getNoteName = () => !loading && (LanguageService.chooseLang(noteDetails?.longName, noteDetails?.longNameFr));

  const redirectTo404 = () => hasError && <Redirect to={ROUT_PATH_404_PAGE} />
  const showIrnDetails = () => Note.isIrnNote(noteDetails) && <IrnNoteDetailsComponent data={noteDetails}/>;

  const showNonIrnDetails = () => {
    if(!Note.isIrnNote(noteDetails)) {
      return (
        <div className="tds-container">
        <div className="tds-sn-product-details-tab-content-container">   
          { 
            pageSelection.map((item, index) => (
              <div key={index}>
                {item}
              </div>
            ))
          }
        </div>
      </div>
      )
    }
  }

  return (
    <div className="tds-sn-product-details-container printable">
      <div className="tds-container">
        <div className="tds-sn-product-details-title-row">
          <h1 className="tds-sn-product-details-title">{getNoteName()}</h1>
          <div className="tds-sn-product-details-icons-container">
          </div>
        </div>
        
      </div>
      {showSpinner()}
      {redirectTo404()}
      {resultFetched() && showIrnDetails()}
      {resultFetched() && showNonIrnDetails()}
    </div>
  );
 
};
export default PrintComponent;