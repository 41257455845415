import Note from "../utils/Note";
import moment from "moment"
import { PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS } from "../constants/ProductDetails.constants";
import {
  getValueInPercentage,
  getYesOrNoValue,
  isNotEmpty,
  removeTrailingZerosAfterDecimal,
  toFixedDecimalLength  
} from "../utils/valueUtils";
import LanguageService from "./Language.service";

const { NOTE_FIELD_KEYS, COUPON_PAYMENT_TYPE, STRUCTURE_TYPE, IRN_NOTE_FEATURES, PPN_NOTE_TYPE } = require("../constants/Notes.constants");

export default class NoteFeatureService {
  static getMaximumShareReturn = (note) => {
    return Note.getFeatureValue(NOTE_FIELD_KEYS.MAXI_MUM_SHARE_RETURN, note);
  }

  static getMinimumShareReturn = (note) => {
    return Note.getFeatureValue(NOTE_FIELD_KEYS.MINI_MUM_SHARE_RETURN, note);
  }

  static getFixedReturn = (note, labelByCondition) => {
    
    if (labelByCondition === PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.fixedReturns) {
      
      let fixedReturns = "";
      const couponSchedules = note.couponSchedule;
      
      for ( const couponSchedule of couponSchedules ) {
        fixedReturns += (couponSchedule.couponRate ? (removeTrailingZerosAfterDecimal(couponSchedule.couponRate*100, 5)) + "%/" : '');          
      }
      return fixedReturns.substring(0, fixedReturns.length - 1);
    } else {
      const fixedSharedReturn = Note.getFeatureValue(NOTE_FIELD_KEYS.FIXED_SHARE_RETURN, note)
      if (fixedSharedReturn) {
        return getValueInPercentage(fixedSharedReturn);
      }      
    } 
    return null;    
  }

  static getFixedReturnsField = (note, label) => {
    const labelByCondition = this.getLabelByCondition(note);
    const fixedReturn = this.getFixedReturn(note, labelByCondition);
    
    if (isNotEmpty(fixedReturn)) {
      return {
        key: (label) ? label : labelByCondition,
        value: fixedReturn
      }
    }
    return null;    
  }

  static getLabelByCondition = (note) => {
    const couponPaymentType = this.getCouponPaymentType(note);
    const structreType = note[NOTE_FIELD_KEYS.STRUCTURE_TYPE];
    const isCoupon = note[NOTE_FIELD_KEYS.IS_COUPON] ? true : false;
    if (couponPaymentType && 
        couponPaymentType === COUPON_PAYMENT_TYPE.AT_MATURITY && 
        structreType && 
        structreType === STRUCTURE_TYPE.AUTOCALLABLE && isCoupon) {
  
      return PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.fixedReturns;
    } else {
      const fixedSharedReturn = Note.getFeatureValue(NOTE_FIELD_KEYS.FIXED_SHARE_RETURN, note)
      if (fixedSharedReturn) {

        return PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.fixedShareReturn;
      }      
    }
  }

  static getMaximumNoteReturn = (note) => {
    return Note.getFeatureValue(NOTE_FIELD_KEYS.MAX_NOTE_RETURN, note);
  }

  static getKnockoutLevel = (note) => {
    return Note.getFeatureValue(NOTE_FIELD_KEYS.KNOCK_OUT_LEVEL, note);
  }

  static getKnockoutReturn = (note) => {
    return Note.getFeatureValue(NOTE_FIELD_KEYS.KNOCK_OUT_RETURN, note);
  }

  static getExcessReturnStrike = (note) => {
    return Note.getFeatureValue(NOTE_FIELD_KEYS.EXCESS_RETURN_STRIKE, note);
  }

  static getBoostLevel = (note) => {
    return Note.getFeatureValue(NOTE_FIELD_KEYS.BOOST_LEVEL, note);
  }

  static getBoostedReturn = (note) => {
    return Note.getFeatureValue(NOTE_FIELD_KEYS.BOOSTED_RETURN, note);
  }

  static getParticipationCap = (note) => {
    return Note.getFeatureValue(NOTE_FIELD_KEYS.PARTICIPATION_CAP, note);
  }

  static getParticipationRate = (note) => {
    return Note.getFeatureValue(NOTE_FIELD_KEYS.PARTICIPATION_RATE, note);
  }

  static getParticipationLevel = (note) => {
    return Note.getFeatureValue(NOTE_FIELD_KEYS.UPSIDE_STRIKE, note);
  }

  static getFloorLevel = (note) => {
    let floorLevel = Note.getFeatureValue(NOTE_FIELD_KEYS.DOWNSIDE_CAP, note);
    floorLevel = floorLevel || floorLevel === 0 ? '' : Math.round(floorLevel);
    return floorLevel;
  }

  static getDownsideMultiplier = (note) => {
    return Note.getFeatureValue(NOTE_FIELD_KEYS.DOWNSIDE_MULTIPLIER, note);
  }

  static getDownsideMultiplierField = (note) => {
    const downsideMultiplier = this.getDownsideMultiplier(note);
    return downsideMultiplier ? toFixedDecimalLength(downsideMultiplier, 5) : ''
  }

  static getDownsideProtectionStrike = (note) => {
    return Note.getFeatureValue(NOTE_FIELD_KEYS.DOWN_SIDE_PROTECTION_STRIKE, note);
  }

  static getDownsideProtectionStrikeField = (note) => {
    const downsideProtectionStrike = this.getDownsideProtectionStrike(note);
    if (downsideProtectionStrike) {
      return {
        key: PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.downsideProtectionStrike,
        value: getValueInPercentage(downsideProtectionStrike)
      };
    }
    return null;
  }

  static getDownsideMaturityBarrier = (note) => {
    return Note.getFeatureValue(NOTE_FIELD_KEYS.DOWNSIDE_MATURITY_BARRIER, note);
  }

  static getCouponPaymentType = (note) => {
    return Note.getFeatureValue(NOTE_FIELD_KEYS.COUPON_PAYMENT_TYPE, note);
  }

  static getBarrierLevelField = (note, barrierLevelLabel) => {
    const downsideProtectionStrike = this.getDownsideProtectionStrike(note);
    let barrierLevel = this.getDownsideMaturityBarrier(note);
    if (barrierLevel && ( ( downsideProtectionStrike && downsideProtectionStrike !== barrierLevel ) || !downsideProtectionStrike ) ) {
      const key = ( barrierLevelLabel ) ? barrierLevelLabel : PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.barrierLevel;
      return {
        key,
        value: Note.convertToPercentage(barrierLevel)
      }
    }
    return null;
  }

  static getBufferLevelField = (note, bufferLevelLabel) => {
    const downsideProtectionStrike = this.getDownsideProtectionStrike(note);
    let barrierLevel = this.getDownsideMaturityBarrier(note);
    if (barrierLevel && downsideProtectionStrike && downsideProtectionStrike === barrierLevel) {
      const key = ( bufferLevelLabel ) ? bufferLevelLabel : PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.bufferLevel;
      return {
        key,
        value: Note.convertToPercentage(barrierLevel)
      }
    }
    return null;
  }

  static getMemory = (note) => {
    return Note.getFeatureValue(NOTE_FIELD_KEYS.MEMORY, note);
  }

  static getMemoryField = (note) => {
    const memory = this.getMemory(note);
    if (memory && memory === "true") {
      return {
        key: PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.memory,
        value: getYesOrNoValue(memory.toLocaleLowerCase(), LanguageService.currentLanguage)
      }
    }
    return null;
  }

  static getMinimumVariableCouponRate = (note) => {
    return Note.getFeatureValue(NOTE_FIELD_KEYS.MINI_MUM_VARIABLE_COUPON, note);
  }

  static getCouponParticipation = (note) => {
    return Note.getFeatureValue(NOTE_FIELD_KEYS.COUPON_PARTICIPATION, note);
  }

  static getVariableCouponThreshold = (note) => {
    return Note.getFeatureValue(NOTE_FIELD_KEYS.VARIABLE_COUPON_THRESHOLD, note);
  }

  static getPaymentThreshold = (note) => {
    return Note.getFeatureValue(NOTE_FIELD_KEYS.COUPON_BARRIER, note);
  }

  static getAutoCallThreshold = (note) => {
    if (note?.autocallSchedule?.length > 0) {
      const now = moment.now(note);
      const afterNow = note.autocallSchedule.filter(record => moment(record.autocallPayDate).isAfter(now));
      const sorted = afterNow.sort((first, second) => {
        const firstDate = moment(first.autocallPayDate);
        const secondDate = moment(second.autocallPayDate);
        if (firstDate.isBefore(secondDate)) {
          return -1;
        }
        else if (firstDate.isAfter(secondDate)) {
          return 1;
        }
        return 0;
      });
      if (sorted?.length > 0) {
        return sorted[0].autocallLevel;
      }
    }
    return null;
  }; 

  static getNonCallPeriod = (note) => {
    return Note.getFeatureValue(NOTE_FIELD_KEYS.NON_CALL_PERIOD, note);
  }

  static getVariableAutoCall = (note) => {
    return Note.getFeatureValue(NOTE_FIELD_KEYS.IS_VARIABLE_AUTOCALL, note);
  }

  static getVariableCouponParticipation = (note) => {
    return Note.getFeatureValue(NOTE_FIELD_KEYS.VARIABLE_COUPON_PARTICIPATION, note);
  }

  static getCouponRate = (note) => {
    return Note.getFeatureValue(NOTE_FIELD_KEYS.COUPON_RATE, note);
  }

  static getIrnCouponRate = (note) => {
    return Note.getFeatureValue(IRN_NOTE_FEATURES.COUPON_RATE, note);
  }

  static comparePerformanceTimeSeries = (a, b) => {
    const d1 = moment(a.reportDate);
    const d2 = moment(b.reportDate);

    if(d1.isBefore(d2)) {
      return 1;
    }
    if(d1.isAfter(d2)) {
      return -1;
    }
    return 0;
  }

  static getBidPrice = (note) => {
    if(note?.performanceTimeseries?.length > 0) {
      const bidPrices = note.performanceTimeseries.filter(e => e.performanceType === 'bidPrice');
      if(bidPrices.length > 0) {
        const latestBid = bidPrices.sort(this.comparePerformanceTimeSeries)[0];
        return latestBid.value;
      }
    }
    return null;
  }

  static getCurrentETF = (note) => {
    if(note?.earlyTradingFee) {
      const now = moment.now();

      const found = note.earlyTradingFee.find(e => {
        const start = moment(e.startDate);
        const end = moment(e.endDate);
        return start.isSameOrBefore(now) && end.isSameOrAfter(now);
      })

      if(found) {
        return found.tradingFee;
      }
    }

    return null;
  }

  static getCouponScheduleRate = (note) => {
    const now = moment.now();
    if(note.couponSchedule) {
        const afterNow = note.couponSchedule.filter(schedule => moment(schedule.couponPayDate).isAfter(now));
        const sorted = afterNow.sort((first, second) => {
            const firstDate = moment(first.couponPayDate);
            const secondDate = moment(second.couponPayDate);
            if (firstDate.isBefore(secondDate)) {
              return -1;
            }
            else if (firstDate.isAfter(secondDate)) {
              return 1;
            }
            return 0;
          });
          if (sorted?.length > 0) {
            return sorted[0].couponRate;
          }
    }
    return null;
  }
  
  static getAssetPerformanceType = (note) => {
    return Note.getFeatureValue(NOTE_FIELD_KEYS.ASSET_PERFORMANCE_TYPE, note);
  }

  static getNoteType = (note) => {
    return note?.[NOTE_FIELD_KEYS.NOTE_TYPE];
  }

  static getBarrierOrBufferOrFloorLevel(note) {
    const noteType = this.getNoteType(note);
    const downsideMaturityBarrier = this.getDownsideMaturityBarrier(note);
    const downsideCap = this.getFloorLevel(note);
    if( noteType === PPN_NOTE_TYPE ) return '';
    return downsideMaturityBarrier && downsideMaturityBarrier > 0 ? downsideMaturityBarrier : downsideCap ? downsideCap : '';
  }

  static getBarrierOrBufferOrFloorLevelField(note) {
    const barrierOrBufferOrFloorLevel = this.getBarrierOrBufferOrFloorLevel(note);
    return barrierOrBufferOrFloorLevel ? Note.convertToPercentage(barrierOrBufferOrFloorLevel) : '';
  }

}