import React from 'react';

export default class ResponsiveBackgroundImageComponent extends React.Component {
    constructor(props) {
        super(props);
        var imgsrc = this.getImage(window.innerWidth);
        this.state = { 
            imgsrc: imgsrc
        };
        this.resizeListener = this.resizeListener.bind(this);
    }
    componentDidMount() {
        window.addEventListener('resize', this.resizeListener);
    }
    getImage(width) {
        if (width > 1299) {
            return this.props.default;
        } else if (width > 1023 && width < 1300) {
            return this.props.tablet1;
        } else if (width > 767 && width < 1024) {
            return this.props.tablet2;
        } else if (width < 768) {
            return this.props.mobile;
        }
        return this.props.default;
    }
    resizeListener() {
        var imgsrc = this.getImage(window.innerWidth);
        this.setState({
            imgsrc: imgsrc
        });
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeListener);
    }
    handleClick() {
        if(this.props.onClick) this.props.onClick();
    }
    render() {
        return (
            <div 
                className={this.props.className}
                title={this.props.title}
                aria-label={this.props.ariaLabel}
                style={ 
                    {
                        backgroundImage: 'url(' + this.state.imgsrc + ')'
                    }
                }
                onClick={this.handleClick.bind(this)}
            >
                {this.props.children}
            </div>
        )
    }
}