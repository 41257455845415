import { isMobileOnly } from "react-device-detect";
import { chooseLang } from "../services/Language.service";

export const EVENT_PAGE_TITLE = "EVENT_PAGE_TITLE";

export const EVENT_PAGE_TITLE_MOBILE_ONLY = "EVENT_PAGE_TITLE_MOBILE_ONLY";

export const EVENT_PAGE_DATE_FILTER_FROM_LABEL = "EVENT_PAGE_DATE_FILTER_FROM_LABEL";

export const EVENT_PAGE_DATE_FILTER_TO_LABEL = "EVENT_PAGE_DATE_FILTER_TO_LABEL";

export const EVENT_PAGE_BACK_BUTTON_LABEL = "EVENT_PAGE_BACK_BUTTON_LABEL";

export const EVENT_PAGE_SEARCH_TITLE_LABEL = "EVENT_PAGE_SEARCH_TITLE_LABEL";

export const EVENT_PAGE_LIFECYCLE_EVENT_PAGE_BARRIER_DETAILS_LABEL =
  "EVENT_PAGE_LIFECYCLE_EVENT_PAGE_BARRIER_DETAILS_LABEL";

export const EVENT_PAGE_LIFECYCLE_EVENT_PAGE_COUPON_DETAILS_LABEL =
  "EVENT_PAGE_LIFECYCLE_EVENT_PAGE_COUPON_DETAILS_LABEL";

export const BUSINESS_EVENT_DESCRIPTION_MAPPING = {
  "Distribution": "DISTRIBUTION",
  "Maturing": "MATURING",
  "Called": "CALLED"
};

export const EVENT_PAGE_LIFECYCLE_EVENT_SEARCH_TABLE_COLUMN = [
  {
    title: isMobileOnly
      ? "EVENT_PAGE_LIFECYCLE_EVENT_TABLE_DATE_LABEL_MOBILE_ONLY"
      : "EVENT_PAGE_LIFECYCLE_EVENT_TABLE_DATE_LABEL",
    field: "eventDate",
    className: "dateOfEvent",
    translate: true,
    isDate: true
  },
  {
    title: "EVENT_PAGE_LIFECYCLE_EVENT_TABLE_DESCRIPTION_LABEL",
    field: "description",
    translationMapping: BUSINESS_EVENT_DESCRIPTION_MAPPING,
    className: "description",
    translate: true,
    capitalizeFirstLetter: true
  },
  {
    title: "EVENT_PAGE_LIFECYCLE_EVENT_TABLE_NAME_OF_NOTE_LABEL",
    field: "noteName",
    fieldFr: "noteNameFr",
    fieldId: "code",
    className: "nameOfNote",
    translate: true,
    clickable: true    
  },
  {
    title: isMobileOnly
      ? "EVENT_PAGE_LIFECYCLE_EVENT_TABLE_FUNDSERV_CODE_LABEL_MOBILE_ONLY"
      : "EVENT_PAGE_LIFECYCLE_EVENT_TABLE_FUNDSERV_CODE_LABEL",
    field: "fundservCode",
    className: "fundServCode",
    translate: true
  },
  { title: "EVENT_PAGE_LIFECYCLE_EVENT_TABLE_CUSIP_LABEL", field: "cusip", className: "cusip", translate: true },
];

export const EVENT_PAGE_CORPORATE_EVENT_SEARCH_TABLE_COLUMN = [
  {
    title: isMobileOnly
      ? "EVENT_PAGE_CORPORATE_EVENT_TABLE_DATE_LABEL_MOBILE_ONLY"
      : "EVENT_PAGE_CORPORATE_EVENT_TABLE_DATE_LABEL",
    field: "eventDate",
    className: "dateOfEvent",
    translate: true,
    isDate: true
  },
  {
    title: "EVENT_PAGE_CORPORATE_EVENT_TABLE_HEADLINE_LABEL",
    field: "headline",
    fieldFr: "headlineFr",
    fieldId: "corpActionId",
    clickable: true,
    className: "headline",
    translate: true
  },
];

export const EVENT_PAGE_EVENT_SEARCH_TABLE_PAGE_SIZE = 20;

export const EVENT_PAGE_EVENT_TYPE_LIFECYCLE_EVENT_KEY = "lifecycleEvent";

export const EVENT_PAGE_EVENT_TYPE_CORPORATE_EVENT_KEY = "corporateEvent";

export const EVENT_PAGE_EVENT_TYPE_FILTER_OPTIONS = [
  { id: EVENT_PAGE_EVENT_TYPE_LIFECYCLE_EVENT_KEY, label: "EVENT_PAGE_LIFECYCLE_EVENT_FILTER_LABEL", translate: true },
  { id: EVENT_PAGE_EVENT_TYPE_CORPORATE_EVENT_KEY, label: "EVENT_PAGE_CORPORATE_EVENT_FILTER_LABEL", translate: true },
];

export const EVENT_PAGE_SEARCH_PLACEHOLDER = "EVENT_PAGE_SEARCH_PLACEHOLDER";

export const EVENT_PAGE_SEARCH_PLACEHOLDER_MOBILE = "EVENT_PAGE_SEARCH_PLACEHOLDER_MOBILE";

export const EVENT_PAGE_BARRIER_DETAILS_TABLE_COLUMNS = [
  {
    title: "EVENT_PAGE_LIFECYCLE_EVENT_PAGE_BARRIER_TABLE_AUTOCALL_DATE_LABEL",
    field: "autoCallDate",
    className: "autoCallDate",
    translate: true,
  },
  {
    title: "EVENT_PAGE_LIFECYCLE_EVENT_PAGE_BARRIER_TABLE_VALUATION_DATE_LABEL",
    field: "valuationDate",
    className: "valuationDate",
    translate: true,
  },
  {
    title: "EVENT_PAGE_LIFECYCLE_EVENT_PAGE_BARRIER_TABLE_AUTOCALL_LEVEL_LABEL",
    field: "autoCallLevel",
    className: "autoCallLevel",
    translate: true,
  },
  {
    title: "EVENT_PAGE_LIFECYCLE_EVENT_PAGE_BARRIER_TABLE_CLOSING_BASKET_LEVEL_LABEL",
    field: "closingBasketLevel",
    className: "closingBasketLevel",
    translate: true,
  },
  {
    title: "EVENT_PAGE_LIFECYCLE_EVENT_PAGE_BARRIER_TABLE_AUTOCALL_FEATURE_LABEL",
    field: "autoCallFeature",
    className: "autoCallFeature",
    translate: true,
  },
  {
    title: "EVENT_PAGE_LIFECYCLE_EVENT_PAGE_BARRIER_TABLE_BASKET_RETURN_LABEL",
    field: "basketReturn",
    className: "basketReturn",
    translate: true,
  },
  {
    title: "EVENT_PAGE_LIFECYCLE_EVENT_PAGE_BARRIER_TABLE_MATURITY_LABEL",
    field: "maturity",
    className: "maturity",
    translate: true,
  },
  {
    title: "EVENT_PAGE_LIFECYCLE_EVENT_PAGE_BARRIER_TABLE_REDEMPTION_AMOUNT_LABEL",
    field: "redemptionAmount",
    className: "redemptionAmount",
    translate: true,
  },
];

export const EVENT_PAGE_COUPON_DETAILS_TABLE_COLUMNS = [
  {
    title: "EVENT_PAGE_LIFECYCLE_EVENT_PAGE_COUPON_TABLE_COUPON_PAY_DATES_LABEL",
    field: "couponPayDates",
    className: "couponPayDates",
    translate: true,
  },
  {
    title: "EVENT_PAGE_LIFECYCLE_EVENT_PAGE_COUPON_TABLE_VALUATION_DATE_LABEL",
    field: "valuationDate",
    className: "valudationDate",
    translate: true,
  },
  {
    title: "EVENT_PAGE_LIFECYCLE_EVENT_PAGE_COUPON_TABLE_BASKET_LEVEL_LABEL",
    field: "basketLevel",
    className: "basketLevel",
    translate: true,
  },
  {
    title: "EVENT_PAGE_LIFECYCLE_EVENT_PAGE_COUPON_TABLE_BASKET_RETURN_LABEL",
    field: "basketReturn",
    className: "basketReturn",
    translate: true,
  },
  {
    title: "EVENT_PAGE_LIFECYCLE_EVENT_PAGE_COUPON_TABLE_COUPON_LABEL",
    field: "coupon",
    className: "coupon",
    translate: true,
  },
];

export const EVENT_PAGE_CORPORATE_EVENT_DETAILS_TABLE_COLUMNS = class {
  columns = [
    {
      title: isMobileOnly
        ? "EVENT_PAGE_CORPORATE_EVENT_PAGE_TABLE_DATE_LABEL_MOBILE_ONLY"
        : "EVENT_PAGE_CORPORATE_EVENT_PAGE_TABLE_DATE_LABEL",
      field: "dateOfEvent",
      className: "dateOfEvent",
      translate: true,
      isDate: true,
      format: "YYYY-MM-DD"
    },
    {
      title: "EVENT_PAGE_CORPORATE_EVENT_PAGE_TABLE_NAME_OF_NOTE_LABEL",
      field: chooseLang("longName", "longNameFr"),
      className: "nameOfNote",
      translate: true,
      clickable: true,
    },
    {
      title: "EVENT_PAGE_CORPORATE_EVENT_PAGE_TABLE_FUNDSERV_CODE_LABEL",
      field: "fundservCode",
      className: "fundServCode",
      translate: true,
    },
    { title: "EVENT_PAGE_CORPORATE_EVENT_PAGE_TABLE_CUSIP_LABEL", field: "cusip", className: "cusip", translate: true },
  ]
}