import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { INVESTOR_FILE_NAME, LANGUAGE_ENGLISH, LANGUAGE_FRENCH} from '../../../constants/Misc.constants';
import { INVESTOR_INFO_TITLE } from '../../../constants/PageTitle.constants';
import { CANADIAN_STRUCTURED_NOTE_PROGRAM_LABEL, INVESTOR_INFORMATION_LABEL } from '../../../constants/ProductDetails.constants';
import { REST_STATIC_FILE_DOWNLOAD_PATH } from '../../../constants/Rest.constants';
import { tr } from '../../../services/Language.service';
import TranslateComponent from '../../misc/translate/Translate.component';

const InvestorInfo = () => {

    useEffect(() => {
      document.title = INVESTOR_INFO_TITLE
    }, [])

        return (
            <div className="tds-container"> 
                <div>
                    <h2 style={{marginLeft: '5px',borderBottom: '2px solid'}}><TranslateComponent label={INVESTOR_INFORMATION_LABEL} /></h2>
                    <ul>
                        <h3><TranslateComponent label={CANADIAN_STRUCTURED_NOTE_PROGRAM_LABEL} /></h3>
                        <li>
                            <a href={tr(`${REST_STATIC_FILE_DOWNLOAD_PATH}?lang=${LANGUAGE_ENGLISH}&fileName=${INVESTOR_FILE_NAME}`)}
                                target="_blank" 
                                rel="noopener noreferrer"
                                >
                                <u><FormattedMessage id="footer.investor.information-link-eng"/></u>
                            </a>
                        </li>
                        <li>
                        <a href={tr(`${REST_STATIC_FILE_DOWNLOAD_PATH}?lang=${LANGUAGE_FRENCH}&fileName=${INVESTOR_FILE_NAME}`)}
                                target="_blank"  
                                rel="noopener noreferrer">
                                <u><FormattedMessage id="footer.investor.information-link-fr"/></u>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        )
}

export default InvestorInfo;
