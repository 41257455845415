import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { isMobile, isMobileOnly } from "react-device-detect";
import {
  EVENT_PAGE_EVENT_SEARCH_TABLE_PAGE_SIZE,
  EVENT_PAGE_EVENT_TYPE_FILTER_OPTIONS,
  EVENT_PAGE_EVENT_TYPE_LIFECYCLE_EVENT_KEY,
  EVENT_PAGE_LIFECYCLE_EVENT_SEARCH_TABLE_COLUMN,
  EVENT_PAGE_CORPORATE_EVENT_SEARCH_TABLE_COLUMN,
  EVENT_PAGE_SEARCH_PLACEHOLDER,
  EVENT_PAGE_SEARCH_PLACEHOLDER_MOBILE,
  EVENT_PAGE_TITLE_MOBILE_ONLY,
  EVENT_PAGE_DATE_FILTER_FROM_LABEL,
  EVENT_PAGE_DATE_FILTER_TO_LABEL,
  EVENT_PAGE_SEARCH_TITLE_LABEL,
  BUSINESS_EVENT_DESCRIPTION_MAPPING,
} from "../../constants/EventPage.constants";
import {
  LINK_PATH_EVENTS_CORPORATE,
  LINK_PATH_NOTE_DETAILS,
} from "../../constants/LinkPaths.constants";
import DateInputComponent from "../misc/date-input/DateInput.component";
import DropdownComponent from "../misc/dropdown/Dropdown.component";
import MultiOptionsSingleSelectButtons from "../misc/multi-options-single-select-buttons/MultiOptionsSingleSelectButtons.component";
import SimpleSearchComponent from "../misc/search/SimpleSearch.component";
import TableComponent from "../misc/table/Table.component";
import PaginatorComponent from "../pagination/Paginator.component";
import TranslateComponent from "../misc/translate/Translate.component";
import LanguageService, { tr } from "../../services/Language.service";
import SpinnerComponent from "../spinner/Spinner.component";
import NoteService from "../../services/Note.service";
import { capitalizeFirstLetter, isNotEmptyArray } from "../../utils/valueUtils";

const EventSearchPageComponent = () => {
  
  const [lifecycleEvents, setLifecycleEvents] = useState({
    total: 0,
    results: []
  });
  const [corpActionEvents, setCorpActionEvents] = useState({
    total: 0,
    results: []
  });  
  const [uniqueDescriptions, setUniqueDescriptions]  = useState([]);
  const [descriptionFilterOptions, setDescriptionFilterOptions]  = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [keyword, setKeyword] = useState(null);
  const [eventTypeSelection, setEventTypeSelection] = useState(EVENT_PAGE_EVENT_TYPE_LIFECYCLE_EVENT_KEY);
  const [descriptionFilterSelection, setDescriptionFilterSelection] = useState("");
  const [currentPageTotalResults, setCurrentPageTotalResults] = useState();
  const [currentPageData, setCurrentPageData] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [minEndDate, setMinEndDate] = useState();
  const [loading, setLoading] = React.useState(true);  
  const lang = LanguageService.currentLanguage;
  const history = useHistory();
  let tabName = history?.tab;
  let pageNumber = history?.page;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if ( eventTypeSelection === EVENT_PAGE_EVENT_TYPE_LIFECYCLE_EVENT_KEY ) {
        const data = await NoteService.getLifecycleEvents({
          page: currentPage,
          keyword,
          eventDateFrom: startDate,
          eventDateTo: endDate,
          eventDescription: descriptionFilterSelection
        });
        setLifecycleEvents(data);
        setLoading(false);
      }      
    };
    fetchData();
  }, [currentPage, keyword, startDate, endDate, descriptionFilterSelection, eventTypeSelection]); 

  useEffect(() => {
    const fetchData = async () => {      
      const data = await NoteService.getEventDescriptions();      
      setUniqueDescriptions(data);
    };
    fetchData();
  }, []);

  useEffect(() => {         
    if ( isNotEmptyArray(uniqueDescriptions) ) {
      const descriptionFilterOptions = uniqueDescriptions.map(e => {
        return { 
          label: capitalizeFirstLetter(BUSINESS_EVENT_DESCRIPTION_MAPPING[e] ? tr(BUSINESS_EVENT_DESCRIPTION_MAPPING[e]) : e), value: e
        } 
      });
      setDescriptionFilterOptions(descriptionFilterOptions);
    }
  }, [uniqueDescriptions, lang]);


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if ( eventTypeSelection !== EVENT_PAGE_EVENT_TYPE_LIFECYCLE_EVENT_KEY ) {
        const data = await NoteService.getCorpActionEvents({
          page: currentPage,
          keyword,
          eventDateFrom: startDate,
          eventDateTo: endDate
        });
        setCorpActionEvents(data);
        setLoading(false);
      }      
    };
    fetchData();
  }, [currentPage, keyword, startDate, endDate, eventTypeSelection]);  

  useEffect(() => {
    if(!history?.goBackFlag) {
        setCurrentPage(1);
        history.tab = EVENT_PAGE_EVENT_TYPE_LIFECYCLE_EVENT_KEY;
        history.page = 1;
    }
    else {
      setEventTypeSelection(history.tab);
      setCurrentPage(history.page)
      if(history?.goBackFlag) {
        history.goBackFlag = false;
      }
    }
  }, [history])

  useEffect(() => {    
    if (eventTypeSelection === EVENT_PAGE_EVENT_TYPE_LIFECYCLE_EVENT_KEY) {
      setCurrentPageData(lifecycleEvents?.results);
      setCurrentPageTotalResults(lifecycleEvents?.total);
    } else {
      setCurrentPageData(corpActionEvents?.results);
      setCurrentPageTotalResults(corpActionEvents?.total);
    }    
  }, [eventTypeSelection, lifecycleEvents.results, corpActionEvents.results, lifecycleEvents?.total, corpActionEvents?.total, pageNumber, tabName]);

  // update maxStartDate, minEndDate based on date selection
  useEffect(() => {
    setMinEndDate(startDate);
  }, [startDate, endDate]);

  const goToPage = (page) => {
    setCurrentPage(page);
  }

  const handleLifecycleEventClick = (field, code) => {
    if (field === "noteName") {
      history.push(`${LINK_PATH_NOTE_DETAILS}/${code}`);
      history.page = currentPage;
    }
  };

  const handleCorporateEventClick = (field, corpActionId) => {
    if (field === "headline") {
      history.push(`${LINK_PATH_EVENTS_CORPORATE}/${corpActionId}`);
      history.page = currentPage;
    }
  };

  const handleSearch = (keyword) => {
    setCurrentPage(1);
    setKeyword(keyword);      
  };

  const handleStartDateChange = (value) => {
    setCurrentPage(1);
    setStartDate(value);
  };

  const handleEndDateChange = (value) => {
    setCurrentPage(1);
    setEndDate(value); 
  };

  const handleDescriptionFilterSelection = (value) => {
    setCurrentPage(1);
    setDescriptionFilterSelection(value);     
  };

  const toggleTab = (event) => {
    setEventTypeSelection(event);
    history.tab=event;
    history.page = 1;
    history.goBackFlag = true;
    setCurrentPage(1);
    setKeyword(null);
    setStartDate(null);
    setEndDate(null);
    setDescriptionFilterSelection('');
  }

  const showSpinner = () => loading && <SpinnerComponent />;
  // TODO: add error condition in the integration story
  const resultFetched = () => !loading;

  return (
    <div className="tds-sn-event-search-page-container tds-container">
      <h1>
        <TranslateComponent label={isMobileOnly ? EVENT_PAGE_TITLE_MOBILE_ONLY : ' '} />
      </h1>
      <SimpleSearchComponent
        id="eventSearch"
        className="search-section"
        placeholder={LanguageService.translate(
          isMobile ? EVENT_PAGE_SEARCH_PLACEHOLDER_MOBILE : EVENT_PAGE_SEARCH_PLACEHOLDER
        )}
        onSearch={handleSearch}
        label={LanguageService.translate(EVENT_PAGE_SEARCH_TITLE_LABEL)}
        hideLabel={true}
      />

      <div className="filter-section">
        <div className="event-type-filter-wrapper">
          <MultiOptionsSingleSelectButtons
            legend="Filter By"
            options={EVENT_PAGE_EVENT_TYPE_FILTER_OPTIONS}
            onSelection={toggleTab}
            selection={eventTypeSelection}
            className="event-type-filter"
          />
        </div>
        <div className="date-range">
          <label htmlFor="dateRangeFilterStartDate" className="date-input-filter-label">
            <TranslateComponent label={EVENT_PAGE_DATE_FILTER_FROM_LABEL} />
          </label>
          <DateInputComponent
            id="dateRangeFilterStartDate"
            selected={startDate}
            onChange={handleStartDateChange}
            className="date-input-filter"
            maxDate={endDate}
          />
          <label htmlFor="dateRangeFilterEndDate" className="date-input-filter-label">
            <TranslateComponent label={EVENT_PAGE_DATE_FILTER_TO_LABEL} />
          </label>
          <span className="date-separator" />
          <DateInputComponent
            id="dateRangeFilterEndDate"
            selected={endDate}
            onChange={handleEndDateChange}
            className="date-input-filter"
            minDate={minEndDate}
          />
        </div>
        {eventTypeSelection === EVENT_PAGE_EVENT_TYPE_LIFECYCLE_EVENT_KEY && (
          <DropdownComponent
            id="filterByDescription"
            label="filter by"
            options={descriptionFilterOptions}
            defaultOption={tr("ALL")}
            selection={descriptionFilterSelection}
            onSelection={handleDescriptionFilterSelection}
            className="filter-by-description-dropdown"
          />
        )}
      </div>
      <div className="result-section">
        {showSpinner()}
        {resultFetched() && (
          <>
            {eventTypeSelection === EVENT_PAGE_EVENT_TYPE_LIFECYCLE_EVENT_KEY ? (
              <div className="lifecycle-event-table-wrapper">
                <TableComponent
                  className="event-search-table lifecycle-event-table"
                  columns={EVENT_PAGE_LIFECYCLE_EVENT_SEARCH_TABLE_COLUMN}
                  data={currentPageData}
                  onRowClick={handleLifecycleEventClick}
                  lang={lang}
                />
              </div>
            ) : (
              <TableComponent
                className="event-search-table corporate-event-table"
                columns={EVENT_PAGE_CORPORATE_EVENT_SEARCH_TABLE_COLUMN}
                data={currentPageData}
                onRowClick={handleCorporateEventClick}
              />
            )}
            <PaginatorComponent
              className="event-search-table-paginator"
              currentPage={currentPage}
              resultsPerPage={EVENT_PAGE_EVENT_SEARCH_TABLE_PAGE_SIZE}
              total={currentPageTotalResults}
              goToPage={goToPage}
              key={`${currentPage} - ${currentPageTotalResults}`}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default EventSearchPageComponent;