var base = 'TD Structured notes';
var divider = ' | ';
export const CURRENT_OFFERINGS_PAGE_TITLE = base + divider + 'Current Offerings';
export const PREVIOUS_OFFERINGS_PAGE_TITLE = base + divider + 'Previous Offerings';
export const EVENTS_PAGE_TITLE = base + divider + "Events";
export const HOME_PAGE_TITLE = base + divider + "Home";
export const PRODUCT_DETAILS_PAGE_TITLE = base + divider + "Notes Details";
export const SEARCH_PAGE_TITLE = base + divider + "Search";
export const DISCLOSURE_TITLE = base + divider + 'Legal Disclosure';
export const INVESTOR_INFO_TITLE = base + divider + 'Investor Information';
export const FAQ_PAGE_TITLE = base + divider + 'FAQ';
export const PRODUCT_INFORMATION_PAGE_TITLE = base + divider + 'Product Information';
export const CONTACT_US_PAGE_TITLE = base + divider + 'Contact Us';
export const PREFERENCES_PAGE_TITLE = base + divider + 'Preferences';

