import React, { useEffect, useRef, useState } from "react";
import {
    PRODUCT_DETAILS_UNDERLYING_INTEREST_AGGREGATE_PERFORMANCE_LABEL,
    PRODUCT_DETAILS_UNDERLYING_INTEREST_NOTE_NOTIFICATION_COLUMNS,
    PRODUCT_DETAILS_UNDERLYING_INTEREST_NOTE_NOTIFICATIONS_LABEL,
    PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_CURRENT_PERFORMANCE_TITLE_LABEL,
    PRODUCT_DETAIL_PERFORMANCE_ANALYSIS_UNDERLYING_DISCLAIMER,
    PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_CURRENT_PERFORMANCE_LABELS,
    PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_CURRENT_PERFORMANCE_INDICATIVE_LABELS,
    PRODUCT_DETAIL_PERFORMANCE_ANALYSIS_UNDERLYING_DISCLAIMER_FR,
    CURRENT_STATUSES,
} from "../../../constants/ProductDetails.constants";
import ModalComponent from "../../misc/modal/Modal.component";
import TableComponent from "../../misc/table/Table.component";
import TranslateComponent from "../../misc/translate/Translate.component";
import LanguageService from "../../../services/Language.service";
import { tr } from "../../../services/Language.service";
import { LANGUAGE_ENGLISH, PARAMS_DATE_FORMAT } from "../../../constants/Misc.constants";
import { isNotEmptyArray, getValueInPercentage, convertToDecimal, convertToCurrency, addDecimalPlaceByNumber, convertToPercentage, isEmptyArray } from "../../../utils/valueUtils";
import KeyValueTableComponent from "../../misc/key-value-table/KeyValueTable.component";
import moment from "moment";
import NoteFeatureService from "../../../services/NoteFeature.service";
import { COUPON_PAYMENT_TYPE, COUPON_TYPE, EVENT_TYPE, NOTE_FIELD_KEYS, PAR_NOTE_TYPE, PERFORMANCE_TIME_SERIES, PERFORMANCE_TYPE, PPN_NOTE_TYPE } from "../../../constants/Notes.constants";
import { SortByDate } from "../../../utils/sorters";
import Note from "../../../utils/Note";
import UtilsService from "../../../services/Utils.service";
import { formatDate, toTDSDate } from "../../../services/Date.service";

const ProductDetailsUnderlyingInterestComponent = (props) => {

    const noteDetails = props.data;

    const [data, setData] = useState([]);
    const [dataColumns, setDataColumns] = useState([]);
    const [dataAggregation, setDataAggregation] = useState(null);
    const [noteNotification, setNoteNotification] = useState([]);
    const [selectedNoteDetails, setSelectedNoteDetails] = useState();
    const [selectedNoteHeadline, setSelectedNoteHeadline] = useState();
    const [assetPerformanceAdjustedData, setAssetPerformanceAdjustedData] = useState(null);
    const [currentPerformanceData, setCurrentPerformanceData] = useState();
    const [status, setStatus] = useState();
    const [noteType, setNoteType] = useState();
    const [couponPaymentType, setCouponPaymentType] = useState();
    const [isIndicativeMaturityAmount, setIsIndicativeMaturityAmount] = useState(false);

    useEffect(() => {

        const getData = () => {
            const data = [];
            let perfSeriesTickerDataByPerformaneType = [];
            // process performanceTimeseries
            if (noteDetails.performanceTimeseries && isNotEmptyArray(noteDetails.performanceTimeseries)) {
                perfSeriesTickerDataByPerformaneType = Note.performanceTimeseriesTickers(null, noteDetails.performanceTimeseries, PERFORMANCE_TYPE.ASSET_PERFORMANCE);
                if (perfSeriesTickerDataByPerformaneType) {
                    perfSeriesTickerDataByPerformaneType.forEach(perfTimeSeries => data.push(
                        {
                            security: LanguageService.chooseLang(perfTimeSeries?.underlyingInformation?.tickerCompany, perfTimeSeries?.underlyingInformation?.tickerCompanyFr),
                            ticker: perfTimeSeries?.underlyingReference,
                            initialPrice: performanceTimeseriesLatestValue(noteDetails.performanceTimeseries, PERFORMANCE_TYPE.INITIAL_LEVEL, perfTimeSeries?.underlyingReference) ?
                                performanceTimeseriesLatestValue(noteDetails.performanceTimeseries, PERFORMANCE_TYPE.INITIAL_LEVEL, perfTimeSeries?.underlyingReference).toFixed(3) : '',
                            shareWeight: getComponentWeight(perfTimeSeries.underlyingReference),
                            currentPrice: performanceTimeseriesLatestValue(noteDetails.performanceTimeseries, PERFORMANCE_TYPE.CURRENT_LEVEL, perfTimeSeries?.underlyingReference) ?
                                performanceTimeseriesLatestValue(noteDetails.performanceTimeseries, PERFORMANCE_TYPE.CURRENT_LEVEL, perfTimeSeries?.underlyingReference).toFixed(3) : '',
                            currentLevel: performanceTimeseriesLatestValue(noteDetails.performanceTimeseries, PERFORMANCE_TYPE.ASSET_PERFORMANCE, perfTimeSeries?.underlyingReference) ?
                                convertToDecimal(performanceTimeseriesLatestValue(noteDetails.performanceTimeseries, PERFORMANCE_TYPE.ASSET_PERFORMANCE, perfTimeSeries?.underlyingReference) * 100, 5) + '%' : '',
                            adjustedCurrentLevel: getAdjustedCurrentLevel(noteDetails.performanceTimeseries, PERFORMANCE_TYPE.ASSET_PERFORMANCE_ADJUSTED, perfTimeSeries?.underlyingReference) ?
                                getAdjustedCurrentLevel(noteDetails.performanceTimeseries, PERFORMANCE_TYPE.ASSET_PERFORMANCE_ADJUSTED, perfTimeSeries?.underlyingReference) : '',
                            underlyingReference: perfTimeSeries?.underlyingInformation?.underlyingReference
                        }
                    ));
                }
            }
            if (isEmptyArray(perfSeriesTickerDataByPerformaneType) && noteDetails.underlyingInterests && isNotEmptyArray(noteDetails.underlyingInterests)) {
                noteDetails.underlyingInterests.forEach(underlyingInterest => data.push(
                    {
                        security: LanguageService.chooseLang(underlyingInterest.underlyingInformation.tickerCompany, underlyingInterest.underlyingInformation.tickerCompanyFr),
                        ticker: underlyingInterest.underlyingInformation.shortTicker,
                        initialPrice: '',
                        shareWeight: getComponentWeight(underlyingInterest.underlyingInformation.underlyingReference),
                        currentPrice: '',
                        currentLevel: '',
                        adjustedCurrentLevel: '',
                        underlyingReference: underlyingInterest.underlyingInformation.underlyingReference
                    }
                ));

                data.forEach(item => {
                    // process performanceTimeseries
                    if (noteDetails.performanceTimeseries) {
                        //initial price
                        const initialLevelPerformanceTimeseriesValue = performanceTimeseriesSingleValue(noteDetails.performanceTimeseries, 'initialLevel', item.underlyingReference);

                        if (initialLevelPerformanceTimeseriesValue) {
                            item.initialPrice = initialLevelPerformanceTimeseriesValue.toFixed(3);
                        }

                        //current price
                        const currentLevelPerformanceTimeseries = performanceTimeseriesLatestValue(noteDetails.performanceTimeseries, 'currentLevel', item.underlyingReference);

                        if (currentLevelPerformanceTimeseries) {
                            item.currentPrice = (currentLevelPerformanceTimeseries).toFixed(3);
                        }

                        //current Return
                        const assetPerformance = performanceTimeseriesLatestValue(noteDetails.performanceTimeseries, 'assetPerformance', item.underlyingReference);

                        if (assetPerformance) {
                            item.currentLevel = convertToDecimal(assetPerformance * 100, 5) + '%';
                        }

                        //adjusted current level
                        const assetPerformanceType = NoteFeatureService.getAssetPerformanceType(noteDetails);
                        if (assetPerformanceType && assetPerformanceType === PERFORMANCE_TYPE.DYNAMIC) {
                            const assetPerformanceAdjusted = performanceTimeseriesLatestValue(noteDetails.performanceTimeseries,
                                PERFORMANCE_TYPE.ASSET_PERFORMANCE_ADJUSTED, item.underlyingReference);

                            if (assetPerformanceAdjusted) {
                                setAssetPerformanceAdjustedData(assetPerformanceAdjusted);
                                item.adjustedCurrentLevel = getValueInPercentage(assetPerformanceAdjusted);
                            }
                        }
                    }
                });
            }
            return data;
        }

        const getComponentWeight = (underlyingReference) => {
            let tickerWeight = null;
            if (formatDate(moment(), PARAMS_DATE_FORMAT) > formatDate(noteDetails.initialValDate, PARAMS_DATE_FORMAT)) {
                tickerWeight = performanceTimeseriesLatestValue(noteDetails.performanceTimeseries,
                    PERFORMANCE_TYPE.WEIGHT, underlyingReference);
            } else if (noteDetails.assetPerformanceType === PERFORMANCE_TYPE.DYNAMIC) {
                return "";
            }

            if (tickerWeight === null) {
                tickerWeight = tickerWeightUnderlyingInterestLatestValue(noteDetails.underlyingInterests, underlyingReference);
            }

            return convertToPercentage(tickerWeight);
        }

        const getAdjustedCurrentLevel = (performanceTimeseries, performanceType, underlyingReference) => {
            //adjusted current level
            const assetPerformanceType = NoteFeatureService.getAssetPerformanceType(noteDetails);
            if (assetPerformanceType && assetPerformanceType === PERFORMANCE_TYPE.DYNAMIC) {
                const assetPerformanceAdjusted = performanceTimeseriesLatestValue(performanceTimeseries,
                    performanceType, underlyingReference);

                if (assetPerformanceAdjusted) {
                    setAssetPerformanceAdjustedData(assetPerformanceAdjusted);
                    return getValueInPercentage(assetPerformanceAdjusted);
                }
            }
            return null;
        }

        const getDataColumns = () => {
            let dataColumns = [
                { title: "PRODUCT_DETAILS_UNDERLYING_INTEREST_SECURITY_LABEL", field: "security", className: "security", translate: true },
                { title: "PRODUCT_DETAILS_UNDERLYING_INTEREST_TICKER_LABEL", field: "ticker", className: "ticker", translate: true },
                { title: "PRODUCT_DETAILS_UNDERLYING_INTEREST_INITIAL_PRICE_LABEL", field: "initialPrice", className: "initialPrice", translate: false },
                { title: "PRODUCT_DETAILS_UNDERLYING_INTEREST_SHARE_WEIGHT_LABEL", field: "shareWeight", className: "shareWeight", translate: true },
                { title: "PRODUCT_DETAILS_UNDERLYING_INTEREST_CURRENT_PRICE_LABEL", field: "currentPrice", className: "currentPrice", translate: false },
                { title: "PRODUCT_DETAILS_UNDERLYING_INTEREST_CURRENT_LEVEL", field: "currentLevel", className: "currentLevel", translate: true },
                { title: "PRODUCT_DETAILS_UNDERLYING_INTEREST_ADJUSTED_CURRENT_LEVEL", field: "adjustedCurrentLevel", className: "adjustedCurrentLevel", translate: true },
            ];
            let initialPriceDateValue = noteDetails.initialValDate ? toTDSDate(noteDetails.initialValDate) : '';
            let currentPriceDateValue = '';

            if (noteDetails.performanceTimeseries) {
                const currentLevelPerformanceTimeseries = noteDetails.performanceTimeseries.filter(performanceTimeserie => performanceTimeserie.performanceType === PERFORMANCE_TYPE.CURRENT_LEVEL);

                if (currentLevelPerformanceTimeseries.length > 0) {
                    const sorted = currentLevelPerformanceTimeseries.sort((first, second) => {
                        const firstDate = moment(first.reportDate);
                        const secondDate = moment(second.reportDate);
                        if (firstDate.isBefore(secondDate)) {
                            return 1;
                        }
                        else if (firstDate.isAfter(secondDate)) {
                            return -1;
                        }
                        return 0;
                    });
                    currentPriceDateValue = toTDSDate(sorted[0].reportDate)
                }
            }

            let initialPriceColumn = dataColumns[2];
            initialPriceColumn.title = LanguageService.translate(initialPriceColumn.title)?.replace('$', '')?.replace('{date}', initialPriceDateValue);
            let currentPriceColumn = dataColumns[4];
            currentPriceColumn.title = LanguageService.translate(currentPriceColumn.title)?.replace('$', '')?.replace('{date}', currentPriceDateValue);

            //Logic to hide adjusted curent level column
            const assetPerformanceType = NoteFeatureService.getAssetPerformanceType(noteDetails);
            if (assetPerformanceType !== PERFORMANCE_TYPE.DYNAMIC || !assetPerformanceAdjustedData) {
                dataColumns = dataColumns.filter(dataColumn => {
                    return dataColumn.field !== 'adjustedCurrentLevel'
                });
            }
            return dataColumns;
        }

        const getDataAggregation = () => {
            let currentPrice;
            let currentLevel;
            let adjustedCurrentLevel;

            if (noteDetails.underlyingInterests && noteDetails.performanceTimeseries) {
                currentPrice = performanceTimeseriesLatestValue(noteDetails.performanceTimeseries,
                    PERFORMANCE_TYPE.ASSET_LEVEL, PERFORMANCE_TIME_SERIES.UNDERLYING_REFERENCE);
                currentLevel = performanceTimeseriesLatestValue(noteDetails.performanceTimeseries,
                    PERFORMANCE_TYPE.ASSET_PERFORMANCE, PERFORMANCE_TIME_SERIES.UNDERLYING_REFERENCE);
                adjustedCurrentLevel = performanceTimeseriesLatestValue(noteDetails.performanceTimeseries,
                    PERFORMANCE_TYPE.ASSET_PERFORMANCE_ADJUSTED, PERFORMANCE_TIME_SERIES.UNDERLYING_REFERENCE);
            }

            if (noteDetails.assetPerformanceType !== PERFORMANCE_TYPE.SINGLE_ASSET) {
                const dataAggregation = {
                    security: tr(PRODUCT_DETAILS_UNDERLYING_INTEREST_AGGREGATE_PERFORMANCE_LABEL),
                    ticker: '',
                    initialPrice: '',
                    shareWeight: '',
                    currentPrice: currentPrice ? addDecimalPlaceByNumber(currentPrice * 100, 3) : '',
                    currentLevel: (currentLevel ? convertToDecimal(currentLevel * 100, 5) + '%' : ''),
                    adjustedCurrentLevel: (adjustedCurrentLevel ? getValueInPercentage(adjustedCurrentLevel) : '')
                };

                return dataAggregation;
            }
            else {
                return null;
            }
        }

        const getNoteNotification = () => {
            const data = [];

            if (isNotEmptyArray(noteDetails.corpActions)) {
                noteDetails.corpActions
                    .forEach(corpAction => {
                        data.push(
                            {
                                dateOfEvent: corpAction.caDate,
                                headline: LanguageService.isLanguage(LANGUAGE_ENGLISH) ? corpAction.caHeader : corpAction.caHeaderFr,
                                details: LanguageService.isLanguage(LANGUAGE_ENGLISH) ? corpAction.caDesc : corpAction.caDescFr
                            }
                        );
                    });
            }

            return data;
        }

        //Method to get current performance data.
        const getCurrentPerformanceData = () => {

            const couponPayType = Note.getFeatureValue(NOTE_FIELD_KEYS.COUPON_PAYMENT_TYPE, noteDetails);
            setNoteType(noteDetails?.noteType);
            setCouponPaymentType(couponPayType);

            const upcomingCouponData = getUpcomingCouponData(couponPayType);
            const maturityData = getMaturityData(couponPayType);
            const upcomingPartialPrincipalRepayData = geUpcomingPartialPrincipalRepay(couponPayType);
            const underlyingInterest = getUnderlyingInterestReturnData();
            const variableReturnData = getVariableReturn(couponPayType);

            setCurrentPerformanceData({
                indicativeUnderlyingInterestReturn: underlyingInterest,
                indicativeUpcomingCoupon: upcomingCouponData,
                indicativeUpcomingPartialRepayment: upcomingPartialPrincipalRepayData,
                indicativeMaturityRedemptionAmount: maturityData,
                indicativeVariableReturn: variableReturnData,
            });
        };

        //Method to get upcoming coupon data.
        const getUpcomingCouponData = (couponPayType) => {
            if (noteDetails?.noteType === PAR_NOTE_TYPE && !noteDetails?.isRoc
                && couponPayType === COUPON_PAYMENT_TYPE.PERIODIC) {
                if (status) {
                    const couponData = getCouponDataByBusinessEvent(EVENT_TYPE.COUPON);
                    return couponData ? (couponData?.eventAmount === 0 ? couponData.eventAmount.toString() : convertToCurrency(couponData.eventAmount * 100, 7)) : '';
                } else {
                    const couponType = Note.getFeatureValue(NOTE_FIELD_KEYS.COUPON_TYPE, noteDetails);
                    if (COUPON_TYPE.FIXED === couponType) {
                        PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_CURRENT_PERFORMANCE_INDICATIVE_LABELS.indicativeUpcomingCoupon = "PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_CURRENT_PERFORMANCE_IUC_LABEL";
                    }
                    const upcomingCouponData = getPerformanceSeriesDataForCouponType();
                    return upcomingCouponData ? (upcomingCouponData?.value === 0 ? (upcomingCouponData.value).toString() : convertToCurrency(upcomingCouponData.value * 100, 7)) : '';
                }
            }
        }

        const setMaturityAmountFlag = (amount) => {
            amount && amount > 0 ? setIsIndicativeMaturityAmount(true) : setIsIndicativeMaturityAmount(false);
        }
        //Method to get upcoming coupon data.
        const getMaturityData = (couponPayType) => {
            if (noteDetails?.noteType === PAR_NOTE_TYPE && !noteDetails?.isRoc) {
                if (status) {
                    const couponData = getCouponDataByBusinessEvent(EVENT_TYPE.MATURITY);
                    return couponData?.eventAmount ? convertToCurrency(couponData.eventAmount * 100, 7) : '';
                }
                else {
                    const maturityData = getPerformanceSeriesMaturityData();
                    const maturityAmount = maturityData ? (maturityData?.value ? maturityData.value : 0) : 0;
                    if (couponPayType === COUPON_PAYMENT_TYPE.PERIODIC) {
                        setMaturityAmountFlag(maturityAmount);
                        return (maturityAmount) > 0 ? (convertToCurrency(maturityAmount * 100, 7)) : '';
                    } else {
                        const couponData = getPerformanceSeriesDataForCouponType();
                        const couponAmount = couponData ? (couponData?.value ? couponData.value : 0) : 0;
                        const totalAmount = maturityAmount + couponAmount;
                        setMaturityAmountFlag(totalAmount);
                        return (totalAmount) > 0 ? (convertToCurrency(totalAmount * 100, 7)) : '';
                    }
                }
            }
        }

        //Method to getpartial Principal Repay
        const geUpcomingPartialPrincipalRepay = (couponPayType) => {
            if (noteDetails?.noteType === PAR_NOTE_TYPE && noteDetails?.isRoc
                && couponPayType === COUPON_PAYMENT_TYPE.PERIODIC) {
                if (status) {
                    const couponData = getCouponDataByBusinessEvent(EVENT_TYPE.COUPON);
                    return couponData?.eventAmount ? convertToDecimal(couponData.eventAmount, 7) : '';
                } else {
                    const couponData = getPerformanceSeriesDataForCouponType();
                    return couponData ? (couponData?.value ? convertToDecimal(couponData.value, 7) : '') : '';
                }
            }
        }

        //Method to get Underlying Interest return.
        const getUnderlyingInterestReturnData = () => {
            const finalValDate = noteDetails?.[NOTE_FIELD_KEYS.FINAL_VALUATION_DATE];
            if (status) {
                const underlyingInterestReturnData = noteDetails?.performanceTimeseries?.filter(perfTimeSeries =>
                    perfTimeSeries.performanceType === PERFORMANCE_TYPE.ASSET_PERFORMANCE_ADJUSTED
                    && perfTimeSeries.underlyingReference === PERFORMANCE_TIME_SERIES.UNDERLYING_REFERENCE
                    && perfTimeSeries.reportDate === finalValDate);
                if (underlyingInterestReturnData?.length > 0) {
                    const underlyingInterestReturn = underlyingInterestReturnData.sort(SortByDate.sortDesc('reportDate'))[0];
                    return underlyingInterestReturn ? (underlyingInterestReturn.value ? convertToPercentage(underlyingInterestReturn.value, 7) : '') : '';
                }
            }
            else {
                const underlyingInterestReturnData = noteDetails?.performanceTimeseries?.filter(perfTimeSeries =>
                    perfTimeSeries.performanceType === PERFORMANCE_TYPE.ASSET_PERFORMANCE_ADJUSTED
                    && perfTimeSeries.underlyingReference === PERFORMANCE_TIME_SERIES.UNDERLYING_REFERENCE);
                if (underlyingInterestReturnData?.length > 0) {
                    const underlyingInterestReturn = underlyingInterestReturnData.sort(SortByDate.sortDesc('reportDate'))[0];
                    return underlyingInterestReturn ? (underlyingInterestReturn.value ? convertToPercentage(underlyingInterestReturn.value, 7) : '') : '';
                }
            }
        }

        const getVariableReturn = (couponPayType) => {
            if (noteDetails?.noteType === PPN_NOTE_TYPE) {
                if (status) {
                    const couponData = getCouponDataByBusinessEvent(EVENT_TYPE.MATURITY);
                    if (couponData) {
                        return (convertToPercentage(couponData?.eventAmount === 0 ? couponData.eventAmount.toString() : ((couponData.eventAmount * 100 - (1 * 100)) / 100), 7));
                    }
                } else {
                    const couponData = getPerformanceSeriesDataForCouponType();
                    const maturityData = getPerformanceSeriesMaturityData();
                    let totalValue = 0;
                    if (couponPayType === COUPON_PAYMENT_TYPE.PERIODIC) {
                        totalValue = maturityData ? (maturityData?.value ? maturityData.value : 0) : 0;
                    } else {
                        totalValue = (((maturityData ? (maturityData?.value ? maturityData.value : 0) : 0))
                            + (couponData ? (couponData?.value ? couponData.value : 0) : 0));
                    }
                    const variableReturn = totalValue === 0 ? totalValue : (totalValue * 100 - (1 * 100)) / 100;
                    return (convertToPercentage(variableReturn === 0 ? variableReturn.toString() : variableReturn, 7));
                }
            }
        }

        const getPerformanceSeriesDataForCouponType = () => {
            const upcomingCouponData = noteDetails?.performanceTimeseries?.filter(perfTimeSeries =>
                perfTimeSeries.performanceType === PERFORMANCE_TYPE.INDICATIVE_COUPON
                && perfTimeSeries.underlyingReference === PERFORMANCE_TIME_SERIES.UNDERLYING_REFERENCE);
            if (upcomingCouponData) {
                const underlyingCouponAfterSort = upcomingCouponData.sort(SortByDate.sortDesc('reportDate'))[0];
                return underlyingCouponAfterSort ? underlyingCouponAfterSort : '';
            }
        }

        const getPerformanceSeriesMaturityData = () => {
            const maturityData = noteDetails?.performanceTimeseries?.filter(perfTimeSeries =>
                perfTimeSeries.performanceType === PERFORMANCE_TYPE.INDICATIVE_MATURITY
                && perfTimeSeries.underlyingReference === PERFORMANCE_TIME_SERIES.UNDERLYING_REFERENCE)

            const maturityAfterSort = maturityData?.sort(SortByDate.sortDesc('reportDate'))[0];
            return maturityAfterSort ? maturityAfterSort : '';
        }

        const getCouponDataByBusinessEvent = (eventType) => {
            const couponData = noteDetails?.events?.filter(event =>
                event.businessEvent === eventType);
            return couponData?.sort(SortByDate.sortDesc('paymentDate'))[0];
        }

        const isNoteStatusCalledOrMatured = () => {
            return (noteDetails?.noteStatus === CURRENT_STATUSES.Called
                || noteDetails?.noteStatus === CURRENT_STATUSES.Matured);
        }

        const updateCurrentPerformanceLevelsIfDisclaimerPresent = () => {
            if (!status && isIndicativeMaturityAmount) {
                PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_CURRENT_PERFORMANCE_INDICATIVE_LABELS.indicativeMaturityRedemptionAmount = "PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_CURRENT_PERFORMANCE_IMRA_INDICATIVE_LABEL_WITH_DISCLAIMER";
            }
        }
        setStatus(isNoteStatusCalledOrMatured());
        setData(getData());
        setDataColumns(getDataColumns());
        setDataAggregation(getDataAggregation());
        setNoteNotification(getNoteNotification());
        getCurrentPerformanceData();
        updateCurrentPerformanceLevelsIfDisclaimerPresent();
    }, [noteDetails, assetPerformanceAdjustedData, status, noteType, couponPaymentType, isIndicativeMaturityAmount]);

    const currentPerformance = UtilsService.createLabelValuesWithHideCondition(
        (status ? PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_CURRENT_PERFORMANCE_LABELS :
            PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_CURRENT_PERFORMANCE_INDICATIVE_LABELS),
        currentPerformanceData
    );

    //find timeseries value for Data aggregation for most recent report date
    const performanceTimeseriesLatestValue = (performanceTimeseries, performanceType, underlyingReference) => {
        const filteredPerformanceTimeseries
            = performanceTimeseries
                .filter(performanceTimeserie => {
                    return performanceTimeserie.performanceType === performanceType && performanceTimeserie.underlyingReference === underlyingReference
                });

        if (filteredPerformanceTimeseries && filteredPerformanceTimeseries.length > 0) {
            const reducedPerformanceTimeserie
                = filteredPerformanceTimeseries
                    .reduce((first, second) => {
                        return first.reportDate >= second.reportDate ? first : second
                    });
            return reducedPerformanceTimeserie.value;
        }
        return null;
    }

    //find timeseries value for Data aggregation for most recent report date
    const tickerWeightUnderlyingInterestLatestValue = (underLyingInterest, underlyingReference) => {
        const filteredUnderLyingInterest
            = underLyingInterest?.filter(underLyingIntrst => {
                return underLyingIntrst.underlyingReference === underlyingReference
            });

        if (filteredUnderLyingInterest && filteredUnderLyingInterest.length > 0) {
            const reducedUnderlyingInterest
                = filteredUnderLyingInterest
                    .reduce((first, second) => {
                        return first.reportDate >= second.reportDate ? first : second
                    });

            return reducedUnderlyingInterest.tickerWeight;
        }
        return null;
    }

    //find one timeseries value
    const performanceTimeseriesSingleValue = (performanceTimeseries, performanceType, underlyingReference) => {
        const myPerformanceTimeserie = performanceTimeseries.find(performanceTimeserie => {
            return performanceTimeserie.performanceType === performanceType && performanceTimeserie.underlyingReference === underlyingReference;
        });

        return myPerformanceTimeserie ? myPerformanceTimeserie.value : null;
    }

    const noteDetailsModalRef = useRef();

    const handleRowClick = (field, index) => {
        if (field === "headline") {
            setSelectedNoteHeadline(noteNotification[index]["headline"])
            setSelectedNoteDetails(noteNotification[index]["details"])
            noteDetailsModalRef.current.showModal();
        }
    }

    const showDataTable = () => data && data.length > 0;

    const showNoteNotificationTable = () => noteNotification && noteNotification.length > 0;

    return (
        <div>
            {showDataTable() && (
                <div className="tds-sn-product-details-underlying-interest-container">
                    <TableComponent
                        className="top-table"
                        columns={dataColumns}
                        data={data}
                        aggregationRow={dataAggregation} />
                </div>
            )}
            <div className="tds-sn-product-details-underlying-interest-container">
                {showNoteNotificationTable() && (
                    <div className="left-container">
                        <div className="note-notification-container">
                            <h3><TranslateComponent label={PRODUCT_DETAILS_UNDERLYING_INTEREST_NOTE_NOTIFICATIONS_LABEL} /></h3>
                            <TableComponent
                                className="note-notifications-table"
                                columns={PRODUCT_DETAILS_UNDERLYING_INTEREST_NOTE_NOTIFICATION_COLUMNS}
                                data={noteNotification}
                                onRowClick={handleRowClick}
                            />
                        </div>
                    </div>
                )}
                {currentPerformance && currentPerformance.length > 0 && (
                    <div className="right-container">
                        <div className="current-performance-container">
                            <h3>
                                <TranslateComponent label={PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_CURRENT_PERFORMANCE_TITLE_LABEL} />
                            </h3>
                            <>
                                <KeyValueTableComponent list={currentPerformance} contained={true} className="key-terms-table" />
                            </>
                        </div>
                        {!status && isIndicativeMaturityAmount &&
                            <div className="current-performance-container">
                                {LanguageService.isLanguage(LANGUAGE_ENGLISH) ? PRODUCT_DETAIL_PERFORMANCE_ANALYSIS_UNDERLYING_DISCLAIMER :
                                    PRODUCT_DETAIL_PERFORMANCE_ANALYSIS_UNDERLYING_DISCLAIMER_FR}
                            </div>
                        }
                    </div>
                )}
            </div>
            <ModalComponent
                ref={noteDetailsModalRef}
                id={'noteDetailsModal'}
                className="note-details-modal-content"
            >
                <h3>{selectedNoteHeadline}</h3>
                <p>
                    {selectedNoteDetails}
                </p>
            </ModalComponent>
        </div>
    );
};

export default ProductDetailsUnderlyingInterestComponent;