import React from "react";
import ModalComponent from "../modal/Modal.component";
import striptags from 'striptags';

const ImageModal = (props) => {

  const imageModal = React.createRef();

  const showImageModal = () => {   
    imageModal.current.showModal();
  } 
  const title = (props.title.props && props.title.props.dangerouslySetInnerHTML) ? striptags(props.title.props.dangerouslySetInnerHTML.__html) : props.title;
  return (
    <div className="tds-image-modal-container">
      <img 
        src={props.src}
        alt='Click to enlarge'
        title='Click to enlarge'
        onClick={showImageModal}
        className='image-link'
      />
      <ModalComponent
        ref={imageModal}
        id={'imageModal'}
        className='image-modal'
      >
        <img 
          src={props.src}
          alt={title}
          title={title}
        />
      </ModalComponent>
    </div>
  );
};

export default ImageModal;