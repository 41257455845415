import { SYSTEM_LOG_LEVEL } from "../constants/System.constants";

export const LOG_LEVEL  = {
    NONE: 0,
    DEBUG: 1,
    INFO: 2,
    WARN: 3,
    ERROR: 4
}

export class Logger {

    DEBUG = "DEBUG";
    INFO = "INFO";
    WARN = "WARN";
    ERROR = "ERROR";
    NONE = "NONE";

    logLevel = 0;

    constructor() {
        this.logLevel = SYSTEM_LOG_LEVEL;
    }

    log(logLevel, message, arg1, arg2, arg3) {
        if (!logLevel) {
            return;
        }
        if (logLevel <= this.logLevel) {
            if (arg1 && arg2 && arg3) {
                console.log(this.getLogLevelString(logLevel) + ": " + message, arg1, arg2, arg3);
            } else if (arg1 && arg2) {
                console.log(this.getLogLevelString(logLevel) + ": " + message, arg1, arg2);
            } else if (arg1) {
                console.log(this.getLogLevelString(logLevel) + ": " + message, arg1);
            } else {
                console.log(this.getLogLevelString(logLevel) + ": " + message);
            }
        }
    }

    getLogLevelString(logLevel) {
        switch (logLevel) {
            case LOG_LEVEL.DEBUG: return this.DEBUG;
            case LOG_LEVEL.INFO: return this.INFO;
            case LOG_LEVEL.WARN: return this.WARN;
            case LOG_LEVEL.ERROR: return this.ERROR;
            default: return this.ERROR;
        }
    }

}

export var logger = new Logger();