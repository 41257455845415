import React, { useEffect, useState } from "react";
import { LANGUAGE_FRENCH } from "../../../constants/Misc.constants";
import { REST_NOTES_FILE_PATH } from "../../../constants/Rest.constants";
import LanguageService from "../../../services/Language.service";

const ProductDetailsAdditionalPerformance = (props) => {
  const noteDetails = props.data;

  const [performanceHtml, setPerformanceHtml] = useState(null);

  useEffect(() => {
    
    if(noteDetails?.files) {
      let lang = "EN";
      if(LanguageService.currentLanguage === LANGUAGE_FRENCH) {
        lang = "FR";
      }
      const found = noteDetails.files.find(file => file.fileType === "additional_performance_data_html" && file.lang?.toUpperCase() === lang);
      if (found) {
        setPerformanceHtml(`${found.code}/${found.fileType}/${found.lang}`);
      }
    }

  }, [noteDetails]);

  const showIframe = () => performanceHtml && <iframe title="performance" src={REST_NOTES_FILE_PATH + "/" + performanceHtml}></iframe>

  return (
    <>
      <div className="tds-sn-product-details-additional-performance-container">
        { showIframe() }
      </div>
    </>
  );
};

export default ProductDetailsAdditionalPerformance;