import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { ROUTE_PATH_EVENTS, ROUTE_PATH_EVENTS_CORPORATE, ROUTE_PATH_EVENTS_LIFECYCLE } from "../../constants/Routes.constants";
import EventSearchPageComponent from "./EventSearchPage.component";
import CorporateEventDetailsPageComponent from "./CorporateEventDetailsPage.component";
import LifecycleEventDetailsPageComponent from "./LifecycleEventDetailsPage.component";
import { EVENTS_PAGE_TITLE } from "../../constants/PageTitle.constants";

const EventsPageContainerComponent = () => {

  useEffect(() => {
    document.title = EVENTS_PAGE_TITLE;
  }, []);

  return (
    <Switch>
      <Route path={ROUTE_PATH_EVENTS_CORPORATE} component={CorporateEventDetailsPageComponent} />
      <Route path={ROUTE_PATH_EVENTS_LIFECYCLE} component={LifecycleEventDetailsPageComponent} />
      <Route exact path={ROUTE_PATH_EVENTS} component={EventSearchPageComponent} />
    </Switch>
  );
};

export default EventsPageContainerComponent;
