import React from 'react';
import { FormattedMessage } from 'react-intl';

export default class TranslateComponent extends React.Component {
    render() {
        return (
            <FormattedMessage id={this.props.label} defaultMessage={this.props.label}/>
        )
    }
}
