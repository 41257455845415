// import React from "react";
// import ReactDOM from "react-dom";
// import "./index.css";
// import App from "./App";


// ReactDOM.render(<App />, document.getElementById("root"));


import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { render } from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import AppComponent from './components/App.component';
import rootReducer from './reducers';
import * as serviceWorker from "./serviceWorker";

const store = createStore(rootReducer)

//GSBM-6545: Prevents the application to be loaded from another website in Iframe * Click Jacking prevention
function isNotLoadedAsIFrame() {
  try {
      return (window.top === window.self)
  } catch (err) {
    return false;
  }
}

render(
  <React.Fragment>
    { isNotLoadedAsIFrame() &&
      <Provider store={store}>
        <AppComponent />
      </Provider>
    }
  </React.Fragment>,
  document.getElementById('root')
  
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();