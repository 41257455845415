import React from "react";

const TabComponent = (props) => {
  const { isActive, onChange, tabTitle } = props;
  return (
    <li className={isActive ? "active" : ""} onClick={onChange}>
      <button title={tabTitle}>{props.children}</button>
    </li>
  );
};

export default TabComponent;
