import moment from 'moment';
import { LANGUAGE_FRENCH, TDS_DATE_FORMAT, TDS_DATE_FORMAT_FR } from '../constants/Misc.constants';
import LanguageService from './Language.service';

export default class DateService {

    static formatDate(date, format) {
        if (!date) {
            return "";
        }

        return moment.utc(date).format(format);
    }

    static formatLocalDate(date, format) {
        if (!date) {
            return "";
        }

        return moment(date).format(format);
    }


    static formatLocalizedDate(date, format) {
        const initDate = this.initLocalizedDate(date);

        return this.formatDate(initDate, format);
    }    

    static toTDSDate = (value, format) => {
        const _format = (format) ? format : LanguageService.currentLanguage === LANGUAGE_FRENCH ? TDS_DATE_FORMAT_FR : TDS_DATE_FORMAT;
        return this.formatLocalizedDate(value, _format);
    }

    static initLocalizedDate = (value) => {
        if(value) {            
            const initDate = moment(value);            
            switch(LanguageService.currentLanguage) {
                case LANGUAGE_FRENCH: 
                    require('moment/locale/fr-ca');
                    initDate.locale('fr-ca');
                break;
                default: 
                    require('moment/locale/en-ca');
                    initDate.locale('en-ca');
            }           

            return initDate;
        } 

        return null;        
    }

    static changeDateLocale = (localizedDate) => {
        if(localizedDate) {
            switch(LanguageService.currentLanguage) {
                case LANGUAGE_FRENCH: 
                    require('moment/locale/fr-ca');
                    localizedDate.locale('fr-ca');
                break;
                default: 
                    require('moment/locale/en-ca');
                    localizedDate.locale('en-ca');
            }
        }
    }
}

const toTDSDate = DateService.toTDSDate;
const initLocalizedDate = DateService.initLocalizedDate;
const formatLocalizedDate = DateService.formatLocalizedDate;
const changeDateLocale = DateService.changeDateLocale;
const formatDate = DateService.formatDate;

const formatLocalDate = DateService.formatLocalDate;
export {
    toTDSDate,
    initLocalizedDate,
    formatLocalizedDate,
    changeDateLocale,
    formatDate,
    formatLocalDate
};