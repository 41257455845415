import React, { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { LANGUAGE_ENGLISH } from "../../constants/Misc.constants";
import { PRODUCT_INFORMATION_PAGE_TITLE } from "../../constants/PageTitle.constants";
import LanguageService, {
  isEnglish,
  tr,
} from "../../services/Language.service";
import ModalComponent from "../misc/modal/Modal.component";
import ResponsiveBackgroundImageComponent from "../misc/responsive-background-image/ResponsiveBackgroundImage.component";
import TranslateComponent from "../misc/translate/Translate.component";
import {
  IRN_PRODUCTS,
  PAR_PRODUCTS,
  PPN_PRODUCTS,
  PRODUCT_CARDS,
  PRODUCT_INFO_LINKED_VIDEOS,
  PRODUCT_INFO_PAGE_VIDEOS,
  TD_INVESTOR_PROFILE_FRAMEWORK_PDF,
} from "./Resources.constants";
import { REST_STATIC_FILE_DOWNLOAD_PATH } from "../../constants/Rest.constants";

const useShowModal = () => {
  const [videoModalContent, setVideoModalContent] = useState();
  const videoModalRef = useRef();

  const showModal = (video) => {
    setVideoModalContent(video);
    videoModalRef.current.showModal();
  };

  return [videoModalContent, showModal, videoModalRef];
};

const openPdf = () => {
  window.open(
    REST_STATIC_FILE_DOWNLOAD_PATH +
      "?lang=" +
      LanguageService.currentLanguage +
      "&fileName=" +
      TD_INVESTOR_PROFILE_FRAMEWORK_PDF,
    "_blank",
    "noopener,noreferrer"
  );
  
};

const ProductInformationComponent = () => {
  document.title = PRODUCT_INFORMATION_PAGE_TITLE;
  const [videoModalContent, showModal, videoModalRef] = useShowModal();

  return (
    <>
      <div className="tds-sn-resources-container tds-container">
        <h2 style={{ color: "green", display: "flex" }}>
          <div>{tr("PRODUCT_INFO")}</div>
          <div style={{ marginLeft: `${isEnglish() ? "500px" : "430px"}` }}>
            {tr("TD_INVESTOR_PROFILES")}
          </div>
        </h2>

        <div className="tds-sn-resources-tile-container">
          <div className="product-info-main">
            {PRODUCT_INFO_PAGE_VIDEOS.map((video) => (
              <div className="tds-sn-resources-tile">
                <div
                  className="tile-image"
                  onClick={() =>
                    video.id !== "tdInvestorProfile"
                      ? showModal(video)
                      : openPdf()
                  }
                  key={video.id}
                  tabIndex="0"
                  aria-label="navigate to"
                >
                  <ResponsiveBackgroundImageComponent
                    className="background-image"
                    default={video.image}
                    tablet1={video.tablet1Image}
                    tablet2={video.tablet2Image}
                    mobile={video.mobileImage}
                    title={tr(video.title)}
                  >
                    <img
                      src={
                        video.id !== "tdInvestorProfile"
                          ? "/assets/img/play-button.png"
                          : video.image
                      }
                      alt=""
                      className={
                        video.id !== "tdInvestorProfile"
                          ? "play-button"
                          : "background-image"
                      }
                    ></img>
                  </ResponsiveBackgroundImageComponent>
                </div>
                <div className="tile-info">
                  <h3>
                    <TranslateComponent label={video.title} />
                  </h3>
                  <p className="production-info-description">
                    <TranslateComponent label={video.description} />
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="product-card-container">
          <h3>
            {PRODUCT_CARDS.isTranslatable && (
              <FormattedMessage id={PRODUCT_CARDS.title} />
            )}
          </h3>
          {PRODUCT_CARDS.items.map((productCard) => (
            <div>
              <a
                href={
                  productCard.link +
                  "?lang=" +
                  LanguageService.currentLanguage +
                  "&fileName=" +
                  productCard.fileName
                }
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontWeight: "bold" }}
              >
                {productCard.isTranslatable && (
                  <FormattedMessage id={productCard.text} />
                )}
                <span
                  className={"td-icon " + PRODUCT_CARDS.icon}
                  style={{ paddingLeft: "5px" }}
                ></span>
              </a>
            </div>
          ))}
        </div>
        <div className="tds-sn-resources-pdf-container">
          <div className="product-category">
            <h3>
              {PAR_PRODUCTS.isTranslatable && (
                <FormattedMessage id={PAR_PRODUCTS.title} />
              )}
            </h3>
            <FormattedMessage id={PAR_PRODUCTS.description} />
            {PAR_PRODUCTS.items.map((parProduct) => (
              <div className="product-category-list">
                <a
                  href={
                    parProduct.link +
                    "?lang=" +
                    LanguageService.currentLanguage +
                    "&fileName=" +
                    parProduct.fileName
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {parProduct.isTranslatable && (
                    <FormattedMessage id={parProduct.text} />
                  )}
                  <span
                    className={`td-icon ${PRODUCT_CARDS.icon} product-card-icon`}
                  ></span>
                </a>
              </div>
            ))}
          </div>
          <div style={{ marginLeft: "120px" }}>
            <div className="product-category">
              <h3>
                {PPN_PRODUCTS.isTranslatable && (
                  <FormattedMessage id={PPN_PRODUCTS.title} />
                )}
              </h3>
              <FormattedMessage id={PPN_PRODUCTS.description} />
              {PPN_PRODUCTS.items.map((ppnProduct) => (
                <div className="product-category-list">
                  <a
                    href={
                      ppnProduct.link +
                      "?lang=" +
                      LanguageService.currentLanguage +
                      "&fileName=" +
                      ppnProduct.fileName
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {ppnProduct.isTranslatable && (
                      <FormattedMessage id={ppnProduct.text} />
                    )}
                    <span
                      className={`td-icon ${PRODUCT_CARDS.icon} product-card-icon`}
                    ></span>
                  </a>
                  {ppnProduct.videoLink && (
                    <span
                      className={`tds-link td-icon ${PRODUCT_CARDS.videoLinkIcon} product-card-icon`}
                      onClick={() =>
                        showModal(PRODUCT_INFO_LINKED_VIDEOS.tdGrowthNotes)
                      }
                    ></span>
                  )}
                </div>
              ))}
            </div>
            <div className="product-category">
              <h3>
                {IRN_PRODUCTS.isTranslatable && (
                  <FormattedMessage id={IRN_PRODUCTS.title} />
                )}
              </h3>
              <FormattedMessage id={IRN_PRODUCTS.description} />
              {IRN_PRODUCTS.items.map((irnProduct) => (
                <div className="product-category-list">
                  <a
                    href={
                      irnProduct.link +
                      "?lang=" +
                      LanguageService.currentLanguage +
                      "&fileName=" +
                      irnProduct.fileName
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {irnProduct.isTranslatable && (
                      <FormattedMessage id={irnProduct.text} />
                    )}
                    <span
                      className={`td-icon ${PRODUCT_CARDS.icon} product-card-icon`}
                    ></span>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
        <ModalComponent
          ref={videoModalRef}
          id={"videoModalRef"}
          className="video-modal-content-product"
        >
          {videoModalContent && (
            <section className="video-content-product">
              <h2>
                <TranslateComponent label={videoModalContent.title} />
              </h2>
              <iframe
                title={tr(videoModalContent.title)}
                src={
                  LanguageService.isLanguage(LANGUAGE_ENGLISH)
                    ? videoModalContent.videoLinkEn
                    : videoModalContent.videoLinkFr
                }
                allow="autoplay; encrypted-media"
              />
            </section>
          )}
        </ModalComponent>
      </div>
    </>
  );
};

export default ProductInformationComponent;
