import React from "react";
import { isEnglish, tr } from "../../services/Language.service";
import { executeInnerHtml } from "../../services/Utils.service";
import { isNotEmptyArray } from "../../utils/valueUtils";
import CollapseComponent from "../misc/collapse/Collapse.component";
import { FAQS, FAQ_DISCLAIMER_EN, FAQ_DISCLAIMER_FR } from "./Resources.constants";
import ImageModalComponent from "../misc/responsive-image/ImageModal.component";
import { FAQ_PAGE_TITLE } from "../../constants/PageTitle.constants";

const FaqsComponent = () => {
  document.title = FAQ_PAGE_TITLE;

  return (

    <div className="tds-sn-faq-container tds-container">
      <h2>FAQ</h2><hr />
      {FAQS.item.map((faq, index) => (
        <CollapseComponent key={index} id={index} title={tr(faq.question)} bottomBorder="none" displayType="flex">
          {
            faq.answers.map((answer, index) => (
              <ul className="answers-list" key={index}>
                <li key={index}>
                  {answer.boldText &&
                    <span>{<b>{tr(answer.firstPart)}</b>}{tr(answer.lastPart)}</span>
                  }
                  {!answer.boldText &&
                    <span>{ executeInnerHtml( tr(answer.answer) ) }</span>
                  }
                  {
                    answer.values &&
                    answer.values.map((value, index) => {
                      return (
                        <ul className="values dashed" key={index}>
                          <li style={{ color: 'black' }}>{tr(value)}</li>
                        </ul>
                      )
                    })
                  }
                  { isNotEmptyArray(answer.guides) &&                      
                    <ul className="guides" key={index}>
                      { answer.guides.map((guide, index) => { 
                          return (
                            <React.Fragment>
                              <li>{tr(guide.title)}</li>
                              { isNotEmptyArray(guide.instructions) && 
                                <ul className="instructions" key={index}>
                                  { guide.instructions.map((instruction, index) => {
                                      const text = executeInnerHtml(tr(instruction.instruction));
                                      return (
                                        <React.Fragment>
                                          <li key={index}>{ text }
                                            { instruction.image &&
                                              <ImageModalComponent src={ tr(instruction.image) } title={ text }/>
                                            }
                                          </li>
                                        </React.Fragment>
                                      )
                                    })
                                  }
                                </ul>
                              }
                            </React.Fragment>
                          )
                        })
                      }
                    </ul>                  
                  }                  
                </li>
              </ul>
            ))
          }
        </CollapseComponent>
      ))}

      { isEnglish() ?
        <div className="faq-disclaimer">
          <span>{FAQ_DISCLAIMER_EN.substring(0, FAQ_DISCLAIMER_EN.indexOf("#$#"))}<sup style={{ fontSize: '7px' }}>&reg;</sup>
            {FAQ_DISCLAIMER_EN.substring(FAQ_DISCLAIMER_EN.lastIndexOf("#$#") + 3, FAQ_DISCLAIMER_EN.length)}</span>
        </div>
        :
        <div className="faq-disclaimer">
          <span>{FAQ_DISCLAIMER_FR.substring(0, FAQ_DISCLAIMER_FR.indexOf("#$#"))}<sup style={{ fontSize: '5px' }}>MD</sup>
            {FAQ_DISCLAIMER_FR.substring(FAQ_DISCLAIMER_FR.lastIndexOf("#$#") + 3, FAQ_DISCLAIMER_FR.length)}</span>
        </div>
      }
    </div>
  )
};
export default FaqsComponent;
