import { REST_NOTES_FILE_PATH } from "../../../constants/Rest.constants";


const ProductDetailsPdfDocumentComponent = (props) => {
  const code = props.match?.params?.code
  const fileType = props.match?.params?.fileType;
  const langType = props.match?.params?.langType;

  const showIframe = () => 
      code && fileType && langType && 
      <iframe title="Document" src={REST_NOTES_FILE_PATH + "/" + code + "/" + fileType + "/" +langType} allowFullScreen={true}
            allow="encrypted-media"/>
  
  return (
    <>
      <div className="tds-sn-product-details-pdf-document-container">
        { showIframe() }
      </div>
    </>
  );
  };
export default ProductDetailsPdfDocumentComponent;