import React from "react";
import LanguageService from "../../../services/Language.service";
import TranslateComponent from "../translate/Translate.component";

const MultiOptionsSingleSelectButtons = (props) => {
  const { className, legend, options, selection, onSelection } = props;

  const handleSelection = (e) => {
    if (onSelection) {
      onSelection(e.target.value);
    }
  };

  return (
    <div className={`tds-sn-multi-options-single-select-buttons-container ${className || null}`}>
      <fieldset className="td-multi-single-select-buttons td-multi-single-select-buttons-segmented">
        <legend className="tds-sn-visually-hidden">{legend}</legend>
        <div className="td-multi-single-select-buttons-group">
          {options.map((option) => (
            <div className="td-multi-single-select-button-wrapper" key={option.id}>
              <input
                type="radio"
                name="selectButtonSegmented3Group"
                value={option.id}
                id={option.id}
                checked={option.id === selection}
                onChange={handleSelection}
              ></input>
              <label title={option.translate ? LanguageService.translate(option.label) : option.label} htmlFor={option.id}>
                {option.translate ? <TranslateComponent label={option.label} /> : option.label}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
};

export default MultiOptionsSingleSelectButtons;
