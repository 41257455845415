import React from "react";
import { withRouter, Link } from "react-router-dom";
import HeaderNavMobileComponent from "./mobile/HeaderNavMobile.component";
import HeaderNavBarComponent from "./HeaderNavBar.component";
import {
  HEADER_TOP_RIGHT_MENU_DATA,
  HEADER_MAIN_NAV_MENU_DATA,
  HEADER_TOP_LEFT_NAV_MENU_DATA 
} from "../../constants/HeaderNav.constants";
import ModalComponent from "../misc/modal/Modal.component";
import LanguageService from '../../services/Language.service';

import { 
  KEY_ENTER, 
  REQUEST_ACCESS_MAIL_FROM_MAILTO, 
  REQUEST_ACCESS_MAIL_FROM, 
  LANGUAGE_ENGLISH
} from '../../constants/Misc.constants';
import {
  TDS_TOP_NAV,
  TDS_TOP_NAV_RIGHT,
  TDS_TOP_NAV_DROPDOWN_LINK,
  TDS_DROPDOWN_CONTENT,
  TDS_NAVBAR,
  TDS_NAVBAR_DROPDOWN_LINK,
} from "../../constants/HeaderNav.constants";
import { ROUTE_PATH_SEARCH } from "../../constants/Routes.constants";
import { EVENT_PAGE_SEARCH_PLACEHOLDER } from "../../constants/EventPage.constants";

class HeaderNavComponent extends React.Component {

  constructor(props) {
    super(props);
    this.topNavSectionLeft = React.createRef();
    this.topNavSectionRight = React.createRef();
    this.headerModal = React.createRef();
    this.state = {
      search: false,
      searchkeywords: "",
      modalType: 'default'
    };       
    this.handleSearchOpenClick = this.handleSearchOpenClick.bind(this);
    this.handleSearchCloseClick = this.handleSearchCloseClick.bind(this);
    this.handleOnMouseLeaveTopNavBar = this.handleOnMouseLeaveTopNavBar.bind(
      this
    );
    this.handleSetSearchValue = this.handleSetSearchValue.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.showModal = this.showModal.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }
  handleSearchOpenClick(event) {
    this.setState(() => ({ search: true }));
  }
  handleSearchCloseClick(event) {
    this.setState(() => ({ search: false }));
  }
  handleSearchClick = (event) => {
    this.handleSearchCloseClick(event);
    this.props.history.push(ROUTE_PATH_SEARCH + '?keyword=' + this.state.searchkeywords);
  }
  handleKeyPress(event) {
    if (event.key === KEY_ENTER) {
      this.handleSearchClick();
    }
  }
  handleSetSearchValue(event) {
    this.setState({ searchkeywords: event.target.value });
  }
  handleSearch(event) {
    this.setState({ searchkeywords: '' });
  }
  handleOnMouseLeaveTopNavBar(event) {
    if (this.topNavSectionLeft && this.topNavSectionLeft.current) {
      this.topNavSectionLeft.current.closeMenus();
    }
    if (this.topNavSectionRight && this.topNavSectionRight.current) {
      this.topNavSectionRight.current.closeMenus();
    }
  }
  showModal(modalType) {
    if (modalType) {
      this.setState({
        modalType: modalType
      }, this.headerModal.current.showModal())
    } else {
      this.headerModal.current.showModal()
    }
  }
  createHeaderNav() {
    return (
      <div className="non-printable">
        <header>
          <div className="td-bg-colour-2 tds-header">
            <div className="tds-container">
              <div
                className="tds-section-left"
                onMouseLeave={this.handleOnMouseLeaveTopNavBar} onClick={this.handleSearch}
              >
                <Link to="/" className="tds-logo">
                  <img
                    src={LanguageService.isLanguage(LANGUAGE_ENGLISH) ? "/assets/img/tds_shield.svg" : "/assets/img/TDS_Header_Logo_FR.svg"}
                    title="TD Securities"
                    alt="TD Securities"
                  />
                </Link>
        {/* Removing Home Link for /resources/priip route */}
        {!window.location.pathname.includes("/resources/priip") && (
        <HeaderNavBarComponent
          ref={this.topNavSectionLeft}
          menuItems={HEADER_TOP_LEFT_NAV_MENU_DATA}
          topClassName={TDS_TOP_NAV}
          dropDownClassName={TDS_TOP_NAV_DROPDOWN_LINK}
          dropDownContentClassName={TDS_DROPDOWN_CONTENT}
          hover={false}
          showModal={this.showModal}
        />
      )}      
              </div>              
              <div
                className="tds-section-right"
                onMouseLeave={this.handleOnMouseLeaveTopNavBar}
              >
              <HeaderNavBarComponent
                ref={this.topNavSectionRight}
                menuItemsRef={HEADER_TOP_RIGHT_MENU_DATA}
                topClassName={TDS_TOP_NAV_RIGHT}
                dropDownClassName={TDS_TOP_NAV_DROPDOWN_LINK}
                hover={false}
                showModal={this.showModal}
                lang={this.props.lang}
              />              
              </div>              
            </div>
          </div> 
          {/* Removing Events and Search bar for /resources/priip route */}         
          {!window.location.pathname.includes("/resources/priip") && (
        <div className="tds-nav">
          <div className="tds-container">
            <nav className="tds-nav-container" onClick={this.handleSearch}>
              <HeaderNavBarComponent
                menuItems={HEADER_MAIN_NAV_MENU_DATA}
                topClassName={TDS_NAVBAR}
                dropDownClassName={TDS_NAVBAR_DROPDOWN_LINK}
                dropDownContentClassName={TDS_DROPDOWN_CONTENT}
                hover={true}
                showModal={this.showModal}
              />
            </nav>
            <div className="sn-search-simple">
              <input
                placeholder={LanguageService.translate(
                  EVENT_PAGE_SEARCH_PLACEHOLDER
                )}
                name="Search"
                type="text"
                className="form-control search"
                style={{marginLeft: "12%", backgroundColor: "white"}}
                id="search"
                value={this.state.searchkeywords}
                onChange={this.handleSetSearchValue}
                onKeyPress={this.handleKeyPress}
                title={LanguageService.translate(EVENT_PAGE_SEARCH_PLACEHOLDER)}
              />
              <div
                className="sn-simple-search-btn"
                onClick={this.handleSearchClick}
              >
                <span className="td-icon td-icon-search"></span>
              </div>
            </div>
          </div>
        </div>
          )}             
        </header>
        <ModalComponent
          ref={this.headerModal}
          id={'headerModal'}
          className={'tds-header-modal'}
        >
          {this.state.modalType === 'default' &&
            <React.Fragment>
              <h1>You don't have access</h1>
              <p>To navigate complex investment climates, trust our Global Strategy team to provide a wide range of relevant macro research, analysis and trade strategy. Email <a href={REQUEST_ACCESS_MAIL_FROM_MAILTO} title={REQUEST_ACCESS_MAIL_FROM}>{REQUEST_ACCESS_MAIL_FROM}</a> to request access to this content.</p>
            </React.Fragment>
          }
          {this.state.modalType === 'equities' &&
            <React.Fragment>
              <h1>You don't have access</h1>
              <p>Sharpen your investment edge with in-depth knowledge from our Equity Research team, covering the sectors that matter most to you. Email <a href={REQUEST_ACCESS_MAIL_FROM_MAILTO} title={REQUEST_ACCESS_MAIL_FROM}>{REQUEST_ACCESS_MAIL_FROM}</a> to request access to this content.</p>
            </React.Fragment>
          }
          {this.state.modalType === 'cross-assets' &&
            <React.Fragment>
              <h1>You don't have access</h1>
              <p>Email <a href={REQUEST_ACCESS_MAIL_FROM_MAILTO} title={REQUEST_ACCESS_MAIL_FROM}>{REQUEST_ACCESS_MAIL_FROM}</a> to request access to this content.</p>
            </React.Fragment>
          }
          {this.state.modalType === 'other' &&
            <React.Fragment>
              <h1>You don't have access</h1>
              <p>TD-sponsored ABCP conduits feature strong credit enhancement and benefits from TD's liquidity support. Email <a href={REQUEST_ACCESS_MAIL_FROM_MAILTO} title={REQUEST_ACCESS_MAIL_FROM}>{REQUEST_ACCESS_MAIL_FROM}</a> to request access to asset performance updates and supplementary info.</p>
            </React.Fragment>
          }
        </ModalComponent>
        <HeaderNavMobileComponent
          showModal={this.showModal}
          hideSearchBar={this.props.hideSearchBar}
          hideMeetingIcon={this.props.hideMeetingIcon}
        />       
      </div>
    )
  }

  render() {  
        return (
          this.createHeaderNav()
        )
    }

}

export default withRouter(HeaderNavComponent);
