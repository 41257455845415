import React from "react";
import LanguageService from "../../services/Language.service";
import TranslateComponent from "../misc/translate/Translate.component";
import { INVESTOR_FRAMEWORK, INVESTOR_FRAMEWORK_DATA } from "./Resources.constants";

const InvestorFrameworkComponent = () => {

  return (
    <div className="tds-sn-resources-container">
      {
        <div className="tds-sn-table-container">
          <h2 style={{color: 'green'}}>{LanguageService.translate("INVESTOR_FRAMEWORK")}</h2>
               {LanguageService.translate("INVESTOR_FRAMEWORK_DESC")}
          <table className="tds-sn-table-container investor-table">
            <tbody>
              {INVESTOR_FRAMEWORK_DATA && 
                INVESTOR_FRAMEWORK_DATA.map((row, index) => ( 
                  <tr key={index}>
                    {INVESTOR_FRAMEWORK && 
                      INVESTOR_FRAMEWORK.map((col) => (
                        <td className={col.className ? col.className : null} key={col.field}>
                          <span 
                            title={row[col.field]}
                          >
                              {
                                row.translate ? <TranslateComponent label={row[col.field]}/> : row[col.field]
                              }
                          </span>
                        </td>
                    ))}
                  </tr>
              ))}
            </tbody>
          </table>
        </div>      
      }
    </div>
  )
};

export default InvestorFrameworkComponent;
