import React from 'react';
import ResponsiveBackgroundImageComponent 
    from '../misc/responsive-background-image/ResponsiveBackgroundImage.component'
import { Redirect } from 'react-router-dom';
import {FOUROFOURPAGE_IMAGE_DEFAULT } from '../../constants/FourOFourPage.constants';
export default class FourOFourPageComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
         }
    }
    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.target} />
        }
    }
    handleGoTohomePageClick = (target) => {
        this.setState(
            { target: target },
            this.setState(
                {
                    redirect: true
                }
            )
        );
    }
    render() {
        return (
            <React.Fragment>
            { this.renderRedirect()}
                <section className="tds-fourofour-page">
                    <ResponsiveBackgroundImageComponent
                        default={FOUROFOURPAGE_IMAGE_DEFAULT.image}
                        tablet1={FOUROFOURPAGE_IMAGE_DEFAULT.tablet1Image}
                        tablet2={FOUROFOURPAGE_IMAGE_DEFAULT.tablet2Image}
                        mobile={FOUROFOURPAGE_IMAGE_DEFAULT.mobileImage}>
                    </ResponsiveBackgroundImageComponent>
                    <div className="tds-fourofour-page-container">
                        <div className="tds-fourofour-page-row">
                            <div className="tds-fourofour-page-column">
                                <div className="tds-fourofour-page-column-description">
                                    <p>
                                        Sorry, we couldn't find what you were looking for.
                                    </p>
                                </div>
                                <div className="tds-fourofour-page-button-wrapper">
                                    <button title="Go to Homepage" 
                                            className="btn td-btn-primary-light"
                                            onClick={
                                                this.handleGoTohomePageClick.bind(this,  process.env.PUBLIC_URL)
                                            }>
                                            Go to Homepage
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}
