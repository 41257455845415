import React from 'react';
import {NO_RESULTS, NOTE_FIELD_KEYS} from '../../../constants/Notes.constants';
import FormatedDateComponent from '../../misc/formated-date/FormatedDate.component';
import {LINK_PATH_NOTE_DETAILS} from "../../../constants/LinkPaths.constants";
import {Link} from 'react-router-dom';
import LanguageService from '../../../services/Language.service';
import TranslateComponent from '../../misc/translate/Translate.component';
import NoteService from '../../../services/Note.service';
import {CURRENT_STATUSES} from '../../../constants/ProductDetails.constants';

export default class CurrentOfferingsSearchResultsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.isSelected = this.isSelected.bind(this);
    }

    isSelected(note) {
        if(this.props.notesToCompare.find(n => n.id === note.id)) {
            return true;
        }
        return false;
    }

    getStatusStyle(note) {
        let style = 'title'
        const status = note[NOTE_FIELD_KEYS.STATUS];

        //coloring only for new, closing soon, status override values
        if (status !== CURRENT_STATUSES.Called
            && status !== CURRENT_STATUSES.Matured
            && status !== CURRENT_STATUSES.Closed
            && status !== CURRENT_STATUSES.Active
        ) {
            style += ' tds-current-offering-search-results-color ';
            if (status === CURRENT_STATUSES.New) {
                style += 'status-new';
            }
            else {
                //ClosingSoon or any status override
                style += 'status-closing-soon';
            }
        }

        return style;
    }

    render() {
        return (
            <React.Fragment>
                <div className="tds-current-offering-search-result-row" style={{
                    'display': this.props.display ? 'block' : 'none'
                }}>
                    <div className="tds-current-offering-search-result-column">
                        <div className="tds-current-offering-search-results-column-title">
                            <h1>{this.props.noteTypeTitle}</h1>
                        </div>
                        <div className="tds-current-offering-search-results-description">
                            {this.props.noteTypeDescription}
                        </div>
                        <div className="tds-current-offering-search-results-description expand"
                            onClick={this.props.handleExpandClick}>
                            <span className={`tds-current-offering-search-results-collapse ${this.props.expanded ? "active" : ""}`}>
                                <img src={process.env.PUBLIC_URL + '/assets/img/icn_collapse.svg'} alt="Collapse" />
                            </span>
                            <span className={`tds-current-offering-search-results-expand ${!this.props.expanded ? "active" : ""}`} >
                                <img src={process.env.PUBLIC_URL + '/assets/img/icn_expand.svg'} alt="Expand" />
                            </span>
                        </div>
                    </div>

                    <div className="tds-current-offering-search-results-expanded" style={{
                        'display': this.props.expanded ? 'block' : 'none'
                    }}>
                        <div className="tds-current-offerings-table table-responsive">
                            {this.props.notes && this.props.notes.length > 0 &&
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="name" onClick={() => this.props.handleSort(NOTE_FIELD_KEYS.NAME)}>
                                                <span className="title"><TranslateComponent label="OFFERINGS_RESULTS_HEADER_NAME"/></span>
                                                <span className="td-icon td-icon-sort"></span>
                                            </th>
                                            <th scope="col" className="fund-serve-code" onClick={() => this.props.handleSort(NOTE_FIELD_KEYS.FUND_SERVE_CODE) }>
                                                <span className="title"><TranslateComponent label="OFFERINGS_RESULTS_HEADER_FUNDSERV_CODE"/></span>
                                                <span className="td-icon td-icon-sort"></span>
                                            </th>
                                            <th scope="col" className="cusip" onClick={() => this.props.handleSort(NOTE_FIELD_KEYS.INVESTMENT_OBJECTIVE)}>
                                                <span className="title"><TranslateComponent label="OFFERINGS_RESULTS_HEADER_INVESTMENT_OBJECTIVE"/></span>
                                                <span className="td-icon td-icon-sort"></span>
                                            </th>
                                            <th scope="col" className="available-unit" onClick={() => this.props.handleSort(NOTE_FIELD_KEYS.AVAILABLE_UNTIL_DATE)}>
                                                <span className="title"><TranslateComponent label="OFFERINGS_RESULTS_HEADER_AVAILABLE_UNTIL"/></span>
                                                <span className="td-icon td-icon-sort"></span>
                                            </th>
                                            <th scope="col" className="maturity-date" onClick={() => this.props.handleSort(NOTE_FIELD_KEYS.MATURITY_DATE)}>
                                                <span className="title"><TranslateComponent label="OFFERINGS_RESULTS_HEADER_MATURITY_DATE"/></span>
                                                <span className="td-icon td-icon-sort"></span>
                                            </th>
                                            <th scope="col" className="term" onClick={() => this.props.handleSort(NOTE_FIELD_KEYS.TERM)}>
                                                <span className="title"><TranslateComponent label="OFFERINGS_RESULTS_HEADER_TERM"/></span>
                                                <span className="td-icon td-icon-sort"></span>
                                            </th>
                                            <th scope="col" className="current-status" onClick={() => this.props.handleSort(NOTE_FIELD_KEYS.STATUS)}>
                                                <span className="title"><TranslateComponent label="OFFERINGS_RESULTS_HEADER_CURRENT_STATUS"/></span>
                                                <span className="td-icon td-icon-sort"></span>
                                            </th>
                                            <th scope="col" className="compare" onClick={() => this.props.handleCompareColumnClick()}>
                                                <span className="title"><TranslateComponent label="OFFERINGS_RESULTS_HEADER_COMPARE"/></span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.props.notes.map((item, index) => (
                                                <tr key={'current-offering-notes-' + index}>
                                                    <td className="name">                                                        
                                                        <Link to={`${LINK_PATH_NOTE_DETAILS}/${item[NOTE_FIELD_KEYS.CODE]}`} title="Note Details">
                                                            {NoteService.getNoteName(item)}
                                                        </Link>
                                                    </td>
                                                    <td className="fund-serve-code">
                                                        <span className="title">{item[NOTE_FIELD_KEYS.FUND_SERVE_CODE]}</span>
                                                    </td>
                                                    <td className="cusip">
                                                        <span className="title">{NoteService.getInvestmentObjective(item)}</span>
                                                    </td>
                                                    <td className="available-unit">
                                                        <span className="title">
                                                            <FormatedDateComponent
                                                                date={item[NOTE_FIELD_KEYS.AVAILABLE_UNTIL_DATE]}
                                                            />
                                                        </span>
                                                    </td>
                                                    <td className="maturity-date">
                                                        <span className="title">
                                                            <FormatedDateComponent
                                                                date={item[NOTE_FIELD_KEYS.MATURITY_DATE]}
                                                            />
                                                        </span>
                                                    </td>
                                                    <td className="term">
                                                        <span className="title">{item[NOTE_FIELD_KEYS.TERM] + " " + LanguageService.translate("YEARS")}</span>
                                                    </td>
                                                    <td className="current-status">
                                                        <span className={this.getStatusStyle(item)}>{NoteService.getNoteStatus(item)}</span>
                                                    </td>
                                                    <td className="compare">
                                                        <div
                                                            className="tds-current-offering-compare-checked form-check form-check-inline">
                                                            <input value={item[NOTE_FIELD_KEYS.CODE]}
                                                                   disabled={this.props.disabled && !this.isSelected(item)}
                                                                   checked={this.isSelected(item)} type="checkbox"
                                                                   onChange={(event) => this.props.handleCompareCheckboxClick(event, item)}/>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            }

                            {(!this.props.notes || this.props.notes.length < 1) &&
                                <div className="tds-current-offering-search-results-description">
                                    <TranslateComponent label={NO_RESULTS} />
                                </div>
                            }

                        </div>
                    </div>

                </div>
            </React.Fragment>
        )
    }
}