import React, { useEffect } from 'react';
import { LANGUAGE_FRENCH, TDS_DATE_FORMAT, TDS_DATE_FORMAT_FR } from '../../../constants/Misc.constants';
import LanguageService from "../../../services/Language.service";
import { initLocalizedDate, changeDateLocale, formatDate } from "../../../services/Date.service";

export default function FormatedDateComponent(props) {

    const lang = LanguageService.currentLanguage;
    const localizedDate = initLocalizedDate(props.date);
    const format = (props.format) ? props.format : (lang === LANGUAGE_FRENCH ? TDS_DATE_FORMAT_FR : TDS_DATE_FORMAT);

    useEffect(() => {
        changeDateLocale(localizedDate); 
    }, [localizedDate, lang]);
    
    return (
        
        <React.Fragment>
            { lang &&
                formatDate(localizedDate, format)
            }
        </React.Fragment>
    )
}
