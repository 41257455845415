import React from 'react';
import {
    NOTE_FIELD_KEYS, NO_RESULTS
} from '../../constants/Notes.constants';
import FormatedDateComponent from '../misc/formated-date/FormatedDate.component';
import {LINK_PATH_NOTE_DETAILS} from "../../constants/LinkPaths.constants";
import { Link } from 'react-router-dom';
import TranslateComponent from '../misc/translate/Translate.component';
import NoteService from '../../services/Note.service';

export default class SearchResultsComponent extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="tds-all-offering-search-result-row">
                    <div className="tds-all-offerings-table table-responsive">
                        {this.props.notes && this.props.notes.length > 0 &&
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" className="name" onClick={() => this.props.handleSort(NOTE_FIELD_KEYS.NAME)}>
                                            <span className="title"><TranslateComponent label="OFFERINGS_RESULTS_HEADER_NAME"/></span>
                                            <span className="td-icon td-icon-sort"></span>
                                        </th>
                                        <th scope="col" className="fund-serve-code" onClick={() => this.props.handleSort(NOTE_FIELD_KEYS.FUND_SERVE_CODE)}>
                                            <span className="title"><TranslateComponent label="OFFERINGS_RESULTS_HEADER_FUNDSERV_CODE"/></span>
                                            <span className="td-icon td-icon-sort"></span>
                                        </th>
                                        <th scope="col" className="cusip" onClick={() => this.props.handleSort(NOTE_FIELD_KEYS.CUSIP)}>
                                            <span className="title"><TranslateComponent label="OFFERINGS_RESULTS_HEADER_CUSIP"/></span>
                                            <span className="td-icon td-icon-sort"></span>
                                        </th>
                                        <th scope="col" className="cusip" onClick={() => this.props.handleSort(NOTE_FIELD_KEYS.NOTE_TYPE)}>
                                            <span className="title"><TranslateComponent label="OFFERINGS_RESULTS_HEADER_PRODUCT_TYPE"/></span>
                                            <span className="td-icon td-icon-sort"></span>
                                        </th>
                                        <th scope="col" className="available-unit" onClick={() => this.props.handleSort(NOTE_FIELD_KEYS.ISSUE_DATE)}>
                                            <span className="title"><TranslateComponent label="OFFERINGS_RESULTS_HEADER_ISSUE_DATE"/></span>
                                            <span className="td-icon td-icon-sort"></span>
                                        </th>
                                        <th scope="col" className="maturity-date" onClick={() => this.props.handleSort(NOTE_FIELD_KEYS.MATURITY_DATE)}>
                                            <span className="title"><TranslateComponent label="OFFERINGS_RESULTS_HEADER_MATURITY_DATE"/></span>
                                            <span className="td-icon td-icon-sort"></span>
                                        </th>
                                        <th scope="col" className="term" onClick={() => this.props.handleSort(NOTE_FIELD_KEYS.CURRENCY)}>
                                            <span className="title"><TranslateComponent label="OFFERINGS_RESULTS_HEADER_CURRENCY"/></span>
                                            <span className="td-icon td-icon-sort"></span>
                                        </th>
                                        <th scope="col" className="current-status" onClick={() => this.props.handleSort(NOTE_FIELD_KEYS.STATUS)}>
                                            <span className="title"><TranslateComponent label="OFFERINGS_RESULTS_HEADER_CURRENT_STATUS"/></span>
                                            <span className="td-icon td-icon-sort"></span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props.notes.map((item, index) => (
                                            <tr key={'all-offering-notes-' + index}>
                                                <td className="name">
                                                    <Link to={`${LINK_PATH_NOTE_DETAILS}/${item[NOTE_FIELD_KEYS.CODE]}`} title="Note Details">
                                                        {NoteService.getNoteName(item)}
                                                    </Link>
                                                </td>
                                                <td className="fund-serve-code">
                                                    <span className="title">{item[NOTE_FIELD_KEYS.FUND_SERVE_CODE]}</span>
                                                </td>
                                                <td className="cusip">
                                                    <span className="title">{item[NOTE_FIELD_KEYS.CUSIP]}</span>
                                                </td>
                                                <td className="product-class">
                                                    <span className="title">{NoteService.getNoteType(item)}</span>
                                                </td>
                                                <td className="issue-date">
                                                    <span className="title">
                                                        <FormatedDateComponent
                                                            date={item[NOTE_FIELD_KEYS.ISSUE_DATE]}
                                                        />
                                                    </span>
                                                </td>
                                                <td className="maturity-date">
                                                    <span className="title">
                                                        <FormatedDateComponent
                                                            date={item[NOTE_FIELD_KEYS.MATURITY_DATE]}
                                                        />
                                                    </span>
                                                </td>
                                                <td className="currency">
                                                    <span className="title">{item[NOTE_FIELD_KEYS.CURRENCY]}</span>
                                                </td>
                                                <td className="current-status">
                                                <span className="title">{NoteService.getNoteStatus(item)}</span>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        }

                        {(!this.props.notes || this.props.notes.length < 1) &&
                            <div className="tds-current-offering-search-results-description">
                                <TranslateComponent label={NO_RESULTS} />
                            </div>
                        }

                    </div>
                </div>
            </React.Fragment>
        )
    }
}