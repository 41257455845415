import React from 'react';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import {
    CATEGORY,
    NOTE_SEARCH_DROPDOWN_OPTIONS,
    NOTE_SEARCH_FIELDS,
    NOTE_SEARCH_LABEL_KEYS
} from '../../constants/Notes.constants';

import {
    ReactSelectDesktopStyles
} from '../../constants/ReactSelect.constants';
import TranslateComponent from '../misc/translate/Translate.component';
import LanguageService from '../../services/Language.service';
import EnumService from '../../services/Enum.service';
import { DATE_INPUT_FORMAT, DATE_INPUT_FORMAT_FR, LANGUAGE_ENGLISH, PLACEHOLDER_DATE_FORMAT, PLACEHOLDER_DATE_FORMAT_FR } from '../../constants/Misc.constants';


export default class OfferingsSearchComponent extends React.Component {
    constructor(props) {
        super(props);
        this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
        this.handleSearchEnter = this.handleSearchEnter.bind(this);
        this.handleIssueDateFromCalendarIconClick = this.handleIssueDateFromCalendarIconClick.bind(this);
        this.handleIssueDateToCalendarIconClick = this.handleIssueDateToCalendarIconClick.bind(this);
        this.handleMaturityDateToCalendarIconClick = this.handleMaturityDateToCalendarIconClick.bind(this);
        this.handleMaturityDateFromCalendarIconClick = this.handleMaturityDateFromCalendarIconClick.bind(this);
    }

    handleIssueDateFromCalendarIconClick = (event) => {
        this.issueDateFromCalendar.setOpen(true);
    }
    handleIssueDateToCalendarIconClick = (event) => {
        this.issueDateToCalendar.setOpen(true);
    }

    handleMaturityDateToCalendarIconClick = (event) => {
        this.maturityDateToCalendar.setOpen(true)
    }

    handleMaturityDateFromCalendarIconClick = (event) => {
        this.maturityDateFromCalendar.setOpen(true)
    }

    renderDropdownOptions(dropdownOptions) {
        return dropdownOptions.map(this.buildDropDownOption);
    }

    buildDropDownOption(option) {
        if (Array.isArray(option)) {
            return { value: option[1], label: LanguageService.translate(option[0]) };
        }
        return { value: option, label: option };
    }

    handleSearchEnter(e) {
        if (e.key === 'Enter') {
            this.props.handleBasicSearchClick(e);
        }
    }

    createDropDownOpations(categroy) {
        const enums = EnumService.getEnums(categroy);
        if(categroy === CATEGORY.PRODUCT_CLASS || categroy === CATEGORY.NOTE_SUB_TYPE) {
            enums.sort((a, b) => a.valueEn.localeCompare(b.valueEn));
        }
        return enums.map(e => {
            if(LanguageService.isLanguage(LANGUAGE_ENGLISH)) {
                return { value: e.valueEn, label: e.valueEn };
            }
            else {
                return { value: e.valueEn, label: e.valueFr };
            }
        });
    }

    handleSearchInputChange(e) {
        this.props.handleSearchChange(NOTE_SEARCH_FIELDS.KEYWORD)(e.target.value);
    }

    render() {
        return (
            <>
                <div className="sn-offerings-search-simple">                        
                    <input className="form-control"
                        placeholder={LanguageService.translate(NOTE_SEARCH_LABEL_KEYS.KEYWORD_SEARCH_PLACEHOLDER)}
                        value={this.props.searchFields?.[NOTE_SEARCH_FIELDS.KEYWORD]}
                        onChange={this.handleSearchInputChange}
                        onKeyDown={this.handleSearchEnter}
                    ></input>
                        
                    <div className="sn-offerings-simple-search-btn" onClick={this.props.handleBasicSearchClick}>
                        <span className="td-icon td-icon-search"></span>
                    </div>


                    <button className="btn btn-block td-btn-secondary-clear sn-offerings-advanced-search-btn" onClick={this.props.handleExpandClick}>
                        <span>
                            <span><TranslateComponent label={NOTE_SEARCH_LABEL_KEYS.ADVANCED_SEARCH} /></span>
                            <span className={`down-caret ${!this.props.expanded ? "active" : ""}`}>
                                <img alt="Expand Advanced Search Options" src={process.env.PUBLIC_URL + '/assets/img/icn_down_caret.svg'} />
                            </span>
                            <span className={`up-caret ${this.props.expanded ? "active" : ""}`}>
                                <img alt="Collapse Advanced Search Options" src={process.env.PUBLIC_URL + '/assets/img/icn_up_caret.svg'} />
                            </span>
                        </span>
                    </button>
                </div>

                { this.props.expanded &&
                    <div className="container-fluid sn-offerings-search">
                        <div className="row ">
                            <div className="col-xs-12 col-sm-6 col-md-3 mb-25 mb-sm-25 mb-md-25">
                                <Select
                                    options={this.createDropDownOpations(CATEGORY.NOTE_TYPE)}
                                    isMulti={false}
                                    isClearable={true}
                                    value={this.props.searchFields?.[NOTE_SEARCH_FIELDS.PRODUCT_TYPE]}
                                    name={NOTE_SEARCH_LABEL_KEYS.PRODUCT_TYPE}
                                    onChange={this.props.handleSearchChange(NOTE_SEARCH_FIELDS.PRODUCT_TYPE)}
                                    styles={ReactSelectDesktopStyles}
                                    placeholder={LanguageService.translate(NOTE_SEARCH_LABEL_KEYS.PRODUCT_TYPE)}
                                />
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-3 mb-25 mb-sm-25 mb-md-25">
                                <Select
                                    options={this.createDropDownOpations(CATEGORY.PRODUCT_CLASS)}
                                    isMulti={false}
                                    isClearable={true}
                                    value={this.props.searchFields?.[NOTE_SEARCH_FIELDS.PRODUCT_CLASS]}
                                    name={NOTE_SEARCH_LABEL_KEYS.PRODUCT_CLASS}
                                    onChange={this.props.handleSearchChange(NOTE_SEARCH_FIELDS.PRODUCT_CLASS)}
                                    styles={ReactSelectDesktopStyles}
                                    placeholder={LanguageService.translate(NOTE_SEARCH_LABEL_KEYS.PRODUCT_CLASS)}
                                />
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-3 mb-25 mb-sm-25 mb-md-25">
                                <Select
                                    options={this.createDropDownOpations(CATEGORY.PRODUCT_TYPE)}
                                    isMulti={false}
                                    isClearable={true}
                                    value={this.props.searchFields?.[NOTE_SEARCH_FIELDS.UNDERLYING_ASSET_TYPE]}
                                    name={NOTE_SEARCH_LABEL_KEYS.ASSET_TYPE}
                                    onChange={this.props.handleSearchChange(NOTE_SEARCH_FIELDS.UNDERLYING_ASSET_TYPE)}
                                    styles={ReactSelectDesktopStyles}
                                    placeholder={LanguageService.translate(NOTE_SEARCH_LABEL_KEYS.ASSET_TYPE)}
                                />
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-3 mb-25 mb-sm-25 mb-md-25">
                                <Select
                                    options={this.createDropDownOpations(CATEGORY.NOTE_SUB_TYPE)}
                                    isMulti={false}
                                    isClearable={true}
                                    value={this.props.searchFields?.[NOTE_SEARCH_FIELDS.STRUCTURE_TYPE]}
                                    name={NOTE_SEARCH_LABEL_KEYS.STRUCTURE_TYPE}
                                    onChange={this.props.handleSearchChange(NOTE_SEARCH_FIELDS.STRUCTURE_TYPE)}
                                    styles={ReactSelectDesktopStyles}
                                    placeholder={LanguageService.translate(NOTE_SEARCH_LABEL_KEYS.STRUCTURE_TYPE)}
                                />
                            </div>
                        </div>
                        <div className="row">                            
                            <div className="col-xs-12 col-sm-6 col-md-3 mb-25 mb-sm-25 mb-md-25">
                                <Select
                                    options={this.createDropDownOpations(CATEGORY.IRN_COUPON_FREQ)}
                                    isMulti={false}
                                    isClearable={true}
                                    value={this.props.searchFields?.[NOTE_SEARCH_FIELDS.PAYMENT_FREQUENCY]}
                                    name={NOTE_SEARCH_LABEL_KEYS.PAYMENT_FREQUENCY}
                                    onChange={this.props.handleSearchChange(NOTE_SEARCH_FIELDS.PAYMENT_FREQUENCY)}
                                    styles={ReactSelectDesktopStyles}
                                    placeholder={LanguageService.translate(NOTE_SEARCH_LABEL_KEYS.PAYMENT_FREQUENCY)}
                                />
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-3 mb-25 mb-sm-25 mb-md-25">
                                <Select
                                    options={this.createDropDownOpations(CATEGORY.INVESTMENT_OBJECTIVE)}
                                    isMulti={false}
                                    isClearable={true}
                                    value={this.props.searchFields?.[NOTE_SEARCH_FIELDS.INVESTMENT_OBJECTIVE]}
                                    name={NOTE_SEARCH_LABEL_KEYS.INVESTOR_PROFILE}
                                    onChange={this.props.handleSearchChange(NOTE_SEARCH_FIELDS.INVESTMENT_OBJECTIVE)}
                                    styles={ReactSelectDesktopStyles}
                                    placeholder={LanguageService.translate(NOTE_SEARCH_LABEL_KEYS.INVESTOR_PROFILE)}
                                />
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-3 mb-25 mb-sm-25 mb-md-25">
                                <Select
                                    options={this.createDropDownOpations(CATEGORY.ASSET_SECTOR)}
                                    isMulti={false}
                                    isClearable={true}
                                    value={this.props.searchFields?.[NOTE_SEARCH_FIELDS.SECTOR]}
                                    name={NOTE_SEARCH_LABEL_KEYS.SECTOR}
                                    onChange={this.props.handleSearchChange(NOTE_SEARCH_FIELDS.SECTOR)}
                                    styles={ReactSelectDesktopStyles}
                                    placeholder={LanguageService.translate(NOTE_SEARCH_LABEL_KEYS.SECTOR)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-sm-6 col-md-3 mb-25 mb-sm-25 mb-md-25">
                                <Select
                                    options={this.createDropDownOpations(CATEGORY.CURRENCY)}
                                    isMulti={false}
                                    isClearable={true}
                                    value={this.props.searchFields?.[NOTE_SEARCH_FIELDS.CURRENCY]}
                                    name={NOTE_SEARCH_LABEL_KEYS.CURRENCY}
                                    onChange={this.props.handleSearchChange(NOTE_SEARCH_FIELDS.CURRENCY)}
                                    styles={ReactSelectDesktopStyles}
                                    placeholder={LanguageService.translate(NOTE_SEARCH_LABEL_KEYS.CURRENCY)}
                                />
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-3 mb-25 mb-sm-25 mb-md-25">
                                <Select
                                    options={this.createDropDownOpations(CATEGORY.ASSET_GEOGRAPHY)}
                                    isMulti={false}
                                    isClearable={true}
                                    value={this.props.searchFields?.[NOTE_SEARCH_FIELDS.GEOGRAPHY]}
                                    name={NOTE_SEARCH_LABEL_KEYS.GEOGRAPHY}
                                    onChange={this.props.handleSearchChange(NOTE_SEARCH_FIELDS.GEOGRAPHY)}
                                    styles={ReactSelectDesktopStyles}
                                    placeholder={LanguageService.translate(NOTE_SEARCH_LABEL_KEYS.GEOGRAPHY)}
                                />
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-3 mb-25 mb-sm-25 mb-md-25">
                                <Select
                                    options={this.renderDropdownOptions(NOTE_SEARCH_DROPDOWN_OPTIONS.CURRENT_STATUSES)}
                                    isMulti={false}
                                    isClearable={true}
                                    value={this.props.searchFields?.[NOTE_SEARCH_FIELDS.CURRENT_STATUS]}
                                    name={NOTE_SEARCH_LABEL_KEYS.CURRENT_STATUS}
                                    onChange={this.props.handleSearchChange(NOTE_SEARCH_FIELDS.CURRENT_STATUS)}
                                    styles={ReactSelectDesktopStyles}
                                    placeholder={LanguageService.translate(NOTE_SEARCH_LABEL_KEYS.CURRENT_STATUS)}
                                />
                            </div>                    
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-sm-6 col-md-3 mb-25 mb-sm-25 mb-md-25">
                                <div className="row">
                                    <div className="col-4 d-flex align-items-center sn-offerings-search-label-col">
                                        <label><TranslateComponent label={NOTE_SEARCH_LABEL_KEYS.ISSUE_DATE_FROM}/></label>
                                    </div>
                                    <div className="col-8 sn-offerings-search-date-col">
                                        <DatePicker
                                            ref={(c) => this.issueDateFromCalendar = c}
                                            wrapperClassName="w-100"
                                            className="form-control text-right"
                                            placeholderText={LanguageService.chooseLang(PLACEHOLDER_DATE_FORMAT, PLACEHOLDER_DATE_FORMAT_FR)}
                                            selected={this.props.searchFields?.[NOTE_SEARCH_FIELDS.ISSUE_DATE_FROM]}
                                            onChange={this.props.handleSearchChange(NOTE_SEARCH_FIELDS.ISSUE_DATE_FROM)}
                                            maxDate={this.props.searchFields?.[NOTE_SEARCH_FIELDS.ISSUE_DATE_TO]}
                                            dateFormat={LanguageService.chooseLang(DATE_INPUT_FORMAT, DATE_INPUT_FORMAT_FR)}
                                        />
                                        <span 
                                            className="td-icon td-icon-bookAppointment sn-offerings-search-calendar-icon"
                                            onClick={this.handleIssueDateFromCalendarIconClick}
                                        ></span>
                                    </div>
                                </div>                        
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-3 mb-25 mb-sm-25 mb-md-25">
                                <div className="row">
                                    <div className="col-4 d-flex align-items-center sn-offerings-search-label-col">
                                        <label><TranslateComponent label={NOTE_SEARCH_LABEL_KEYS.ISSUE_DATE_TO}/></label>
                                    </div>
                                    <div className="col-8 sn-offerings-search-date-col">
                                        <DatePicker
                                            ref={(c) => this.issueDateToCalendar = c}
                                            wrapperClassName="w-100"
                                            className="form-control text-right"
                                            placeholderText={LanguageService.isLanguage(LANGUAGE_ENGLISH) ? PLACEHOLDER_DATE_FORMAT : PLACEHOLDER_DATE_FORMAT_FR}
                                            selected={this.props.searchFields?.[NOTE_SEARCH_FIELDS.ISSUE_DATE_TO]}
                                            onChange={this.props.handleSearchChange(NOTE_SEARCH_FIELDS.ISSUE_DATE_TO)}
                                            minDate={this.props.searchFields?.[NOTE_SEARCH_FIELDS.ISSUE_DATE_FROM]}
                                            dateFormat={LanguageService.chooseLang(DATE_INPUT_FORMAT, DATE_INPUT_FORMAT_FR)}
                                        />
                                        <span 
                                            className="td-icon td-icon-bookAppointment sn-offerings-search-calendar-icon"
                                            onClick={this.handleIssueDateToCalendarIconClick}
                                        >
                                        </span>                                     
                                    </div>
                                </div>                        
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-3 mb-25 mb-sm-25 mb-md-25">
                                <div className="row">
                                    <div className="col-4 d-flex align-items-center sn-offerings-search-label-col">
                                        <label><TranslateComponent label={NOTE_SEARCH_LABEL_KEYS.MATURITY_DATE_FROM}/></label>
                                    </div>
                                    <div className="col-8 sn-offerings-search-date-col">
                                        <DatePicker
                                            ref={(c) => this.maturityDateFromCalendar = c}
                                            wrapperClassName="w-100"
                                            className="form-control text-right"
                                            placeholderText={LanguageService.chooseLang(PLACEHOLDER_DATE_FORMAT, PLACEHOLDER_DATE_FORMAT_FR)}
                                            selected={this.props.searchFields?.[NOTE_SEARCH_FIELDS.MATURITY_DATE_FROM]}
                                            onChange={this.props.handleSearchChange(NOTE_SEARCH_FIELDS.MATURITY_DATE_FROM)}
                                            maxDate={this.props.searchFields?.[NOTE_SEARCH_FIELDS.MATURITY_DATE_TO]}
                                            dateFormat={LanguageService.chooseLang(DATE_INPUT_FORMAT, DATE_INPUT_FORMAT_FR)}
                                        />
                                        <span 
                                            className="td-icon td-icon-bookAppointment sn-offerings-search-calendar-icon"
                                            onClick={this.handleMaturityDateFromCalendarIconClick}
                                        >
                                        </span>
                                    </div>
                                </div>                        
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-3 mb-25 mb-sm-25 mb-md-25">
                                <div className="row">
                                    <div className="col-4 d-flex align-items-center sn-offerings-search-label-col">
                                        <label><TranslateComponent label={NOTE_SEARCH_LABEL_KEYS.MATURITY_DATE_TO}/></label>
                                    </div>
                                    <div className="col-8 sn-offerings-search-date-col">
                                        <DatePicker
                                            ref={(c) => this.maturityDateToCalendar = c}
                                            wrapperClassName="w-100"
                                            className="form-control text-right"
                                            placeholderText={LanguageService.chooseLang(PLACEHOLDER_DATE_FORMAT, PLACEHOLDER_DATE_FORMAT_FR)}
                                            selected={this.props.searchFields?.[NOTE_SEARCH_FIELDS.MATURITY_DATE_TO]}
                                            onChange={this.props.handleSearchChange(NOTE_SEARCH_FIELDS.MATURITY_DATE_TO)}
                                            minDate={this.props.searchFields?.[NOTE_SEARCH_FIELDS.MATURITY_DATE_FROM]}
                                            dateFormat={LanguageService.chooseLang(DATE_INPUT_FORMAT, DATE_INPUT_FORMAT_FR)}
                                        />
                                        <span 
                                            className="td-icon td-icon-bookAppointment sn-offerings-search-calendar-icon"
                                            onClick={this.handleMaturityDateToCalendarIconClick}
                                        ></span>                                     
                                    </div>
                                </div>                        
                            </div>             
                        </div>
                    
                        <div className="row ">
                            <div className="col-xs-12 col-sm-6 col-md-3 mb-25 mb-sm-25 mb-md-25">
                                <Select
                                    options={this.renderDropdownOptions(NOTE_SEARCH_DROPDOWN_OPTIONS.TERMS)}
                                    isMulti={false}
                                    isClearable={true}
                                    value={this.props.searchFields?.[NOTE_SEARCH_FIELDS.TERM_YEARS_FROM]}
                                    name={NOTE_SEARCH_LABEL_KEYS.TERM_FROM}
                                    onChange={this.props.handleSearchChange(NOTE_SEARCH_FIELDS.TERM_YEARS_FROM)}
                                    styles={ReactSelectDesktopStyles}
                                    placeholder={LanguageService.translate(NOTE_SEARCH_LABEL_KEYS.TERM_FROM)}
                                    aria-labelledby="term-label"
                                />
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-3 mb-25 mb-sm-25 mb-md-25">
                                <Select
                                    options={this.renderDropdownOptions(NOTE_SEARCH_DROPDOWN_OPTIONS.TERMS)}
                                    isMulti={false}
                                    isClearable={true}
                                    value={this.props.searchFields?.[NOTE_SEARCH_FIELDS.TERM_YEARS_TO]}
                                    name={NOTE_SEARCH_LABEL_KEYS.TERM_TO}
                                    onChange={this.props.handleSearchChange(NOTE_SEARCH_FIELDS.TERM_YEARS_TO)}
                                    styles={ReactSelectDesktopStyles}
                                    placeholder={LanguageService.translate(NOTE_SEARCH_LABEL_KEYS.TERM_TO)}
                                    aria-labelledby="term-label"
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xs-6 col-sm-6 col-md-3 mb-25 mb-sm-0 mb-md-0">
                                <button className="btn btn-block td-btn-primary-light" onClick={this.props.handleAdvancedSearchClick}><TranslateComponent label="SEARCH"/></button>
                            </div>
                            <div className="col-xs-6 col-sm-6 col-md-3">
                                <button className="btn btn-block td-btn-secondary-clear" onClick={this.props.handleResetButtonClick}><TranslateComponent label="RESET"/></button>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}
