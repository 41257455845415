import React from "react";
import TranslateComponent from "../../misc/translate/Translate.component";

const Checkbox = ({selected, onchange, label, name, error}) => {
  return (
    <div className="td-form-stacked">
      <input
        id="check_per"
        name={name}
        type="checkbox"
        value="ens_perCheck"
        aria-hidden="true"
        className={!selected ? "" : "checked"}
      />
      <label onClick={() => onchange(name)} className="label-checked">
        <span
          className="td-label-content-wrapper"
          aria-checked="false"
          role="checkbox"
        >
          <span className="td-label-check"></span>
          <span className="td-label-content">
            <TranslateComponent label={label} />
          </span>
        </span>
        {error && <p className="tds-priip-error-msg"><TranslateComponent label={error}/></p>}
      </label>
    </div>
  );
};

export default React.memo(Checkbox);
