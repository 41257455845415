import React from 'react';
import { withRouter, Link } from "react-router-dom";
import HeaderNavBarMobileComponent from './HeaderNavBarMobile.component';
import { HEADER_MAIN_NAV_MOBILE_MENU_DATA_PRIIP, TDS_ACTIVE } from '../../../constants/HeaderNav.constants';
import {
    HEADER_MAIN_NAV_MOBILE_MENU_DATA,
    T_SEARCH,
    T_HOME
} from '../../../constants/HeaderNav.constants';
import TranslateComponent from '../../misc/translate/Translate.component';
import LanguageService from '../../../services/Language.service';
import { KEY_ENTER } from '../../../constants/Misc.constants';
import { ROUTE_PATH_SEARCH } from '../../../constants/Routes.constants';

class HeaderNavMobileComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navLeftActive: false,
            navRightActive: false,
            searchkeywords: "",
        };
        
        this.handleOpenNavLeft = this.handleOpenNavLeft.bind(this);
        this.handleOpenNavRight = this.handleOpenNavRight.bind(this);
        this.handleCloseNavLeft = this.handleCloseNavLeft.bind(this);
        this.handleCloseNavRight = this.handleCloseNavRight.bind(this);
        this.stopBodyScroll = this.stopBodyScroll.bind(this);
        this.startBodyScroll = this.startBodyScroll.bind(this);
        this.handleSetSearchValue = this.handleSetSearchValue.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.showMeeting = this.showMeeting.bind(this);
    }
    stopBodyScroll() {
        document.body.style.overflow = "hidden";
    }
    startBodyScroll() {
        document.body.style.overflow = "auto";
    }
    handleOpenNavLeft(event) {
        window.scrollTo({ top: 0 });
        this.stopBodyScroll();
        this.setState({ navLeftActive: true });
    }
    handleOpenNavRight(event) {
        window.scrollTo({ top: 0 });
        this.stopBodyScroll()
        this.setState({ navRightActive: true });
    }
    handleCloseNavLeft(event) {
        this.startBodyScroll();
        this.setState({ navLeftActive: false });
    }
    handleCloseNavRight(event) {
        this.startBodyScroll();
        this.setState({ navRightActive: false });
    }
    handleLeftMenuItemClick = () => {
        this.handleCloseNavLeft();
    }
    handleRightMenuItemClick = () => {
        this.handleCloseNavRight();
    }
    showModal(modalType) {
        if (modalType) {
            this.setState({
                modalType: modalType
            }, this.headerModal.current.showModal())
        } else {
            this.headerModal.current.showModal()
        }
    }

    handleSearchClick = (event) => {
        this.handleCloseNavLeft(event);
        this.props.history.push(ROUTE_PATH_SEARCH + '?keyword=' + this.state.searchkeywords);
      }
      handleKeyPress(event) {
        if (event.key === KEY_ENTER) {
          this.handleSearchClick(event);
        }
      }

    handleSetSearchValue(event) {
        this.setState({ searchkeywords: event.target.value });
    }
    showMeeting() {
        this.props.showMeeting();
    }

    render() {
        return (
            <header className="tds-mobile-nav">
                <div className="tds-mobile-nav-header">
                    <span className="td-icon td-icon-hamburger icon-mobile"
                        onClick={this.handleOpenNavLeft}></span>
                    <Link
                        to="/"
                        className="tds-logo-mobile">
                        <img src="/assets/img/tds_shield.svg" title="TD Securities" alt="TD Securities" />
                    </Link>
                    <React.Fragment>
                        <span className="td-icon td-icon-avatar icon-mobile"
                            onClick={this.handleOpenNavRight} ></span>
                    </React.Fragment>                    
                </div>
                {
                    (this.state.navLeftActive || this.state.navRightActive) &&
                    <div className="tds-mobile-nav-background-overlay active"></div>
                }
                <div className={`tds-mobile-nav-left ${this.state.navLeftActive ? TDS_ACTIVE : ""}`}>
                    <div className="tds-mobile-nav-logo-container">
                        <Link
                            to="/"
                            className="tds-logo-mobile"
                            onClick={this.handleCloseNavLeft}
                        >
                            <img src="/assets/img/tds_shield.svg" title="TD Securities" alt="TD Securities" />
                        </Link>
                        <span className="td-icon td-icon-close icon-mobile"
                            onClick={this.handleCloseNavLeft}></span>
                    </div>
                    {!window.location.pathname.includes("/resources/priip") && <nav className="tds-mobile-nav-container">
                        {!this.props.hideSearchBar &&
                            <div className="tds-mobile-search-container form-group">
                                <div className="input-group">
                                    <input
                                        name="search"
                                        type="text"
                                        className="form-control"
                                        id="search"
                                        placeholder={LanguageService.translate(T_SEARCH)}
                                        onChange={this.handleSetSearchValue}
                                        onKeyPress={this.handleKeyPress}
                                    />
                                     <div className="input-group-prepend" onClick={this.handleSearchClick}>
                                        <span className="td-icon td-icon-search icon-small"></span>
                                    </div>
                                </div>
                            </div>
                        }
                    </nav> }
                    <nav className="tds-mobile-nav-container">
                    {!window.location.pathname.includes("/resources/priip") && (
                         <div className="tds-mobile-navbar-home">
                            <Link
                            to="/"
                            className="tds-mobile-navbar-link"
                            onClick={this.handleCloseNavLeft}
                             >
                            <TranslateComponent label={T_HOME} />
                            </Link>
                          </div>
                     )}
                        <HeaderNavBarMobileComponent
                             menuItems={window.location.pathname.includes('/resources/priip') ? HEADER_MAIN_NAV_MOBILE_MENU_DATA_PRIIP :HEADER_MAIN_NAV_MOBILE_MENU_DATA}
                             closeNavPanel={this.handleLeftMenuItemClick}
                             showModal={this.props.showModal}/>
                    </nav>
                </div>
                <div className={`tds-mobile-nav-right ${this.state.navRightActive ? TDS_ACTIVE : ""}`}>
                    <div className="tds-mobile-nav-logo-container">
                        <span className="td-icon td-icon-avatar icon-mobile"></span>
                        <span className="td-icon td-icon-close icon-mobile"
                            onClick={this.handleCloseNavRight}></span>
                    </div>                    
                </div>

            </header>
        )
    }
}

export default withRouter(HeaderNavMobileComponent);