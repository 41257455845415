export const CONTACT_CARD_OUTER_CSS_CLASS = 'tds-contact-card-outer';
export const CONTACT_CARD_ICON_CSS_CLASS = 'tds-contact-card-icon';
export const CONTACT_CARD_TEXT_CSS_CLASS = 'tds-contact-card-text';

export const GET = "GET";
export const BLOB = "blob";
export const FORWARD_SLASH = '/';
export const TEXT_PLAIN = 'text/plain';
export const PDF_FILE_TYPE = 'application/pdf';
export const PDF_EXTENSION = '.pdf';

export const KEY_ENTER = 'Enter';

export const FACE_TO_FACE = 'Face-to-face';
export const CONFERANCE_CALL = 'Conference call';
export const ROUND_TABLE = 'Round-table';
export const CONFERENCE = 'Conference';
export const OTHER = 'Other';
export const DEFAULT_EMAIL = 'info@tdsecurities.com';
export const SUBJECT_REQUEST_MEETING = 'TDS Portal: Request a meeting';


export const JSON_EXTENSION = '.json';

export const LANGUAGE_COOKIE_NAME = 'language';

export const LANGUAGE_ENGLISH = 'en';
export const LANGUAGE_FRENCH = 'fr';

export const REQUEST_ACCESS_MAIL_FROM = 'tds.access@tdsecurities.com';
export const REQUEST_ACCESS_MAIL_FROM_MAILTO = 'mailto:tds.access@tdsecurities.com';
export const INVESTOR_FILE_NAME = 'Shelf Prospectus.pdf';
export const ENGLISH = 'ENGLISH';
export const FRENCH = 'FRENCH';
export const DIRECTOR_M = 'DIRECTOR_M';
export const DIRECTOR_F = 'DIRECTOR_F';
export const ASSOCIATE_F = 'ASSOCIATE_F';

export const TDS_DATE_FORMAT = 'MMM DD, YYYY';
export const TDS_DATE_FORMAT_FR = 'DD MMM YYYY';
export const PARAMS_DATE_FORMAT = 'YYYY-MM-DD';
export const PLACEHOLDER_DATE_FORMAT = 'MM/DD/YYYY';
export const PLACEHOLDER_DATE_FORMAT_FR = 'JJ/MM/AAAA';
export const DATE_INPUT_FORMAT = 'MM/dd/yyyy';
export const DATE_INPUT_FORMAT_FR = 'dd/MM/yyyy';
