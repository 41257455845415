import React, {useEffect, useState} from "react";
import moment from "moment";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {useIntl} from "react-intl";
import {isNotEmptyArray} from "../../../utils/valueUtils";
import LanguageService from "../../../services/Language.service";
import {
  LANGUAGE_FRENCH,
  TDS_DATE_FORMAT,
  TDS_DATE_FORMAT_FR,
} from "../../../constants/Misc.constants";

const ProductDetailsHistoricalPerformanceChartComponent = (props) => {
  const {data} = props;
  const intl = useIntl();
  const [points, setPoints] = useState();
  const [visibility, setVisibility] = useState({
    bidPrice: true,
    underlyingValue: true,
  });

  const bidPriceColor = "#038203";
  const underlyingValueColor = "#FF9500";
  const inactiveColor = "#cccccc";

  const getMomentDateWithLocale = (date) => {
    moment.locale(
      LanguageService.currentLanguage === LANGUAGE_FRENCH ? "fr-ca" : "en-ca"
    );

    return moment(
      date,
      LanguageService.currentLanguage === LANGUAGE_FRENCH
        ? TDS_DATE_FORMAT_FR
        : TDS_DATE_FORMAT
    );
  };

  useEffect(() => {
    const chartData = new Map();

    if (visibility["bidPrice"] && isNotEmptyArray(data?.bidPrice)) {
      data?.bidPrice?.forEach((item) => {
        const dateValue = getMomentDateWithLocale(item.date).valueOf();
        const bidPrice = parseFloat(item.price);

        chartData.set(dateValue, {
          date: item.date,
          bidPrice,
        });
      });
    }

    if (
      visibility["underlyingValue"] &&
      isNotEmptyArray(data?.underlyingValue)
    ) {
      data?.underlyingValue?.forEach((item) => {
        const dateValue = getMomentDateWithLocale(item.date).valueOf();

        if (!chartData.has(dateValue)) {
          chartData.set(dateValue, {
            date: item.date,
          });
        }
        const underlyingValue = parseFloat(item.price * 100);

        chartData.get(dateValue)["underlyingValue"] = underlyingValue;
      });
    }

    const sortedChartData = new Map([...chartData].sort());
    const values = Array.from(sortedChartData.values());
    setPoints(
      values.length > 0 ? values : [{date: ""}] //supress "auto" label on X axis if not data
    );
  }, [data, visibility]);

  const selectBar = (event) => {
    const key = event.dataKey;

    setVisibility({
      ...visibility,
      [key]: !visibility[key],
    });
  };

  const renderColorfulLegendText = (value, entry, index) => {
    const colors = {
      bidPrice: bidPriceColor,
      underlyingValue: underlyingValueColor,
    };

    const key = entry.dataKey;

    const style = {
      ...entry,
      color: visibility[key] ? colors[key] : inactiveColor,
      cursor: "pointer",
    };

    return <span style={style}>{value}</span>;
  };

  return (
    <ResponsiveContainer width="100%" height="60%">
      <LineChart data={points}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          style={{
            fontSize: "0.8rem",
          }}
        />
        <YAxis
          style={{
            fontSize: "0.8rem",
          }}
          padding={{bottom: 15}}
          tickFormatter={(value) => value.toFixed()}
          type="number"
          domain={["dataMin", "dataMax"]}
        />
        <Tooltip
          itemStyle={{
            fontSize: "0.8rem",
          }}
          labelStyle={{
            fontSize: "0.8rem",
          }}
          labelFormatter={(value) => {
            return `Date: ${value}`;
          }}
          formatter={(value) => value.toFixed(2)}
        />
        <Legend
          onClick={selectBar}
          wrapperStyle={{
            fontSize: "0.8rem",
          }}
          formatter={renderColorfulLegendText}
        />
        <Line
          type="basic"
          dataKey="bidPrice"
          name={intl.formatMessage({
            id: "PRODUCT_DETAILS_HISTORICAL_PERFORMANCE_CHART_BID_PRICE",
          })}
          stroke="#038203"
          connectNulls="true"
          dot={false}
        />
        <Line
          type="monotone"
          dataKey="underlyingValue"
          name={intl.formatMessage({
            id: "PRODUCT_DETAILS_HISTORICAL_PERFORMANCE_CHART_UNDERLYING_VALUE",
          })}
          stroke="#FF9500"
          connectNulls="true"
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ProductDetailsHistoricalPerformanceChartComponent;
