import {
  NOTE_FIELD_KEYS,
  NOTE_FIELD_COUPON_RATE_POTENTIAL_VALUE,
  NOTE_FIELD_COUPON_FREQUENCY_CONDITIONAL_VALUE,
  NOTE_FIELD_PERCENTAGE_LEVEL_LABEL, 
  NOTE_FIELD_AUTOCALL_VARIABLE_LABEL,
  PERFORMANCE_TYPE,
  IRN_NOTE_TYPE,
  PERFORMANCE_TIME_SERIES
} from '../constants/Notes.constants';
import { PRODUCT_TYPE } from '../constants/ProductDetails.constants';
import LanguageService from '../services/Language.service';
import { SortByDate } from './sorters';
import { appendForwardSlash, convertToPercentage, isEmptyArray, isNotEmptyArray } from './valueUtils';


export default class Note {
  static seeIfAllReturnFeaturesArePresent(note) {
    return note?.[NOTE_FIELD_KEYS.FIXED_SHARE_RETURN] || note?.[NOTE_FIELD_KEYS.MAXI_MUM_SHARE_RETURN] ||
      note?.[NOTE_FIELD_KEYS.MINI_MUM_SHARE_RETURN] || Note.renderAssetLeastPerformingTickerValue(note) ||
      Note.renderAssetBestPerformingTickerValue(note) || Note.renderAssetRainbowWeightValue(note) ||
      Note.renderFinalMaturityLevelValue(note);
  }

  static showIfNotHunderedPercent(note, noteKey) {
    return note[noteKey] < 100;
  }

  static getCouponRateValue(note) {
    return (note[NOTE_FIELD_KEYS.IS_CONTINGENT_COUPON] && note[NOTE_FIELD_KEYS.IS_COUPON_PEROIDIC]) ? NOTE_FIELD_COUPON_RATE_POTENTIAL_VALUE : note[NOTE_FIELD_KEYS.COUPON_RATE] + "%";
  }

  static getCouponFrequencyValue(note, checkForExistenceOfCouponPerodic) {
    if (checkForExistenceOfCouponPerodic && !note[NOTE_FIELD_KEYS.IS_COUPON_PEROIDIC]) return null;
    return (note[NOTE_FIELD_KEYS.IS_CONTINGENT_COUPON] && note[NOTE_FIELD_KEYS.IS_COUPON_PEROIDIC]) ? NOTE_FIELD_COUPON_FREQUENCY_CONDITIONAL_VALUE : note[NOTE_FIELD_KEYS.COUPON_FREQUENCY];
  }

  static getAutoCallLevelValue(note) {
    return (note[NOTE_FIELD_KEYS.IS_VARIABLE_AUTOCALL]) ? NOTE_FIELD_AUTOCALL_VARIABLE_LABEL : note[NOTE_FIELD_KEYS.AUTO_CALL_LEVEL] + NOTE_FIELD_PERCENTAGE_LEVEL_LABEL;
  }

  static renderAssetLeastPerformingTickerValue(note) {
    return note?.[NOTE_FIELD_KEYS.IS_RAINBOW] && note?.[NOTE_FIELD_KEYS.RAINBOW_TYPE] === "worstOf";
  }
  
  static renderAssetBestPerformingTickerValue(note) {
    return note?.[NOTE_FIELD_KEYS.IS_RAINBOW] && note?.[NOTE_FIELD_KEYS.RAINBOW_TYPE] === "bestOf";
  }
  
  static renderAssetRainbowWeightValue(note) {
    return note?.[NOTE_FIELD_KEYS.IS_RAINBOW];
  }

  static renderFinalMaturityLevelValue(note) {
    return note?.[NOTE_FIELD_KEYS.MATURITY_AVERAGING_DATES] !== "null";
  }

  static featureExists(featureName, note) {
    const result = note?.features?.filter(feature => feature.featureName === featureName);
    return (result && result.length > 0);
  }

  static featuresExist(note) {
    const result = note?.features;
    return (result && result.length > 0);
  }

  static getFeatureValue(featureName, note) {
    const result = note?.features?.filter(feature => feature.featureName === featureName);
    return result?.[0]?.featureValue;
  }

    
  static getAutoCallThreshold(autocallSchedule) {
    if (autocallSchedule?.length > 0) {
      let autoCallLevels = "";
      const autoCallSchedules = autocallSchedule.sort(SortByDate.sortAsc('autocallPayDate'));
      
      if (autoCallSchedules?.length > 0) {
        let autoCallLevelValues = [];
        autoCallSchedules.forEach(record => {autoCallLevelValues.push(record.autocallLevel)});
        autoCallLevelValues = [...new Set(autoCallLevelValues)];
        if(autoCallLevelValues.length > 1) {
          for ( const autoCallSchedule of autoCallSchedules ) {
            autoCallLevels += (autoCallSchedule.autocallLevel ? (appendForwardSlash(convertToPercentage(autoCallSchedule.autocallLevel))) : '');          
          }
          return autoCallLevels.substring(0, autoCallLevels.length - 1);
        } else {
          return convertToPercentage(autoCallLevelValues?.[0]);
        }
      }
    }
    return null;
  };


  static getUnderlierInfo(noteDetails) {
    let tickerCompanies = '';
    let tickerCompaniesList = [];
    let perfTimeSeriesData = [];
    const productType = noteDetails[NOTE_FIELD_KEYS.PRODUCT_TYPE];
   
    if (noteDetails?.performanceTimeseries && isNotEmptyArray(noteDetails?.performanceTimeseries)) {
      perfTimeSeriesData = this.performanceTimeseriesTickers(null, noteDetails.performanceTimeseries, PERFORMANCE_TYPE.ASSET_PERFORMANCE);
      if(perfTimeSeriesData) {
        if (productType === PRODUCT_TYPE.indexLinked || productType === PRODUCT_TYPE.fundLinked) {
          perfTimeSeriesData.forEach(perfTimeSeries => tickerCompaniesList.push(LanguageService.chooseLang(perfTimeSeries.underlyingInformation.tickerCompany, perfTimeSeries.underlyingInformation.tickerCompanyFr)));
        } else if (productType === PRODUCT_TYPE.equityLinked || productType === PRODUCT_TYPE.multiAsset) {
          perfTimeSeriesData.forEach(perfTimeSeries => tickerCompaniesList.push(perfTimeSeries.underlyingInformation.shortTicker));
        }
      }
   } 
   if (isEmptyArray(perfTimeSeriesData) && noteDetails?.underlyingInterests && isNotEmptyArray(noteDetails?.underlyingInterests)) {
      if (productType === PRODUCT_TYPE.indexLinked || productType === PRODUCT_TYPE.fundLinked) {
        noteDetails.underlyingInterests.forEach(underlyingInterest => tickerCompaniesList.push(LanguageService.chooseLang(underlyingInterest.underlyingInformation.tickerCompany, underlyingInterest.underlyingInformation.tickerCompanyFr)));
      } else if (productType === PRODUCT_TYPE.equityLinked || productType === PRODUCT_TYPE.multiAsset) {
        noteDetails.underlyingInterests.forEach(underlyingInterest => tickerCompaniesList.push(underlyingInterest.underlyingInformation.shortTicker));
      }
    }
    if(tickerCompaniesList?.length > 0) {
      tickerCompaniesList.forEach( tickerCompany => {
        tickerCompanies += tickerCompany + ", ";
      });
    }
    if(tickerCompanies) {
      return tickerCompanies.substring(0, tickerCompanies.lastIndexOf(","));
    }
    return tickerCompanies;
  };

  //find timeseries value for ticker and most recent report date
  static performanceTimeseriesTickers = (code, performanceTimeseries, performanceType) => {
    const tmpPerfTimeSeriesTickerData = [];
    let filteredPerformanceTimeseries = [];
    if(code) {
      filteredPerformanceTimeseries = performanceTimeseries
      .filter(performanceTimeserie => {
          return performanceTimeserie.performanceType === performanceType 
          && performanceTimeserie.underlyingReference !== PERFORMANCE_TIME_SERIES.UNDERLYING_REFERENCE && code === performanceTimeserie.code
    });
  } else {
      filteredPerformanceTimeseries = performanceTimeseries
      .filter(performanceTimeserie => {
          return performanceTimeserie.performanceType === performanceType 
          && performanceTimeserie.underlyingReference !== PERFORMANCE_TIME_SERIES.UNDERLYING_REFERENCE 
    });
  }

    if(filteredPerformanceTimeseries) {
        const uniqueTickers = [...new Set(filteredPerformanceTimeseries.map((pt) => pt.underlyingReference))];
        uniqueTickers.forEach(ticker => tmpPerfTimeSeriesTickerData.push(
            filteredPerformanceTimeseries
            .filter(performanceTimeserie => {
                    return performanceTimeserie.underlyingReference === ticker
                }).sort(SortByDate.sortDesc('reportDate'))[0]));
        return tmpPerfTimeSeriesTickerData ? tmpPerfTimeSeriesTickerData : null;
    }
}

  static isIrnNote = (noteDetails) => {
    if(noteDetails?.noteType === IRN_NOTE_TYPE) {      
      return true;
    }
    return false;
  }

  //formatting utils
  static convertToPercentage(value, decimalPlaces = 2, removeTrailingZeros = true) {
    if(value && !isNaN(value)) {
      let percentaged = (value * 100).toFixed(decimalPlaces) + "%";
      if(removeTrailingZeros && /\.0+%/g.test(percentaged)) {
        percentaged = (value * 100).toFixed(0) + "%";
      }
      return percentaged;
    }
    return value;
  }

  static converToDecimal(value, decimalPlaces = 2) {
    if(value && !isNaN(value)) {
      let formatted = value.toFixed(decimalPlaces);
      return formatted;
    }
    return value;
  }

  //Method to get bid price section data.
  static getBidPriceByMostRecentDate = (noteDetails) => {
    if (noteDetails?.performanceTimeseries?.length > 0) {
      const bidPrices = noteDetails.performanceTimeseries.filter(e =>
        e.performanceType === PERFORMANCE_TYPE.BID_PRICE);
      if (bidPrices.length > 0) {
        const latestBid = bidPrices.sort(SortByDate.sortDesc('reportDate'))[0];
        return latestBid
      }
    }
    return null;
  }

}