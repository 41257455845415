import ReactGA from "react-ga4";
import EnvironmentService from '../services/Environment.service';
import { logger, LOG_LEVEL } from "../system/Logger";
import { REST_GA_FLAG_PATH, REST_GA_TRACKING_ID_PATH } from '../constants/Rest.constants';
import HttpClient from '../utils/HttpClient';
import { isNotEmpty } from "../utils/valueUtils";
import Cookies from "js-cookie";
import { COOKIE_CONSENT_COOKIE_NAME } from "../constants/GoogleAnalytics.constants";

export const enableGA = async () => {
    const isGAEnabled = await getGAEnabledFlag();

    if (isGAEnabled) {
        const gaOptions = {};
        const trackingId = await getGATrackId(gaOptions);
        if (trackingId) {
            logger.log(LOG_LEVEL.INFO, "GoogleAnalytics", "Connecting to Google Anlytics...");
            ReactGA.initialize(
                [
                    {
                        trackingId,
                        gaOptions
                    }
                ]
            );
            ReactGA.send("pageview");
            logger.log(LOG_LEVEL.INFO, "GoogleAnalytics", `Google Analytics initialized.`);
        } else {
            logger.log(LOG_LEVEL.INFO, "GoogleAnalytics", "Unable to initialize Google Analytics: unable to retrieve GA tracking id. ");
        }
    } else {
        logger.log(LOG_LEVEL.INFO, "GoogleAnalytics", "Google Analytics reporting is disabled in Vault.");           
    }        
}

export const disableGA = async (message) => {
    logger.log(LOG_LEVEL.INFO, "GoogleAnalytics", (isNotEmpty(message) ? message: "Disabling Google Analytics."));
    const gaOptions = {};
    const gaTrackingId = await getGATrackId(gaOptions);

    if (isNotEmpty(gaTrackingId)) {
        Cookies.remove("_ga_" + removePrefix(gaTrackingId));
    }
    Cookies.remove("_ga");
    Cookies.remove("_gid");
    Cookies.remove("_gat");
    ReactGA.reset();
    logger.log(LOG_LEVEL.INFO, "GoogleAnalytics", "Google Analytics disabled.");
};

export const enableDisableGA = () => {
    const isGaInitialized = ReactGA.isInitialized;
    const cookieConsentFlag = Cookies.get(COOKIE_CONSENT_COOKIE_NAME) === "true" ? true : false;
    if (cookieConsentFlag && !isGaInitialized) {
        enableGA();
    } else {
        disableGA();
    }
}

export const disableGAByDeclinedCookieConsent = async () => {
    disableGA("Disabling Google Analytics: cookie consent declined by the user.")
};

export const disableGAByRevokedCookieConsent = async () => {
    disableGA("Disabling Google Analytics: cookie consent revoked by the user.")
};

export const isGaInitialized = async () => {
    return ReactGA.isInitialized;
};


async function getGAEnabledFlag() {
    const gaFlagData = await HttpClient.get(REST_GA_FLAG_PATH);
    if(gaFlagData && gaFlagData.googleAnalyticsFlag && gaFlagData.googleAnalyticsFlag === "false") {
        return false;
    }
    return true;
}

const getGATrackId = async (gaOptions) => {
    const trackingIdData = await HttpClient.get(REST_GA_TRACKING_ID_PATH);
    if (trackingIdData && isNotEmpty(trackingIdData.gaTrackingId)) {

        if ( EnvironmentService.isLocal() ) {
            gaOptions.cookieDomain = "auto";
        }

        return trackingIdData.gaTrackingId;
    }

    return null;
};

const removePrefix = (trackerId) => {
    return trackerId.substring(trackerId.indexOf("-") + 1, trackerId.length);
}