import { LINK_PATH_CURRENT_OFFERINGS } from "./LinkPaths.constants";

export const HOME_PAGE_TILES = [
  {
    id: "principalNote",
    title: "HOME_PAGE_TILE_CURRENT_OFFERINGS_PRINCIPLE_NOTE_TITLE",
    description: "HOME_PAGE_TILE_CURRENT_OFFERINGS_PRINCIPLE_NOTE_DESCRIPTION",
    className: "current-offerings",
    path: LINK_PATH_CURRENT_OFFERINGS,
    image: "/assets/img/Laptop.svg",
    tablet1Image: "/assets/img/Laptop.svg",
    tablet2Image: "/assets/img/Laptop.svg",
    mobileImage: "/assets/img/Laptop.svg",
  },
  {
    id: "protectedNote",
    title: "HOME_PAGE_TILE_CURRENT_OFFERINGS_PROTECTED_NOTE_TITLE",
    description: "HOME_PAGE_TILE_CURRENT_OFFERINGS_PROTECTED_NOTE_DESCRIPTION",
    className: "current-offerings",
    path: LINK_PATH_CURRENT_OFFERINGS,
    image: "/assets/img/Umbrella.svg",
    tablet1Image: "/assets/img/Umbrella.svg",
    tablet2Image: "/assets/img/Umbrella.svg",
    mobileImage: "/assets/img/Umbrella.svg",
  },
  {
    id: "interestRateNote",
    title: "HOME_PAGE_TILE_CURRENT_OFFERINGS_INTEREST_RATE_TITLE",
    description: "HOME_PAGE_TILE_CURRENT_OFFERINGS_INTEREST_RATE_NOTE_DESCRIPTION",
    className: "current-offerings",
    path: LINK_PATH_CURRENT_OFFERINGS,
    image: "/assets/img/Hand-Out-with-Dollar.svg",
    tablet1Image: "/assets/img/Hand-Out-with-Dollar.svg",
    tablet2Image: "/assets/img/Hand-Out-with-Dollar.svg",
    mobileImage: "/assets/img/Hand-Out-with-Dollar.svg",
  },
  /*{
    id: "events",
    title: "HOME_PAGE_TILE_EVENTS_TITLE",
    description: "HOME_PAGE_TILE_EVENTS_DESCRIPTION",
    className: "events",
    path: LINK_PATH_EVENTS,
    image: "/assets/img/events-475x230.jpg",
    tablet1Image: "/assets/img/events-340x220.jpg",
    tablet2Image: "/assets/img/events-340x220.jpg",
    mobileImage: "/assets/img/events-340x220.jpg",
  },*/
  /*{
    id: "resources",
    title: "HOME_PAGE_TILE_RESOURCES_TITLE",
    description: "HOME_PAGE_TILE_RESOURCES_DESCRIPTION",
    className: "resources",
    path: LINK_PATH_RESOURCES,
    image: "/assets/img/resources-475x230.jpg",
    tablet1Image: "/assets/img/resources-340x220.jpg",
    tablet2Image: "/assets/img/resources-340x220.jpg",
    mobileImage: "/assets/img/resources-340x220.jpg",
  },*/
];

export const HOME_PAGE_BANNER = {
  title: "HOME_PAGE_BANNER_TITLE",
  description: "HOME_PAGE_BANNER_DESCRIPTION",
  image: "/assets/img/structured-notes-a-1920x360.jpg",
  tablet1Image: "/assets/img/structured-notes-a-1024x360.jpg",
  tablet2Image: "/assets/img/structured-notes-a-768x360.jpg",
  mobileImage: "/assets/img/structured-notes-a-640x360.jpg",
}

export const HOME_PAGE_BANNER_TITLE = "HOME_PAGE_BANNER_TITLE";

export const HOME_PAGE_BANNER_DESCRIPTION = "HOME_PAGE_BANNER_DESCRIPTION";

export const HOME_PAGE_CURRENT_OFFERING_LINK = "HOME_PAGE_CURRENT_OFFERING_LINK";

export const HOME_PAGE_CURRENT_OFFERING_LINK_FR = "HOME_PAGE_CURRENT_OFFERING_LINK";


export const HOME_PAGE_VIDEOS = [
  {
    id: "introToNotes",
    title: "HOME_PAGE_BANNER_BUTTON_INTRODUCTION_TO_NOTES",
    videoLinkEn: "https://players.brightcove.net/6058082966001/7SfwEDF2a_default/index.html?videoId=6061695124001",
    videoLinkFr: "https://players.brightcove.net/6058082966001/7SfwEDF2a_default/index.html?videoId=6312226346112",
  },
  {
    id: "typesOfNotes",
    title: "HOME_PAGE_BANNER_BUTTON_TYPES_OF_NOTES",
    videoLinkEn: "https://players.brightcove.net/6058082966001/7SfwEDF2a_default/index.html?videoId=6061702657001",
    videoLinkFr: "https://players.brightcove.net/6058082966001/7SfwEDF2a_default/index.html?videoId=6312227714112",
  },
];
