
const crypto = require('crypto');
const { LANGUAGE_ENGLISH } = require('../constants/Misc.constants');
const { DOLLAR_SIGN } = require('../constants/ProductDetails.constants');

function exists(obj) {
  return obj !== undefined && obj !== null;
}

function isNotEmpty(str) {
  return str && str.toString().trim().length > 0;
}

function isEmpty(str) {
  return !isNotEmpty(str);
}

function isNotEmptyArray(array) {
  return Array.isArray(array) && array.length > 0;
}

function isEmptyArray(array) {
  return !isNotEmptyArray(array);
}

function isNotEmptyStringArray(array) {
  if (isNotEmptyArray(array)) {
    if (array.filter(value => isNotEmpty(value)).length > 0) return true;
  }
  return false;
}

function getValueInPercentage(value, truncateDecimal) {
  let convertedValue = value * 100;
  if (convertedValue.toString().indexOf(".") > 0) {
    convertedValue = toFixedDecimalLength(convertedValue, (truncateDecimal === true) ? 0 : 5);
  }
  return convertedValue + '%';
}

const addDecimalPlaceByNumber = (value, number) => {
  if (value) {
    value = value.toString();
    if (value.indexOf(".") === -1) {
      return value;
    } else {
      const subValue = value.substring(value.indexOf(".") + 1, value.length);
      if (subValue.length >= 1) {
        return parseFloat(value).toFixed(number);
      }
    }
  }
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function toFixedDecimalLength(value, decimalLength, addZeros) {
  const re = new RegExp('^-?\\d+(?:.\\d{0,' + (decimalLength || -1) + '})?');
  let formattedValue = value.toString().match(re)[0];
  if(addZeros === true) {
    let decimal = 0;
    if(formattedValue.indexOf('.') !== -1) {
      decimal = formattedValue.substring(formattedValue.indexOf('.') + 1).length
    } else {
      formattedValue += '.'
    }
    while(decimal < decimalLength) {
      formattedValue += '0';
      decimal++;
    }    
  }
  return formattedValue;
}

/**
 * Function to update array with new data.
 * key is field name and index to get data to be updated in an Array at specific position.
 * @returns 
 */
const updateArray = (array, key, value, index) => {
  let data = array[index];
  data[key] = value;
  return ([data]);
}

/**
* Generates a pseudorandom number. Replaces Math's random().
*/
const randomNumber = () => {
  const random = parseFloat('0.' + parseInt(crypto.randomBytes(8).toString('hex')));

  return random;
}

const truncateDecimal = (value) => {
  if(isNotEmpty(value)) {
    return value.toString().split('.')[0];
  }
  return value;  
}

const removeTrailingZerosAfterDecimal = (value, decimalPlace) => {
  let updateValue = Number.parseFloat(value).toFixed(decimalPlace).toString();
  updateValue = updateValue.replace(/0+$/, '');
  if (updateValue.charAt(updateValue.length-1) === '.') {
      updateValue = updateValue.substring(0, updateValue.length-1);
  }
  return updateValue;
}

const getDecimalLength = (value) => {
  const deciamlLength = value.indexOf(".") !== -1 ? 
  value.substring(value.indexOf(".")+1, value.length).length: 0;
  return deciamlLength;
}

const getYesOrNoValue = (value, lang) => {
  if(value === 'true') {
    return (lang === LANGUAGE_ENGLISH ? 'Yes' : 'Oui');
  } else {
    return (lang === LANGUAGE_ENGLISH ? 'No' : 'Non');
  }
}

const convertToDecimal = (value, decimalPlaces = 2) => {
  if(value && !isNaN(value)) {
    if(value && value.toString().indexOf(".") !== -1) {
      return getValueWithoutRoundOffAfterDecimal(value.toString(), decimalPlaces, false, false);
    } else {
      let formatted = value.toFixed(decimalPlaces);
      return formatted;
    }
  }
  return value;
}

const convertToCurrency = (value, decimalPlaces) => {
  if (decimalPlaces) {
    return (DOLLAR_SIGN + convertToDecimal(value, decimalPlaces));
  }
  return (DOLLAR_SIGN + value);
}

const convertToPercentage = (value, decimalPlaces = 2, removeTrailingZeros = true) => {
  if(value && !isNaN(value)) {
    const valueStr = (value*100).toString();
   
    if(valueStr && valueStr.indexOf(".") !== -1) {
      return getValueWithoutRoundOffAfterDecimal(valueStr, decimalPlaces, removeTrailingZeros, true);
    } else {
      return getPercentagedValue(value*100, decimalPlaces, removeTrailingZeros);
    }
  }
  return value;
}

const convertArrayToPercentages = (values, decimalPlaces = 2, removeTrailingZeros = true, separator='/') => {
  
  let array = null;

  try {
    array = JSON.parse(values);
  } catch(err) {
    return values;
  }

  if( isNotEmptyArray(array) ) {
    let percentagesStr = "";
    array.forEach((value, index) => {
      
      const result = convertToPercentage(value, decimalPlaces, removeTrailingZeros);
      percentagesStr += result;
      if( (index + 1) < array.length ) {
        percentagesStr += separator;
      }
    });

    return percentagesStr;
  }

  return convertToPercentage(values, decimalPlaces, removeTrailingZeros);
}

const getValueWithoutRoundOffAfterDecimal = (value, decimalPlaces, removeTrailingZeros, isPercent) => {
  if(value && value.indexOf(".") !== -1) {
    let valueBeforeDecimal = value.substring(0, value.indexOf("."));
    let valueAfterDecimal = value.substring(value.indexOf(".")+1, value.length);
    let finalValue = value;
    if(valueAfterDecimal.length > 8) {
      finalValue = parseFloat(value).toFixed(8);
      valueBeforeDecimal = finalValue.substring(0, finalValue.indexOf("."));
      valueAfterDecimal = finalValue.substring(finalValue.indexOf(".")+1, finalValue.length);
    }
    if(valueAfterDecimal.length >= decimalPlaces) {
      finalValue = (valueBeforeDecimal + "." +valueAfterDecimal.substring(0, decimalPlaces));
       return isPercent ? finalValue + '%' : finalValue;
    } else {
      return isPercent ? getPercentagedValue(parseFloat(finalValue), decimalPlaces, removeTrailingZeros) : parseFloat(finalValue).toFixed(decimalPlaces);
    }
  }
  return value;
}

const getPercentagedValue = (value, decimalPlaces, removeTrailingZeros) => {
  let percentaged = (value).toFixed(decimalPlaces) + "%";
  if(removeTrailingZeros && /\.0+%/g.test(percentaged)) {
    percentaged = (value).toFixed(0) + "%";
  }
  return percentaged;
}

const appendForwardSlash = (value) => {
  return value + '/';
}

module.exports = {
  isNotEmpty,
  isEmpty,
  isEmptyArray,
  isNotEmptyArray,
  isNotEmptyStringArray,
  getValueInPercentage,
  addDecimalPlaceByNumber,
  capitalizeFirstLetter,
  toFixedDecimalLength,
  truncateDecimal,
  updateArray,
  randomNumber,
  getYesOrNoValue,
  removeTrailingZerosAfterDecimal,
  getDecimalLength,
  convertToDecimal,
  convertToCurrency,
  convertToPercentage,
  convertArrayToPercentages,
  appendForwardSlash,
  exists
}