import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  EVENT_PAGE_BACK_BUTTON_LABEL,
  EVENT_PAGE_BARRIER_DETAILS_TABLE_COLUMNS,
  EVENT_PAGE_COUPON_DETAILS_TABLE_COLUMNS,
  EVENT_PAGE_LIFECYCLE_EVENT_PAGE_BARRIER_DETAILS_LABEL,
  EVENT_PAGE_LIFECYCLE_EVENT_PAGE_COUPON_DETAILS_LABEL,
} from "../../constants/EventPage.constants";
import { LINK_PATH_EVENTS } from "../../constants/LinkPaths.constants";
import NoteService from "../../services/Note.service";
import TableComponent from "../misc/table/Table.component";
import TranslateComponent from "../misc/translate/Translate.component";
import SpinnerComponent from "../spinner/Spinner.component";

const LifecycleEventDetailsPageComponent = () => {
  const { id } = useParams();

  const [title, setTitle] = useState();
  const [barrierDetailsData, setBarrierDetailsData] = useState();
  const [couponDetailsData, setCouponDetailsData] = useState();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const data = await NoteService.getNoteLifecycleEventById(id);
      setTitle(data.title);
      setBarrierDetailsData(data.barrierDetails);
      setCouponDetailsData(data.couponDetails);
      setLoading(false);
    };
    fetchData();
  }, [id]);

  const showSpinner = () => loading && <SpinnerComponent />;

  // TODO: add error condition in the integration story
  const resultFetched = () => !loading;

  return (
    <div className="tds-sn-lifecycle-event-details-container tds-container">
      <Link to={LINK_PATH_EVENTS} className="back-button" title="Back to Event Search">
        &lt; <TranslateComponent label={EVENT_PAGE_BACK_BUTTON_LABEL} />
      </Link>
      {showSpinner()}
      {resultFetched() && (
        <>
          <h1>{title}</h1>
          <h3>
            <TranslateComponent label={EVENT_PAGE_LIFECYCLE_EVENT_PAGE_BARRIER_DETAILS_LABEL} />
          </h3>
          <div className="barrier-details-table-wrapper">
            <TableComponent
              className="barrier-details-table"
              columns={EVENT_PAGE_BARRIER_DETAILS_TABLE_COLUMNS}
              data={barrierDetailsData}
            />
          </div>
          <h3>
            <TranslateComponent label={EVENT_PAGE_LIFECYCLE_EVENT_PAGE_COUPON_DETAILS_LABEL} />
          </h3>
          <TableComponent
            className="coupon-details-table"
            columns={EVENT_PAGE_COUPON_DETAILS_TABLE_COLUMNS}
            data={couponDetailsData}
          />
        </>
      )}
    </div>
  );
};

export default LifecycleEventDetailsPageComponent;
