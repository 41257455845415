import React from 'react';
import { Link } from "react-router-dom";
import {
    TDS_TOP_NAV_DROPDOWN_LINK_MOBILE,
    TDS_DROPDOWN_CONTENT_MOBILE,
    TDS_DROPDOWN_ACTIVE,
    LINK_DESTINATION_INTERNAL,
    LINK_DESTINATION_EXTERNAL,
    LINK_DESTINATION_LANGUAGE
} from '../../../constants/HeaderNav.constants';
import {
    LANGUAGE_ENGLISH
} from '../../../constants/Misc.constants';
import TranslateComponent from '../../misc/translate/Translate.component';
import LanguageService from '../../../services/Language.service';
import { LOG_LEVEL, logger } from '../../../system/Logger';

export default class HeaderNavDropDownMobileComponent extends React.Component {
    dropDownClassName = TDS_TOP_NAV_DROPDOWN_LINK_MOBILE;
    dropDownContentClassName = TDS_DROPDOWN_CONTENT_MOBILE;

    constructor(props) {
        super(props);
        logger.log(LOG_LEVEL.DEBUG, "HeaderNavDropDownMobileComponent", this.props);
        this.state = {
            open: false
        }
        this.dropDownItem = this.props.dropDownData;
        this.hover = false || this.props.hover;
        this.createItems();
        this.handleOnClick = this.handleOnClick.bind(this);
        this.handleOnMenuItemClick = this.handleOnMenuItemClick.bind(this);
        this.handleLanguageClick = this.handleLanguageClick.bind(this);
    }
    handleOnMenuItemClick = (event) => {
        if (this.props.closeNavPanel) {
            this.props.closeNavPanel();
        }
        if (event.target.dataset.hasaccess === "false") {
            event.preventDefault();
            this.props.showModal(event.target.dataset.modaltype);
        }
    }
    handleOnClick(event) {
        event.preventDefault();
        if (this.state.open) {
            this.setState({ open: false });
        } else {
            this.setState({ open: true });
        }
    }
    closeMenu() {
        this.setState({ open: false });
    }
    handleLanguageClick(e) {
        e.preventDefault();
        var language = LANGUAGE_ENGLISH;
        if (e.target && e.target.dataset && e.target.dataset.language) {
            language = e.target.dataset.language;
        }
        LanguageService.switchLanguage(language)
    }
    createItems() {
        if (this.dropDownItem && this.dropDownItem.items) {
            this.items = [];
            for (var item of this.dropDownItem.items) {
                if (item.destination === LINK_DESTINATION_INTERNAL) {
                    this.items.push(
                        <li
                            key={this.dropDownItem.text + '_' + item.text}
                        >
                            <Link
                                to={item.link}
                                data-hasaccess={item.hasAccess}
                                data-modaltype={item.modalType}
                                onClick={this.handleOnMenuItemClick}
                            >
                                {
                                    item.isTranslatable &&
                                    <TranslateComponent label={item.text} />
                                }
                                {
                                    !item.isTranslatable &&
                                    <React.Fragment>
                                        {item.text}
                                    </React.Fragment>
                                }
                            </Link>
                        </li>
                    )
                } else if (item.destination === LINK_DESTINATION_EXTERNAL) {
                    this.items.push(
                        <li
                            key={this.dropDownItem.text + '_' + item.text}
                        >
                            <a
                                href={item.link}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {
                                    item.isTranslatable &&
                                    <TranslateComponent label={item.text} />
                                }
                                {
                                    !item.isTranslatable &&
                                    <React.Fragment>
                                        {item.text}
                                    </React.Fragment>
                                }
                            </a>
                        </li>
                    )
                } else if (item.destination === LINK_DESTINATION_LANGUAGE) {
                    this.items.push(
                        <li
                            data-language={item.language}
                            onClick={this.handleLanguageClick}
                        >
                            <a
                                href={'# '}
                                data-language={item.language}

                            >
                                {
                                    item.isTranslatable &&
                                    <TranslateComponent label={item.text} />
                                }
                                {
                                    !item.isTranslatable &&
                                    <React.Fragment>
                                        {item.text}
                                    </React.Fragment>
                                }
                            </a>
                            {
                                LanguageService.isLanguage(item.language) &&
                                <span className="td-icon td-icon-checkMark icon-small"></span>
                            }
                        </li>
                    )
                }
            }
        }
    }
    render() {
        if (this.props.dropDownData) {
            return (
                <div className="tds-mobile-navbar-item">
                    <a href="/"
                        className={this.dropDownClassName + `${this.state.open ? TDS_DROPDOWN_ACTIVE : ""}`}
                        onClick={this.handleOnClick}
                    >
                        {
                            this.dropDownItem.isTranslatable &&
                            <TranslateComponent label={this.dropDownItem.text} />
                        }
                        {
                            !this.dropDownItem.isTranslatable &&
                            <React.Fragment>
                                {this.dropDownItem.text}
                            </React.Fragment>
                        }
                        {
                            !this.state.open &&
                            <span className="td-icon td-icon-downCaret icon-mobile"></span>
                        }
                        {
                            this.state.open &&
                            <span className="td-icon td-icon-upCaret icon-mobile"></span>
                        }

                    </a>
                    <ul
                        className={this.dropDownContentClassName + `${this.state.open ? TDS_DROPDOWN_ACTIVE : ""}`}>
                        {this.items}
                    </ul>
                </div>
            )
        } else {
            return null;
        }
    }
}
