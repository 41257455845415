import moment from "moment";

export class SortByDate {

    static sortDesc(fieldName) {
        const sortDesc = (first, second) => {
            const firstDate = moment(first[fieldName]);
            const secondDate = moment(second[fieldName]);
            if (firstDate.isBefore(secondDate)) {
                return 1;
            }
            else if (firstDate.isAfter(secondDate)) {
                return -1;
            }
            return 0;
        }
        return sortDesc;        
    }

    static sortAsc(fieldName) {
        const sortAsc = (first, second) => {
            const firstDate = moment(first[fieldName]);
            const secondDate = moment(second[fieldName]);
            if (firstDate.isBefore(secondDate)) {
                return -1;
            }
            else if (firstDate.isAfter(secondDate)) {
                return 1;
            }
            return 0;
        }
        return sortAsc;        
    }
}