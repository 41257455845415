import HttpClient from "../utils/HttpClient";
import moment from "moment";
import {
  REST_NOTES_PATH,
  REST_NOTES_ENUMS_PATH,
  REST_NOTE_EVENTS_PATH,
  REST_NOTE_CORPORATE_ACTION_BY_ID_PATH,
  REST_NOTE_LIFECYCLE_EVENTS_PATH,
  REST_NOTE_CORP_ACTION_EVENTS_PATH,
  REST_NOTE_EVENT_DESCRIPTIONS_PATH
} from "../constants/Rest.constants";
import {
  IRN_NOTE_FEATURES,
  IRN_NOTE_TYPE,
  NOTE_FIELD_KEYS,
  NOTE_SEARCH_FIELDS,
  PAR_NOTE_TYPE,
  PERFORMANCE_TYPE,
  PPN_NOTE_TYPE,
  PRODUCT_CLASS
} from "../constants/Notes.constants";
import {
  MockLifecycleEventDetailsData,
} from "../sampleMockupData/eventMockupData";
import  { isNotEmpty, isNotEmptyArray } from "../utils/valueUtils";
import LanguageService from "./Language.service";
import { LANGUAGE_ENGLISH, PARAMS_DATE_FORMAT } from "../constants/Misc.constants";
import EnumService from "./Enum.service";
import Note from "../utils/Note";
import { SortByDate } from "../utils/sorters";
import { formatLocalDate, toTDSDate } from "../services/Date.service";
import {CURRENT_STATUSES} from "../constants/ProductDetails.constants";

export default class NoteService {
  static noteEvents;

  static getNotes(searchFields) {
    //dates are formatted from local timezone to UTC timezone, then converted to YYY-MM-DD format
    const issueDateFrom =
      searchFields && searchFields[NOTE_SEARCH_FIELDS.ISSUE_DATE_FROM] ? formatLocalDate(new Date(searchFields[NOTE_SEARCH_FIELDS.ISSUE_DATE_FROM]), PARAMS_DATE_FORMAT) : null;
    const issueDateTo =
      searchFields && searchFields[NOTE_SEARCH_FIELDS.ISSUE_DATE_TO] ? formatLocalDate(new Date(searchFields[NOTE_SEARCH_FIELDS.ISSUE_DATE_TO]), PARAMS_DATE_FORMAT) : null;
    const maturityDateFrom =
      searchFields && searchFields[NOTE_SEARCH_FIELDS.MATURITY_DATE_FROM] ? formatLocalDate(new Date(searchFields[NOTE_SEARCH_FIELDS.MATURITY_DATE_FROM]), PARAMS_DATE_FORMAT) : null;
    const maturityDateTo =
      searchFields && searchFields[NOTE_SEARCH_FIELDS.MATURITY_DATE_TO] ? formatLocalDate(new Date(searchFields[NOTE_SEARCH_FIELDS.MATURITY_DATE_TO]), PARAMS_DATE_FORMAT) : null;
    const queryParams = {
      keyword: searchFields?.[NOTE_SEARCH_FIELDS.KEYWORD],
      currency: searchFields?.[NOTE_SEARCH_FIELDS.CURRENCY]?.value,
      productType: searchFields?.[NOTE_SEARCH_FIELDS.UNDERLYING_ASSET_TYPE]?.value,
      noteType: searchFields?.[NOTE_SEARCH_FIELDS.PRODUCT_TYPE]?.value,
      noteSubType: searchFields?.[NOTE_SEARCH_FIELDS.STRUCTURE_TYPE]?.value,
      investmentObjective: searchFields?.[NOTE_SEARCH_FIELDS.INVESTMENT_OBJECTIVE]?.value,
      productClass: searchFields?.[NOTE_SEARCH_FIELDS.PRODUCT_CLASS]?.value,
      sector: searchFields?.[NOTE_SEARCH_FIELDS.SECTOR]?.value,
      geography: searchFields?.[NOTE_SEARCH_FIELDS.GEOGRAPHY]?.value,
      frequency: searchFields?.[NOTE_SEARCH_FIELDS.PAYMENT_FREQUENCY]?.value,
      bufferBarrierLevel: searchFields?.[NOTE_SEARCH_FIELDS.BARRIER_BUFFER_LEVEL]?.value,
      issueDateFrom: issueDateFrom,
      issueDateTo: issueDateTo,
      maturityDateFrom: maturityDateFrom,
      maturityDateTo: maturityDateTo,
      currentOfferings: searchFields?.[NOTE_SEARCH_FIELDS.CURRENT_OFFERINGS],
      previousOfferings: searchFields?.[NOTE_SEARCH_FIELDS.PREVIOUS_OFFERINGS],
      noteStatus: searchFields?.[NOTE_SEARCH_FIELDS.CURRENT_STATUS]?.value,
      numberOfTermYearsFrom: searchFields?.[NOTE_SEARCH_FIELDS.TERM_YEARS_FROM]?.value,
      numberOfTermYearsTo: searchFields?.[NOTE_SEARCH_FIELDS.TERM_YEARS_TO]?.value,
      page: searchFields?.[NOTE_SEARCH_FIELDS.PAGE],
      pageSize: searchFields?.[NOTE_SEARCH_FIELDS.PAGE_SIZE],
      orderBy: searchFields?.[NOTE_SEARCH_FIELDS.ORDER_BY],
      descending: searchFields?.[NOTE_SEARCH_FIELDS.DESCENDING]
    };
    return HttpClient.get(REST_NOTES_PATH, queryParams);
  }

  static parseNotesTypes(allNotes) {
    const feeBasedNotes = allNotes?.length > 0 ? this.parseNotesSubTypes(allNotes.filter((note) => note.productClass === PRODUCT_CLASS.A_CLASS)) : [];
    const noFeeBasedNotes = allNotes?.length > 0 ? this.parseNotesSubTypes(allNotes.filter((note) => note.productClass === PRODUCT_CLASS.F_CLASS)) : [];
    return { feeBasedNotes, noFeeBasedNotes };
  }

  static isTheNoteLaunched(note) {
    return note.noteStatus === "Launched";
  }

  static isTheNoteNotLaunched(note) {
    return note.noteStatus !== "Launched";
  }

  static filterPrevOfferings(allNotes) {
    return allNotes.filter(this.isTheNoteNotLaunched);
  }

  static filterCurrentOfferings(allNotes) {
    return allNotes.filter(this.isTheNoteLaunched);
  }

  static parseNotesSubTypes(allNotes) {
    const feeBasedParNotes = allNotes.filter((note) => note.noteType === PAR_NOTE_TYPE);
    const feeBasedPpnNotes = allNotes.filter((note) => note.noteType === PPN_NOTE_TYPE);
    const feeBasedIrnNotes = allNotes.filter((note) => note.noteType === IRN_NOTE_TYPE);
    return {
      ppnNotes: feeBasedPpnNotes,
      parNotes: feeBasedParNotes,
      irnNotes: feeBasedIrnNotes,
    };
  }

  static async getNoteDetailsById(code) {
    const noteDetails = await HttpClient.get(REST_NOTES_PATH + "/" + code);

    if (noteDetails.relatedUnderlyingInformation) {
      this.setUnderlyingInformation(noteDetails.performanceTimeseries, noteDetails.relatedUnderlyingInformation);
      this.setUnderlyingInformation(noteDetails.corpActions, noteDetails.relatedUnderlyingInformation);
      this.setUnderlyingInformation(noteDetails.underlyingInterests, noteDetails.relatedUnderlyingInformation);
    }

    return noteDetails;
  }

  static async getNoteDetailsByCodes(codes) {
    const queryParams = {
      codes: codes,
    };
    const noteDetails = await HttpClient.get(REST_NOTES_PATH + "/codes/details", queryParams );

    return noteDetails;
  }

  static setUnderlyingInformation(collection, relatedUnderlyingInformation) {
    if (collection) {
      collection.forEach(e => {
        if (e.underlyingReference) {
          e.underlyingInformation = relatedUnderlyingInformation[e.underlyingReference];
        }
      });
    }
  }

  static async getAllEnums() {
    return HttpClient.get(REST_NOTES_ENUMS_PATH);
  }

  static async getAllNoteEvents(keyword) {
    let eventServiceRequest = REST_NOTE_EVENTS_PATH;
    if (isNotEmpty(keyword)) {
      eventServiceRequest += `?keyword=${keyword}`
    }

    return HttpClient.get(eventServiceRequest);
  }

  static async getLifecycleEvents(searchParams) {
    let searchCriteria = "";
    if (isNotEmpty(searchParams.keyword)) {
      searchCriteria += `&keyword=${searchParams.keyword}`
    }
    if (isNotEmpty(searchParams.eventDescription)) {
      searchCriteria += `&eventDescription=${searchParams.eventDescription}`
    }
    if (searchParams.page > 1) {
      searchCriteria += `&page=${searchParams.page}`
    }
    if (isNotEmpty(searchParams.eventDateFrom)) {
      searchCriteria += `&eventDateFrom=${searchParams.eventDateFrom}`
    }
    if (isNotEmpty(searchParams.eventDateTo)) {
      searchCriteria += `&eventDateTo=${searchParams.eventDateTo}`
    }
    if( isNotEmpty(searchCriteria) ) {
      searchCriteria = searchCriteria.replace("&", "?")
    }

    return HttpClient.get(REST_NOTE_LIFECYCLE_EVENTS_PATH + searchCriteria);
  }

  static async getCorpActionEvents(searchParams) {
    let searchCriteria = "";
    if (isNotEmpty(searchParams.keyword)) {
      searchCriteria += `&keyword=${searchParams.keyword}`
    }    
    if (searchParams.page > 1) {
      searchCriteria += `&page=${searchParams.page}`
    }
    if (isNotEmpty(searchParams.eventDateFrom)) {
      searchCriteria += `&eventDateFrom=${searchParams.eventDateFrom}`
    }
    if (isNotEmpty(searchParams.eventDateTo)) {
      searchCriteria += `&eventDateTo=${searchParams.eventDateTo}`
    }
    if( isNotEmpty(searchCriteria) ) {
      searchCriteria = searchCriteria.replace("&", "?")
    }

    return HttpClient.get(REST_NOTE_CORP_ACTION_EVENTS_PATH + searchCriteria);
  }

  static async getEventDescriptions() {    

    return HttpClient.get(REST_NOTE_EVENT_DESCRIPTIONS_PATH);
  }

  // TODO: integrate with backend API
  static async getNoteLifecycleEventById(id) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(MockLifecycleEventDetailsData);
      }, 2000);
    });
  }

  static async getNoteCorporateEventById(corpActionId) {

    return HttpClient.get(`${REST_NOTE_CORPORATE_ACTION_BY_ID_PATH}/${corpActionId}`);
  }

  static getNoteName(note) {
    const noteName = (LanguageService.currentLanguage === LANGUAGE_ENGLISH) ? note[NOTE_FIELD_KEYS.NAME] : note[NOTE_FIELD_KEYS.NAME_FR]
    if (noteName) {
      return noteName
    }
    return note[NOTE_FIELD_KEYS.NAME];
  }

  static getNoteType(note) {
    const noteTypeEn = note[NOTE_FIELD_KEYS.NOTE_TYPE];
    if (LanguageService.currentLanguage === LANGUAGE_ENGLISH) {
      return noteTypeEn;
    }
    else {
      const found = EnumService.getEnums('note_type').find(e => e.valueEn === noteTypeEn);
      if (found) {
        return found.valueFr;
      }
      else {
        return noteTypeEn;
      }
    }
  }

  static getNoteProductClass(note) {
    const productClassEn = note[NOTE_FIELD_KEYS.PRODUCT_CLASS];
    if (LanguageService.currentLanguage === LANGUAGE_ENGLISH) {
      return productClassEn;
    }
    else {
      const found = EnumService.getEnums('product_class').find(e => e.valueEn === productClassEn);
      if (found) {
        return found.valueFr;
      }
      else {
        return productClassEn;
      }
    }
  }

  static getNoteProductType(note) {
    const productTypeEn = note[NOTE_FIELD_KEYS.PRODUCT_TYPE];
    if (LanguageService.currentLanguage === LANGUAGE_ENGLISH) {
      return productTypeEn;
    }
    else {
      const found = EnumService.getEnums('product_type').find(e => e.valueEn === productTypeEn);
      if (found) {
        return found.valueFr;
      }
      else {
        return productTypeEn;
      }
    }
  }

  static getNoteAssetGeography(note) {
    const assetGeographyEn = note[NOTE_FIELD_KEYS.ASSET_GEOGRAPHY];
    if (LanguageService.currentLanguage === LANGUAGE_ENGLISH) {
      return assetGeographyEn;
    }
    else {
      const found = EnumService.getEnums('asset_geography').find(e => e.valueEn === assetGeographyEn);
      if (found) {
        return found.valueFr;
      }
      else {
        return assetGeographyEn;
      }
    }
  }

  static getNoteSubType(note) {
    const noteSubTypeEn = note[NOTE_FIELD_KEYS.STRUCTURE_TYPE];
    if (LanguageService.currentLanguage === LANGUAGE_ENGLISH) {
      return noteSubTypeEn;
    }
    else {
      const found = EnumService.getEnums('note_sub_type').find(e => e.valueEn === noteSubTypeEn);
      if (found) {
        return found.valueFr;
      }
      else {
        return noteSubTypeEn;
      }
    }
  }

  static getAssetSector(note) {
    const assetSectorEn = note[NOTE_FIELD_KEYS.ASSET_SECTOR];
    if (LanguageService.currentLanguage === LANGUAGE_ENGLISH) {
      return assetSectorEn;
    }
    else {
      const found = EnumService.getEnums('asset_sector').find(e => e.valueEn === assetSectorEn);
      if (found) {
        return found.valueFr;
      }
      else {
        return assetSectorEn;
      }
    }
  }

  static getInvestmentObjective(note) {
    const investmentObjectiveEn = note[NOTE_FIELD_KEYS.INVESTMENT_OBJECTIVE];
    if (LanguageService.currentLanguage === LANGUAGE_ENGLISH) {
      return investmentObjectiveEn;
    }
    else {
      const found = EnumService.getEnums('investment_objective').find(e => e.valueEn === investmentObjectiveEn);
      if (found) {
        return found.valueFr;
      }
      else {
        return investmentObjectiveEn;
      }
    }
  }

  static getNoteStatus(note) {
    const status = note[NOTE_FIELD_KEYS.STATUS];

    //check if status is override, (e.g. Sold Out)
    if (status !== CURRENT_STATUSES.New
        && status !== CURRENT_STATUSES.Called
        && status !== CURRENT_STATUSES.Matured
        && status !== CURRENT_STATUSES.Closed
        && status !== CURRENT_STATUSES.ClosingSoon){

      if (LanguageService.currentLanguage === LANGUAGE_ENGLISH) {
        return status; //status override is English value
      }
      else {
        //get the French value from status_override enum, based on English value of status override
        const statusOverrideEnum = EnumService.getEnums('status_override').find(e => e.valueEn === status);
        if (statusOverrideEnum) {
          return statusOverrideEnum.valueFr;
        }
        else {
          //status override enum not found, use English value as default
          return status
        }
      }
    }
    else {
      const status = "NOTE_STATUS_" + note[NOTE_FIELD_KEYS.STATUS]?.toUpperCase().replace(" ", "_");
      return LanguageService.translate(status);
    }
  }

  static isHistoricalNote(note) {
    if (note?.files) {
      const performanceDataHtml = note.files.find(file => file.fileType === "additional_performance_data_html");
      if (performanceDataHtml) {
        return true;
      }
    }
    return false;
  }

  static getNoteIrnExtensionFrequency(note) {
    const extensionFrequency = Note.getFeatureValue(IRN_NOTE_FEATURES.EXTENSION_FREQ, note);
    if (LanguageService.currentLanguage === LANGUAGE_ENGLISH) {
      return extensionFrequency;
    }
    else {
      const found = EnumService.getEnums('irn_extension_frequency').find(e => e.valueEn === extensionFrequency);
      if (found) {
        return found.valueFr;
      }
      else {
        return extensionFrequency;
      }
    }
  }

  static getNoteIrnCouponType(note) {
    const couponType = Note.getFeatureValue(IRN_NOTE_FEATURES.COUPON_TYPE, note);
    if (LanguageService.currentLanguage === LANGUAGE_ENGLISH) {
      return couponType;
    }
    else {
      const found = EnumService.getEnums('coupon_type').find(e => e.valueEn === couponType);
      if (found) {
        return found.valueFr;
      }
      else {
        return couponType;
      }
    }
  }

  static getNoteIrnCouponFrequency(note) {
    const couponFrequency = Note.getFeatureValue(IRN_NOTE_FEATURES.COUPON_FREQUENCY, note);
    if (LanguageService.currentLanguage === LANGUAGE_ENGLISH) {
      return couponFrequency;
    }
    else {
      const found = EnumService.getEnums('coupon_frequency').find(e => e.valueEn === couponFrequency);
      if (found) {
        return found.valueFr;
      }
      else {
        return couponFrequency;
      }
    }
  }

  static getCallFrequency(note) {
    const callFrequency = Note.getFeatureValue(NOTE_FIELD_KEYS.CALL_FREQUENCY, note);
    if (LanguageService.currentLanguage === LANGUAGE_ENGLISH) {
      return callFrequency;
    }
    else {
      const found = EnumService.getEnums('call_frequency').find(e => e.valueEn === callFrequency);
      if (found) {
        return found.valueFr;
      }
      else {
        return callFrequency;
      }
    }
  }

  static getNoteCouponFrequency(note) {
    const couponFrequency = Note.getFeatureValue(NOTE_FIELD_KEYS.COUPON_FREQUENCY, note);
    if (LanguageService.currentLanguage === LANGUAGE_ENGLISH) {
      return couponFrequency;
    }
    else {
      const found = EnumService.getEnums('coupon_frequency').find(e => e.valueEn === couponFrequency);
      if (found) {
        return found.valueFr;
      }
      else {
        return couponFrequency;
      }
    }
  }

  static getNoteMinimumVariableCouponPeriod(note) {
    const couponPeriod = Note.getFeatureValue(NOTE_FIELD_KEYS.MINI_MUM_VARIABLE_COUPON_PERIOD, note);
    if (LanguageService.currentLanguage === LANGUAGE_ENGLISH) {
      return couponPeriod;
    }
    else {
      const found = EnumService.getEnums('coupon_frequency').find(e => e.valueEn === couponPeriod);
      if (found) {
        return found.valueFr;
      }
      else {
        return couponPeriod;
      }
    }
  }

  static getHistoricalPerformanceData(noteDetails) {
    const historicalPerfData = [];
    const historicalPerformanceData = noteDetails?.performanceTimeseries?.filter(perfTimeSeries =>
      perfTimeSeries.performanceType === PERFORMANCE_TYPE.BID_PRICE);
    if (historicalPerformanceData?.length && historicalPerformanceData.length > 0) {
      historicalPerformanceData.forEach(perfData => {
        historicalPerfData.push({
          'date': toTDSDate(perfData.reportDate),
          'price': '' + Note.converToDecimal(perfData.value)
        });
      });
    }
    return historicalPerfData;
  }

  static getEarlyTradingFeeData(noteDetails) {
    const etfData = [];
    if (noteDetails?.earlyTradingFee) {
      noteDetails.earlyTradingFee.forEach((etf) => {
        etfData.push({
          'from': toTDSDate(etf.startDate),
          'until': toTDSDate(etf.endDate),
          'etfPerNote': etf.tradingFee ? '' + Note.converToDecimal(etf.tradingFee*100) : '',
        });
      });
    }
    return etfData;
  }

  static compareDates(date1, date2) {
    const d1 = moment(date1);
    const d2 = moment(date2);

    if (d1.isBefore(d2)) {
      return 1;
    }
    if (d1.isAfter(d2)) {
      return -1;
    }
    return 0;
  }

  static getNoteAccountEligibility(note) {
    const accountEligibilityEn = note[NOTE_FIELD_KEYS.ACCOUNT_ELIGIBILITY];
    if (LanguageService.currentLanguage === LANGUAGE_ENGLISH) {
      return accountEligibilityEn;
    }
    else {
      const found = EnumService.getEnums('account_eligibility').find(e => e.valueEn === accountEligibilityEn);
      if (found) {
        return found.valueFr;
      }
      else {
        return accountEligibilityEn;
      }
    }
  }

  static getOpeningLevel(note) {
    if (note?.assetPerformanceType === PERFORMANCE_TYPE.SINGLE_ASSET && isNotEmptyArray(note?.performanceTimeseries)) {
      const initialLevelPerformances = note.performanceTimeseries
        .filter(perfTimeSeries => perfTimeSeries.performanceType === PERFORMANCE_TYPE.INITIAL_LEVEL)
        .sort(SortByDate.sortDesc('reportDate'));
      if (isNotEmptyArray(initialLevelPerformances)) {
        return Note.converToDecimal(initialLevelPerformances[0].value, 3);
      }      
    }
    return '100.000'
  }

  static getNoteCouponPaymentRateAnnualised(note) {

    const couponFrequency = Note.getFeatureValue(NOTE_FIELD_KEYS.COUPON_FREQUENCY, note);
    let couponRate = Note.getFeatureValue(NOTE_FIELD_KEYS.COUPON_RATE, note);

    let couponPaymentRateAnnualised = null;

    if (couponFrequency && couponRate) {
      couponRate = couponRate * 100;

      if (couponFrequency === 'Daily') {
        couponPaymentRateAnnualised = couponRate * 365;
      }
      else if (couponFrequency === 'Monthly') {
        couponPaymentRateAnnualised = couponRate * 12;
      }
      else if (couponFrequency === 'Quarterly') {
        couponPaymentRateAnnualised = couponRate * 4;
      }
      else if (couponFrequency === 'Semi-Annually') {
        couponPaymentRateAnnualised = couponRate * 2;
      }
      else if (couponFrequency === 'Annually') {
        couponPaymentRateAnnualised = couponRate;
      }
    }

    return couponPaymentRateAnnualised;
  }
}