import "whatwg-fetch";

async function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;

  throw error;
}

const extractBody = (response) => {
  const contentType = response.headers.get("content-type");
  if (contentType && contentType.indexOf("application/json") !== -1) {
    return response.json();
  }
  return response.text();
};

function buildQueryParams(params) {
  var query_string = '';
  if (!params) {
    return query_string;
  }
  var keys = Object.keys(params);
  for (var i = 0; i < keys.length; i++) {
    if (params[keys[i]]) {
      query_string += encodeURIComponent(keys[i]) + '=' + encodeURIComponent(params[keys[i]]);
      if (i !== keys.length - 1 && keys.length > 1) {
        query_string += '&';
      }
    }
  }
  return query_string;
}

const HttpClient = {
  async get(url, params) {
    var _url = url;
    if (params) {
      _url = _url + "?" + buildQueryParams(params);
    } 
    const response = await checkStatus(
      await fetch(_url, {
        method: "GET",
      })
    );
    return extractBody(response);
  },

  async post(url, body, { headers = {}, ...options }) {
    const response = await checkStatus(
      await fetch(url, {
        method: "POST",
        headers: {
          ...headers,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
        ...options,
      })
    );

    return extractBody(response);
  },
};

export default HttpClient;