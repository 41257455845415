import React from "react";
import { HOME_PAGE_CURRENT_OFFERING_LINK } from "../../constants/HomePage.constants";
import ResponsiveBackgroundImageComponent from "../misc/responsive-background-image/ResponsiveBackgroundImage.component";
import TranslateComponent from "../misc/translate/Translate.component";

const HomePageTileComponent = (props) => {
  const { id, title, image, tablet1Image, tablet2Image, mobileImage, description, onClick } = props;
  const handleTileClick = (e) => {
    onClick(id);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onClick(id);
    }
  };

  return (
    <div
      className="tds-sn-home-page-tile"
      onClick={handleTileClick}
      onKeyUp={handleKeyPress}
      tabIndex="0"
      aria-label="navigate to"
    >
      <div className="tds-sn-home-page-tile-content">
        <div className="tile-image">
          <ResponsiveBackgroundImageComponent
              className="background-image"
              default={image}
              tablet1={tablet1Image}
              tablet2={tablet2Image}
              mobile={mobileImage}
              title={title} 
          ></ResponsiveBackgroundImageComponent>
        </div>
        <div className="tile-details-container" id={id}>
          <div className="title">     
            <h3><TranslateComponent label={title}/></h3>
          </div>
          <div className="description"> 
            <p><TranslateComponent label={description}/></p>
          </div>
          <div className="button">
            <button className="btn td-btn-primary-light" onClick={handleTileClick}>
              <TranslateComponent label={HOME_PAGE_CURRENT_OFFERING_LINK}/>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageTileComponent;
