import React, { useEffect, useState } from 'react';
import {
    NOTE_FIELD_LABELS,
    NOTE_FIELD_KEYS,
    NOTE_MODALS_PAYOFF_FEATURES_LABEL_KEY} from '../../../constants/Notes.constants';

import NoteUtils from '../../../utils/Note';
import { LINK_PATH_NOTE_DETAILS } from '../../../constants/LinkPaths.constants';
import { Link } from 'react-router-dom';
import TranslateComponent from '../../misc/translate/Translate.component';
import NoteService from '../../../services/Note.service';
import LanguageService from '../../../services/Language.service';
import featureService from '../../../services/NoteFeature.service';
import Note from '../../../utils/Note';
import { toTDSDate } from "../../../services/Date.service";

export default function CurrentOfferingsFeaturesModal(props) {

    const note = props.note;

    const [keyTerms, setKeyTerms] = useState([]);
    const [featureTerms, setFeatureTerms] = useState([]);

    useEffect(() => {
        const getKeyTerms = () => {
            const keyTerms = [];
            
            const issueDate = note[NOTE_FIELD_KEYS.ISSUE_DATE];
            if(issueDate) {
                keyTerms.push({
                    key: NOTE_FIELD_LABELS.ISSUE_DATE,
                    value: toTDSDate(issueDate)
                });
            }

            const maturityDate = note[NOTE_FIELD_KEYS.MATURITY_DATE];
            if(maturityDate) {
                keyTerms.push({
                    key: NOTE_FIELD_LABELS.MATURITY_DATE,
                    value: toTDSDate(maturityDate)
                });
            }

            const fundServCode = note[NOTE_FIELD_KEYS.FUND_SERVE_CODE];
            if(fundServCode) {
                keyTerms.push({
                    key: NOTE_FIELD_LABELS.FUND_SERVE_CODE,
                    value: fundServCode
                });
            }

            const cusip = note[NOTE_FIELD_KEYS.CUSIP];
            if(cusip) {
                keyTerms.push({
                    key: NOTE_FIELD_LABELS.CUSIP,
                    value: cusip
                });
            }

            const currency = note[NOTE_FIELD_KEYS.CURRENCY];
            if(currency) {
                keyTerms.push({
                    key: NOTE_FIELD_LABELS.CURRENCY,
                    value: currency
                });
            }

            const productClass = note[NOTE_FIELD_KEYS.PRODUCT_CLASS];
            if(productClass) {
                keyTerms.push({
                    key: NOTE_FIELD_LABELS.PRODUCT_CLASS,
                    value: NoteService.getNoteProductClass(note)
                });
            }

            const assetGeography = note[NOTE_FIELD_KEYS.ASSET_GEOGRAPHY];
            if(assetGeography) {
                keyTerms.push({
                    key: NOTE_FIELD_LABELS.ASSET_GEOGRAPHY,
                    value: NoteService.getNoteAssetGeography(note)
                });
            }

            const structureType = note[NOTE_FIELD_KEYS.STRUCTURE_TYPE];
            if(structureType) {
                keyTerms.push({
                    key: NOTE_FIELD_LABELS.STRUCTURE_TYPE,
                    value: NoteService.getNoteSubType(note)
                });
            }

            const assetSector = note[NOTE_FIELD_KEYS.ASSET_SECTOR];
            if(assetSector) {
                keyTerms.push({
                    key: NOTE_FIELD_LABELS.SECTOR,
                    value: NoteService.getAssetSector(note)
                });
            }

            const investmentObjective = note[NOTE_FIELD_KEYS.INVESTMENT_OBJECTIVE];
            if(investmentObjective) {
                keyTerms.push({
                    key: NOTE_FIELD_LABELS.INVESTMENT_OBJECTIVE,
                    value: NoteService.getInvestmentObjective(note)
                });
            }

            return keyTerms;
        };

        const getFeatureTerms = () => {
            const featureTerms = [];

            if(NoteUtils.featureExists(NOTE_FIELD_KEYS.AUTO_CALL_LEVEL, note)) {
                featureTerms.push({
                    key: NOTE_FIELD_LABELS.AUTO_CALL_LEVEL,
                    value: Note.convertToPercentage(NoteUtils.getFeatureValue(NOTE_FIELD_KEYS.AUTO_CALL_LEVEL, note))
                });
            }

            const barrierLevel = featureService.getBarrierLevelField(note);
            if(barrierLevel) {
                featureTerms.push(barrierLevel);
            }            

            const bufferLevel = featureService.getBufferLevelField(note);
            if(bufferLevel) {
                featureTerms.push(bufferLevel);
            }

            if(NoteUtils.featureExists(NOTE_FIELD_KEYS.EXCESS_RETURN_PARTICIPATION, note)) {
                featureTerms.push({
                    key: NOTE_FIELD_LABELS.EXCESS_RETURN_PARTICIPATION,
                    value: Note.convertToPercentage(NoteUtils.getFeatureValue(NOTE_FIELD_KEYS.EXCESS_RETURN_PARTICIPATION, note))
                });
            }

            if(NoteUtils.featureExists(NOTE_FIELD_KEYS.DOWNSIDE_MULTIPLIER, note)) {
                featureTerms.push({
                    key: NOTE_FIELD_LABELS.DOWNSIDE_MULTIPLIER,
                    value: Note.convertToPercentage(NoteUtils.getFeatureValue(NOTE_FIELD_KEYS.DOWNSIDE_MULTIPLIER, note))
                });
            }

            const fixedReturn = featureService.getFixedReturnsField(note, NOTE_FIELD_LABELS.FIXED_RETURN);
            if (fixedReturn) {
                featureTerms.push(fixedReturn);
            }

            if(NoteUtils.featureExists(NOTE_FIELD_KEYS.BOOST_LEVEL, note)) {
                featureTerms.push({
                    key: NOTE_FIELD_LABELS.BOOST_LEVEL,
                    value: Note.convertToPercentage(NoteUtils.getFeatureValue(NOTE_FIELD_KEYS.BOOST_LEVEL, note))
                });
            }

            if(NoteUtils.featureExists(NOTE_FIELD_KEYS.BOOSTED_RETURN, note)) {
                featureTerms.push({
                    key: NOTE_FIELD_LABELS.BOOSTED_RETURN,
                    value: Note.convertToPercentage(NoteUtils.getFeatureValue(NOTE_FIELD_KEYS.BOOSTED_RETURN, note))
                });
            }

            if(NoteUtils.featureExists(NOTE_FIELD_KEYS.COUPON_PAYMENT_FREQUENCY, note)) {
                featureTerms.push({
                    key: NOTE_FIELD_LABELS.COUPON_PAYMENT_FREQUENCY,
                    value: LanguageService.translateCouponPaymentFrequency(NoteUtils.getFeatureValue(NOTE_FIELD_KEYS.COUPON_PAYMENT_FREQUENCY, note))
                });
            }

            if(NoteUtils.featureExists(NOTE_FIELD_KEYS.COUPON_BARRIER, note)) {
                featureTerms.push({
                    key: NOTE_FIELD_LABELS.COUPON_PAYMENT_THRESHOLD,
                    value: Note.convertToPercentage(NoteUtils.getFeatureValue(NOTE_FIELD_KEYS.COUPON_BARRIER, note))
                });
            }

            if(NoteUtils.featureExists(NOTE_FIELD_KEYS.PARTICIPATION_RATE, note)) {
                featureTerms.push({
                    key: NOTE_FIELD_LABELS.PARTICIPATION_RATE,
                    value: Note.convertToPercentage(NoteUtils.getFeatureValue(NOTE_FIELD_KEYS.PARTICIPATION_RATE, note))
                });
            }

            if(NoteUtils.featureExists(NOTE_FIELD_KEYS.MAX_NOTE_RETURN, note)) {
                featureTerms.push({
                    key: NOTE_FIELD_LABELS.PARTICIPATION_CAP,
                    value: Note.convertToPercentage(NoteUtils.getFeatureValue(NOTE_FIELD_KEYS.MAX_NOTE_RETURN, note))
                });
            }

            if(NoteUtils.featureExists(NOTE_FIELD_KEYS.COUPON_RATE, note)) {
                featureTerms.push({
                    key: NOTE_FIELD_LABELS.COUPON_RATE,
                    value: Note.convertToPercentage(NoteUtils.getFeatureValue(NOTE_FIELD_KEYS.COUPON_RATE, note))
                });
            }

            return featureTerms;
        }

        setKeyTerms(getKeyTerms());
        setFeatureTerms(getFeatureTerms());

    }, [note]);

    return (
        <React.Fragment>
            <h3><TranslateComponent label="OFFERINGS_RESULTS_HEADER_NOTE_FEATURE"/></h3>

            <div className="tds-current-offerings-note-features-modal table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col"
                                className="name">
                                <span className="title"></span>
                            </th>
                            <th scope="col"
                                className="td-equity-index">
                                <span className="title">
                                    <Link to={`${LINK_PATH_NOTE_DETAILS}/${props?.note[NOTE_FIELD_KEYS.CODE]}`} title="Note Details">
                                        {NoteService.getNoteName(props.note)}
                                    </Link>    
                                </span>
                            </th>
                        </tr>
                    </thead>

                    <tbody>

                        {
                            keyTerms.map((keyTerm, index) =>
                                <tr key={index}>
                                    <td className="name">
                                        <span className="title"><TranslateComponent label={keyTerm.key}/></span>
                                    </td>
                                    <td className="td-equity-index">
                                        <span className="title">{keyTerm.value}</span>
                                    </td>
                                </tr>
                            )
                        }
                        
                        {
                            featureTerms.length > 0 &&
                            <tr>
                                <td className="name">
                                    <span className="title">{<TranslateComponent label={NOTE_MODALS_PAYOFF_FEATURES_LABEL_KEY}/>}</span>
                                </td>
                                <td className="td-equity-index">
                                    <span className="title">&nbsp;</span>
                                </td>
                            </tr>
                        }

                        {
                            featureTerms.map((featureTerm, index) =>
                                <tr key={index}>
                                    <td className="name payoff-features">
                                        <span className="title"><TranslateComponent label={featureTerm.key}/></span>
                                    </td>
                                    <td className="td-equity-index">
                                        <span className="title">{featureTerm.value}</span>
                                    </td>
                                </tr>
                            )
                        }
                                                                                       
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    )
}
