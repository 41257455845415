export const MockEventsData = {
  lifecycleEvents: [
    {
      "id": "100001",
      "dateOfEvent": "5-May-2021",
      "description": "Issuing",
      "nameOfNote": "TD U.S. Healthcare Companies-Linked Autocallable Coupon Notes (USD) Series 566",
      "fundServCode": "TDN1843",
      "cusip": "89115ZYT8",
    },
    {
      "id": "100002",
      "dateOfEvent": "5-May-2021",
      "description": "Maturing",
      "nameOfNote": "TD U.S. Healthcare Companies-Linked Autocallable Coupon Notes (USD) Series 566",
      "fundServCode": "TDN1843",
      "cusip": "89115ZYT8",
    },
    {
      "id": "100003",
      "dateOfEvent": "5-May-2021",
      "description": "Called",
      "nameOfNote": "TD U.S. Healthcare Companies-Linked Autocallable Coupon Notes (USD) Series 566",
      "fundServCode": "TDN1843",
      "cusip": "89115ZYT8",
    },
    {
      "id": "100004",
      "dateOfEvent": "5-May-2021",
      "description": "Distribution",
      "nameOfNote": "TD U.S. Healthcare Companies-Linked Autocallable Coupon Notes (USD) Series 566",
      "fundServCode": "TDN1843",
      "cusip": "89115ZYT8",
    },
    {
      "id": "100005",
      "dateOfEvent": "4-May-2021",
      "description": "Issuing",
      "nameOfNote": "TD U.S. Healthcare Companies-Linked Autocallable Coupon Notes (USD) Series 566",
      "fundServCode": "TDN1843",
      "cusip": "89115ZYT8",
    },
    {
      "id": "100006",
      "dateOfEvent": "4-May-2021",
      "description": "Maturing",
      "nameOfNote": "TD U.S. Healthcare Companies-Linked Autocallable Coupon Notes (USD) Series 566",
      "fundServCode": "TDN1843",
      "cusip": "89115ZYT8",
    },
    {
      "id": "100007",
      "dateOfEvent": "4-May-2021",
      "description": "Called",
      "nameOfNote": "TD U.S. Healthcare Companies-Linked Autocallable Coupon Notes (USD) Series 566",
      "fundServCode": "TDN1843",
      "cusip": "89115ZYT8",
    },
    {
      "id": "100008",
      "dateOfEvent": "4-May-2021",
      "description": "Distribution",
      "nameOfNote": "TD U.S. Healthcare Companies-Linked Autocallable Coupon Notes (USD) Series 566",
      "fundServCode": "TDN1843",
      "cusip": "89115ZYT8",
    },
    {
      "id": "100009",
      "dateOfEvent": "3-May-2021",
      "description": "Issuing",
      "nameOfNote": "TD U.S. Healthcare Companies-Linked Autocallable Coupon Notes (USD) Series 566",
      "fundServCode": "TDN1843",
      "cusip": "89115ZYT8",
    },
    {
      "id": "100010",
      "dateOfEvent": "3-May-2021",
      "description": "Maturing",
      "nameOfNote": "TD U.S. Healthcare Companies-Linked Autocallable Coupon Notes (USD) Series 566",
      "fundServCode": "TDN1843",
      "cusip": "89115ZYT8",
    },
    {
      "id": "100011",
      "dateOfEvent": "3-May-2021",
      "description": "Called",
      "nameOfNote": "TD U.S. Healthcare Companies-Linked Autocallable Coupon Notes (USD) Series 566",
      "fundServCode": "TDN1843",
      "cusip": "89115ZYT8",
    },
    {
      "id": "100012",
      "dateOfEvent": "3-May-2021",
      "description": "Distribution",
      "nameOfNote": "TD U.S. Healthcare Companies-Linked Autocallable Coupon Notes (USD) Series 566",
      "fundServCode": "TDN1843",
      "cusip": "89115ZYT8",
    },
    {
      "id": "100013",
      "dateOfEvent": "2-May-2021",
      "description": "Issuing",
      "nameOfNote": "TD U.S. Healthcare Companies-Linked Autocallable Coupon Notes (USD) Series 566",
      "fundServCode": "TDN1843",
      "cusip": "89115ZYT8",
    },
    {
      "id": "100014",
      "dateOfEvent": "2-May-2021",
      "description": "Maturing",
      "nameOfNote": "TD U.S. Healthcare Companies-Linked Autocallable Coupon Notes (USD) Series 566",
      "fundServCode": "TDN1843",
      "cusip": "89115ZYT8",
    },
    {
      "id": "100015",
      "dateOfEvent": "2-May-2021",
      "description": "Called",
      "nameOfNote": "TD U.S. Healthcare Companies-Linked Autocallable Coupon Notes (USD) Series 566",
      "fundServCode": "TDN1843",
      "cusip": "89115ZYT8",
    },
    {
      "id": "100016",
      "dateOfEvent": "2-May-2021",
      "description": "Distribution",
      "nameOfNote": "TD U.S. Healthcare Companies-Linked Autocallable Coupon Notes (USD) Series 566",
      "fundServCode": "TDN1843",
      "cusip": "89115ZYT8",
    },
  ],
  corporateEvents: [
    {
      "id": "200001",
      "dateOfEvent": "5-May-2021",
      "headline": "PWF Acquisition by POW",
    },
    {
      "id": "200002",
      "dateOfEvent": "4-May-2021",
      "headline": "CNQ CT substituted for ECA",
    },
    {
      "id": "200003",
      "dateOfEvent": "3-May-2021",
      "headline": "IMO CT substituted for ECA",
    },
    {
      "id": "200004",
      "dateOfEvent": "2-May-2021",
      "headline": "Movartis AG/Alcon Inc. Spin-O!",
    },
    {
      "id": "200005",
      "dateOfEvent": "1-May-2021",
      "headline": "Newmont Goldcorp Corp. Acquisition",
    },
    {
      "id": "200006",
      "dateOfEvent": "30-Apr-2021",
      "headline": "Manitoba Telecom - Merger Event",
    },
    {
      "id": "200007",
      "dateOfEvent": "29-Apr-2021",
      "headline": "Canadian Oil Sands Limited - Merger Event",
    },
    {
      "id": "200008",
      "dateOfEvent": "28-Apr-2021",
      "headline": "GlaxoSmithKline PLC - Special Dividend",
    },
    {
      "id": "200009",
      "dateOfEvent": "5-May-2021",
      "headline": "PWF Acquisition by POW",
    },
    {
      "id": "200010",
      "dateOfEvent": "4-May-2021",
      "headline": "CNQ CT substituted for ECA",
    },
    {
      "id": "200011",
      "dateOfEvent": "3-May-2021",
      "headline": "IMO CT substituted for ECA",
    },
    {
      "id": "200012",
      "dateOfEvent": "2-May-2021",
      "headline": "Movartis AG/Alcon Inc. Spin-O!",
    },
    {
      "id": "200013",
      "dateOfEvent": "1-May-2021",
      "headline": "Newmont Goldcorp Corp. Acquisition",
    },
    {
      "id": "200014",
      "dateOfEvent": "30-Apr-2021",
      "headline": "Manitoba Telecom - Merger Event",
    },
    {
      "id": "200015",
      "dateOfEvent": "29-Apr-2021",
      "headline": "Canadian Oil Sands Limited - Merger Event",
    },
    {
      "id": "200016",
      "dateOfEvent": "28-Apr-2021",
      "headline": "GlaxoSmithKline PLC - Special Dividend",
    },   
  ]
};

export const MockLifecycleEventDetailsData = {
  id: "100001",
  title: "TD U.S. Healthcare Companies-Linked Autocallable Coupon Notes (USD) Series 566",
  barrierDetails: [
    {
      autoCallDate: "18-Dec-20",
      valuationDate: "14-Dec-20",
      autoCallLevel: "105%",
      closingBasketLevel: "90.12",
      autoCallFeature: "No",
      basketReturn: "xx.xx",
      maturity: "xx.xx",
      redemptionAmount: "xx.xx",
    },
    {
      autoCallDate: "18-Dec-20",
      valuationDate: "14-Dec-20",
      autoCallLevel: "105%",
      closingBasketLevel: "90.12",
      autoCallFeature: "No",
      basketReturn: "xx.xx",
      maturity: "xx.xx",
      redemptionAmount: "xx.xx",
    },
    {
      autoCallDate: "18-Dec-20",
      valuationDate: "14-Dec-20",
      autoCallLevel: "105%",
      closingBasketLevel: "90.12",
      autoCallFeature: "No",
      basketReturn: "xx.xx",
      maturity: "xx.xx",
      redemptionAmount: "xx.xx",
    },
    {
      autoCallDate: "18-Dec-20",
      valuationDate: "14-Dec-20",
      autoCallLevel: "105%",
      closingBasketLevel: "90.12",
      autoCallFeature: "No",
      basketReturn: "xx.xx",
      maturity: "xx.xx",
      redemptionAmount: "xx.xx",
    },
    {
      autoCallDate: "18-Dec-20",
      valuationDate: "14-Dec-20",
      autoCallLevel: "105%",
      closingBasketLevel: "90.12",
      autoCallFeature: "No",
      basketReturn: "xx.xx",
      maturity: "xx.xx",
      redemptionAmount: "xx.xx",
    },
  ],
  couponDetails: [
    {
      couponPayDates: "18-Dec-20",
      valuationDate: "14-Dec-20",
      basketLevel: "100.11",
      basketReturn: "0.10768%",
      coupon: "$3.250",
    },
    {
      couponPayDates: "18-Dec-20",
      valuationDate: "14-Dec-20",
      basketLevel: "100.11",
      basketReturn: "0.10768%",
      coupon: "$3.250",
    },
    {
      couponPayDates: "18-Dec-20",
      valuationDate: "14-Dec-20",
      basketLevel: "100.11",
      basketReturn: "0.10768%",
      coupon: "$3.250",
    },
  ],
};

export const MockCorporateEventDetailsData = {
  id: "100002",
  title: "PWF Acquisition by POW",
  overview: "On February 18, 2020, all of the issued and outstanding common shares of Power Financial Corporation (\"PWF\") were acquired by Power Corporation of Canada (\"POW\") pursuant to a reorganization transaction (the \"Transaction\"). It is anticipated that PWF common shares will be delisted from the Exchange as soon as practicable. The Transaction was deemed a Substitution Event by the Calculation Agent. As a result, the Calculation Agent has adjusted the Basket e!ective February 18, 2020 as follows: (i) PWF is an Excluded Share, and (ii) one common share of POW is a Replacement Share. The Calculation Agent has determined that the Initial Share Price of POW shall be equal to the Closing Price of POW on the Toronto Stock Exchange on the Initial Valuation Date, adjusted to reflect the economic e!ect of this Substitution Event on the Notes as of February 18, 2020. The Basket Return will be calculated by the Calculation Agent on each Valuation Date after February 18, 2020 as per the methods outlined in the Pricing Supplement or Information Statement related to the Notes, as applicable.",
  events: [
    {
      dateOfEvent: "18-Feb-2020",
      nameOfNote: "TD Canadian Companies-Linked 1% Coupon Plus Growth Notes Series 5",
      fundServCode: "TDN1843",
      cusip: "89115ZYT8",
    },
    {
      dateOfEvent: "18-Feb-2020",
      nameOfNote: "TD Canadian Companies-Linked 1% Coupon Plus Growth Notes Series 5",
      fundServCode: "TDN1843",
      cusip: "89115ZYT8",
    },
  ]
};
