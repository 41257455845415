import React from "react";
import TranslateComponent from "../translate/Translate.component";
import { capitalizeFirstLetter, isNotEmpty } from "../../../utils/valueUtils";
import { toTDSDate } from "../../../services/Date.service";
import { chooseLang, tr } from "../../../services/Language.service";

const TableComponent = (props) => {
  const { className, columns, data, aggregationRow, onSort, onRowClick } = props;

  const handleRowClick = (field, index) => () => {
    onRowClick(field, index);
  }

  function displayValue(column, value, valueFr) {
    if (column.isDate === true && value) {
      return toTDSDate(value, column.format);
    }    

    let displayValue = "";

    if (value) {
      if(column.translationMapping) {
        displayValue = tr(column.translationMapping[value])
      } else {
        displayValue = value
      }
    }
    if ( value && valueFr ) {
      displayValue = chooseLang(value, valueFr);
    }

    if (column.capitalizeFirstLetter === true && isNotEmpty(displayValue)) {
      displayValue = capitalizeFirstLetter(displayValue);
    }

    return displayValue;
  }

  const handleSort = (col) => {
    onSort(col)
  }

  return (
    <table className={"tds-sn-table-container " + className}>
      <thead>
        <tr>
          {columns &&
            columns.map((col) => (
              <th className={col.className ? col.className : null} key={col.field}>
                <span
                  onClick={col.sortable && onSort ? ()=> {handleSort(col.field)} : null}
                  className={col.sortable ? "clickable" : null}
                >
                  {col.translate ? <TranslateComponent label={col.title} /> : col.title !== ''?col.title : ''}
                  {col.sortable ? <span className="td-icon td-icon-sort sort-icon" /> : null}
                </span>
              </th>
            ))}
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map((row, index) => (
            <tr key={index}>
              {columns && 
                columns.map((col) => (
                  <td className={col.className ? col.className : null} key={col.field}>
                    <span 
                      className={col.clickable ? "clickable-field": null}
                      onClick={col.clickable ? handleRowClick(col.field, (col.fieldId) ? row[col.fieldId] : row.eventSearchId ? row.eventSearchId: index): null}
                      title={col.clickable ? row[col.field]: null}
                    >
                      {displayValue(col, row[col.field], row[col.fieldFr])}
                    </span>
                  </td>
              ))}
            </tr>
          ))}
        {aggregationRow && (
          <tr className="aggregation-row">
            {columns.map(col => (
              <td key={col.field}>{aggregationRow[col.field]}</td>
            ))}
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default React.memo(TableComponent);
