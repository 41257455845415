import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import HomePageTileComponent from "./HomePageTile.component";
import {
  HOME_PAGE_BANNER,
  HOME_PAGE_BANNER_DESCRIPTION,
  HOME_PAGE_BANNER_TITLE,
  HOME_PAGE_TILES,
  HOME_PAGE_VIDEOS,
} from "../../constants/HomePage.constants";
import ModalComponent from "../misc/modal/Modal.component";
import LanguageService from "../../services/Language.service";
import { LANGUAGE_ENGLISH } from "../../constants/Misc.constants";
import ResponsiveBackgroundImageComponent from "../misc/responsive-background-image/ResponsiveBackgroundImage.component";
import TranslateComponent from "../misc/translate/Translate.component";
import { HOME_PAGE_TITLE } from "../../constants/PageTitle.constants";

const useShowModal = () => {
  const [videoModalContent, setVideoModalContent] = useState();
  const videoModalRef = useRef();

  const showModal = (id) => {
    setVideoModalContent(HOME_PAGE_VIDEOS.find((v) => v.id === id));
    videoModalRef.current.showModal();
  };

  return [videoModalContent, showModal, videoModalRef];
};

const HomePageComponent = (props) => {
  const history = useHistory();
  const [videoModalContent, showModal, videoModalRef] = useShowModal();

  const handleTileClick = (id) => {
    const tile = HOME_PAGE_TILES.find((t) => t.id === id);

    if (tile) {
      history.push(tile.path, id);
    }
  };

  useEffect(() => {
    document.title = HOME_PAGE_TITLE;
  }, []);

  return (
    <div className="tds-sn-home-container">
      <ResponsiveBackgroundImageComponent
        className="top-banner"
        default={HOME_PAGE_BANNER.image}
        tablet1={HOME_PAGE_BANNER.tablet1Image}
        tablet2={HOME_PAGE_BANNER.tablet2Image}
        mobile={HOME_PAGE_BANNER.mobileImage}
      >
        <div className="content">
          <h1><TranslateComponent label={HOME_PAGE_BANNER_TITLE} /></h1>
          <p><TranslateComponent label={HOME_PAGE_BANNER_DESCRIPTION} /></p>
          {HOME_PAGE_VIDEOS.map((video) => (
            <button title={LanguageService.translate(video.title)} onClick={() => showModal(video.id)} key={video.id}>
              <span>
                <span className="td-icon td-icon-videoPlayButtonCircle" />
                <TranslateComponent label={video.title} />
              </span>
            </button>
          ))}
        </div>
      </ResponsiveBackgroundImageComponent>
      
      <div className="tds-container">
        <div className="tds-sn-home-tile-container">
          {HOME_PAGE_TILES.map(
            ({ id, title, description, className, image, tablet1Image, tablet2Image, mobileImage }) => (
              <HomePageTileComponent
                key={id}
                id={id}
                title={title}
                description={description}
                className={className}
                image={image}
                tablet1Image={tablet1Image}
                tablet2Image={tablet2Image}
                mobileImage={mobileImage}
                onClick={handleTileClick}
              />
            )
          )}
        </div>
      </div>
      <ModalComponent ref={videoModalRef} id={"videoModalRef"} className="video-modal-content">
        {videoModalContent && (
          <section className="video-content">
            <h2><TranslateComponent label={videoModalContent.title} /></h2>
            <iframe
              title={LanguageService.translate(videoModalContent.title)}
              src={
                LanguageService.isLanguage(LANGUAGE_ENGLISH)
                  ? videoModalContent.videoLinkEn
                  : videoModalContent.videoLinkFr
              }
              allowFullScreen={true}
              allow="encrypted-media"
            />
          </section>
        )}
      </ModalComponent>
      
    </div>
  );
};

export default HomePageComponent;
