import React from 'react';
import { SEARCH_PAGE_TITLE } from '../../constants/PageTitle.constants';
import { PAGE_TITLE_LABEL_KEY, RESULTS_PAGE_SIZE } from '../../constants/Search.constants';

import SearchResultsComponent from './SearchResults.component';
import Spinner from '../spinner/Spinner.component';
import Paginator from '../pagination/Paginator.component';
import NoteService from '../../services/Note.service';
import { NOTE_SEARCH_FIELDS } from '../../constants/Notes.constants';
import { ROUTE_PATH_SEARCH } from '../../constants/Routes.constants';
import OfferingsSearchComponent from '../search/OfferingsSearch.component';
import TranslateComponent from '../misc/translate/Translate.component';
import queryString from 'query-string';

const defaultSearchFields = {
    [NOTE_SEARCH_FIELDS.KEYWORD]: "",
    [NOTE_SEARCH_FIELDS.PRODUCT_TYPE]: null,
    [NOTE_SEARCH_FIELDS.PRODUCT_CLASS]: null,
    [NOTE_SEARCH_FIELDS.UNDERLYING_ASSET_TYPE]: null,
    [NOTE_SEARCH_FIELDS.STRUCTURE_TYPE]: null,
    [NOTE_SEARCH_FIELDS.BARRIER_BUFFER_LEVEL]: null,
    [NOTE_SEARCH_FIELDS.PAYMENT_FREQUENCY]: null,
    [NOTE_SEARCH_FIELDS.INVESTMENT_OBJECTIVE]: null,
    [NOTE_SEARCH_FIELDS.SECTOR]: null,
    [NOTE_SEARCH_FIELDS.CURRENCY]: null,
    [NOTE_SEARCH_FIELDS.GEOGRAPHY]: null,
    [NOTE_SEARCH_FIELDS.CURRENT_STATUS]: null,
    [NOTE_SEARCH_FIELDS.ISSUE_DATE_FROM]: null,
    [NOTE_SEARCH_FIELDS.ISSUE_DATE_TO]: null,
    [NOTE_SEARCH_FIELDS.MATURITY_DATE_FROM]: null,
    [NOTE_SEARCH_FIELDS.MATURITY_DATE_TO]: null,
    [NOTE_SEARCH_FIELDS.TERM_YEARS_FROM]: null,
    [NOTE_SEARCH_FIELDS.TERM_YEARS_TO]: null
};

const defaultOfferings = {
    total: 0,
    results: []
};

export default class SearchComponent extends React.Component {
    constructor(props) {
        super(props);

        this.handleBasicSearchClick = this.handleBasicSearchClick.bind(this);
        this.handleAdvancedSearchClick = this.handleAdvancedSearchClick.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleSearchFieldChange = this.handleSearchFieldChange.bind(this);
        this.setHistoryState = this.setHistoryState.bind(this);
        this.setStateAndHistory = this.setStateAndHistory.bind(this);
        this.handleAdvancedSearchExpandedClick = this.handleAdvancedSearchExpandedClick.bind(this);
        this.handleResetButtonClick = this.handleResetButtonClick.bind(this);
        this.handleUnload = this.handleUnload.bind(this);
        this.handleBrowserBackClick = this.handleBrowserBackClick.bind(this);
        this.handleSort = this.handleSort.bind(this);

        this.goToPage = this.goToPage.bind(this);

        const state = { ...window.history.state };
        if (!state.searchFields) {
            let urlQueryParams = queryString.parse(this.props.location?.search);
            if(urlQueryParams?.keyword) {
                const searchFields = { ...defaultSearchFields };
                searchFields.keyword = urlQueryParams.keyword;
                state.searchFields = searchFields;
            }
            else {
                state.searchFields = defaultSearchFields;
            }
            state.allOfferings = defaultOfferings
            state.advancedSearchExpanded = false;
            state.loadingNotes = false;
        }        

        this.state = {
            ...state
        };
        console.log("window history", window.history);
    }

    componentDidMount() {        
        if(!window.history.state?.allOfferings) {
            let urlQueryParams = queryString.parse(this.props.location?.search);
            if(urlQueryParams?.keyword) {
                const keyword = urlQueryParams.keyword;
                this.getAllOfferings({ keyword }, 1, true);
            }
            else {
                this.getAllOfferings({}, 1, true);
            }
        }
        document.title = SEARCH_PAGE_TITLE;
        window.addEventListener('beforeunload', this.handleUnload);
        window.addEventListener('popstate', this.handleBrowserBackClick);

        this.unlisten = this.props.history?.listen((location, action) => {
            let urlQueryParams = queryString.parse(location.search);
            console.log("params", urlQueryParams);
            if(urlQueryParams.keyword || urlQueryParams.keyword === "") {
                const keyword = urlQueryParams.keyword;
                const searchFields = { ...this.state.searchFields };
                searchFields.keyword = keyword;
                this.setState({searchFields});
                this.getAllOfferings({ keyword }, 1, true);
            }
        });
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleUnload);
        window.removeEventListener('popstate', this.handleBrowserBackClick);
        if (this.unlisten) {
            this.unlisten();
        }
    }

    goToPage(page) {
        this.getAllOfferings(this.state.options, page, false);
    }

    getAllOfferings(options, page, replace) {
        this.setState({
            loadingNotes: true,
            options: options,
            page: page
        });

        options.page = page;
        options.pageSize = RESULTS_PAGE_SIZE;
        
        NoteService.getNotes(options).then((offerings) => {
            this.setComponentStateAfterNotesCall(offerings, false, replace);
        }).catch(() => {
            this.setComponentStateAfterNotesCall(defaultOfferings, false, replace);
        });
    }

    setComponentStateAfterNotesCall(offerings, loading, replace) {
        this.setStateAndHistory({
            allOfferings: offerings,
            loadingNotes: loading
        }, replace);
    }

    handleSort(orderBy) {
        let descending = this.state.options?.orderBy === orderBy ? !this.state.options.descending : false;
        let options = { ...this.state.options, orderBy, descending };
        let page = this.state.page;
        this.getAllOfferings(options, page, true);
    }

    handleUnload() {
        this.setHistoryState(null, true);
    }

    handleBrowserBackClick(e) {          
        this.setState({
            ...e.state,
            loadingNotes: true
        }, () => {
            this.setState({
                loadingNotes: false
            })
        });
    }

    setStateAndHistory(state, replace, callback) {
        const historyState = {
            ...this.state,
            ...state            
        }
        console.log("new history state", historyState);
        this.setHistoryState(historyState, replace);
        this.setState(state, callback);
    }

    setHistoryState(state, replace) {
        if(replace) {
            window.history.replaceState(state, null, ROUTE_PATH_SEARCH);
        }
        else {
            window.history.pushState(state, null, ROUTE_PATH_SEARCH);
        }
    }

    handleSearchChange(field) {
        return (value) => {
            this.handleSearchFieldChange(field, value);
        }
    }

    handleSearchFieldChange(field, value) {
        const searchFields = { ...this.state.searchFields };
        searchFields[field] = value;
        this.setStateAndHistory({searchFields}, true);
    }

    handleBasicSearchClick() {
        this.getAllOfferings({
            [NOTE_SEARCH_FIELDS.KEYWORD]: this.state.searchFields[NOTE_SEARCH_FIELDS.KEYWORD]
        }, 1, true);
    }

    handleAdvancedSearchClick() {
        const searchFields = {
            ...this.state.searchFields
        };
        this.getAllOfferings({
            ...searchFields
        }, 1, true);
    }

    handleAdvancedSearchExpandedClick() {
        this.setStateAndHistory({
            advancedSearchExpanded: !this.state.advancedSearchExpanded
        }, true);
    }

    handleResetButtonClick() {
        this.setStateAndHistory({
            searchFields: defaultSearchFields
        }, true);
    }

    render() {
        return (
            <React.Fragment>
                <section className="tds-previous-offering">
                    <div className="tds-container">
                        <div className="tds-previous-offerings-title">
                            <h1><TranslateComponent label={PAGE_TITLE_LABEL_KEY} /></h1>
                        </div>

                        <OfferingsSearchComponent
                            expanded={this.state.advancedSearchExpanded}
                            handleExpandClick={this.handleAdvancedSearchExpandedClick}
                            searchFields={this.state.searchFields}
                            handleSearchChange={this.handleSearchChange}                                 
                            handleBasicSearchClick={this.handleBasicSearchClick}
                            handleAdvancedSearchClick={this.handleAdvancedSearchClick} 
                            handleResetButtonClick={this.handleResetButtonClick}
                        />

                        <React.Fragment>
                            {!this.state.loadingNotes &&
                                <React.Fragment>
                                    <SearchResultsComponent
                                        notes={this.state.allOfferings.results} 
                                        handleSort={this.handleSort}/>
                                </React.Fragment>
                            }

                            {
                                this.state.loadingNotes &&
                                <React.Fragment>
                                    <Spinner
                                        marginTop={100}
                                        minHeight={1200}
                                        marginLeft={10} />
                                </React.Fragment>
                            }

                            {(!this.state.loadingNotes && this.state.allOfferings && this.state.allOfferings.total > 0) &&
                                <Paginator
                                    currentPage={this.state.page}
                                    resultsPerPage={RESULTS_PAGE_SIZE}
                                    total={this.state.allOfferings.total}
                                    goToPage={this.goToPage}
                                />
                            }
                        </React.Fragment>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}
