import React, { useState, useEffect } from "react";
import LanguageService from "../../../services/Language.service";
import { isNotEmptyArray } from "../../../utils/valueUtils";
import TranslateComponent from "../../misc/translate/Translate.component";
import { LINK_PATH_NOTE_DETAILS } from "../../../constants/LinkPaths.constants";
import { REST_NOTES_FILE_PATH } from "../../../constants/Rest.constants";

const ProductDetailsDocuments = (props) => {
  const noteDetails = props.data;

  const [documents, setDocuments] = useState([]);

  useEffect(() => {

    const getFileNameLabel = (file) => {
      return LanguageService.translate("PRODUCT_DETAILS_NOTE_DOCUMENTS_FILE_TYPE_" + file.fileType.toUpperCase())
    }

    const getDocuments = () => {
      const docs = [];
      if (isNotEmptyArray(noteDetails.files)) {
        noteDetails.files.forEach(file => {
          if (file.fileType !== "additional_performance_data_html" && file.lang === LanguageService.currentLanguage.toUpperCase()) {
            docs.push({
              code: file.code,
              fileType: file.fileType,
              lang: file.lang,
              label: getFileNameLabel(file)
            });                                   
          }
        });
      }

      return docs;
    }

    setDocuments(getDocuments());
  }, [noteDetails]);

  const downloadPdf = (e, fileName, fileUrl) => {
   e.preventDefault();
   const tempLink = document.createElement("a");
    tempLink.href = fileUrl;
    tempLink.setAttribute("download", fileName);
    document.body.appendChild(tempLink);
    tempLink.click();
  }

  return (
    <>
      <div className={`tds-sn-product-details-documents-container ${props.customClassName}`}>
        {
          (documents?.length === 0) && <TranslateComponent label="PRODUCT_DETAILS_NOTE_DOCUMENTS_NO_DOCUMENTS_EXISTS"/>
        }

        {
          documents.map((doc, index) => {
            const langType = doc.lang;
            const fileUrl = REST_NOTES_FILE_PATH + `/${doc.code}/${doc.fileType}/${langType}`;
            const routePath = LINK_PATH_NOTE_DETAILS+`/${doc.code}/file/${doc.fileType}/${langType}`;
            return (<span key={index}>
              <a href={routePath}  title={LanguageService.translate("PRODUCT_DETAILS_NOTE_DOCUMENTS_DOWNLOAD")} onClick={(e) => downloadPdf(e, doc.fileType, fileUrl)}><span className="sn-product-details-documents-icon td-icon td-icon-download"></span></a>&nbsp;
              <a href={routePath} target="_blank" rel="noopener noreferrer">{doc.label}</a><br/>
            </span>)
          })
        }
      </div>
    </>
  );
};

export default ProductDetailsDocuments;