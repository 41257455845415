import React, { useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DATE_INPUT_FORMAT, DATE_INPUT_FORMAT_FR, PLACEHOLDER_DATE_FORMAT, PLACEHOLDER_DATE_FORMAT_FR } from "../../../constants/Misc.constants";
import LanguageService from "../../../services/Language.service";

const DateInputComponent = (props) => {

  const { id, onChange, selected, className, ...otherProps } = props;

  const datePickerRef = useRef();

  const handleDatePickerClick = () => {
    datePickerRef.current.setOpen(true);
  }

  const handleDateChange = (date) => {
    onChange(date);
  }

  return (
    <div className={`tds-sn-date-input-container ${className || ""}`}>
      <DatePicker
        ref={datePickerRef}
        id={id}
        className="form-control text-right"
        selected={selected}
        placeholderText={LanguageService.chooseLang(PLACEHOLDER_DATE_FORMAT, PLACEHOLDER_DATE_FORMAT_FR)}
        onChange={handleDateChange}
        dateFormat={LanguageService.chooseLang(DATE_INPUT_FORMAT, DATE_INPUT_FORMAT_FR)}
        {...otherProps}
      />
      <span className="td-icon td-icon-bookAppointment" onClick={handleDatePickerClick}></span>
    </div>
  );
};

export default DateInputComponent;
