import React from "react";

const IconComponent = (props) => {
  const { name, onClick } = props;
  return (
    <div className="tds-sn-icon-container" onClick={onClick}>
      <span className={`td-icon td-icon-${name}`}></span>
      <span className="tds-sn-icon-text">
        {props.children}
      </span>
    </div>
    );
};

export default IconComponent;
