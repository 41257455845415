export const REST_BASE_PATH = "/api/v1";

export const REST_PERSON_SEARCH_PATH = REST_BASE_PATH + "/analyst/search1";

export const REST_ANALYSTS_PATH = REST_BASE_PATH + "/analyst/search";

export const REST_ANALYST_PROFILE_PATH = REST_BASE_PATH + "/analyst/";

export const REST_ANALYST_TEAMS_PATH = REST_BASE_PATH + "/analyst/group/teams";

export const REST_NOTES_PATH = REST_BASE_PATH + "/notes";

export const REST_NOTES_FILE_PATH = REST_BASE_PATH + "/notes/file";

export const REST_NOTE_EVENTS_PATH = REST_BASE_PATH + "/sn-event";

export const REST_NOTE_LIFECYCLE_EVENTS_PATH = REST_NOTE_EVENTS_PATH + "/lifecycle-event";

export const REST_NOTE_CORP_ACTION_EVENTS_PATH = REST_NOTE_EVENTS_PATH + "/corp-action-event";

export const REST_NOTE_EVENT_DESCRIPTIONS_PATH = REST_NOTE_EVENTS_PATH + "/event-description";

export const REST_NOTE_CORPORATE_ACTION_BY_ID_PATH = REST_BASE_PATH + "/sn-event/corp-action";

export const REST_NOTES_ENUMS_PATH = REST_BASE_PATH + "/notes/enums/all";

export const REST_STATIC_FILE_DOWNLOAD_PATH = REST_BASE_PATH + "/static/file/download";

export const REST_GA_FLAG_PATH = REST_BASE_PATH + "/vault/ga/flag";

export const REST_GA_TRACKING_ID_PATH = REST_BASE_PATH + "/vault/ga/track-id";

export const RES_404 = 404;