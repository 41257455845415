import React from 'react';
import { Link } from "react-router-dom";
import {
    TDS_TOP_NAV_DROPDOWN_LINK,
    TDS_DROPDOWN_CONTENT,
    TDS_DROPDOWN_ACTIVE,
    LINK_DESTINATION_INTERNAL,
    LINK_DESTINATION_EXTERNAL,
    LINK_DESTINATION_LANGUAGE
} from '../../constants/HeaderNav.constants';
import {
    ENGLISH, FRENCH, LANGUAGE_ENGLISH, LANGUAGE_FRENCH
} from '../../constants/Misc.constants';
import { LOG_LEVEL, logger } from '../../system/Logger';
import { Redirect } from 'react-router-dom';
import { createBrowserHistory } from "history";
import { isFirefox } from 'react-device-detect';
import TranslateComponent from '../misc/translate/Translate.component';
import LanguageService from '../../services/Language.service';
import {tr} from '../../services/Language.service';

export default class HeaderNavDropDownComponent extends React.Component {
    dropDownClassName = TDS_TOP_NAV_DROPDOWN_LINK;
    dropDownContentClassName = TDS_DROPDOWN_CONTENT;
    history = createBrowserHistory()
    constructor(props) {
        super(props);
        logger.log(LOG_LEVEL.DEBUG, "HeaderNavDropDownComponent", this.props);
        this.state = {
            open: false,
            redirect: false,
            target: this.history.location.pathname,
            lang: ENGLISH
        }
        this.dropDownClassName = this.props.dropDownClassName || this.dropDownClassName;
        this.dropDownContentClassName = this.props.dropDownContentClassName || this.dropDownContentClassName;
        this.dropDownItem = this.props.dropDownData;
        this.hover = false || this.props.hover;
        this.handleOnClick = this.handleOnClick.bind(this);
        this.handleOnMouseEnter = this.handleOnMouseEnter.bind(this);
        this.handleOnMouseLeave = this.handleOnMouseLeave.bind(this);
        this.handleLinkClick = this.handleLinkClick.bind(this);
        this.handleLanguageClick = this.handleLanguageClick.bind(this);
        if (this.dropDownItem?.text === ENGLISH || this.dropDownItem?.text === FRENCH) {
            this.dropDownItem.text = LanguageService.isLanguage(LANGUAGE_ENGLISH) ? ENGLISH : FRENCH;
        }
    }
    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.target} />
        }
    }
    handleOnClick(event) {
        event.preventDefault();
        if (this.state.open) {
            this.setState({ open: false });
        } else {
            this.tellParentToCloseOtherMenus();
            this.setState({ open: true, redirect: false });
        }
    }
    handleOnMouseEnter(event) {
        this.tellParentToCloseOtherMenus();
        if (this.hover) {
            this.setState({ open: true, redirect: false });
        }
    }
    handleOnMouseLeave(event) {
        if (this.hover) {
            this.setState({ open: false });
        }
    }
    closeMenu() {
        this.setState({ open: false });
    }
    tellParentToCloseOtherMenus() {
        if (!isFirefox) {
            this.props.closeOtherMenus();
        }
    }
    navigate(target, hasAccess, modalType) {
        if (hasAccess) {
            this.setState(
                { target: target },
                this.setState(
                    {
                        redirect: true
                    }
                )
            );
        } else {
            this.props.showModal(modalType);
        }
    }
    navigateExternal(target) {
        window.open(target);
    }
    handleLinkClick(e) {
        e.preventDefault();
    }
    handleLanguageClick(e) {
        e.preventDefault();
        this.dropDownItem.text = e.target.dataset.language === LANGUAGE_ENGLISH ? ENGLISH : FRENCH;
        let language = LANGUAGE_ENGLISH;
        if (e.target && e.target.dataset && e.target.dataset.language) {
            language = e.target.dataset.language;
        }
        this.setState({ lang: language });
        LanguageService.switchLanguage(language)
    }
    render() {
        if (this.props.dropDownData) {
            return (
                <div className="tds-nav-item"
                    onClick={this.handleOnClick}
                    onMouseEnter={this.handleOnMouseEnter}
                    onMouseLeave={this.handleOnMouseLeave}
                >
                    {this.renderRedirect()}
                    {
                        !this.state.open &&
                        <React.Fragment>
                            <a
                                href="# "
                                className={this.dropDownClassName}>
                                {
                                    this.dropDownItem.isTranslatable &&
                                    <TranslateComponent label={this.state.lang === LANGUAGE_FRENCH ? FRENCH : this.dropDownItem.text} />
                                }
                                {
                                    !this.dropDownItem.isTranslatable &&
                                    <React.Fragment>
                                        {this.dropDownItem.text}
                                    </React.Fragment>
                                }
                                <span className="td-icon td-icon-downCaret icon-tiny"></span>
                            </a>
                        </React.Fragment>
                    }
                    {
                        this.state.open &&
                        <React.Fragment>
                            <a
                                href="# "
                                className={this.dropDownClassName + TDS_DROPDOWN_ACTIVE}
                            >
                                {
                                    this.dropDownItem.isTranslatable &&
                                    <TranslateComponent label={this.dropDownItem.text} />
                                }
                                {
                                    !this.dropDownItem.isTranslatable &&
                                    <React.Fragment>
                                        {this.dropDownItem.text}
                                    </React.Fragment>
                                }
                                <span className="td-icon td-icon-upCaret icon-tiny"></span>
                            </a>
                            <ul className={this.dropDownContentClassName + TDS_DROPDOWN_ACTIVE}>
                                {
                                    this.props.dropDownData.items.map((item, index) => (
                                        <React.Fragment
                                            key={this.dropDownItem.text + index + '_' + item.text}
                                        >
                                            { item.destination === LINK_DESTINATION_EXTERNAL &&
                                                <li>
                                                    <a
                                                        href={tr(item.link)}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="tds-dropdown-content-external-link" 
                                                        onClick={this.navigateExternal.bind(this, tr(item.link))}
                                                    >
                                                        {
                                                            item.isTranslatable &&
                                                            <TranslateComponent label={item.text} />
                                                        }
                                                        {
                                                            !item.isTranslatable &&
                                                            <React.Fragment>
                                                                {item.text}
                                                            </React.Fragment>
                                                        }
                                                    </a>
                                                </li>
                                            }
                                            { item.destination === LINK_DESTINATION_INTERNAL &&
                                                <li
                                                    onClick={this.navigate.bind(this, item.link, item.hasAccess, item.modalType)}
                                                >
                                                    <Link
                                                        to={item.link}
                                                        onClick={this.handleLinkClick}
                                                    >
                                                        {
                                                            item.isTranslatable &&
                                                            <TranslateComponent label={item.text} />
                                                        }
                                                        {
                                                            !item.isTranslatable &&
                                                            <React.Fragment>
                                                                {item.text}
                                                            </React.Fragment>
                                                        }
                                                    </Link>
                                                </li>
                                            }
                                            { item.destination === LINK_DESTINATION_LANGUAGE &&
                                                <li
                                                    data-language={item.language}
                                                    onClick={this.handleLanguageClick}
                                                >
                                                    <a
                                                        href={'# '}
                                                        data-language={item.language}

                                                    >
                                                        {
                                                            item.isTranslatable &&
                                                            <TranslateComponent label={item.text} />
                                                        }
                                                        {
                                                            !item.isTranslatable &&
                                                            <React.Fragment>
                                                                {item.text}
                                                            </React.Fragment>
                                                        }
                                                    </a>
                                                    {
                                                        LanguageService.isLanguage(item.language) &&
                                                        <span className="td-icon td-icon-checkMark icon-small"></span>
                                                    }
                                                </li> 
                                            }
                                        </React.Fragment>
                                    ))
                                }
                            </ul>
                        </React.Fragment>
                    }
                </div>
            )
        } else {
            return null;
        }
    }
}
