import NoteService from "./Note.service";

export default class EnumService {

    static enums = [];

    static async initialize() {
        EnumService.enums = await NoteService.getAllEnums()
    }

    static getEnums(category) {
        return EnumService.enums.filter(e => e.fieldName === category);
    }

}