import React from "react";

const TabContentComponent = (props) => {
  const { value, index } = props;

  if (value === index) {
    return (
      <section className="tds-sn-tab-content active">{props.children}</section>
    );
  }

  return null;
};

export default TabContentComponent;
