import React, { useState, useEffect } from "react";
import {
  PRODUCT_DETAILS_HISTORICAL_PRICE_TABLE_PAGE_SIZE,
  PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_HISTORICAL_PRICE_TABLE_COLUMNS,
  PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_HISTORICAL_PRICE_TABLE_LABEL,
  PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_HISTORICAL_PRICE_TABLE_EXPORT_LABEL,
} from "../../../constants/ProductDetails.constants";
import TableComponent from "../../misc/table/Table.component";
import TranslateComponent from "../../misc/translate/Translate.component";
import PaginatorComponent from "../../pagination/Paginator.component";
import { EVENT_PAGE_DATE_FILTER_FROM_LABEL, EVENT_PAGE_DATE_FILTER_TO_LABEL } from "../../../constants/EventPage.constants";
import DateInputComponent from "../../misc/date-input/DateInput.component";
import moment from "moment";

const ProductDetailsHistoricalPriceTable = (props) => {
  const data = props?.data;
  const showExport = props?.showExport;
  const [filteredData, setFilteredData] = useState(data);
  const [exportMenuOpen, setExportMenuOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataForDate, setDataForDate] = useState(data);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [minEndDate, setMinEndDate] = useState();

  useEffect (() => {
    let result = data;
    if (startDate) {
      result = data.filter(d => moment(d.date).toDate() >=  moment(startDate).toDate());
    }
    if (endDate) {
      result = result.filter(d => moment(d.date).toDate() <= moment(endDate).toDate());
    }
    setFilteredData(result);
    setDataForDate(result);
  }, [data, startDate, endDate]);

  // update filtered data based on table page selection
  useEffect(() => {
    const startIndex = (currentPage - 1) * PRODUCT_DETAILS_HISTORICAL_PRICE_TABLE_PAGE_SIZE;
    const endIndex = currentPage * PRODUCT_DETAILS_HISTORICAL_PRICE_TABLE_PAGE_SIZE - 1;
    let dataForPageAndDate = dataForDate?.filter((d, index) => index >= startIndex && index <= endIndex);

    setFilteredData(dataForPageAndDate);
  }, [dataForDate, currentPage]);

  // update maxStartDate, minEndDate based on date selection
  useEffect(() => {
    setMinEndDate(startDate);
  }, [startDate, endDate]);


  const handleExportMenuOpen = () => {
    setExportMenuOpen(!exportMenuOpen);
  };

  const handleExportSelection = (id) => {
    setExportMenuOpen(false);
  };

  const exportOptions = [
    { id: "exportToPdf", label: "Export To Pdf" },
    { id: "exportToExcel", label: "Export To Excel" },
  ];


  return (
    <div className="tds-sn-product-details-historical-price-table-container">
      <h3>
        <TranslateComponent label={PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_HISTORICAL_PRICE_TABLE_LABEL} />
      </h3>
      {showExport &&
        <div className="export-dropdown">
          <div className="export-icon" onClick={handleExportMenuOpen} tabIndex="0">
            <img src="/assets/img/icn_excel_file.svg" title="Export" alt="Export" />
            <span>
              <TranslateComponent label={PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_HISTORICAL_PRICE_TABLE_EXPORT_LABEL} />
            </span>
          </div>
          <div className={`export-dropdown-content ${exportMenuOpen ? "open" : null}`}>
            {exportOptions.map((option) => (
              <span key={option.id} onClick={() => handleExportSelection(option.id)}>
                {option.label}
              </span>
            ))}
          </div>
        </div>
      }

      <div className="date-range">
        <label htmlFor="dateRangeFilterStartDate" className="date-input-filter-label">
          <TranslateComponent label={EVENT_PAGE_DATE_FILTER_FROM_LABEL} />
        </label>
        <DateInputComponent
          id="dateRangeFilterStartDate"
          selected={startDate}
          onChange={setStartDate}
          className="date-input-filter"
          maxDate={endDate}
        />
        <label htmlFor="dateRangeFilterEndDate" className="date-input-filter-label">
          <TranslateComponent label={EVENT_PAGE_DATE_FILTER_TO_LABEL} />
        </label>
        <span className="date-separator" />
        <DateInputComponent
          id="dateRangeFilterEndDate"
          selected={endDate}
          onChange={setEndDate}
          className="date-input-filter end-date-mobile"
          minDate={minEndDate}
        />
      </div>

      <TableComponent
        className="historical-price-table"
        columns={PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_HISTORICAL_PRICE_TABLE_COLUMNS}
        data={filteredData}
      />
      <PaginatorComponent
        className="historical-price-table-paginator"
        currentPage={currentPage}
        resultsPerPage={PRODUCT_DETAILS_HISTORICAL_PRICE_TABLE_PAGE_SIZE}
        total={dataForDate?.length}
        goToPage={setCurrentPage}
        key={`${currentPage} - ${dataForDate?.length}`}
      />
    </div>
  );
};

export default ProductDetailsHistoricalPriceTable;
