import React, {useEffect, useState} from "react";
import TranslateComponent from "../misc/translate/Translate.component";
import {PAGE_TITLE_LABEL_KEY} from "../../constants/Priip.constants";
import PriipDisclaimer from "./priip-disclaimer/PriipDisclaimer.component";
import PriipTable from "./priip-table/PriipTable.component";

const Priip = () => {
  const [renderTable, setRenderTable] = useState(false);
  const handleSubmit = () => {
    setRenderTable((prevState) => !prevState);
  };
  useEffect(() => { document.title = "PRIIPs KID Service" }, []);
  return (
    <section className="tds-previous-offering">
      <div className="tds-container">
        <div className="tds-previous-offerings-title">
          <h1>
            <TranslateComponent label={PAGE_TITLE_LABEL_KEY} />
          </h1>
        </div>
        {!renderTable ? (
          <>
            <PriipDisclaimer setRender={handleSubmit} />
          </>
        ) : (
          <>
            <PriipTable id={"table-priip"} />
          </>
        )}
      </div>
    </section>
  );
};

export default React.memo(Priip);
