export var ENV_LOCAL_URL = 'localhost';
export var ENV_DEV_URL = 'gsbm-web.appgw2.eastus2.dev.azure.td.com';
export var ENV_PAT_URL = 'pat.gsbm.tdbank.com';
export var ENV_PROD_URL = 'portal.tdsecurities.com';

export var ENV_LOCAL_LABEL = 'LOCAL';
export var ENV_DEV_LABEL = 'DEV';
export var ENV_PAT_LABEL = 'PAT';
export var ENV_PROD_LABEL = 'PROD';

