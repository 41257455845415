import React, { useState, useEffect } from "react";
import {
  PRODUCT_DETAILS_NOTE_EVENTS_BARRIER_DETAILS_LABEL,
  PRODUCT_DETAILS_NOTE_EVENTS_ROC_DETAILS_LABEL,
  PRODUCT_DETAILS_NOTE_EVENTS_COUPON_DETAILS_LABEL,
  PRODUCT_DETAILS_NOTE_EVENTS_MATURITY_REDEMPTION_PAYMENT_DETAILS_LABEL,
  PRODUCT_DETAILS_NOTE_EVENTS_BARRIER_DETAILS_COLUMNS,
  PRODUCT_DETAILS_NOTE_EVENTS_COUPON_DETAILS_COLUMNS,
  PRODUCT_DETAILS_NOTE_EVENTS_ROC_DETAILS_COLUMNS,
  PRODUCT_DETAILS_NOTE_EVENTS_MATURITY_REDEMPTION_PAYMENT_DETAILS_COLUMNS,
  PRODUCT_DETAILS_NOTE_EVENTS_MATURITY_AVERAGING_DATE_DETAILS_LABEL,
} from "../../../constants/ProductDetails.constants";
import LanguageService from "../../../services/Language.service";
import CollapseComponent from "../../misc/collapse/Collapse.component";
import TableComponent from "../../misc/table/Table.component";
import DateService from "../../../services/Date.service";
import Note from "../../../utils/Note";
import {
  NOTE_FIELD_KEYS, NOTE_EVENT_FIELDS, EVENT_TYPE, PERFORMANCE_TIME_SERIES,
  PERFORMANCE_TYPE, COUPON_PAYMENT_TYPE, COUPON_TYPE, STRUCTURE_TYPE
} from "../../../constants/Notes.constants";
import { convertToCurrency, getDecimalLength, getYesOrNoValue, isEmptyArray, isNotEmptyArray, removeTrailingZerosAfterDecimal, toFixedDecimalLength, updateArray } from "../../../utils/valueUtils";
import { LANGUAGE_ENGLISH } from "../../../constants/Misc.constants";
import NoteService from "../../../services/Note.service";

const ProductDetailsNoteEventsComponent = (props) => {
  const noteDetails = props.data;
  const isExpandable = props.isExpandable;
  const [callDetails, setCallDetails] = useState([]);
  const [couponOrRocDetails, setCouponOrRocDetails] = useState([]);
  const [maturityRedemptionPaymentDetails, setMaturityRedemptionPaymentDetails] = useState([]);
  const [maturityAveragingDateDetails, setmMaturityAveragingDateDetails] = useState([]);
  const [isRoc, setIsRoc] = useState(false);
  const [isCallable, setIsCallable] = useState(false);
  const [maturityAveragingDateTickerColumns, setMaturityAveragingDateTickerColumns] = useState([]);
  const [callDetailDataColumns, setCallDetailDataColumns] = useState([]);
  const [couponOrRocColumnDetail, setCouponOrRocColumnDetail] = useState([]);
  const language = LanguageService.currentLanguage;
  useEffect(() => {
    setIsRoc(noteDetails?.[NOTE_FIELD_KEYS.IS_ROC] ? true : false);
    const couponPaymentype = Note.getFeatureValue(NOTE_FIELD_KEYS.COUPON_PAYMENT_TYPE, noteDetails);
    const isCoupon = noteDetails?.[NOTE_FIELD_KEYS.IS_COUPON] ? true : false;
    const getEventNoteDetails = () => {
      const getCallDetails = () => {
        setIsCallable((Note.getFeatureValue(NOTE_FIELD_KEYS.IS_CALLABLE, noteDetails)?.toLocaleLowerCase()) === 'true' ? true : false);
        const callDetail = [];
        let hasCouponValue = false;

        if (noteDetails?.autocallSchedule?.length > 0) {
          noteDetails.autocallSchedule.forEach(autoCall => {
            const eventForNoteCalled = (noteDetails?.events?.filter(event =>
              autoCall.autocallPayDate === event.paymentDate && event.businessEvent === EVENT_TYPE.AUTOCALLED));

            const eventForMaturity = noteDetails?.events?.filter(event =>
              event.businessEvent === EVENT_TYPE.MATURITY
              && autoCall.autocallPayDate === event.paymentDate);

            const couponRates = noteDetails?.couponSchedule?.filter(coupon =>
              coupon.couponPayDate === autoCall.autocallPayDate);
            if (couponRates && couponRates && couponRates[0]?.couponRate > 0) {
              hasCouponValue = true;
            }

            const underLyingIntrstReturn = noteDetails?.performanceTimeseries?.filter(perfTimeSeries =>
            (perfTimeSeries.performanceType === PERFORMANCE_TYPE.ASSET_PERFORMANCE_ADJUSTED
              && perfTimeSeries.reportDate === autoCall.autocallDeterminationDate
              && perfTimeSeries.underlyingReference === PERFORMANCE_TIME_SERIES.UNDERLYING_REFERENCE));
            callDetail.push({
              [NOTE_EVENT_FIELDS.AUTOCALL_DATE]: autoCall.autocallPayDate,
              [NOTE_EVENT_FIELDS.VALUATION_DATE]: autoCall.autocallDeterminationDate,
              [NOTE_EVENT_FIELDS.AUTOCALL_LEVEL]: autoCall.autocallLevel ? (getDecimalLength('' + autoCall.autocallLevel * 100) > 0 ? Note.converToDecimal(autoCall.autocallLevel * 100) : autoCall.autocallLevel * 100) + '%' : '',
              [NOTE_EVENT_FIELDS.FIXED_RETURN]: (isCoupon && (couponPaymentype === COUPON_PAYMENT_TYPE.AT_MATURITY) && couponRates && couponRates[0]?.couponRate !== null) ? (removeTrailingZerosAfterDecimal(couponRates[0]?.couponRate * 100, 5)) + "%" : '',
              [NOTE_EVENT_FIELDS.CLOSING_LEVEL]: getClosingLevel(autoCall.autocallDeterminationDate),
              [NOTE_EVENT_FIELDS.AUTOCALL_FEATURE]: eventForNoteCalled && eventForNoteCalled.length > 0 ? getYesOrNoValue(eventForNoteCalled[0]?.eventAmount !== 0 ? 'true' : 'false', language) : '',
              [NOTE_EVENT_FIELDS.UNDERLYING_INTEREST_RETURN]: underLyingIntrstReturn && underLyingIntrstReturn?.[0] && underLyingIntrstReturn?.[0]?.value ? (Note.converToDecimal(underLyingIntrstReturn?.[0]?.value * 100, 5) + '%') : '',
              [NOTE_EVENT_FIELDS.MATURITY]: eventForMaturity && eventForMaturity?.[0] && eventForMaturity?.[0]?.eventAmount ? convertToCurrency(eventForMaturity?.[0]?.eventAmount * 100, 7) : ''
            });
          });
        }
        setCallDetails(callDetail);
        setCallDetailDataColumns(updateCallDetailDataColumns(isCoupon, couponPaymentype, hasCouponValue));
      }

      const updateCallDetailDataColumns = (isCoupon, couponPaymentype, hasCouponValue) => {
        let dataColumns = PRODUCT_DETAILS_NOTE_EVENTS_BARRIER_DETAILS_COLUMNS;
        const structureType = noteDetails?.[NOTE_FIELD_KEYS.STRUCTURE_TYPE];
        let autoCallLevelColumn = dataColumns[2];
        let fixedReturnColumn = dataColumns[3];
        let closingLevelColumn = dataColumns[4];
        let UIReturnColumn = dataColumns[6];
        if (!isCoupon || (couponPaymentype !== COUPON_PAYMENT_TYPE.AT_MATURITY) || !hasCouponValue) {
          dataColumns = dataColumns.filter(item => item.title !== fixedReturnColumn.title);
        }
        if (structureType === STRUCTURE_TYPE.ISSUER_CALLABLE) {
          dataColumns = dataColumns.filter(item =>
            item.title !== autoCallLevelColumn.title && item.title !== closingLevelColumn.title
            && item.title !== UIReturnColumn.title);
        }
        return dataColumns;
      }
      /**
     * Method to get coupon or roc data.
     */
      const getCouponOrRocDetails = () => {
        const couponOrRocDetail = [];
        let accumulativeEventAmount = 0;
        let couponType = "";
        if (noteDetails?.couponSchedule?.length > 0) {
          const paymentThreshold = Note.getFeatureValue(NOTE_FIELD_KEYS.COUPON_BARRIER, noteDetails);
          couponType = Note.getFeatureValue(NOTE_FIELD_KEYS.COUPON_TYPE, noteDetails);
          noteDetails.couponSchedule.forEach(coupon => {
            const event = noteDetails?.events?.filter(event => (coupon.couponPayDate === event.paymentDate
              && event.businessEvent === EVENT_TYPE.COUPON));
            const eventAmount = event && event?.[0] && event?.[0]?.eventAmount !== 0 ? removeTrailingZerosAfterDecimal(event?.[0]?.eventAmount * 100, 7) : '';

            const underLyingIntrstReturn = noteDetails?.performanceTimeseries?.filter(perfTimeSeries =>
            (perfTimeSeries.performanceType === PERFORMANCE_TYPE.ASSET_PERFORMANCE_ADJUSTED
              && perfTimeSeries.reportDate === coupon.couponDeterminationDate
              && perfTimeSeries.underlyingReference === PERFORMANCE_TIME_SERIES.UNDERLYING_REFERENCE));

            if (isCoupon && couponPaymentype === COUPON_PAYMENT_TYPE.PERIODIC) {
              if (isRoc) {
                accumulativeEventAmount = accumulativeEventAmount += (event && event?.[0] && event?.[0]?.eventAmount ? event?.[0]?.eventAmount : 0);
                couponOrRocDetail.push({
                  [NOTE_EVENT_FIELDS.COUPON_PAY_DATE]: coupon.couponPayDate,
                  [NOTE_EVENT_FIELDS.VALUATION_DATE]: coupon.couponDeterminationDate,
                  [NOTE_EVENT_FIELDS.PAY_THRESHOLD]: paymentThreshold ? (getDecimalLength('' + paymentThreshold * 100) > 0 ? Note.converToDecimal(paymentThreshold * 100) : paymentThreshold * 100) + '%' : '',
                  [NOTE_EVENT_FIELDS.CLOSING_LEVEL]: getClosingLevel(coupon.couponDeterminationDate),
                  [NOTE_EVENT_FIELDS.UNDERLYING_INTEREST_RETURN]: underLyingIntrstReturn && underLyingIntrstReturn?.[0] && underLyingIntrstReturn?.[0]?.value ? (Note.converToDecimal(underLyingIntrstReturn?.[0]?.value * 100, 5) + '%') : '',
                  [NOTE_EVENT_FIELDS.PAYMENT]: event?.[0]?.eventAmount > 0 ? getYesOrNoValue('true', language) : event?.[0]?.eventAmount === 0 ? getYesOrNoValue('false', language) : ' ',
                  [NOTE_EVENT_FIELDS.ROC]: eventAmount ? (getDecimalLength(eventAmount) === 0 ? convertToCurrency(parseFloat(eventAmount), 2) : convertToCurrency(parseFloat(eventAmount), 7)) : '',
                  [NOTE_EVENT_FIELDS.ADJUST_COST_BASE]: event?.[0]?.businessEvent ? convertToCurrency(100 - (accumulativeEventAmount * 100), 7) : ''
                });
              } else {
                couponOrRocDetail.push({
                  [NOTE_EVENT_FIELDS.COUPON_PAY_DATE]: coupon.couponPayDate,
                  [NOTE_EVENT_FIELDS.VALUATION_DATE]: coupon.couponDeterminationDate,
                  [NOTE_EVENT_FIELDS.PAY_THRESHOLD]: paymentThreshold ? (getDecimalLength('' + paymentThreshold * 100) > 0 ? Note.converToDecimal(paymentThreshold * 100) : paymentThreshold * 100) + '%' : '',
                  [NOTE_EVENT_FIELDS.CLOSING_LEVEL]: getClosingLevel(coupon.couponDeterminationDate),
                  [NOTE_EVENT_FIELDS.COUPON_PAID]: event?.[0]?.eventAmount > 0 ? getYesOrNoValue('true', language) : event?.[0]?.eventAmount === 0 ? getYesOrNoValue('false', language) : ' ',
                  [NOTE_EVENT_FIELDS.UNDERLYING_INTEREST_RETURN]: underLyingIntrstReturn && underLyingIntrstReturn?.[0] && underLyingIntrstReturn?.[0]?.value ? (Note.converToDecimal(underLyingIntrstReturn?.[0]?.value * 100, 5) + '%') : '',
                  [NOTE_EVENT_FIELDS.COUPON]: eventAmount ? (getDecimalLength(eventAmount) === 0 ? convertToCurrency(parseFloat(eventAmount), 2) : convertToCurrency(parseFloat(eventAmount), 7)) : '',
                });
              }
            }
          });
        }
        setCouponOrRocDetails(couponOrRocDetail);
        setCouponOrRocColumnDetail(getCouponOrRocColumnDetail(couponType));
      }

      /**
     * Method to get maturity redemption payment detail.
     */
      const getRedemptionPaymentDetails = () => {
        const maturityRedemptionPaymentDetails = [];
        const maturityDate = noteDetails?.[NOTE_FIELD_KEYS.MATURITY_DATE];
        const finalValDate = noteDetails?.[NOTE_FIELD_KEYS.FINAL_VALUATION_DATE];
        let event = null;
        const eventAutocallable = noteDetails?.events?.find(event =>
          event.businessEvent === EVENT_TYPE.AUTOCALLED && event.eventAmount === 1);


        if (eventAutocallable && noteDetails?.autocallSchedule?.length > 0) {
          noteDetails.autocallSchedule.forEach(autoCall => {
            event = (noteDetails?.events?.filter(event =>
              autoCall.autocallPayDate === event.paymentDate && event.businessEvent === EVENT_TYPE.MATURITY));
          });
        } else if (!eventAutocallable) {
          event = noteDetails?.events?.filter(event => (maturityDate === event.paymentDate
            && event.businessEvent === EVENT_TYPE.MATURITY));
        }

        const underLyingIntrstReturn = noteDetails?.performanceTimeseries?.filter(perfTimeSeries =>
        (perfTimeSeries.performanceType === PERFORMANCE_TYPE.ASSET_PERFORMANCE_ADJUSTED
          && perfTimeSeries.reportDate === finalValDate
          && perfTimeSeries.underlyingReference === PERFORMANCE_TIME_SERIES.UNDERLYING_REFERENCE));

        const openingLevel = NoteService.getOpeningLevel(noteDetails);

        maturityRedemptionPaymentDetails.push({
          [NOTE_EVENT_FIELDS.MATURITY_DATE]: maturityDate,
          [NOTE_EVENT_FIELDS.FINAL_VALIDATE_DATE]: finalValDate,
          [NOTE_EVENT_FIELDS.OPENING_LEVEL]: openingLevel,
          [NOTE_EVENT_FIELDS.CLOSING_LEVEL]: getClosingLevel(finalValDate),
          [NOTE_EVENT_FIELDS.UNDERLYING_INTEREST_RETURN]: underLyingIntrstReturn && underLyingIntrstReturn?.[0] && underLyingIntrstReturn?.[0]?.value ? (Note.converToDecimal(underLyingIntrstReturn?.[0]?.value * 100, 5) + '%') : '',
          [NOTE_EVENT_FIELDS.MATURITY_REDEMPTION_PAYMENT]: event && event?.[0] && event?.[0]?.eventAmount ? convertToCurrency(event[0].eventAmount * 100, 7) : '',
        });
        setMaturityRedemptionPaymentDetails(maturityRedemptionPaymentDetails);
      }

      /**
     * Function to get maturity averaging level detail.
     */
      const getAveragingLevel = () => {
        let maturityAveragingDateDetails = [];
        let index = 0;
        if (noteDetails?.maturityAveragingDates?.length > 0) {
          noteDetails.maturityAveragingDates.forEach(maturityAveragingDate => {
            const perfTimeSeries = noteDetails?.performanceTimeseries?.filter(perfTimeSeries =>
            (perfTimeSeries.performanceType === PERFORMANCE_TYPE.CURRENT_LEVEL &&
              perfTimeSeries.reportDate === maturityAveragingDate.averagingDate));

            const basketLevel = noteDetails?.performanceTimeseries?.filter(perfTimeSeries =>
            (perfTimeSeries.performanceType === PERFORMANCE_TYPE.ASSET_PERFORMANCE &&
              perfTimeSeries.reportDate === maturityAveragingDate.averagingDate &&
              perfTimeSeries.underlyingReference === PERFORMANCE_TIME_SERIES.UNDERLYING_REFERENCE));

            maturityAveragingDateDetails.push({
              'dates': DateService.formatLocalizedDate(maturityAveragingDate.averagingDate),
              'basketLevel': basketLevel ? basketLevel?.[0]?.value ? Note.converToDecimal((basketLevel?.[0]?.value + 1) * 100, 2) : '' : '',
            });
            if (isNotEmptyArray(perfTimeSeries)) {
              perfTimeSeries.filter(perfTime =>
                updateArray(maturityAveragingDateDetails,
                  (perfTime.underlyingReference ? perfTime.underlyingReference : ''), perfTime?.value ? toFixedDecimalLength(perfTime.value, 2) : '', index)
              )
            }
            if (isEmptyArray(perfTimeSeries)) {
              noteDetails.underlyingInterests?.forEach(underlyingInterest => {
                perfTimeSeries.filter(perfTime =>
                  underlyingInterest.underlyingReference === perfTime.underlyingReference ?
                    updateArray(maturityAveragingDateDetails,
                      (underlyingInterest.underlyingReference ? underlyingInterest.underlyingReference : ''), perfTime?.value ? toFixedDecimalLength(perfTime.value, 2) : '', index) : ''
                )
              });
            }
            index++;
          });
          setmMaturityAveragingDateDetails(maturityAveragingDateDetails);
        }
        //Creating dynamic Ticker column
        setMaturityAveragingDateTickerColumns(createAveragingLevelColumns());
      }
      /**
       * Function to create dynamic columns.
       * @returns 
       */
      const createAveragingLevelColumns = () => {
        const averagingLevelColumns = [];
        averagingLevelColumns.push({
          title: "Dates",
          field: "dates",
          className: "dates",
          translate: true,
          isDate: true
        });
        if (noteDetails.underlyingInterests) {
          noteDetails.underlyingInterests.forEach(underlyingInterest => {
            averagingLevelColumns.push({
              title: underlyingInterest.underlyingReference,
              field: underlyingInterest.underlyingReference,
              className: "ticker",
              translate: true
            });
          });
        }
        averagingLevelColumns.push({
          title:
            LanguageService.isLanguage(LANGUAGE_ENGLISH) ? "Basket Level" : "Niveau du panier",
          field: "basketLevel",
          className: "basketLevel",
          translate: true
        });
        return averagingLevelColumns;
      }

      const getCouponOrRocColumnDetail = (couponType) => {
        let columns = isRoc ? PRODUCT_DETAILS_NOTE_EVENTS_ROC_DETAILS_COLUMNS : PRODUCT_DETAILS_NOTE_EVENTS_COUPON_DETAILS_COLUMNS;
        let selectedColumn = [];
        if (couponType === COUPON_TYPE.FIXED) {
          selectedColumn = columns.filter(column =>
          (column.field === NOTE_EVENT_FIELDS.COUPON_PAY_DATE || column.field === NOTE_EVENT_FIELDS.COUPON
            || column.field === NOTE_EVENT_FIELDS.ROC || column.field === NOTE_EVENT_FIELDS.ADJUST_COST_BASE)
          );
        } else {
          selectedColumn = columns;
        }
        return selectedColumn;
      }

      const getClosingLevel = (date) => {
        let closingLevel = [];
        if (noteDetails.assetPerformanceType === PERFORMANCE_TYPE.SINGLE_ASSET) {
          closingLevel = noteDetails?.performanceTimeseries?.filter(perfTimeSeries =>
          (perfTimeSeries.performanceType === PERFORMANCE_TYPE.CURRENT_LEVEL
            && perfTimeSeries.reportDate === date));
          return (closingLevel && closingLevel?.[0]
            && closingLevel?.[0]?.value ? Note.converToDecimal((closingLevel?.[0]?.value), 3) : '');
        } else {
          closingLevel = noteDetails?.performanceTimeseries?.filter(perfTimeSeries =>
          (perfTimeSeries.performanceType === PERFORMANCE_TYPE.ASSET_LEVEL
            && perfTimeSeries.reportDate === date));
          return (closingLevel && closingLevel?.[0]
            && closingLevel?.[0]?.value ? Note.converToDecimal((closingLevel?.[0]?.value * 100), 3) : '');
        }
      }
      getCallDetails();
      getCouponOrRocDetails();
      getRedemptionPaymentDetails();
      getAveragingLevel();
    }
    getEventNoteDetails();
  }, [isCallable, isRoc, noteDetails, language]);
  return (
    <div className="tds-sn-product-details-note-events-container">
      {isCallable && callDetails.length > 0 &&
        <CollapseComponent id="barrier-details" title={LanguageService.translate(PRODUCT_DETAILS_NOTE_EVENTS_BARRIER_DETAILS_LABEL)} className="barrier-details" isExpandable={isExpandable}>
          <TableComponent
            className="barrier-details-table"
            columns={callDetailDataColumns}
            data={callDetails}
          />
        </CollapseComponent>
      }
      {couponOrRocDetails.length > 0 &&
        <CollapseComponent id={isRoc ? "roc-details" : "coupon-details"} title={LanguageService.translate(isRoc ? PRODUCT_DETAILS_NOTE_EVENTS_ROC_DETAILS_LABEL
          : PRODUCT_DETAILS_NOTE_EVENTS_COUPON_DETAILS_LABEL)} className={isRoc ? "roc-details" : "coupon-details"} isExpandable={isExpandable}>
          <TableComponent
            className={isRoc ? "roc-details-table" : "coupon-details-table"}
            columns={couponOrRocColumnDetail}
            data={couponOrRocDetails}
          />
        </CollapseComponent>
      }
      {maturityRedemptionPaymentDetails.length > 0 &&
        <CollapseComponent id="maturity-redemption-payment-details" title={LanguageService.translate(
          PRODUCT_DETAILS_NOTE_EVENTS_MATURITY_REDEMPTION_PAYMENT_DETAILS_LABEL)} className="maturity-redemption-payment-details" isExpandable={isExpandable}>
          <TableComponent
            className="maturity-redemption-payment-details-table"
            columns={PRODUCT_DETAILS_NOTE_EVENTS_MATURITY_REDEMPTION_PAYMENT_DETAILS_COLUMNS}
            data={maturityRedemptionPaymentDetails}
          />
        </CollapseComponent>
      }
      {maturityAveragingDateDetails.length > 0 &&
        <CollapseComponent id="maturity-redemption-payment-details" title={LanguageService.translate(
          PRODUCT_DETAILS_NOTE_EVENTS_MATURITY_AVERAGING_DATE_DETAILS_LABEL)} className="maturity-redemption-payment-details" isExpandable={isExpandable}>
          <TableComponent
            className="averaging-level-details-table"
            columns={maturityAveragingDateTickerColumns}
            data={maturityAveragingDateDetails}
          />
        </CollapseComponent>
      }
    </div>
  )
};

export default ProductDetailsNoteEventsComponent;