import React from 'react';

export default class StatelessModalComponent extends React.Component {    
    render() {
        return (
            <div id={this.props.id}
                className={this.props.active ? "tds-modal active" : "tds-modal"}>
                <div className={'tds-modal-content ' + this.props.className}>
                    <span 
                        className="tds-modal-close td-icon td-icon-close td-interactive-icon"
                        onClick={this.props.handleHideModal}
                    ></span>
                    {this.props.children}
                </div>
            </div>
        )
    }
}
