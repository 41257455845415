import {ASCENDING_SORT} from "../constants/Priip.constants";
import HttpClient from "../utils/HttpClient";

export default class PriipService {
  static getPriipData(page, options) {
    if (options && options.search && !options.sort) {
      return HttpClient.get(
        `/api/v1/priip/search?isin=${options.searchQuery}&page=${page}&size=10`
      );
    } else if (options && options.sort && !options.search) {
      const sortDirection = options.direction === ASCENDING_SORT ? true : false;
      return HttpClient.get(
        `/api/v1/priip/search?isin=${options.searchQuery}&page=${page}&size=10&orderBy=${options.key}&ascending=${sortDirection}`
      );
    } else if (options && options.sort && options.search) {
      const sortDirection = options.direction === ASCENDING_SORT ? true : false;
      return HttpClient.get(
        `/api/v1/priip/search?isin=${options.searchQuery}&page=${page}&size=10&orderBy=${options.key}&ascending=${sortDirection}`
      );
    } else {
      return HttpClient.get(`/api/v1/priip/search?page=${page}&size=10`);
    }
  }

  static getPriipSearchData(query, page) {
    return HttpClient.get(
      `/api/v1/priip/search?isin=${query}&page=${page}&size=10`
    );
  }

  static priipSort(key, direction, page) {
    const sortDirection = direction === ASCENDING_SORT ? true : false;
    return HttpClient.get(
      `/api/v1/priip/search?page=${page}&size=10&orderBy=${key}&ascending=${sortDirection}`
    );
  }
}

export const priipDownload = async (id, name) => {
  try {
    const response = await fetch(`/api/v1/priip/download/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    });
    if (!response.ok) {
      throw new Error("Failed");
    }
    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${name}.pdf`);
    document.body.appendChild(link);
    link.click();

    link.parentNode.removeChild(link);
  } catch {
    console.error("download failed");
  }
};

export const handleCopyPriip = async (id) => {
  try {
    const url = `${window.location.origin}/api/v1/priip/download/${id}`;
    await navigator.clipboard.writeText(url);
  } catch {
    console.error("copy failed");
  }
};
