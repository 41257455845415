import React, {useEffect, useState} from 'react';
import {
    IRN_NOTE_FEATURES,
    IRN_NOTE_TYPE,
    NOTE_FIELD_KEYS,
    NOTE_FIELD_LABELS,
    NOTE_MODALS_PAYOFF_FEATURES_LABEL_KEY,
    PERFORMANCE_TYPE
} from '../../../constants/Notes.constants';

import NoteUtils from '../../../utils/Note';
import Note from '../../../utils/Note';
import {Link} from 'react-router-dom';
import {LINK_PATH_NOTE_DETAILS} from '../../../constants/LinkPaths.constants';
import TranslateComponent from '../../misc/translate/Translate.component';
import NoteService from '../../../services/Note.service';
import featureService from '../../../services/NoteFeature.service';
import {
    convertToPercentage,
    getDecimalLength,
    isEmptyArray,
    isNotEmptyArray,
    isNotEmptyStringArray,
    removeTrailingZerosAfterDecimal
} from '../../../utils/valueUtils';
import LanguageService from '../../../services/Language.service';
import {PRODUCT_TYPE} from '../../../constants/ProductDetails.constants';
import {toTDSDate} from "../../../services/Date.service";

export default function CurrentOfferingsComparisonModal(props) {

    const notes = props.notes;
    const [keyTerms, setKeyTerms] = useState([]);
    const [featureTerms, setFeatureTerms] = useState([]);
    const [autocallSchedule, setAutocallSchedule] = useState([]);
    const [underlyingInformation, setUnderlyingInformation] = useState([]);
    const [loading, setLoading] = useState(true);
    const irnNote = notes.find(note =>  note.noteType === IRN_NOTE_TYPE);
    const isIrnNote = irnNote ? true : false;

    useEffect(() => {
        const autoCallScheduleData = [];
        const fetchNoteDetails = async () => {
            let notesCode = [];
                notes.forEach(note => {
                notesCode.push(note.code);
            });
            const notesDetails = await NoteService.getNoteDetailsByCodes(notesCode);
            if(notesDetails) {
                for(const code of notesCode ) {
                    autoCallScheduleData.push({key: code,
                                value: notesDetails.autoCallSchedule.filter( autocall => autocall.code === code)});
                }
                setAutocallSchedule(autoCallScheduleData);
                if(!isIrnNote) {
                    setUnderlyingInformation(getUnderlyingInformationData(notesDetails));
                }
                setLoading(false);
            }
        } 

        const getTickerData = (tickerData, note, tickerCompaniesList) => {
            if(tickerData.code === note.code) {
                if (note.productType === PRODUCT_TYPE.indexLinked || note.productType === PRODUCT_TYPE.fundLinked) {
                    tickerCompaniesList.push(LanguageService.chooseLang(tickerData.underlyingInformation.tickerCompany, tickerData.underlyingInformation.tickerCompanyFr));
                } else if (note.productType === PRODUCT_TYPE.equityLinked || note.productType === PRODUCT_TYPE.multiAsset) {
                    tickerCompaniesList.push(LanguageService.chooseLang(tickerData.underlyingInformation.shortTicker, tickerData.underlyingInformation.shortTicker));
                } 
            }
        }

        const getTickerCompanyDataInStringFormat = (code, tickerCompaniesList, underlyingInformationData) => {
            if(tickerCompaniesList && isNotEmptyArray(tickerCompaniesList)) {
                const uniqueTicker = [...new Set(tickerCompaniesList)];
                let tickerCompanies = '';
                uniqueTicker.forEach(tickr => {tickerCompanies += tickr + ", "})
                underlyingInformationData.push({key: code, value: (tickerCompanies? tickerCompanies.substring(0, tickerCompanies.lastIndexOf(",")) : '')});
            }
        }
        const getUnderlyingInformationData = (notesDetails) => {
            const underlyingInformationData = [];
            const perfTimeSeries = notesDetails.performanceTimeSeries;
            const underlyingInterests = notesDetails.underlyingInterest;
            const underlyingInformation = notesDetails.underlyingInformation;
            const notes = notesDetails.notes;
            let perfSeriesTickerDataByPerformanceType = [];

            if(perfTimeSeries && isNotEmptyArray(perfTimeSeries)) {
                NoteService.setUnderlyingInformation(perfTimeSeries, underlyingInformation);
                notes.forEach(note => {
                    let tickerCompaniesList = [];
                    perfSeriesTickerDataByPerformanceType = Note.performanceTimeseriesTickers(note.code, perfTimeSeries, PERFORMANCE_TYPE.ASSET_PERFORMANCE);
                    perfSeriesTickerDataByPerformanceType.forEach(tickerData => {
                       getTickerData(tickerData, note, tickerCompaniesList);
                    }); 
                    getTickerCompanyDataInStringFormat(note.code, tickerCompaniesList, underlyingInformationData);
                });
            } 
            if (isEmptyArray(perfSeriesTickerDataByPerformanceType) && underlyingInterests && isNotEmptyArray(underlyingInterests)) {
                NoteService.setUnderlyingInformation(underlyingInterests, underlyingInformation);
                notes.forEach(note => {
                    let tickerCompaniesList = [];
                    underlyingInterests.forEach(tickerData => {
                       getTickerData(tickerData, note, tickerCompaniesList);
                    }); 
                    getTickerCompanyDataInStringFormat(note.code, tickerCompaniesList, underlyingInformationData);
                });
              }
            return underlyingInformationData ? underlyingInformationData : null;
        }       
        fetchNoteDetails();

    }, [notes, isIrnNote]);
   
    useEffect(() => {

        function checkIfKeyIsPresentInAnyNotes(key) {
            return notes.some(function (note) {
                return note[key];
            });
        }
    
        function checkIfFeatureIsPresentInAnyNotes(key) {
            return notes.some(function (note) {
                return NoteUtils.featureExists(key, note);
            });
        }
        
        const getKeyTerms = () => {
            const keyTerms = [];

            if(checkIfKeyIsPresentInAnyNotes(NOTE_FIELD_KEYS.ISSUE_DATE)) {
                const labelKey = NOTE_FIELD_LABELS.ISSUE_DATE;
                const values = notes.map((note) => {
                    const issueDate = note[NOTE_FIELD_KEYS.ISSUE_DATE];
                    if(issueDate) {
                        return toTDSDate(issueDate)
                    }
                    else {
                        return "";
                    }
                });
                keyTerms.push({
                    key: labelKey,
                    values: values
                });
            }

            if(checkIfKeyIsPresentInAnyNotes(NOTE_FIELD_KEYS.MATURITY_DATE)) {
                const labelKey = NOTE_FIELD_LABELS.MATURITY_DATE;
                const values = notes.map((note) => {
                    const maturityDate = note[NOTE_FIELD_KEYS.MATURITY_DATE];
                    if(maturityDate) {
                        return toTDSDate(maturityDate)
                    }
                    else {
                        return "";
                    }
                });
                keyTerms.push({
                    key: labelKey,
                    values: values
                });
            }

            if(checkIfKeyIsPresentInAnyNotes(NOTE_FIELD_KEYS.FUND_SERVE_CODE)) {
                const labelKey = NOTE_FIELD_LABELS.FUND_SERVE_CODE;
                const values = notes.map((note) => {
                    const fundServCode = note[NOTE_FIELD_KEYS.FUND_SERVE_CODE];
                    if(fundServCode) {
                        return fundServCode;
                    }
                    else {
                        return "";
                    }
                });
                keyTerms.push({
                    key: labelKey,
                    values: values
                });
            }

            if(checkIfKeyIsPresentInAnyNotes(NOTE_FIELD_KEYS.CUSIP)) {
                const labelKey = NOTE_FIELD_LABELS.CUSIP;
                const values = notes.map((note) => {
                    const cusip = note[NOTE_FIELD_KEYS.CUSIP];
                    if(cusip) {
                        return cusip;
                    }
                    else {
                        return "";
                    }
                });
                keyTerms.push({
                    key: labelKey,
                    values: values
                });
            }

            if(checkIfKeyIsPresentInAnyNotes(NOTE_FIELD_KEYS.CURRENCY)) {
                const labelKey = NOTE_FIELD_LABELS.CURRENCY;
                const values = notes.map((note) => {
                    const currency = note[NOTE_FIELD_KEYS.CURRENCY];
                    if(currency) {
                        return currency;
                    }
                    else {
                        return "";
                    }
                });
                keyTerms.push({
                    key: labelKey,
                    values: values
                });
            }

            if(checkIfKeyIsPresentInAnyNotes(NOTE_FIELD_KEYS.PRODUCT_CLASS)) {
                const labelKey = NOTE_FIELD_LABELS.PRODUCT_CLASS;
                const values = notes.map((note) => {
                    const productClass = note[NOTE_FIELD_KEYS.PRODUCT_CLASS];
                    if(productClass) {
                        return NoteService.getNoteProductClass(note);
                    }
                    else {
                        return "";
                    }
                });
                keyTerms.push({
                    key: labelKey,
                    values: values
                });
            }

            if(checkIfKeyIsPresentInAnyNotes(NOTE_FIELD_KEYS.ASSET_GEOGRAPHY)) {
                const labelKey = NOTE_FIELD_LABELS.ASSET_GEOGRAPHY;
                const values = notes.map((note) => {
                    const assetGeography = note[NOTE_FIELD_KEYS.ASSET_GEOGRAPHY];
                    if(assetGeography) {
                        return NoteService.getNoteAssetGeography(note);
                    }
                    else {
                        return "";
                    }
                });
                keyTerms.push({
                    key: labelKey,
                    values: values
                });
            }

            if(checkIfKeyIsPresentInAnyNotes(NOTE_FIELD_KEYS.STRUCTURE_TYPE)) {
                const labelKey = NOTE_FIELD_LABELS.STRUCTURE_TYPE;
                const values = notes.map((note) => {
                    const structureType = note[NOTE_FIELD_KEYS.STRUCTURE_TYPE];
                    if(structureType) {
                        return NoteService.getNoteSubType(note);
                    }
                    else {
                        return "";
                    }
                });
                keyTerms.push({
                    key: labelKey,
                    values: values
                });
            }
            
            if(!isIrnNote && underlyingInformation && underlyingInformation.length > 0) {
                const labelKey = NOTE_FIELD_LABELS.UNDERLIER;
                    let underLineInfovalues = [];
                    for(const note of notes) {
                        const noteValue = underlyingInformation.find(underlyingInfo => {
                            return (underlyingInfo.key === note.code);
                        })
                        if(noteValue) {
                            underLineInfovalues.push([noteValue.value]);
                        }
                    }
                    if(underLineInfovalues && underLineInfovalues.length > 0) {
                    keyTerms.push({
                        key: labelKey,
                        values: underLineInfovalues
                    });
                }
            }

            if(checkIfKeyIsPresentInAnyNotes(NOTE_FIELD_KEYS.ASSET_SECTOR)) {
                const labelKey = NOTE_FIELD_LABELS.SECTOR;
                const values = notes.map((note) => {
                    const assetSector = note[NOTE_FIELD_KEYS.ASSET_SECTOR];
                    if(assetSector) {
                        return NoteService.getAssetSector(note);
                    }
                    else {
                        return "";
                    }
                });
                keyTerms.push({
                    key: labelKey,
                    values: values
                });
            }

            if(checkIfKeyIsPresentInAnyNotes(NOTE_FIELD_KEYS.INVESTMENT_OBJECTIVE)) {
                const labelKey = NOTE_FIELD_LABELS.INVESTMENT_OBJECTIVE;
                const values = notes.map((note) => {
                    const investmentObjective = note[NOTE_FIELD_KEYS.INVESTMENT_OBJECTIVE];
                    if(investmentObjective) {
                        return NoteService.getInvestmentObjective(note);
                    }
                    else {
                        return "";
                    }
                });
                keyTerms.push({
                    key: labelKey,
                    values: values
                });
            }

            return keyTerms;
        }
        const getFeatureTerms = () => {
            const featureTerms = [];
           
                const labelKey = NOTE_FIELD_LABELS.AUTO_CALL_LEVEL;
                let valuesTmp = [];
                for(const note of notes) {
                    const noteValue = autocallSchedule.find(autocall => {
                        return (autocall.key === note.code);
                    })
                    if(noteValue) {
                        valuesTmp.push([Note.getAutoCallThreshold(noteValue?.value)]);
                    }
                }
                if(valuesTmp && valuesTmp.length > 0) {
                featureTerms.push({
                    key: labelKey,
                    values: valuesTmp
                });
            }
           
            if(checkIfFeatureIsPresentInAnyNotes(NOTE_FIELD_KEYS.BARRIER_LEVEL)) {                
                             
                const barrierLevels = notes.map((note) => featureService.getBarrierLevelField(note, NOTE_FIELD_LABELS.BARRIER_LEVEL));

                if (isNotEmptyArray(barrierLevels)) { 
                    const barrierLevelValues =  barrierLevels.map(level => {
                        if(level) return level.value;
                        return '';
                    })        
                    if( isNotEmptyStringArray(barrierLevelValues) ) {
                        featureTerms.push({
                            key: NOTE_FIELD_LABELS.BARRIER_LEVEL,
                            values: barrierLevelValues
                        });
                    }            
                }
                const bufferLevels = notes.map((note) => featureService.getBufferLevelField(note, NOTE_FIELD_LABELS.BUFFER_LEVEL));
                if (isNotEmptyArray(bufferLevels)) { 
                    const barrierLevelValues =  bufferLevels.map(level => {
                        if(level) return level.value;
                        return '';
                    })
                    if( isNotEmptyStringArray(barrierLevelValues) ) {
                        featureTerms.push({
                            key: NOTE_FIELD_LABELS.BUFFER_LEVEL,
                            values: barrierLevelValues
                        });
                    }
                }
            }

            if(checkIfFeatureIsPresentInAnyNotes(NOTE_FIELD_KEYS.DOWNSIDE_MULTIPLIER)) {
                const labelKey = NOTE_FIELD_LABELS.DOWNSIDE_MULTIPLIER;
                const values = notes.map((note) => {
                    const downsideMultiplier = NoteUtils.getFeatureValue(NOTE_FIELD_KEYS.DOWNSIDE_MULTIPLIER, note);
                    if(downsideMultiplier) {
                        return Note.convertToPercentage(downsideMultiplier);
                    }
                    else {
                        return "";
                    }
                });
                featureTerms.push({
                    key: labelKey,
                    values: values
                });
            }

            const values = notes.map((note) => {
                const fixedReturn = featureService.getFixedReturnsField(note);
                if(fixedReturn) {
                    return fixedReturn.value;
                }
                else {
                    return "";
                }
            });
            if (isNotEmptyStringArray(values)) {
                featureTerms.push({
                    key:  NOTE_FIELD_LABELS.FIXED_RETURN,
                    values: values
                });
            }

            if(checkIfFeatureIsPresentInAnyNotes(NOTE_FIELD_KEYS.BOOST_LEVEL)) {
                const labelKey = NOTE_FIELD_LABELS.BOOST_LEVEL;
                const values = notes.map((note) => {
                    const boostLevel = NoteUtils.getFeatureValue(NOTE_FIELD_KEYS.BOOST_LEVEL, note);
                    if(boostLevel) {
                        return Note.convertToPercentage(boostLevel);
                    }
                    else {
                        return "";
                    }
                });
                featureTerms.push({
                    key: labelKey,
                    values: values
                });
            }

            if(checkIfFeatureIsPresentInAnyNotes(NOTE_FIELD_KEYS.BOOSTED_RETURN)) {
                const labelKey = NOTE_FIELD_LABELS.BOOSTED_RETURN;
                const values = notes.map((note) => {
                    const boostedReturn = NoteUtils.getFeatureValue(NOTE_FIELD_KEYS.BOOSTED_RETURN, note);
                    if(boostedReturn) {
                        return Note.convertToPercentage(boostedReturn);
                    }
                    else {
                        return "";
                    }
                });
                featureTerms.push({
                    key: labelKey,
                    values: values
                });
            }

            if(checkIfFeatureIsPresentInAnyNotes(NOTE_FIELD_KEYS.COUPON_PAYMENT_FREQUENCY)) {
                const labelKey = NOTE_FIELD_LABELS.COUPON_PAYMENT_FREQUENCY;
                const values = notes.map((note) => {
                    const paymentFrequency = NoteService.getNoteCouponFrequency(note);
                    if(paymentFrequency) {
                        return paymentFrequency;
                    }
                    else {
                        return "";
                    }
                });
                featureTerms.push({
                    key: labelKey,
                    values: values
                });
            }

            if(checkIfFeatureIsPresentInAnyNotes(NOTE_FIELD_KEYS.COUPON_BARRIER)) {
                const labelKey = NOTE_FIELD_LABELS.COUPON_PAYMENT_THRESHOLD;
                const values = notes.map((note) => {
                    const couponBarrier = NoteUtils.getFeatureValue(NOTE_FIELD_KEYS.COUPON_BARRIER, note);
                    if(couponBarrier) {
                        return Note.convertToPercentage(couponBarrier);
                    }
                    else {
                        return "";
                    }
                });
                featureTerms.push({
                    key: labelKey,
                    values: values
                });
            }

            if(checkIfFeatureIsPresentInAnyNotes(NOTE_FIELD_KEYS.PARTICIPATION_RATE)) {
                const labelKey = NOTE_FIELD_LABELS.PARTICIPATION_RATE;
                const values = notes.map((note) => {
                    const participationRate = NoteUtils.getFeatureValue(NOTE_FIELD_KEYS.PARTICIPATION_RATE, note);
                    if(participationRate) {
                        return Note.convertToPercentage(participationRate);
                    }
                    else {
                        return "";
                    }
                });
                featureTerms.push({
                    key: labelKey,
                    values: values
                });
            }            

            if(checkIfFeatureIsPresentInAnyNotes(NOTE_FIELD_KEYS.MAX_NOTE_RETURN)) {
                const labelKey = NOTE_FIELD_LABELS.PARTICIPATION_CAP;
                const values = notes.map((note) => {
                    const participationCap = NoteUtils.getFeatureValue(NOTE_FIELD_KEYS.MAX_NOTE_RETURN, note);
                    if(participationCap) {
                        return Note.convertToPercentage(participationCap);
                    }
                    else {
                        return "";
                    }
                });
                featureTerms.push({
                    key: labelKey,
                    values: values
                });
            }

            if(checkIfFeatureIsPresentInAnyNotes(NOTE_FIELD_KEYS.COUPON_RATE)) {
                const labelKey = NOTE_FIELD_LABELS.PAYMENT_RATE;
                const values = notes.map((note) => {
                    const couponRate = NoteUtils.getFeatureValue(NOTE_FIELD_KEYS.COUPON_RATE, note);
                    if(couponRate) {
                        return Note.convertToPercentage(couponRate);
                    }
                    else {
                        return "";
                    }
                });
                featureTerms.push({
                    key: labelKey,
                    values: values
                });
            }

            let atLeastOneAnnualizedPaymentRateFound = false;
            const annualizedPaymentRateValues = notes.map((note) => {
                let paymentRateAnnualised = NoteService.getNoteCouponPaymentRateAnnualised(note);
                if (paymentRateAnnualised) {
                    atLeastOneAnnualizedPaymentRateFound = true;
                    paymentRateAnnualised = removeTrailingZerosAfterDecimal(paymentRateAnnualised, 5);
                    const paymentRateAnnualisedDecimalLength = getDecimalLength(paymentRateAnnualised);
                    return convertToPercentage(paymentRateAnnualised/100, paymentRateAnnualisedDecimalLength !== 0 ?
                        paymentRateAnnualisedDecimalLength <3 ? 3 :  paymentRateAnnualisedDecimalLength >3 ? 5 : paymentRateAnnualisedDecimalLength : 3);
                }
                else {
                    return "";
                }
            });

            if (atLeastOneAnnualizedPaymentRateFound) {
                featureTerms.push({
                    key: NOTE_FIELD_LABELS.ANNUALIZED_PAYMENT_RATE,
                    values: annualizedPaymentRateValues
                });
            }

            if(isIrnNote) {
                if(checkIfFeatureIsPresentInAnyNotes(IRN_NOTE_FEATURES.COUPON_RATE)) {
                    const labelKey = NOTE_FIELD_LABELS.IRN_COUPON_RATE_LABEL;
                    const values = notes.map((note) => {
                        const irnCouponRate = NoteUtils.getFeatureValue(IRN_NOTE_FEATURES.COUPON_RATE, note);
                        if(irnCouponRate) {
                            return Note.convertToPercentage(irnCouponRate);
                        }
                        else {
                            return "";
                        }
                    });
                    featureTerms.push({
                        key: labelKey,
                        values: values
                    });
                }
                if(checkIfFeatureIsPresentInAnyNotes(IRN_NOTE_FEATURES.MIN_COUPON_RATE)) {
                    const labelKey = NOTE_FIELD_LABELS.IRN_MIN_COUPON_RATE_LABEL;
                    const values = notes.map((note) => {
                        const irnCouponRate = NoteUtils.getFeatureValue(IRN_NOTE_FEATURES.MIN_COUPON_RATE, note);
                        if(irnCouponRate) {
                            return Note.convertToPercentage(irnCouponRate);
                        }
                        else {
                            return "";
                        }
                    });
                    featureTerms.push({
                        key: labelKey,
                        values: values
                    });
                }

                if(checkIfFeatureIsPresentInAnyNotes(IRN_NOTE_FEATURES.MAX_COUPON_RATE)) {
                    const labelKey = NOTE_FIELD_LABELS.IRN_MAX_COUPON_RATE_LABEL;
                    const values = notes.map((note) => {
                        const irnCouponRate = NoteUtils.getFeatureValue(IRN_NOTE_FEATURES.MAX_COUPON_RATE, note);
                        if(irnCouponRate) {
                            return Note.convertToPercentage(irnCouponRate);
                        }
                        else {
                            return "";
                        }
                    });
                    featureTerms.push({
                        key: labelKey,
                        values: values
                    });
                }
            }

            return featureTerms;
        }        

        setKeyTerms(getKeyTerms());
        setFeatureTerms(getFeatureTerms());

    }, [notes, isIrnNote, loading, autocallSchedule, underlyingInformation]);
    return (
        <React.Fragment>
            <h3><TranslateComponent label="OFFERINGS_COMPARISON_MODAL_HEADER"/></h3>
            { !loading && 
            <div className="tds-previous-offerings-comparison-modal table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col"
                                className="name">
                                <span className="title"></span>
                            </th>

                            {notes.map((note, index) => {
                                return (
                                    <th
                                        key={'tds-current-offerings-comparision-note-name' + index}
                                        scope="col"
                                        >
                                        <span className="title">
                                            <Link to={`${LINK_PATH_NOTE_DETAILS}/${note[NOTE_FIELD_KEYS.CODE]}`} title="Note Details">
                                                {NoteService.getNoteName(note)}
                                            </Link>
                                        </span>

                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>

                        {
                            keyTerms.map((keyTerm, index) => 
                                <tr key={"keyTerms-" + index}>
                                    <td className="name">
                                        <span className="title"><TranslateComponent label={keyTerm.key}/></span>
                                    </td>
                                    {
                                        keyTerm.values.map((value, subIndex) => 
                                            <td key={"keyTermsSub-" + subIndex}>
                                                <span className="title">{value}</span>
                                            </td>
                                        )
                                    }
                                </tr>
                            )
                        }

                        {
                            featureTerms.length > 0 &&
                            <tr>
                                <td className="name">
                                    <span className="title"><TranslateComponent label={NOTE_MODALS_PAYOFF_FEATURES_LABEL_KEY}/></span>
                                </td>
                                {
                                    notes.map((note, index) => 
                                        <td key={index}>
                                            <span className="title">&nbsp;</span>
                                        </td>
                                    )
                                }
                            </tr>
                        }

                        {
                            featureTerms.map((featureTerm, index) => 
                                <tr key={"featureTerms-" + index}>
                                    <td className="name payoff-features">
                                        <span className="title"><TranslateComponent label={featureTerm.key}/></span>
                                    </td>
                                    {
                                        featureTerm.values.map((value, subIndex) => 
                                            <td key={"featureTermsSub-" + subIndex}>
                                                <span className="title">{value}</span>
                                            </td>
                                        )
                                    }
                                </tr>
                            )
                        }

                    </tbody>
                </table>
            </div>
        }
        </React.Fragment>
    )
}
