import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { COOKIE_CONSENT_COOKIE_EXPIRATION, 
  COOKIE_CONSENT_COOKIE_NAME } from "../../constants/GoogleAnalytics.constants";
import { LINK_TDSECURITIES_PRIVACY, LINK_TDSECURITIES_PRIVACY_FR } from "../../constants/LinkPaths.constants";
import LanguageService from "../../services/Language.service";
import { enableDisableGA } from "../../../src/services/GoogleAnalytics.service";
import { PREFERENCES_PAGE_TITLE } from "../../constants/PageTitle.constants";

const Preferences = () => {
  document.title = PREFERENCES_PAGE_TITLE;
  const [cookies, setCookie] = useCookies([COOKIE_CONSENT_COOKIE_NAME]);
  const [consent, setConsent] = useState(false);  

  useEffect(() => {
    const cookieConsent = cookies[COOKIE_CONSENT_COOKIE_NAME] === "true" ? true : false
    setConsent(cookieConsent);
  }, [cookies]);

  const handleConsentChange = () => {
    var expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + COOKIE_CONSENT_COOKIE_EXPIRATION);

    const newConsentValue = cookies[COOKIE_CONSENT_COOKIE_NAME] === "true" ? false : true;

    setCookie(
      COOKIE_CONSENT_COOKIE_NAME,
      `${newConsentValue}`,
      { path: "/", expires: expiryDate }
    );

    enableDisableGA();

    if (newConsentValue === false) {
      window.location.reload();
    }
  };
  
  return (
    <>
      <div className="tds-homepage-container preferences">
        <div className="tds-container">
          <div className="tds-divider-space-10">&nbsp;</div>
          <h1>{LanguageService.translate("preferences.title")}</h1>
          <p>{LanguageService.translate("preferences.description")}</p>
          <div className="tds-sn-tabs-container">
            <div className="tds-container">
              <nav>
                <ol>
                  {/* <li className="">
                    <button title="Add Choices">Ad Choices</button>
                  </li> */}
                  <li className="active">
                    <button title={LanguageService.translate("preferences.personalization")}>
                      {LanguageService.translate("preferences.personalization")}
                    </button>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="tds-divider-space-10">&nbsp;</div>
          <p>{LanguageService.translate("preferences.personalization.information")}</p>
          <div className="rte">
            <ul>
              <li>
                <strong>{LanguageService.translate("preferences.personalization.content")}</strong>
                <br />
                {LanguageService.translate("preferences.personalization.content.description")}
                <p></p>
                <div className="td-form-stacked">
                  <input
                    id="check_per"
                    name="check"
                    type="checkbox"
                    value="ens_perCheck"
                    aria-hidden="true"
                    className={consent ? "" : "checked"}
                  />
                  <label
                    onClick={() => handleConsentChange()}
                    className="label-checked"
                  >
                    <span
                      className="td-label-content-wrapper"
                      aria-checked="false"
                      role="checkbox"
                    >
                      <span className="td-label-check"></span>
                      <span className="td-label-content">
                        {LanguageService.translate("preferences.personalization.no.content")}
                      </span>
                    </span>
                  </label>
                </div>
              </li>

              <li>
                <strong>
                  {LanguageService.translate("preferences.personalization.esential.title")}
                </strong>
                <br />
                {LanguageService.translate("preferences.personalization.esential.description")}
                <p></p>
                {LanguageService.translate("preferences.personalization.esential.description.2")}
              </li>
            </ul>

            <div className="td-row">
              <div className="td-col-xs-12">
                <hr className="td-thick-divider-line" />
              </div>
            </div>

            <p>
              {LanguageService.translate("preferences.personalization.learn")}{" "}
              <a href={LanguageService.chooseLang(LINK_TDSECURITIES_PRIVACY, LINK_TDSECURITIES_PRIVACY_FR)}>
                {LanguageService.translate("preferences.personalization.privacy")}
              </a>
              .<br />
            </p>

            <div className="tds-divider-space-10">&nbsp;</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Preferences;