import React from 'react';
import { CURRENT_OFFERINGS_PAGE_TITLE } from '../../constants/PageTitle.constants';
import {
    PAR_NOTE_TYPE_LABEL_KEY,
    PAR_NOTE_TYPE_DESC_LABEL_KEY,
    PPN_NOTE_TYPE_LABEL_KEY,
    PPN_NOTE_TYPE_DESC_LABEL_KEY,
    IRN_NOTE_TYPE_LABEL_KEY,
    IRN_NOTE_TYPE_DESC_LABEL_KEY,
    FEE_NOTE_TYPE_LABEL_KEY,
    NON_FEE_NOTE_TYPE_TAB_VALUE,
    FEE_NOTE_TYPE_TAB_VALUE,
    NON_FEE_NOTE_TYPE_TAB_NAME,
    FEE_NOTE_TYPE_TAB_NAME,
    NOTE_SEARCH_FIELDS,
    NOTE_FIELD_KEYS,
    NON_FEE_NOTE_TYPE_LABEL_KEY
} from '../../constants/Notes.constants';
import { PAGE_TITLE_LABEL_KEY } from '../../constants/CurrentOfferings.constants';

import CurrentOfferingTabsComponent from "./current-offerings-tabs/CurrentOfferingsTabs.component";
import CurrentOfferingsSearchResultsComponent from './current-offerings-search-results/CurrentOfferingsSearchResults.component';
import CurrentOfferingsFeaturesModal from './current-offerings-features-modal/CurrentOfferingsFeaturesModal.component';
import CurrentOfferingsComparisonModal from './current-offerings-comparison-modal/CurrentOfferingsComparisonModal.component';
import Spinner from '../spinner/Spinner.component';
import NoteService from '../../services/Note.service';
import OfferingsSearchComponent from '../search/OfferingsSearch.component';
import { ROUTE_PATH_CURRENT_OFFERINGS } from '../../constants/Routes.constants';
import StatelessModalComponent from '../misc/modal/StatelessModal.component';
import TranslateComponent from '../misc/translate/Translate.component';

const currentOfferingTabs = [
    {
        value: FEE_NOTE_TYPE_TAB_VALUE,
        name: FEE_NOTE_TYPE_TAB_NAME,
        label: FEE_NOTE_TYPE_LABEL_KEY
    },
    {
        value: NON_FEE_NOTE_TYPE_TAB_VALUE,
        name: NON_FEE_NOTE_TYPE_TAB_NAME,
        label: NON_FEE_NOTE_TYPE_LABEL_KEY
    }
];

const defaultOfferings = {
    feeBasedNotes: {
        ppnNotes: [],
        parNotes: [],
        irnNotes: []
    },
    noFeeBasedNotes: {
        ppnNotes: [],
        parNotes: [],
        irnNotes: []
    }
};

const defaultSelectedNotes = [[],[],[],[],[],[]];

let defaultNotesSectionExpandedStatus = [true, false, false];

const defaultSortColumns = {
    [NOTE_FIELD_KEYS.NAME]: true,
    [NOTE_FIELD_KEYS.FUND_SERVE_CODE]: true,
    [NOTE_FIELD_KEYS.CUSIP]: true,
    [NOTE_FIELD_KEYS.AVAILABLE_UNTIL_DATE]: true,
    [NOTE_FIELD_KEYS.MATURITY_DATE]: true,
    [NOTE_FIELD_KEYS.TERM]: true,
    [NOTE_FIELD_KEYS.CURRENT_STATUS]: true
};

const defaultSortOrders = [ {...defaultSortColumns}, {...defaultSortColumns}, {...defaultSortColumns}, 
    {...defaultSortColumns}, {...defaultSortColumns}, {...defaultSortColumns} ];

const defaultSearchFields = {
    [NOTE_SEARCH_FIELDS.KEYWORD]: "",
    [NOTE_SEARCH_FIELDS.PRODUCT_TYPE]: null,
    [NOTE_SEARCH_FIELDS.PRODUCT_CLASS]: null,
    [NOTE_SEARCH_FIELDS.UNDERLYING_ASSET_TYPE]: null,
    [NOTE_SEARCH_FIELDS.STRUCTURE_TYPE]: null,
    [NOTE_SEARCH_FIELDS.BARRIER_BUFFER_LEVEL]: null,
    [NOTE_SEARCH_FIELDS.PAYMENT_FREQUENCY]: null,
    [NOTE_SEARCH_FIELDS.INVESTMENT_OBJECTIVE]: null,
    [NOTE_SEARCH_FIELDS.SECTOR]: null,
    [NOTE_SEARCH_FIELDS.CURRENCY]: null,
    [NOTE_SEARCH_FIELDS.GEOGRAPHY]: null,
    [NOTE_SEARCH_FIELDS.CURRENT_STATUS]: null,
    [NOTE_SEARCH_FIELDS.ISSUE_DATE_FROM]: null,
    [NOTE_SEARCH_FIELDS.ISSUE_DATE_TO]: null,
    [NOTE_SEARCH_FIELDS.MATURITY_DATE_FROM]: null,
    [NOTE_SEARCH_FIELDS.MATURITY_DATE_TO]: null,
    [NOTE_SEARCH_FIELDS.TERM_YEARS_FROM]: null,
    [NOTE_SEARCH_FIELDS.TERM_YEARS_TO]: null
};

const NON_FEE_BASED_NOTE_TYPE = 'F-Class';

export default class CurrentOfferingComponent extends React.Component {
    constructor(props) {
        super(props);
        this.handleTabClick = this.handleTabClick.bind(this);
        this.handleFeaturesColumnClick = this.handleFeaturesColumnClick.bind(this);
        this.handleCompareColumnClick = this.handleCompareColumnClick.bind(this);
        this.handleCompareCheckboxClick = this.handleCompareCheckboxClick.bind(this);
        this.handleBasicSearchClick = this.handleBasicSearchClick.bind(this);
        this.handleAdvancedSearchClick = this.handleAdvancedSearchClick.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleSearchFieldChange = this.handleSearchFieldChange.bind(this);
        this.setHistoryState = this.setHistoryState.bind(this);
        this.setStateAndHistory = this.setStateAndHistory.bind(this);
        this.handleAdvancedSearchExpandedClick = this.handleAdvancedSearchExpandedClick.bind(this);
        this.handleResetButtonClick = this.handleResetButtonClick.bind(this);
        this.handleUnload = this.handleUnload.bind(this);
        this.handleHideFeatureModal = this.handleHideFeatureModal.bind(this);
        this.handleNotesSectionExpandClick = this.handleNotesSectionExpandClick.bind(this);
        this.comparisonCheckBoxesDisabled = this.comparisonCheckBoxesDisabled.bind(this);
        this.showCoparisonModal = this.showCoparisonModal.bind(this);
        this.handleHideComparisonModal = this.handleHideComparisonModal.bind(this);
        this.handleSort = this.handleSort.bind(this);
        
        const state = { ...window.history.state };
        this.getSatusByNoteType(state.state);  //set default Notetype for expand and collapse.
        if (!state.searchFields) {
            state.searchFields = defaultSearchFields;
            state.activeTabName = NON_FEE_NOTE_TYPE_TAB_NAME;
            state.allOfferings = defaultOfferings;
            state.currentlySelectedNote = null;
            state.notesToCompare = [];
            state.advancedSearchExpanded = false;
            state.showNotesFeaturesModal = false;
            state.showNotesComparisonModal = false;
            state.notesSectionExpandedStatus = defaultNotesSectionExpandedStatus;
            state.selectedNotes = defaultSelectedNotes;
            state.sortOrders = defaultSortOrders;
        }        

        this.state = {
            ...state
        };

        console.log("window history", window.history);
    }

    handleUnload() {
        this.setHistoryState(null, true);
    }

    getSatusByNoteType( noteType ) {
       switch (noteType) {
        case "principalNote":
        defaultNotesSectionExpandedStatus = [true, false, false];
            break;
        case "protectedNote":
            defaultNotesSectionExpandedStatus = [false, true, false];
        break;
        case "interestRateNote":
            defaultNotesSectionExpandedStatus = [false, false, true];
        break;
        default:
            defaultNotesSectionExpandedStatus = [true, false, false];
        break;
       }
    }

    setStateAndHistory(state, callback) {
        const historyState = {
            ...this.state,
            ...state            
        }
        console.log("new history state", historyState);
        this.setHistoryState(historyState, true);
        this.setState(state, callback);
    }

    componentDidMount() {
        if(!window.history.state?.allOfferings) {
            this.getCurrentOfferings({ 
                [NOTE_SEARCH_FIELDS.CURRENT_OFFERINGS]: true
            });
        }
        document.title = CURRENT_OFFERINGS_PAGE_TITLE;
        window.addEventListener('beforeunload', this.handleUnload);
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleUnload);
    }

    handleTabClick(event) {
        event.persist();
        const tabType = event.target.value;

        switch (tabType) {
            case currentOfferingTabs[0].value:
                this.setStateAndHistory({
                    activeTabName: FEE_NOTE_TYPE_TAB_NAME,
                });
                break;

            case currentOfferingTabs[1].value:
                this.setStateAndHistory({
                    activeTabName: NON_FEE_NOTE_TYPE_TAB_NAME,
                });
                break;

            default:
                this.setStateAndHistory({
                    activeTabName: FEE_NOTE_TYPE_TAB_NAME,
                });
                break;
        }
    }

    handleBasicSearchClick() {
        this.getCurrentOfferings({
            [NOTE_SEARCH_FIELDS.KEYWORD]: this.state.searchFields[NOTE_SEARCH_FIELDS.KEYWORD], 
            [NOTE_SEARCH_FIELDS.CURRENT_OFFERINGS]: true
        });
    }

    handleAdvancedSearchClick() {
        const searchFields = {
            ...this.state.searchFields
        };
        this.getCurrentOfferings({
            ...searchFields,
            [NOTE_SEARCH_FIELDS.CURRENT_OFFERINGS]: true
        });
    }

    handleFeaturesColumnClick(note) {
        this.setStateAndHistory({
            currentlySelectedNote: note,
            showNotesFeaturesModal: true
        });
    }

    showCoparisonModal(index) {
        let notesToCompare = [...this.state.selectedNotes[index]];
        let showNotesComparisonModal = true;

        this.setStateAndHistory({
            notesToCompare,
            showNotesComparisonModal
        });
    }

    handleCompareColumnClick(index) {
        return () => {
            if (this.state.selectedNotes[index].length > 0) this.showCoparisonModal(index);
        }
    }

    handleCompareCheckboxClick(index) {
        return (event, note) => {
            const { checked } = event.target;

            let indexedSelectedNotes;
            if (!checked) {
                indexedSelectedNotes = this.state.selectedNotes[index].filter((stateNote) => stateNote.id !== note.id);
            }
            if (checked) { 
                indexedSelectedNotes = [...this.state.selectedNotes[index], note];
            }

            let selectedNotes = [...this.state.selectedNotes];
            selectedNotes[index] = indexedSelectedNotes;

            this.setStateAndHistory({
                selectedNotes
            });
        }
    }

    comparisonCheckBoxesDisabled(index) {            
        if(this.state.selectedNotes[index].length > 2) {
            return true;
        }

        return false;
    }

    getCurrentOfferings(options) {
        this.setStateAndHistory({
            loadingNotes: true
        });
        
        NoteService.getNotes(options).then((currentOfferings) => {
            currentOfferings = NoteService.parseNotesTypes(currentOfferings.results);
            this.setComponentStateAfterNotesCall(currentOfferings, false);
        }).catch(() => {
            this.setComponentStateAfterNotesCall(defaultOfferings, false);
        });
    }

    setComponentStateAfterNotesCall(offerings, loading) {

        let productClass = this.state.searchFields[NOTE_SEARCH_FIELDS.PRODUCT_CLASS];
        let tab = FEE_NOTE_TYPE_TAB_NAME;
        if(productClass?.value === NON_FEE_BASED_NOTE_TYPE) {
            tab = NON_FEE_NOTE_TYPE_TAB_NAME;
        }

        this.setStateAndHistory({
            allOfferings: offerings,
            activeTabName: tab,
            loadingNotes: loading,
            notesToCompare: [],
            selectedNotes: defaultSelectedNotes,
            currentlySelectedNote: null,
            sortOrders: defaultSortOrders
        });
    }

    handleAdvancedSearchExpandedClick() {
        this.setStateAndHistory({
            advancedSearchExpanded: !this.state.advancedSearchExpanded
        });
    }

    handleSearchChange(field) {
        return (value) => {
            this.handleSearchFieldChange(field, value);
        }
    }

    handleSearchFieldChange(field, value) {
        const searchFields = { ...this.state.searchFields };
        searchFields[field] = value;
        this.setStateAndHistory({searchFields});
    }

    setHistoryState(state, replace) {
        if(replace) {
            window.history.replaceState(state, null, ROUTE_PATH_CURRENT_OFFERINGS);
        }
        else {
            window.history.pushState(state, null, ROUTE_PATH_CURRENT_OFFERINGS);
        }
    }

    handleResetButtonClick() {
        this.setStateAndHistory({
            searchFields: defaultSearchFields
        })
    }

    handleHideFeatureModal() {
        this.setStateAndHistory({
            showNotesFeaturesModal: false
        })
    }

    handleHideComparisonModal() {
        this.setStateAndHistory({
            showNotesComparisonModal: false
        })
    }

    handleNotesSectionExpandClick(index) {
        return () => {
            const status = [...this.state.notesSectionExpandedStatus];
            status[index] = !status[index];
            this.setStateAndHistory({
                notesSectionExpandedStatus: status
            });
        };
    }

    handleSort(index) {
        function getNotesFromIndex(allOfferings) {
            switch(index) {
                case 0:
                    return allOfferings.noFeeBasedNotes.parNotes;
                case 1:
                    return allOfferings.noFeeBasedNotes.ppnNotes;
                case 2:
                    return allOfferings.noFeeBasedNotes.irnNotes;
                case 3:
                    return allOfferings.feeBasedNotes.parNotes;
                case 4:
                    return allOfferings.feeBasedNotes.ppnNotes;
                case 5:
                    return allOfferings.feeBasedNotes.irnNotes;
                default:
                    return null;
            }
        }

        function sortFunction(sortColumn, asc) {
            return (firstValue, secondValue) => {
                let comparison = 0;
                if (firstValue[sortColumn] < secondValue[sortColumn]) {
                    comparison = -1;
                }
                if (firstValue[sortColumn] > secondValue[sortColumn]) {
                    comparison = 1;
                }
                if(!asc) {
                    comparison = comparison * -1;
                }

                return comparison;
            }
        }

        return (sortColumn) => {
            let asc = this.state.sortOrders[index][sortColumn];
            let sortOrders = { ...this.state.sortOrders };
            sortOrders[index][sortColumn] = !asc;
            
            let allOfferings = { ...this.state.allOfferings };
            getNotesFromIndex(allOfferings).sort(sortFunction(sortColumn, asc));

            this.setStateAndHistory({
                sortOrders,
                allOfferings
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <StatelessModalComponent
                    id={'notefeaturesModal'}
                    className={'tds-previous-offerings-note-features-modal'}
                    active={this.state.showNotesFeaturesModal}
                    handleHideModal={this.handleHideFeatureModal}
                >
                    {this.state.currentlySelectedNote && <CurrentOfferingsFeaturesModal note={this.state.currentlySelectedNote} />}
                </StatelessModalComponent>

                <StatelessModalComponent
                    ref={this.noteComparisonModal}
                    id={'noteComparisonModal'}
                    className={'tds-previous-offerings-comparison-modal'}
                    active={this.state.showNotesComparisonModal}
                    handleHideModal={this.handleHideComparisonModal}
                >
                    {this.state.notesToCompare.length > 0 && <CurrentOfferingsComparisonModal notes={this.state.notesToCompare} />}
                </StatelessModalComponent>

                <section className="tds-current-offering">
                    <div className="tds-container">
                        <div className="tds-current-offerings-title">
                            <h1><TranslateComponent label={PAGE_TITLE_LABEL_KEY} /></h1>
                        </div>

                        <OfferingsSearchComponent
                            expanded={this.state.advancedSearchExpanded}
                            handleExpandClick={this.handleAdvancedSearchExpandedClick}
                            searchFields={this.state.searchFields}
                            handleSearchChange={this.handleSearchChange}                                 
                            handleBasicSearchClick={this.handleBasicSearchClick}
                            handleAdvancedSearchClick={this.handleAdvancedSearchClick} 
                            handleResetButtonClick={this.handleResetButtonClick}
                        />
 

                        <React.Fragment>
                            <CurrentOfferingTabsComponent
                                handleSingleTabClick={this.handleTabClick}
                                tabs={currentOfferingTabs}
                                defaultTab={this.state.activeTabName}
                                disableTabs={this.state.loadingNotes} />

                            {!this.state.loadingNotes &&
                                <React.Fragment>
                                    <CurrentOfferingsSearchResultsComponent
                                        notes={this.state.allOfferings.noFeeBasedNotes.parNotes}
                                        notesToCompare={this.state.selectedNotes[0]}
                                        noteTypeTitle={<TranslateComponent label={PAR_NOTE_TYPE_LABEL_KEY} />}
                                        noteTypeDescription={<TranslateComponent label={PAR_NOTE_TYPE_DESC_LABEL_KEY} />}
                                        handleFeaturesColumnClick={this.handleFeaturesColumnClick}
                                        handleCompareColumnClick={this.handleCompareColumnClick(0)}
                                        handleCompareCheckboxClick={this.handleCompareCheckboxClick(0)}
                                        handleSort={this.handleSort(0)}
                                        expanded={this.state.notesSectionExpandedStatus[0]}
                                        handleExpandClick={this.handleNotesSectionExpandClick(0)}
                                        disabled={this.comparisonCheckBoxesDisabled(0)}
                                        display={this.state.activeTabName === NON_FEE_NOTE_TYPE_TAB_NAME} />
                                    <CurrentOfferingsSearchResultsComponent
                                        notes={this.state.allOfferings.noFeeBasedNotes.ppnNotes}
                                        notesToCompare={this.state.selectedNotes[1]}
                                        noteTypeTitle={<TranslateComponent label={PPN_NOTE_TYPE_LABEL_KEY} />}
                                        noteTypeDescription={<TranslateComponent label={PPN_NOTE_TYPE_DESC_LABEL_KEY} />}
                                        handleFeaturesColumnClick={this.handleFeaturesColumnClick}
                                        handleCompareColumnClick={this.handleCompareColumnClick(1)}
                                        handleCompareCheckboxClick={this.handleCompareCheckboxClick(1)}
                                        handleSort={this.handleSort(1)}
                                        expanded={this.state.notesSectionExpandedStatus[1]}
                                        handleExpandClick={this.handleNotesSectionExpandClick(1)}
                                        disabled={this.comparisonCheckBoxesDisabled(1)}
                                        display={this.state.activeTabName === NON_FEE_NOTE_TYPE_TAB_NAME} />
                                    <CurrentOfferingsSearchResultsComponent
                                        notes={this.state.allOfferings.noFeeBasedNotes.irnNotes}
                                        notesToCompare={this.state.selectedNotes[2]}
                                        noteTypeTitle={<TranslateComponent label={IRN_NOTE_TYPE_LABEL_KEY} />}
                                        noteTypeDescription={<TranslateComponent label={IRN_NOTE_TYPE_DESC_LABEL_KEY} />}
                                        handleFeaturesColumnClick={this.handleFeaturesColumnClick}
                                        handleCompareColumnClick={this.handleCompareColumnClick(2)}
                                        handleCompareCheckboxClick={this.handleCompareCheckboxClick(2)}
                                        handleSort={this.handleSort(2)}
                                        expanded={this.state.notesSectionExpandedStatus[2]}
                                        handleExpandClick={this.handleNotesSectionExpandClick(2)}
                                        disabled={this.comparisonCheckBoxesDisabled(2)}
                                        display={this.state.activeTabName === NON_FEE_NOTE_TYPE_TAB_NAME} />

                                    <CurrentOfferingsSearchResultsComponent
                                        notes={this.state.allOfferings.feeBasedNotes.parNotes}
                                        notesToCompare={this.state.selectedNotes[3]}
                                        noteTypeTitle={<TranslateComponent label={PAR_NOTE_TYPE_LABEL_KEY} />}
                                        noteTypeDescription={<TranslateComponent label={PAR_NOTE_TYPE_DESC_LABEL_KEY} />}
                                        handleFeaturesColumnClick={this.handleFeaturesColumnClick}
                                        handleCompareColumnClick={this.handleCompareColumnClick(3)}
                                        handleCompareCheckboxClick={this.handleCompareCheckboxClick(3)}
                                        handleSort={this.handleSort(3)}
                                        expanded={this.state.notesSectionExpandedStatus[0]}
                                        handleExpandClick={this.handleNotesSectionExpandClick(0)}
                                        disabled={this.comparisonCheckBoxesDisabled(3)}
                                        display={this.state.activeTabName === FEE_NOTE_TYPE_TAB_NAME} />
                                    <CurrentOfferingsSearchResultsComponent
                                        notes={this.state.allOfferings.feeBasedNotes.ppnNotes}
                                        notesToCompare={this.state.selectedNotes[4]}
                                        noteTypeTitle={<TranslateComponent label={PPN_NOTE_TYPE_LABEL_KEY} />}
                                        noteTypeDescription={<TranslateComponent label={PPN_NOTE_TYPE_DESC_LABEL_KEY} />}
                                        handleFeaturesColumnClick={this.handleFeaturesColumnClick}
                                        handleCompareColumnClick={this.handleCompareColumnClick(4)}
                                        handleCompareCheckboxClick={this.handleCompareCheckboxClick(4)}
                                        handleSort={this.handleSort(4)}
                                        expanded={this.state.notesSectionExpandedStatus[1]}
                                        handleExpandClick={this.handleNotesSectionExpandClick(1)}
                                        disabled={this.comparisonCheckBoxesDisabled(4)}
                                        display={this.state.activeTabName === FEE_NOTE_TYPE_TAB_NAME} />
                                    <CurrentOfferingsSearchResultsComponent
                                        notes={this.state.allOfferings.feeBasedNotes.irnNotes}
                                        notesToCompare={this.state.selectedNotes[5]}
                                        noteTypeTitle={<TranslateComponent label={IRN_NOTE_TYPE_LABEL_KEY} />}
                                        noteTypeDescription={<TranslateComponent label={IRN_NOTE_TYPE_DESC_LABEL_KEY} />}
                                        handleFeaturesColumnClick={this.handleFeaturesColumnClick}
                                        handleCompareColumnClick={this.handleCompareColumnClick(5)}
                                        handleCompareCheckboxClick={this.handleCompareCheckboxClick(5)}
                                        handleSort={this.handleSort(5)}
                                        expanded={this.state.notesSectionExpandedStatus[2]}
                                        handleExpandClick={this.handleNotesSectionExpandClick(2)}
                                        disabled={this.comparisonCheckBoxesDisabled(5)}
                                        display={this.state.activeTabName === FEE_NOTE_TYPE_TAB_NAME} />
                                </React.Fragment>
                            }

                            {
                                this.state.loadingNotes &&
                                <React.Fragment>
                                    <Spinner
                                        marginTop={100}
                                        minHeight={1200}
                                        marginLeft={10} />
                                </React.Fragment>
                            }
                        </React.Fragment>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}
