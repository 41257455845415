import React from 'react';
import TranslateComponent from '../../misc/translate/Translate.component';

export default class CurrentOfferingTabsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.handleTabClick = this.handleTabClick.bind(this);
    }

    handleTabClick(e) {
        this.props.handleSingleTabClick(e);
    }

    render() {
        const { tabs } = this.props;
        return (
            <div className="tds-tab-menu-container tds-current-offering-tab-menu-container">
                <fieldset className="td-multi-single-select-buttons td-multi-single-select-buttons-segmented" >
                    <div className="td-multi-single-select-buttons-group tds-offerning-note-search">
                        {
                            tabs && tabs.map((tab, index) => {
                                return (
                                    <div key={index} className="td-multi-single-select-button-wrapper">
                                        <input
                                            key={index}
                                            type="radio"
                                            name={tab.name}
                                            id={tab.name}
                                            value={tab.value}
                                            checked={this.props.defaultTab === tab.name}
                                            disabled={this.props.disableTabs}
                                            onChange={this.handleTabClick}
                                        />

                                        <label htmlFor={tab.name}>{<TranslateComponent label={tab.label} />}</label>
                                    </div>
                                )
                            })
                        }
                    </div>
                </fieldset>
            </div>
        )
    }
}