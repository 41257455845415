import React, { cloneElement } from "react";

const TabsComponent = (props) => {
  const { value } = props;

  const onTabClick = (idx) => props.onChange(idx);

  const childrenWithHandler = React.Children.map(props.children, (child, idx) =>
    cloneElement(child, {
      onChange: () => onTabClick(idx),
      isActive: value === idx ? true : false,
    })
  );

  return (
    <div className="tds-sn-tabs-container">
      <div className="tds-container">
        <nav>
          <ol>{childrenWithHandler}</ol>
        </nav>
      </div>
    </div>
  );
};

export default TabsComponent;
