import React from 'react';

export default class SpinnerComponent extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="tds-container">
                    <div className="tds-spinner" style={
                            {
                                'minHeight': this.props.minHeight,
                                'marginTop': this.props.marginTop,
                                'marginLeft': this.props.marginLeft
                            }
                        }>

                        <img src={process.env.PUBLIC_URL + '/assets/img/loading-spinner-small.gif'} 
                        alt="Loading...." />
                    </div>

                </div>
                <div className="tds-clear"></div>
            </React.Fragment>
        )
    }
}
