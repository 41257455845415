import React from "react";

const DropdownComponent = (props) => {
  const { className, id, label, options, defaultOption, selection, onSelection } = props;

  const handleSelection = (e) => {
    onSelection(e.target.value);
  }

  return (
    <div className={`tds-sn-dropdown-container ${className || null}`}>
      <div className="form-group td-form-group-padding">
        <label className="tds-sn-visually-hidden" htmlFor={id}>{label}</label>
        <div className="td-select">
          <select className="form-control" id={id} onChange={handleSelection} value={selection}>
            {
              defaultOption && <option value="">{defaultOption}</option>
            }
            {
              options && options.map((option, index) => {

                if(option.label && option.value) {
                  return (
                    <option key={index} value={option.value}>{option.label}</option>
                  )
                } else {
                  return (
                    <option key={index} value={option}>{option}</option>
                  )
                }
              })
            }
          </select>
        </div>
      </div>
    </div>
  );
};

export default DropdownComponent;
